import { FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import SelectField from '../SelectField';
import { Button } from '../Button';
import { monthsList, yearList } from '../../config/consts';
import { NsiStoreContext } from '../../stores/nsi';
import { toJS } from 'mobx';
import { format, isAfter, isBefore, isEqual, isSameDay } from 'date-fns';
import { ru } from 'date-fns/locale';
import { observer } from 'mobx-react-lite';

interface Props {
  handleSelect: (data: any) => void;
  handleClose: () => void;
  initialDate?: any;
}

const YearAndMonthSelectorWithBackendData: FC<Props> = observer(
  ({ handleSelect, handleClose, initialDate }) => {
    const { yearsRazdel, monthsRazdel } = useContext(NsiStoreContext);

    const findCurrentMonth = () => {
      return monthsRazdel[format(new Date(), 'yyyy', { locale: ru })].find(
        (item) =>
          (isAfter(new Date(), new Date(item.periodStart)) &&
            isBefore(new Date(), new Date(item.periodEnd))) ||
          isSameDay(new Date(), new Date(item.periodEnd)) ||
          isSameDay(new Date(), new Date(item.periodStart)),
      );
    };

    const [data, setData] = useState({
      month: initialDate?.selectedMonth ? initialDate.selectedMonth : findCurrentMonth(),
      year: initialDate?.selectedMonth
        ? initialDate?.selectedMonth.appliesToYear
        : findCurrentMonth().appliesToYear,
    });

    const handleChange = useCallback(({ value, type }: { value: any; type: string }) => {
      if (type === 'year') {
        setData((state) => ({
          ...state,
          [type]: value.value,
          month: monthsRazdel[value.value][0],
        }));
      } else {
        setData((state) => ({ ...state, [type]: value }));
      }
    }, []);

    const handleApply = useCallback(() => {
      handleSelect(data);
      handleClose();
    }, [data]);

    return (
      <ComponentContainer>
        <Content>
          <Field>
            <SelectField
              label={'Месяц'}
              value={data.month}
              onChange={(el) => handleChange({ value: el, type: 'month' })}
              list={monthsRazdel[data.year]}
            />
          </Field>
          <Field>
            <SelectField
              label={'Год'}
              value={data.year}
              onChange={(el) => handleChange({ value: el, type: 'year' })}
              list={yearsRazdel}
            />
          </Field>
        </Content>
        <Footer>
          <Button hollow onClick={handleClose}>
            Отмена
          </Button>
          <Button onClick={handleApply}>Применить</Button>
        </Footer>
      </ComponentContainer>
    );
  },
);

const ComponentContainer = styled.div`
  width: 280px;
`;
const Footer = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const Content = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Field = styled.div``;

export default YearAndMonthSelectorWithBackendData;
