import styled from 'styled-components';
import Card from '../../../components/Card';
import Divider from '../../../components/Divider';
import { FC, memo } from 'react';
import IndicatorsModal from './IndicatorsCharts/IndicatorsModal';
import ExecutionIndicators from './ExecutionIndicators';
import IndicatorRating from './IndicatorRating';
import IndicatorSummaryComponent from './IndicatorSummary';

interface Props {
  innerFilterCallbacks?: {
    [key: string]: any;
  };
}

const IndicatorsSummary: FC<Props> = memo(({ innerFilterCallbacks }) => {
  return (
    <>
      <Card>
        <Content>
          <IndicatorSummaryComponent innerFilterCallbacks={innerFilterCallbacks} />
          <Divider margin={'0px'} />
          <ExecutionIndicators innerFilterCallbacks={innerFilterCallbacks} />
          <Divider margin={'0px'} />
          <IndicatorRating innerFilterCallbacks={innerFilterCallbacks} />
        </Content>
      </Card>
      <IndicatorsModal />
    </>
  );
});

const Content = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export default IndicatorsSummary;
