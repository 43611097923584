import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import HorizontalList from '../../../components/HorizontalList';
import Stats from '../../../components/Stats';
import Paper from '../../../components/Paper';
import Top from '../../../components/Top';
import { getProblems, getTopCompanies, getTopIndustriesTaxes } from '../../../actions';
import { AppStoreContext } from '../../../stores/app';
import { observer } from 'mobx-react-lite';

interface Props {
  filter: any;
  hideIndustriesTaxes?: boolean;
  tablesWithoutPeriod?: boolean;
}

const Tables: FC<Props> = observer(
  ({ filter, hideIndustriesTaxes = false, tablesWithoutPeriod = false }) => {
    const { selectedOmsu } = useContext(AppStoreContext);
    const [problemsData, setProblemsData] = useState([]);
    const [topCompaniesData, setTopCompaniesData] = useState([]);
    const [topIndustriesTaxesData, setTopIndustriesTaxesData] = useState([]);
    const [loadingProblemsData, setLoadingProblemsData] = useState(false);
    const [loadingTopCompaniesData, setLoadingTopCompaniesData] = useState(false);
    const [loadingTopIndustriesTaxesData, setLoadingTopIndustriesTaxesData] = useState(false);

    const fetchProblemsData = useCallback(async () => {
      if (selectedOmsu) {
        try {
          setLoadingProblemsData(true);
          const params: any = {
            omsuId: selectedOmsu.id,
          };
          if (!tablesWithoutPeriod) {
            params.periodId = filter.selectedMonth?.id;
          }
          const response = await getProblems(params);
          setProblemsData(response.data?.items || []);
        } catch (e) {
          //
        } finally {
          setLoadingProblemsData(false);
        }
      }
    }, [selectedOmsu, filter.selectedMonth, tablesWithoutPeriod]);

    const fetchTopCompaniesData = useCallback(async () => {
      if (selectedOmsu) {
        try {
          setLoadingTopCompaniesData(true);
          const params: any = {
            omsuId: selectedOmsu.id,
          };
          if (!tablesWithoutPeriod) {
            params.periodId = filter.selectedMonth?.id;
          }
          const response = await getTopCompanies(params);
          setTopCompaniesData(response.data?.items || []);
        } catch (e) {
          //
        } finally {
          setLoadingTopCompaniesData(false);
        }
      }
    }, [selectedOmsu, filter.selectedMonth, tablesWithoutPeriod]);

    const fetchIndicatorsOMSUData = useCallback(async () => {
      if (selectedOmsu) {
        try {
          setLoadingTopIndustriesTaxesData(true);
          const params = {
            omsuId: selectedOmsu.id,
            periodId: filter.selectedMonth?.id,
          };
          const response = await getTopIndustriesTaxes(params);
          setTopIndustriesTaxesData(response.data?.items || []);
        } catch (e) {
          //
        } finally {
          setLoadingTopIndustriesTaxesData(false);
        }
      }
    }, [selectedOmsu, filter.selectedMonth]);

    useEffect(() => {
      fetchProblemsData();
    }, [fetchProblemsData, filter.selectedMonth]);

    useEffect(() => {
      fetchTopCompaniesData();
    }, [fetchTopCompaniesData, filter.selectedMonth]);

    useEffect(() => {
      if (!hideIndustriesTaxes) fetchIndicatorsOMSUData();
    }, [fetchIndicatorsOMSUData, hideIndustriesTaxes, filter.selectedMonth]);

    const problems = useMemo(() => {
      return {
        id: 0,
        title: 'Топ 5 проблем',
        list: problemsData.filter(item => item.name).map((item) => ({
          id: item.id,
          label: item.name,
          place: item.id,
        })),
        loading: loadingProblemsData,
      };
    }, [problemsData, loadingProblemsData]);

    const topCompanies = useMemo(() => {
      return {
        id: 1,
        title: 'Топ 5 компаний',
        subtitle: 'Динамика',
        list: topCompaniesData.filter(item => item.name).map((item) => ({
          id: item.id,
          label: item.name,
          data: <Stats progress={item.dynamics} />,
          place: item.id,
        })),
        loading: loadingTopCompaniesData,
      };
    }, [topCompaniesData, loadingTopCompaniesData]);

    const topIndustriesTaxes = useMemo(() => {
      return {
        id: 2,
        title: 'Топ 5 отраслей по налогам',
        subtitle: '%',
        list: topIndustriesTaxesData.filter(item => item.name).map((item) => ({
          id: item.id,
          label: item.name,
          data: item.share,
          place: item.id,
        })),
        loading: loadingTopIndustriesTaxesData,
      };
    }, [topIndustriesTaxesData, loadingTopIndustriesTaxesData]);

    const list = useMemo(() => {
      const arr = [];
      arr.push(problems, topCompanies);
      if (!hideIndustriesTaxes) {
        arr.push(topIndustriesTaxes);
      }
      return arr;
    }, [problems, topCompanies, topIndustriesTaxes, hideIndustriesTaxes]);

    return (
      <HorizontalList list={list} mobileItemsInRow={2}>
        {(item) => (
          <Paper>
            <Top {...item} />
          </Paper>
        )}
      </HorizontalList>
    );
  },
);

export default Tables;
