import React, { useMemo } from 'react';
import { preparePieChartValues } from '../../utils/prepareChartValues';
import { prepareNumber } from '../../utils/prepareNumber';
import { FC } from 'react';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

interface Item {
  name: string;
  y: number;
  color?: any;
}

interface Props {
  data: Item[];
  onClick?: any;
  total?: number;
  width?: number;
}

const PieChart: FC<Props> = ({ data: dataProps, onClick, total: totalProps, width = 300 }) => {
  const data = useMemo(() => preparePieChartValues(dataProps), [dataProps]);

  const chartOptions = useMemo(() => {
    const total = totalProps || data.reduce((prev, curr) => prev + curr.count, 0);
    return {
      chart: {
        margin: [-70, 0, -20, 0],
        // plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: 120,
        width: width,
      },
      // pane: {
      //   innerSize: '100%',
      //   background: {
      //     backgroundColor: '#000',
      //   },
      // },
      credits: {
        enabled: false,
      },
      title: {
        text: prepareNumber(total),
        align: 'center',
        verticalAlign: 'middle',
        y: 65,
        style: {
          fontSize: 24,
          fontFamily: 'Montserrat Bold',
          color: '#172B75',
        },
      },
      tooltip: {
        enabled: false,
        formatter: function () {
          return `${this.point.options.name}: <b>${prepareNumber(this.point.options.count)}</b>`;
        },
      },
      plotOptions: {
        pie: {
          // allowPointSelect: true,
          cursor: 'pointer',
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '100%'],
          size: '100%',
        },
        series: {
          point: {
            events: {
              click: function () {
                onClick && onClick(this);
              },
            },
          },
        },
      },
      series: [
        {
          type: 'pie',
          innerSize: '60%',
          data,
          dataLabels: {
            enabled: true,
            style: {
              fontSize: 12,
              fontFamily: 'Roboto Condensed Regular',
              color: 'rgba(0, 0, 0, 0.3)',
            },
            connectorColor: '#BDBDBD',
            crop: false,
            distance: 5,
          },
        },
        {
          type: 'pie',
          innerSize: '60%',
          data,
          dataLabels: {
            crop: false,
            overflow: 'none',
            formatter: function () {
              return prepareNumber(this.point.options.count);
            },
            color: '#ffffff',
            distance: -15,
            style: {
              fontSize: 20,
              fontWeight: 700,
              fontFamily: 'Montserrat Semibold',
              stroke: '#172B75',
              strokeWidth: 4,
              strokeLinecap: 'round',
              paintOrder: 'stroke fill',
              // textShadow:
              //   '1px 0 0 #172B75, -1px 0 0 #172B75, 0 1px 0 #172B75, 0 -1px 0 #172B75, 1px 1px #172B75, -1px -1px 0 #172B75, 1px -1px 0 #172B75, -1px 1px 0 #172B75',
              letterSpacing: 2,
            },
          },
        },
      ],
    };
  }, [data, totalProps, onClick]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default PieChart;
