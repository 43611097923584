const num = 1212300;

export const emptyData = [];

export const dataExample = [
  {
    title: 'Переселение граждан из аварийного жилищного фонда в Московской области млрд руб.',
    totalPlan: num.toLocaleString(),
    FBPlan: num.toLocaleString(),
    OBPlan: num.toLocaleString(),
    totalExecution: num.toLocaleString(),
    FBExecution: num.toLocaleString(),
    OBExecution: num.toLocaleString(),
    totalPercent: 100,
    FBPercent: 100,
    OBPercent: 100,
    totalPercent1: 100,
    FBPercent1: 100,
    OBPercent1: 100,
    children: [
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
      },
    ],
    options: {
      order: true,
    },
  },
  {
    title: 'Всего',
    totalPlan: num.toLocaleString(),
    FBPlan: num.toLocaleString(),
    OBPlan: num.toLocaleString(),
    totalExecution: num.toLocaleString(),
    totalPercent: 100,
    options: {
      order: true,
      styles: {
        title: {
          color: 'pink',
        },
        totalPlan: {
          color: 'blue',
        },
      },
      colspan: {
        totalExecution: 3,
        totalPercent: 6,
      },
    },
  },
  {
    title: 'Всего',
    totalPlan: num.toLocaleString(),
    FBPlan: num.toLocaleString(),
    OBPlan: num.toLocaleString(),
    totalExecution: num.toLocaleString(),
    totalPercent: 100,
    options: {
      order: true,
      styles: {
        title: {
          color: 'pink',
        },
        totalPlan: {
          color: 'blue',
        },
      },
      colspan: {
        totalExecution: 3,
        totalPercent: 6,
      },
    },
  },
  {
    title: 'Всего',
    totalPlan: num.toLocaleString(),
    FBPlan: num.toLocaleString(),
    OBPlan: num.toLocaleString(),
    totalExecution: num.toLocaleString(),
    totalPercent: 100,
    options: {
      order: true,
      styles: {
        title: {
          color: 'pink',
        },
        totalPlan: {
          color: 'blue',
        },
      },
      colspan: {
        totalExecution: 3,
        totalPercent: 6,
      },
    },
  },
  {
    title: 'Всего',
    totalPlan: num.toLocaleString(),
    FBPlan: num.toLocaleString(),
    OBPlan: num.toLocaleString(),
    totalExecution: num.toLocaleString(),
    totalPercent: 100,
    options: {
      order: true,
      styles: {
        title: {
          color: 'pink',
        },
        totalPlan: {
          color: 'blue',
        },
      },
      colspan: {
        totalExecution: 3,
        totalPercent: 6,
      },
    },
  },
  {
    title: 'Всего',
    totalPlan: num.toLocaleString(),
    FBPlan: num.toLocaleString(),
    OBPlan: num.toLocaleString(),
    totalExecution: num.toLocaleString(),
    FBExecution: num.toLocaleString(),
    OBExecution: num.toLocaleString(),
    totalPercent: 100,
    FBPercent: 100,
    OBPercent: 100,
    totalPercent1: 100,
    FBPercent1: 100,
    OBPercent1: 100,
    children: [
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
      },
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
      },
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
        options: {
          styles: {
            totalPlan: {
              color: '#000',
              'background-color': 'pink',
            },
          },
        },
      },
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
      },
    ],
    options: {
      autoOpen: true,
      order: true,
    },
  },
  {
    title: 'Всего',
    totalPlan: num.toLocaleString(),
    FBPlan: num.toLocaleString(),
    OBPlan: num.toLocaleString(),
    totalExecution: num.toLocaleString(),
    FBExecution: num.toLocaleString(),
    OBExecution: num.toLocaleString(),
    totalPercent: 100,
    FBPercent: 100,
    OBPercent: 100,
    totalPercent1: 100,
    FBPercent1: 100,
    OBPercent1: 100,
    children: [
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
      },
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
      },
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
        options: {
          styles: {
            totalPlan: {
              color: '#000',
              'background-color': 'pink',
            },
          },
        },
      },
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
      },
    ],
    options: {
      autoOpen: true,
      order: true,
    },
  },
  {
    title: 'Всего',
    totalPlan: num.toLocaleString(),
    FBPlan: num.toLocaleString(),
    OBPlan: num.toLocaleString(),
    totalExecution: num.toLocaleString(),
    FBExecution: num.toLocaleString(),
    OBExecution: num.toLocaleString(),
    totalPercent: 100,
    FBPercent: 100,
    OBPercent: 100,
    totalPercent1: 100,
    FBPercent1: 100,
    OBPercent1: 100,
    children: [
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
      },
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
      },
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
        options: {
          styles: {
            totalPlan: {
              color: '#000',
              'background-color': 'pink',
            },
          },
        },
      },
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
      },
    ],
    options: {
      autoOpen: true,
      order: true,
    },
  },
  {
    title: 'Всего',
    totalPlan: num.toLocaleString(),
    FBPlan: num.toLocaleString(),
    OBPlan: num.toLocaleString(),
    totalExecution: num.toLocaleString(),
    FBExecution: num.toLocaleString(),
    OBExecution: num.toLocaleString(),
    totalPercent: 100,
    FBPercent: 100,
    OBPercent: 100,
    totalPercent1: 100,
    FBPercent1: 100,
    OBPercent1: 100,
    children: [
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
      },
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
      },
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
        options: {
          styles: {
            totalPlan: {
              color: '#000',
              'background-color': 'pink',
            },
          },
        },
      },
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
      },
    ],
    options: {
      autoOpen: true,
      order: true,
    },
  },
  {
    title: 'Всего',
    totalPlan: num.toLocaleString(),
    FBPlan: num.toLocaleString(),
    OBPlan: num.toLocaleString(),
    totalExecution: num.toLocaleString(),
    FBExecution: num.toLocaleString(),
    OBExecution: num.toLocaleString(),
    totalPercent: 100,
    FBPercent: 100,
    OBPercent: 100,
    totalPercent1: 100,
    FBPercent1: 100,
    OBPercent1: 100,
    children: [
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
      },
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
      },
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
        options: {
          styles: {
            totalPlan: {
              color: '#000',
              'background-color': 'pink',
            },
          },
        },
      },
      {
        title: 'Всего',
        totalPlan: num.toLocaleString(),
        FBPlan: num.toLocaleString(),
        OBPlan: num.toLocaleString(),
        totalExecution: num.toLocaleString(),
        FBExecution: num.toLocaleString(),
        OBExecution: num.toLocaleString(),
        totalPercent: 100,
        FBPercent: 100,
        OBPercent: 100,
        totalPercent1: 100,
        FBPercent1: 100,
        OBPercent1: 100,
      },
    ],
    options: {
      autoOpen: true,
      order: true,
    },
  },
];
