import styled from 'styled-components';
import Contact from '../../../../components/Contact';
import { Modal } from '../../../../components/Modal';
import Section from '../../../../components/Section';
import { connectModal } from '../../../../hocs/Modal';
import PlanModalHeader from './PlanModalHeader';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import TextField from '../../../../components/TextField';
import Files from '../../../../components/Files';
import { StatusType } from '../../../../components/Status';

const PlanModal = connectModal('plan-modal')(
  ({
    handleHide,
    stage,
    status,
    title,
    roadmapCurrent,
    roadmapTotal,
    responsible,
    planDate,
    planEndDate,
    factResult,
    factDate,
    planResult,
    resonNotPerformance,
    correctiveAction,
    deadlineElimination,
    files,
  }) => {
    return (
      <Modal
        title={''}
        onClose={handleHide}
        header={
          <PlanModalHeader
            stage={stage}
            status={status}
            roadmapCurrent={roadmapCurrent}
            roadmapTotal={roadmapTotal}
          />
        }
      >
        <Content>
          <Title>{title}</Title>
          {responsible.length > 0 && (
            <Section title={'Ответственные'}>
              <List>
                {responsible.map((item) => (
                  <ListItem key={item.id}>
                    <Contact {...item} />
                  </ListItem>
                ))}
              </List>
            </Section>
          )}
          {status === StatusType.PROGRESS || status === StatusType.PLANNED ? (
            <Section title={'Плановая информация'}>
              {files.length > 0 && (
                <Row>
                  <Files title={'Вложения'} files={files} />
                </Row>
              )}
              <Row>
                <List>
                  <ListItem>
                    <TextField
                      label={'Планируемый период проведения'}
                      value={`${planDate} - ${planEndDate}`}
                    />
                  </ListItem>
                </List>
              </Row>
            </Section>
          ) : (
            <Section title={'Результат выполнения'}>
              <List>
                {factDate && (
                  <ListItem>
                    <TextField label={'Фактическая дата завершения'} value={factDate} />
                  </ListItem>
                )}
                {factResult && (
                  <ListItem>
                    <TextField label={'Фактический результат выполнения'} value={factResult} />
                  </ListItem>
                )}
                {resonNotPerformance && (
                  <ListItem>
                    <TextField label={'Причины невыполнения'} value={resonNotPerformance} />
                  </ListItem>
                )}

                {/* Поправить прочерк, как появятся поля в модели с бека */}
                <ListItem>
                  <TextField
                    label={'Причины невыполнения (ручной ввод)'}
                    value={'-'}
                  />
                </ListItem>

                {correctiveAction && (
                  <ListItem>
                    <TextField label={'Меры по устранению'} value={correctiveAction} />
                  </ListItem>
                )}

                {/* Поправить прочерк, как появятся поля в модели с бека */}
                <ListItem>
                  <TextField
                    label={'Меры по устранению (ручной ввод)'}
                    value={'-'}
                  />
                </ListItem>

                <ListItem>
                  <TextField label={'Срок устранения'} value={deadlineElimination} />
                </ListItem>
              </List>
              {files.length > 0 && (
                <Row>
                  <Files title={'Вложения'} files={files} />
                </Row>
              )}
              {((planDate && planEndDate) || planResult) && (
                <Row>
                  <Section title={'Плановая информация'}>
                    <List>
                      {planDate && planEndDate && (
                        <ListItem>
                          <TextField
                            label={'Планируемый период проведения'}
                            value={`${planDate} - ${planEndDate}`}
                          />
                        </ListItem>
                      )}
                      {planResult && (
                        <ListItem>
                          <TextField label={'Планируемый результат'} value={planResult} />
                        </ListItem>
                      )}
                    </List>
                  </Section>
                </Row>
              )}
            </Section>
          )}
        </Content>
      </Modal>
    );
  },
);

const Title = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-family: ${({ theme }) => theme.fonts.montserrat.semibold};
  margin-bottom: 24px;
`;
const List = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px 24px;
  width: 100%;
`;
const ListItem = styled.div`
  flex: 0 0 calc(50% - 12px);
`;
const Content = styled.div`
  padding: 0 24px 24px 24px;
  width: 100%;
`;

const Row = styled.div`
  margin-top: 12px;
`;

export default PlanModal;
