import { FC } from 'react';

const CloseIcon: FC = () => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7 7.7L1.75 12.95C1.66667 13.05 1.55 13.1083 1.4 13.125C1.26667 13.125 1.15 13.0667 1.05 12.95C0.933334 12.85 0.875 12.7333 0.875 12.6C0.875 12.4667 0.933334 12.35 1.05 12.25L6.3 7L1.05 1.75C0.950001 1.66667 0.891667 1.55833 0.875 1.425C0.875 1.275 0.933334 1.15 1.05 1.05C1.15 0.933332 1.26667 0.874999 1.4 0.874999C1.53333 0.874999 1.65 0.933332 1.75 1.05L7 6.3L12.25 1.05C12.3333 0.949999 12.4417 0.899999 12.575 0.899999C12.725 0.883332 12.85 0.933332 12.95 1.05C13.0667 1.15 13.125 1.26667 13.125 1.4C13.125 1.53333 13.0667 1.65 12.95 1.75L7.7 7L12.95 12.25C13.05 12.3333 13.1 12.45 13.1 12.6C13.1167 12.7333 13.0667 12.85 12.95 12.95C12.85 13.0667 12.7333 13.125 12.6 13.125C12.4667 13.125 12.35 13.0667 12.25 12.95L7 7.7Z'
        fill='black'
        fillOpacity='0.56'
      />
    </svg>
  );
};

export default CloseIcon;
