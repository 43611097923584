import styled from 'styled-components';
import { FC } from 'react';
import LegendItem from './LegendItem';

export interface Item {
  id: number;
  color: string;
  label: string;
}

interface Props {
  list: Item[];
}

const Legend: FC<Props> = ({ list }) => {
  return (
    <ComponentContainer>
      {list.map((item) => (
        <LegendItem key={item.id} {...item} />
      ))}
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  > div {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export default Legend;
