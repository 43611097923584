import styled from 'styled-components';
import React, { FC, ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';

interface Props {
  children: ReactElement | ReactNode;
}

export const PageWrapper: FC<Props> = observer(({ children }) => {
  return <Content>{children}</Content>;
});

export const Content = styled.div`
  padding: 24px 24px 56px 24px;
  position: relative;
`;
