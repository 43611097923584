import styled from 'styled-components';
import { FC, ReactElement } from 'react';
import { font } from '../styled/mixins/fonts';
import CardTable from './CardTable';

export interface Item {
  id: number;
  label: string;
  amount?: number | Array<number>;
  isBold?: boolean;
  details?: {
    id: number;
    label: string;
    amount?: number | Array<number>;
    isBold?: boolean;
  }[];
}

interface Props {
  title: string;
  headerComponent?: ReactElement;
  subtitle: string | ReactElement;
  data: Item[];
}

const BudgetCard: FC<Props> = ({ title, headerComponent, subtitle, data }) => {
  return (
    <ComponentContainer>
      <CardHeader>
        <Side>
          <Title>{title}</Title>
          {headerComponent && <Additional>{headerComponent}</Additional>}
        </Side>
        <Subtitle>{subtitle}</Subtitle>
      </CardHeader>
      <CardContent>
        {data.map((item, idx) => (
          <CardTable key={item.id} lastRow={data.length === idx + 1} {...item} />
        ))}
      </CardContent>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  padding: 8px 0 16px 0;
`;
const CardHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  justify-content: space-between;
  margin-bottom: 8px;
`;
const CardContent = styled.div``;
const Title = styled.div`
  ${({ theme }) =>
    font({
      family: theme.fonts.montserrat.semibold,
      size: theme.fonts.sizes.xmd,
      lineHeight: '34px',
    })};
`;
const Subtitle = styled.div`
  ${({ theme }) =>
    font({
      family: theme.fonts.robotoCondensed.regular,
      size: theme.fonts.sizes.sm,
      color: theme.colors.dark,
    })};
`;
const Additional = styled.div`
  margin-left: 12px;
`;
const Side = styled.div`
  display: flex;
  align-items: flex-end;
`;

export default BudgetCard;
