export enum CalendarType {
  ALL_YEARS,
  CURRENT_YEAR,
  NEXT_YEAR,
  SPECIFIC_MONTH,
  SPECIFIC_DATE,
}

export const calendarConfig = [
  {
    id: 0,
    label: 'Все года',
    value: CalendarType.ALL_YEARS,
  },
  {
    id: 1,
    label: 'В текущем году',
    value: CalendarType.CURRENT_YEAR,
  },
  {
    id: 2,
    label: 'В следующем году',
    value: CalendarType.NEXT_YEAR,
  },
  {
    id: 3,
    label: 'В определенный месяц',
    value: CalendarType.SPECIFIC_MONTH,
  },
  {
    id: 4,
    label: 'В определенную дату',
    value: CalendarType.SPECIFIC_DATE,
  },
];
