const DragIcon = () => (
  <svg width='16' height='6' viewBox='0 0 16 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1 1.75C0.783334 1.75 0.6 1.68333 0.45 1.55C0.316667 1.4 0.25 1.21667 0.25 0.999999C0.25 0.783333 0.316667 0.608333 0.45 0.475C0.6 0.324999 0.783334 0.249999 1 0.249999H15C15.2167 0.249999 15.3917 0.324999 15.525 0.475C15.675 0.608333 15.75 0.783333 15.75 0.999999C15.75 1.21667 15.675 1.4 15.525 1.55C15.3917 1.68333 15.2167 1.75 15 1.75H1ZM1 5.75C0.783334 5.75 0.6 5.68333 0.45 5.55C0.316667 5.4 0.25 5.21667 0.25 5C0.25 4.78333 0.316667 4.60833 0.45 4.475C0.6 4.325 0.783334 4.25 1 4.25H15C15.2167 4.25 15.3917 4.325 15.525 4.475C15.675 4.60833 15.75 4.78333 15.75 5C15.75 5.21667 15.675 5.4 15.525 5.55C15.3917 5.68333 15.2167 5.75 15 5.75H1Z'
      fill='black'
    />
  </svg>
);

export default DragIcon;
