import React, { FC } from 'react';
import styled from 'styled-components';
import { font } from '../styled/mixins/fonts';
import LoaderAnimatedIcon from './LoaderAnimatedIcon';

interface Props {
  alternative?: boolean;
}

const Loader: FC<Props> = ({ alternative }) => {
  return (
    <ComponentContainer>
      <LoaderAnimatedIcon alternative={alternative} />
      <Label type={alternative}>Загрузка</Label>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 100%;
`;
const Label = styled.div<{ type: boolean }>`
  margin-top: 8px;
  ${({ type, theme }) => font({ color: type ? '#fff' : theme.colors.primary })}
`;

export { Loader };
