export const preparePieChartValues = (dataParam) => {
  const data = dataParam.filter((el) => el.y !== undefined && !isNaN(el.y));
  let sum = 0;
  const arr = data.map((el) => {
    sum += el.y;
    return el.y || 1;
  });
  const min = Math.min(...arr);
  const diff = min / sum;
  const minSeg = 0.25;
  return data.map((el) => {
    return {
      ...el,
      count: el.y,
      y: el.y ? Math.pow(el.y, Math.log(minSeg) / (Math.log(diff) || 1)) : 1,
    };
  });
};

export const prepareRadialChartValues = (rawData) => {
  const data = rawData.map((el) => ({
    ...el,
    data: el.data.filter((el) => el.y !== undefined && !isNaN(el.y)),
  }));
  let sum = 0;
  const arr = [];
  data.forEach((el) => {
    el.data.forEach((item) => {
      sum += item.y;
      arr.push(item.y || 1);
    });
  });

  const min = Math.min(...arr);
  const diff = min / sum;
  const minSeg = 0.25;
  return data.map((el) => {
    return {
      ...el,
      data: el.data.map((item) => {
        return {
          ...item,
          count: item.y,
          y: item.y ? Math.pow(item.y, Math.log(minSeg) / Math.log(diff)) : 1,
        };
      }),
    };
  });
};
