import { FC } from 'react';
import styled from 'styled-components';
import EmailIcon from '../../assets/icons/EmailIcon';
import Icon from '../Icon';

interface Props {
  name: string;
  contact: string;
}

const Contact: FC<Props> = ({ name, contact }) => {
  return (
    <ContactWrapper href={`mailto:${contact}`}>
      <Name>{name}</Name>
      <Icon opacity={0.56} size={18}>
        <EmailIcon />
      </Icon>
    </ContactWrapper>
  );
};

const ContactWrapper = styled.a`
  color: #000;
  text-decoration: none;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 6px 12px 6px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Name = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.montserrat.medium};
  flex: 1 1 auto;
`;

export default Contact;
