import { PageWrapper } from '../../components/styled/PageWrapper';
import Accordion from '../../components/Accordion';
import AgendaRatingAddition from './AgendaRatingAddition';
import AgendaRating from './AgendaRating';
import AgendaBudgetAddition from './AgendaBudgetAddition';
import AgendaBudget from './AgendaBudget';
import AgendaFinance from './AgendaFinance';
import AgendaEconomics from './AgendaEconomics';
import { useContext, useEffect, useMemo } from 'react';
import { useFilter } from '../../hooks/useFilter';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import {
  initialBudgetFilterFilterValues,
  initialEconomicsFilterValues,
  initialFinanceAdditionFilterFilterValues,
} from './consts';
import AgendaRatingCollapsedContent from './AgendaRatingCollapsedContent';
import AgendaBudgetCollapsedContent from './AgendaBudgetCollapsedContent';
import AgendaEconomicsCollapsedContent from './AgendaEconomicsCollapsedContent';
import { NsiStoreContext } from '../../stores/nsi';
import { observer } from 'mobx-react-lite';
import CityDistrictHead from '../Objects/SummaryInfo/CityDistrictHead';
import Card from '../../components/Card';
import styled from 'styled-components';
import { AppStoreContext } from '../../stores/app';
import { toJS } from 'mobx';
import { Loader } from '../../components/Loader';

const AgendaPage = observer(() => {
  const { filter: budgetFilter, setFilterCallback: setBudgetFilterCallback } = useFilter({
    initialFilterValues: { ...initialBudgetFilterFilterValues },
    name: 'agendaBudgetFilters',
    dateFields: ['fromDate', 'toDate'],
  });

  const {
    filter: financeAdditionFilter,
    setFilterCallback: setFinanceFilterCallback,
    sorting,
    setSortingCallback,
  } = useFilter({
    initialFilterValues: { ...initialFinanceAdditionFilterFilterValues },
    name: 'agendaFinanceAddition',
    sortingName: 'agendaFinanceAdditionSorting',
    dateFields: ['fromDate', 'toDate'],
  });

  const { filter: economicsFilter, setFilterCallback: setEconomicsFilterCallback } = useFilter({
    initialFilterValues: { ...initialEconomicsFilterValues },
    name: 'agendaEconomicsFilters',
    dateFields: ['fromDate', 'toDate'],
  });

  const { currentMonth } = useContext(NsiStoreContext);
  const { selectedOmsu } = useContext(AppStoreContext);

  useEffect(() => {
    if (currentMonth) {
      if (!budgetFilter.selectedMonth) {
        setBudgetFilterCallback('selectedMonth', currentMonth);
      }
      if (!financeAdditionFilter.selectedMonth) {
        setFinanceFilterCallback('selectedMonth', currentMonth);
      }
      if (!economicsFilter.selectedMonth) {
        setEconomicsFilterCallback('selectedMonth', currentMonth);
      }
    }
  }, [currentMonth]);

  const accordionConfig = useMemo(() => {
    return [
      {
        id: 0,
        header: {
          title: `Рейтинг на ${format(new Date(), 'dd MMMM yyyy', { locale: ru })}`,
          addition: <AgendaRatingAddition />,
          collapsedContent: <AgendaRatingCollapsedContent />,
        },
        content: <AgendaRating />,
      },
      {
        id: 1,
        header: {
          title: 'Бюджет',
          addition: (
            <AgendaBudgetAddition
              filter={budgetFilter}
              setFilterCallback={setBudgetFilterCallback}
            />
          ),
          collapsedContent: <AgendaBudgetCollapsedContent filter={budgetFilter} />,
          collapsedAdditionVisible: false,
        },
        content: <AgendaBudget filter={budgetFilter} />,
      },
      {
        id: 2,
        header: {
          title: 'Финподдержка из бюджета МО',
          addition: (
            <AgendaBudgetAddition
              filter={financeAdditionFilter}
              setFilterCallback={setFinanceFilterCallback}
            />
          ),
          collapsedContent: <AgendaEconomicsCollapsedContent filter={financeAdditionFilter} />,
          collapsedAdditionVisible: false,
        },
        content: (
          <AgendaFinance
            filter={financeAdditionFilter}
            sorting={sorting}
            setSortingCallback={setSortingCallback}
          />
        ),
      },
      {
        id: 3,
        header: {
          title: 'Экономика',
          addition: (
            <AgendaBudgetAddition
              filter={economicsFilter}
              setFilterCallback={setEconomicsFilterCallback}
            />
          ),
          collapse: false,
        },
        content: <AgendaEconomics filter={economicsFilter} />,
      },
    ];
  }, [budgetFilter, financeAdditionFilter, economicsFilter, sorting]);

  if (selectedOmsu?.idx === 0) return <Loader />;

  return (
    <PageWrapper>
      {selectedOmsu && (
        <CardWrapper>
          <Card>
            <CityDistrictHead />
          </Card>
        </CardWrapper>
      )}
      <Accordion items={accordionConfig} />
    </PageWrapper>
  );
});

const CardWrapper = styled.div`
  margin-bottom: 32px;
`;

export default AgendaPage;
