import styled from 'styled-components';
import LineChart from '../../../components/LineChart';
import ComplexTable from '../../../components/ComplexTable';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IndicatorStoreContext } from '../../../stores/indicator';
import { getIndicatorPeriodStatistic } from '../../../actions';
import { get, isNumber, unionBy } from 'lodash';
import Stats from '../../../components/Stats';
import { theme } from '../../../styles/theme';
import { Loader } from '../../../components/Loader';
import Card from '../../../components/Card';
import { prepareNumber } from '../../../utils/prepareNumber';

interface Props {
  indicatorId: string;
  precision?: number;
}

interface StatisticItem {
  indicatorValues: {
    indicatorValue: number;
    periodName: string;
    periodId: string;
    periodLinkId: string;
  }[];
  year: number;
}
interface Data {
  currentYearStatistic: StatisticItem;
  lastYearStatistic: StatisticItem;
  currentYearStatisticPlan: StatisticItem;
  changes: {
    indicatorValue: number;
    periodName: string;
    periodId: string;
    periodLinkId: string;
  }[];
}

const IndicatorChartStats: FC<Props> = observer(({ indicatorId, precision }) => {
  const { indicatorHeaderFilters } = useContext(IndicatorStoreContext);

  const [data, setData] = useState<Data>();
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const tableRef: any = useRef();

  useEffect(() => {
    if (indicatorHeaderFilters?.period !== undefined) {
      const fetchData = async () => {
        setDataLoading(true);
        try {
          const response = await getIndicatorPeriodStatistic({
            indicatorId,
            periodId: indicatorHeaderFilters.period?.id,
          });
          setData(response.data);
        } catch (e) {
          setData(undefined);
        } finally {
          setDataLoading(false);
        }
      };
      fetchData();
    }
  }, [indicatorHeaderFilters.period]);

  const config = useMemo(() => {
    let chartConfig;
    let tableFields = [];
    let tableData = [];
    
    if (data) {
      const allPeriods = unionBy(
        data?.currentYearStatistic?.indicatorValues,
        data?.lastYearStatistic?.indicatorValues,
        'periodLinkId',
      ).map((item) => ({
        ...item,
      }));

      const allValues = [
        ...(data?.currentYearStatistic?.indicatorValues || []),
        ...(data?.lastYearStatistic?.indicatorValues || []),
      ].map((itm) => itm.indicatorValue);

      const maxDataLevel = Math.max(...allValues);
      const minDataLevel = Math.min(...allValues);
      const maxChartLevel = Math.ceil(maxDataLevel / 10) * 10 + 10;
      const minChartLevel = minDataLevel >= 0 ? 0 : Math.ceil(minDataLevel / 10) * 10 - 10;
        
      chartConfig = {
        chart: {
          // width: 88 * 12,
        },
        title: {
          text: '',
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
        },
        xAxis: {
          categories: Array(allPeriods.length).fill(''),
          lineColor: 'rgba(0, 0, 0, 0.12)',
          gridLineWidth: 1,
          gridLineDashStyle: 'dash',
          tickmarkPlacement: 'on',
        },
        yAxis: {
          min: minChartLevel,
          max: maxChartLevel,
          title: null,
          labels: {
            style: {
              fontFamily: 'Roboto Condensed Regular',
              fontSize: 12,
              opacity: 0.3,
            },
            formatter: (item) =>
              item.value >= 1000000
                ? `${item.value / 1000000} млн.`
                : item.value >= 1000
                ? `${item.value / 1000} тыс.`
                : item.value,
          },
          lineWidth: 1,
          lineColor: 'rgba(0, 0, 0, 0.12)',
          gridLineWidth: 0,
          plotLines: [
            {
              width: 2,
              value: get(data, 'currentYearStatisticPlan.indicatorValues[0].indicatorValue'),
              dashStyle: 'dash',
              color: 'rgba(0, 0, 0, 0.2)',
            },
          ],
        },
        tooltip: {
          formatter: function() {
              return `<span style="color: ${this.point.color}; fill: ${this.point.color};">●</span> ${this?.point?.options?.periodName}: <b>${prepareNumber(this.y, precision)}</b>`;
          }
        },
        series: [
          {
            name: data?.lastYearStatistic ? `${data?.currentYearStatistic?.year} г.` : 'факт',
            data: data?.currentYearStatistic?.indicatorValues.map((itm) => ({
              y: itm.indicatorValue,
              periodName: itm.periodName 
            })),
            color: '#A3A9D4',
            marker: {
              lineWidth: 2,
              lineColor: '#A3A9D4',
              fillColor: 'white',
              symbol: 'circle',
            },
          },
          ...(data?.lastYearStatistic
            ? [
                {
                  name: `${data?.lastYearStatistic?.year} г.`,
                  data: data?.lastYearStatistic?.indicatorValues.map((itm) => ({
                    y: itm.indicatorValue,
                    periodName: itm.periodName 
                  })),
                  color: '#172B75',
                  marker: {
                    lineWidth: 2,
                    lineColor: '#172B75',
                    fillColor: 'white',
                    symbol: 'circle',
                  },
                },
              ]
            : []),
          {
            // Series that mimics the plot line
            color: 'rgba(0, 0, 0, 0.2)',
            name: data?.lastYearStatistic ? `План ${data?.currentYearStatisticPlan?.year} г.` : 'план',
            dashStyle: 'dash',
            marker: {
              enabled: false,
            },
          },
        ],
      };

      tableFields = [
        {
          id: 0,
          label: '',
          field: `title`,
          width: '7.5%',
          sorting: false,
          options: {
            'text-align': 'left',
            padding: '8px 6px 8px 0',
            'border-bottom': 'none',
            color: theme.colors.dark,
            'font-family': theme.fonts.robotoCondensed.regular,
          },
          ignoreHandleNumber: true,
        },
        ...allPeriods.map((item, idx) => ({
          id: idx + 1,
          label: item.periodName,
          field: `period${idx}`,
          width: `${91 / allPeriods.length}%`,
          sorting: false,
          options: {
            'border-bottom': 'none',
          },
          ignoreHandleNumber: true,
        })),
      ];

      tableData = [
        {
          title: 'Изменение',
          ...allPeriods.reduce((acc, item, idx) => {
            const value = data.changes.find((itm) => itm.periodLinkId === item.periodLinkId)?.indicatorValue;
            return {
              ...acc,
              [`period${idx}`]: isNumber(value) ? (
                <StatsCellContainer>
                  <Stats progress={value} definition={''} precision={precision} />
                </StatsCellContainer>
              ) : (
                '-'
              ),
            };
          }, {}),
        },
        {
          title: data?.lastYearStatistic ? `${data?.currentYearStatistic?.year} г.` : ' ',
          ...allPeriods.reduce((acc, item, idx) => {
            const value = data.currentYearStatistic.indicatorValues.find(
              (itm) => itm.periodLinkId === item.periodLinkId,
            )?.indicatorValue;
            return {
              ...acc,
              [`period${idx}`]: isNumber(value) ? prepareNumber(value, precision) : '-',
            };
          }, {}),
        },
        ...(data?.lastYearStatistic
          ? [
              {
                title: `${data?.lastYearStatistic?.year} г.`,
                ...allPeriods.reduce((acc, item, idx) => {
                  const value = data?.lastYearStatistic?.indicatorValues.find(
                    (itm) => itm.periodLinkId === item.periodLinkId,
                  )?.indicatorValue;
                  return {
                    ...acc,
                    [`period${idx}`]: isNumber(value) ? prepareNumber(value, precision) : '-',
                  };
                }, {}),
              },
            ]
          : []),
      ];
    }

    return {
      chartConfig,
      tableFields: [[], [...tableFields]],
      tableData,
    };
  }, [data]);

  const [chartWidth, setChartWidth] = useState(0);
  const timer: any = useRef();

  useEffect(() => {
    const setWidthCallback = () => {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setChartWidth(tableRef.current.scrollWidth);
      }, 200);
    };
    if (!dataLoading) {
      setWidthCallback();
    }
    window.addEventListener('resize', setWidthCallback);
    return () => {
      clearTimeout(timer.current);
      window.removeEventListener('resize', setWidthCallback);
    };
  }, [dataLoading]);

  return (
    <ComponentContainer>
      <Card maxHeight borderless>
        {dataLoading ? (
          <Loader />
        ) : (
          <ChartContainer key={chartWidth} containerWidth={chartWidth}>
            {config.chartConfig && <LineChart config={config.chartConfig} />}
          </ChartContainer>
        )}
        <div ref={tableRef}>
          {dataLoading ? (
            <Loader />
          ) : (
            <ComplexTable fields={config.tableFields} data={config.tableData} loading={dataLoading} />
          )}
        </div>
      </Card>
    </ComponentContainer>
  );
});

const StatsCellContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const ChartContainer = styled.div<{ containerWidth: number }>`
  padding-left: 3%;
  width: ${({ containerWidth }) => containerWidth + 'px'};
`;
const ComponentContainer = styled.div`
  padding: 24px;
`;

export default IndicatorChartStats;
