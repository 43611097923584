import styled from 'styled-components';
import SelectList from '../../../components/Calendar/SelectList';
import Select from '../../../components/Select';
import { TextButton } from '../../../components/Button';
import { ModalsStore } from '../../../stores/modal';
import BudgetDebtModal from "./BudgetDebtModal";
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { NsiStoreContext } from '../../../stores/nsi';
import { observer } from 'mobx-react-lite';
import { AppStoreContext } from '../../../stores/app';
import SelectMultiple from '../../../components/SelectMultiple';

interface Props {
  filter: any;
  setFilter: any;
  setMassFilterCallback: any;
}

const BudgetDebtAddition: FC<Props> = observer(({ filter, setFilter, setMassFilterCallback }) => {

  const { years, Territories } = useContext(NsiStoreContext);

  const [listThree, setListThree] = useState([]);

  useEffect(() => {
    setListThree(
      Territories.map((item) => ({
        ...item,
        checked: filter.selectedOmsu ? filter.selectedOmsu.split(',').includes(item.id) : false,
      })),
    );
  }, [Territories]);

  useEffect(() => {
    if (Territories?.length && !filter.selectedOmsu) {
      setFilter('selectedOmsu',
        Territories?.filter(item => {
          switch (item.name) {
            case 'г. Москва':
            case 'г. Санкт-Петербург':
            case 'Ленинградская область': return true;
          
            default: return false;
          }
        })
        .map((item) => item.id)
        .join(',')
      )
    }
  }, [Territories])

  useEffect(() => {
    if (
      years?.length &&
      !filter.selectedYear
    ) {
      const currentYear =
        years.find((el) => el.appliesToYear === new Date().getFullYear()) || years[0];
      onYearClick(currentYear);
    }
  }, [years, filter.selectedYear]);

  const onYearClick = useCallback(
    (e: any) => {
      setFilter('selectedYear', e);
    },
    [],
  );

  const handleLocalListThree = useCallback(({ check, name }) => {
    setListThree((list) =>
      list.map((item) => (item.value === name ? { ...item, checked: check } : item)),
    );
  }, []);

  const onListAccept = useCallback((listThree) => {
    const selectedElements = listThree
      .filter((item) => item.checked)
      .map((item) => item.id)
      .join(',');
      setFilter('selectedOmsu', selectedElements);
  }, []);

  const listOfFilters = useMemo(() => {
    
    const selectedOmsuCount = filter?.selectedOmsu.trim() && filter?.selectedOmsu.split(',')?.length;
    
    return [
      {
        element: (
          <Select
            renderList={({ active, onClose }) => (
              <SelectList active={active} onClose={onClose} list={years} onClick={onYearClick} />
            )}
            label={''}
            value={filter.selectedYear}
            fieldFullsize={false}
          />
        )
      },
      {
        element: (
          <Select
            renderList={({ active, onClose }) => (
              <SelectMultiple
                onClose={onClose}
                list={listThree}
                onClick={handleLocalListThree}
                onAccept={() => onListAccept(listThree)}
              />
            )}
            fullHeight
            label={selectedOmsuCount ? `Сравнить - ${selectedOmsuCount}` : 'Сравнить'}
            value={''}
            fieldFullsize={false}
          />
        )
      }
    ]
  }, [filter, years, listThree])

  return (
    <ComponentContainer>
      {listOfFilters?.map(itm => itm.element)}
      <TextButton onClick={() => ModalsStore.showModal('budget-debt-modal', { parentFilter: filter })}>
        Подробнее...
      </TextButton>
      <BudgetDebtModal/>
    </ComponentContainer>
  )
});

const ComponentContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export default BudgetDebtAddition;
