import { startOfYear, endOfYear, setYear } from 'date-fns';

export const monthsNames = {
  0: 'Январь',
  1: 'Февраль',
  2: 'Март',
  3: 'Апрель',
  4: 'Май',
  5: 'Июнь',
  6: 'Июль',
  7: 'Август',
  8: 'Сентябрь',
  9: 'Октябрь',
  10: 'Ноябрь',
  11: 'Декабрь',
};

export const monthsNamesShort = {
  0: 'янв',
  1: 'февр',
  2: 'март',
  3: 'апр',
  4: 'май',
  5: 'июнь',
  6: 'июль',
  7: 'авг',
  8: 'сент',
  9: 'окт',
  10: 'нояб',
  11: 'дек',
};

export const monthsList = Object.keys(monthsNames).map((key) => ({
  id: key,
  value: key,
  label: monthsNames[key].toString(),
}));

const currentYear = new Date().getFullYear();

export const yearList = Array(11)
  .fill(0)
  .map((el, idx) => ({
    id: idx - 5 + currentYear,
    value: idx - 5 + currentYear,
    label: (idx - 5 + currentYear).toString(),
  }));

export const minDateConst = startOfYear(setYear(new Date(), yearList[0].value));
export const maxDateConst = endOfYear(setYear(new Date(), yearList[yearList.length - 1].value));
