export const gpFields = [
  {
    label: 'Наименование',
    field: 'name',
    width: '40%',
    justifyContent: 'flex-start',
    options: {
      text: 'left',
    },
  },
  {
    label: 'БА',
    field: 'ba',
    width: '6%',
    justifyContent: 'flex-end',
  },
  {
    label: 'в т.ч. ФБ',
    field: 'bafb',
    width: '6%',
    justifyContent: 'flex-end',
  },
  {
    label: 'БО',
    field: 'bo',
    width: '6%',
    justifyContent: 'flex-end',
  },
  {
    label: 'в т.ч. ФБ',
    field: 'bofb',
    width: '6%',
    justifyContent: 'flex-end',
  },
  {
    label: '%',
    field: 'bopercent',
    width: '6%',
  },
  {
    label: '% ФБ',
    field: 'fbpercent',
    width: '6%',
  },
  {
    label: 'Касса',
    field: 'register',
    width: '6%',
    justifyContent: 'flex-end',
  },
  {
    label: 'в т.ч. ФБ',
    field: 'registerfb',
    width: '6%',
    justifyContent: 'flex-end',
  },
  {
    label: '%',
    field: 'registerpercent',
    width: '6%',
  },
  {
    label: '% ФБ',
    field: 'fbpercent1',
    width: '6%',
  },
];
