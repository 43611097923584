import ActionIcon from '../ActionIcon';
import CloseIcon from '../../assets/icons/CloseIcon';
import styled from 'styled-components';
import { FC } from 'react';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const SearchField: FC<Props> = ({ value, onChange }) => {
  return (
    <SearchWrapper>
      <input
        placeholder={'Найти'}
        type='text'
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
      <IconWrapper>
        {value.length > 0 && (
          <ActionIcon action={() => onChange('')}>
            <CloseIcon />
          </ActionIcon>
        )}
      </IconWrapper>
    </SearchWrapper>
  );
};

const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.light};
  input {
    border: 0;
    outline: none;
    padding: 15px;
    font-size: ${({ theme }) => theme.fonts.sizes.md};
    line-height: 20px;
    font-family: ${({ theme }) => theme.fonts.montserrat.medium};
    background-color: transparent;
    width: 100%;
  }
`;

const IconWrapper = styled.div`
  width: 40px;
`;

export default SearchField;
