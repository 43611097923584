import { FC, useCallback, useState } from 'react';
import {
  endOfMonth,
  isAfter,
  format,
  addDays,
  isSameDay,
  isBefore,
  subDays,
  startOfMonth,
  startOfWeek,
  endOfWeek,
  setDate,
  setYear,
  setMonth,
  addMonths,
} from 'date-fns';
import styled from 'styled-components';
import ActionIcon from '../ActionIcon';
import ArrowIcon from '../../assets/icons/ArrowIcon';
import Select from '../Select';
import SelectList from './SelectList';
import { formatDate } from '../../utils/dates';
import InputField from '../InputField';
import { Button } from '../Button';
import { maxDateConst, minDateConst, monthsList, yearList } from '../../config/consts';

interface IProps {
  selectedDate?: any;
  maxDate?: any;
  minDate?: any;
  renderItem: any;
  weekCalendar?: boolean;
  onDoubleClick?: any;
  isWeekView?: boolean;
  onClick: (date: any) => void;
  withFooter?: boolean;
  handleClose?: any;
  handleSelect?: any;
  fromDate?: Date;
  toDate?: Date;
}

const d = new Date();

const getWeekday = (day: any) => Number(format(day, 'i'));

const fillDate = ({
  day,
  minDate,
  maxDate,
  selectedDate,
  currentMonth,
  days,
}: {
  day: any;
  minDate: any;
  maxDate: any;
  selectedDate: any;
  currentMonth: any;
  days: any;
}) => {
  let disabled = false;
  if (!disabled && minDate) {
    disabled = isBefore(day, minDate);
  }
  if (!disabled && maxDate) {
    disabled = isAfter(day, maxDate);
  }
  return {
    // сама дата
    day,
    // день недели, начинается с 1
    weekday: getWeekday(day),
    // это выбранная дата
    isSelected: isSameDay(day, selectedDate),
    // это выбранный месяц
    isCurrentMonth: day.getMonth() === currentMonth,
    // номер строки
    row: Math.floor(days.length / 7),
    // дата на которую нельзя кликнуть - меньше чем мин дата или больше чем макс дата
    disabled,
    // это сегодняшний день
    isToday: isSameDay(day, new Date()),
    isAfter: isAfter(day, new Date()),
  };
};

const Calendar: FC<IProps> = ({
  selectedDate = d,
  maxDate = maxDateConst,
  minDate = minDateConst,
  renderItem,
  isWeekView = false,
  // onClick,
  withFooter = false,
  handleClose,
  handleSelect,
  fromDate,
  toDate,
}) => {
  const [state, setState] = useState({
    fromDate: fromDate || new Date(),
    toDate: toDate || new Date(),
  });

  const [currentMonth, setCurrentMonth] = useState(monthsList[state.fromDate.getMonth()]);
  const [currentYear, setCurrentYear] = useState(
    yearList.find((el) => el.value === state.fromDate.getFullYear()),
  );

  const [step, setStep] = useState(0);

  const handleClick = (item) => {
    if (step === 0) {
      setState({
        fromDate: item.day,
        toDate: item.day,
      });
      setStep(1);
    } else if (step === 1) {
      const isA = isAfter(state.fromDate, item.day);
      const newState = {
        fromDate: isA ? item.day : state.fromDate,
        toDate: isA ? state.fromDate : item.day,
      };
      setState(newState);
      setStep(0);
    }
  };

  const onApply = useCallback(() => {
    handleSelect(state);
    handleClose();
  }, [state]);

  const currentDate = setYear(
    setMonth(setDate(new Date(), 1), Number(currentMonth.value)),
    currentYear.value,
  );

  const fillValues = useCallback(() => {
    const first = isWeekView
      ? startOfWeek(currentDate, { weekStartsOn: 1 })
      : startOfMonth(currentDate);
    const last = isWeekView ? endOfWeek(currentDate, { weekStartsOn: 1 }) : endOfMonth(currentDate);

    const days: any[] = [];
    let current = new Date(first.getTime());
    for (let prefixI = 1; prefixI < getWeekday(first); prefixI += 1) {
      const date = subDays(new Date(first.getTime()), prefixI);
      days.unshift(
        fillDate({
          day: date,
          minDate,
          maxDate,
          selectedDate,
          currentMonth: currentDate.getMonth(),
          days,
        }),
      );
    }

    while (isAfter(last, current)) {
      const day = new Date(current.getTime());
      days.push(
        fillDate({
          day,
          minDate,
          maxDate,
          selectedDate,
          currentMonth: currentDate.getMonth(),
          days,
        }),
      );
      current = addDays(current, 1);
    }

    for (let postfixI = 1; postfixI <= 7 - getWeekday(last); postfixI += 1) {
      const date = addDays(new Date(last.getTime()), postfixI);
      days.push(
        fillDate({
          day: date,
          minDate,
          maxDate,
          selectedDate,
          currentMonth: currentDate.getMonth(),
          days,
        }),
      );
    }

    return days.map((item: any, idx: number) => (
      <CalendarDay key={'item' + item.row} onClick={() => handleClick(item)}>
        {renderItem(item, idx)}
      </CalendarDay>
    ));
  }, [maxDate, minDate, selectedDate, currentDate, isWeekView, step]);

  const renderDayOfWeek = (index: number) => {
    switch (index) {
      case 1:
        return 'ПН';
      case 2:
        return 'ВТ';
      case 3:
        return 'СР';
      case 4:
        return 'ЧТ';
      case 5:
        return 'ПТ';
      case 6:
        return 'СБ';
      case 7:
        return 'ВС';
      default:
        return '';
    }
  };

  const fillDaysOfWeek = () => {
    const arr = [];
    for (let i = 1; i <= 7; i++) {
      arr.push(<CalendarHeaderContent>{renderDayOfWeek(i)}</CalendarHeaderContent>);
    }
    return arr;
  };

  const changeMonth = useCallback(
    (direction: number) => {
      const dt = addMonths(currentDate, direction);
      setCurrentMonth(monthsList[dt.getMonth()]);
      setCurrentYear(yearList.find((el) => el.value === dt.getFullYear()));
    },
    [currentDate],
  );

  const handleMonth = useCallback((el) => {
    setCurrentMonth(el);
  }, []);

  const handleYear = useCallback((el) => {
    setCurrentYear(el);
  }, []);

  return (
    <CalendarWrapper>
      <CalendarRange>
        <InputField
          onChange={() => null}
          placeholder={'Дата от'}
          value={formatDate(state.fromDate)}
          autoFocus={step === 0}
        />
        <Separator>-</Separator>
        <InputField
          onChange={() => null}
          placeholder={'Дата до'}
          value={formatDate(state.toDate)}
          autoFocus={step === 1}
        />
      </CalendarRange>
      <CalendarControls>
        <ActionIcon
          action={() => changeMonth(-1)}
          size={'small'}
          rotate={90}
          opacity={0.56}
          disabled={isBefore(addMonths(currentDate, -1), minDate)}
        >
          <ArrowIcon />
        </ActionIcon>
        <Select
            isAnchorPopup={false}
          renderList={({ active, onClose }) => (
            <SelectList list={monthsList} onClick={handleMonth} onClose={onClose} active={active} />
          )}
          label={'Месяц'}
          value={currentMonth}
        />
        <Select
            isAnchorPopup={false}
          renderList={({ active, onClose }) => (
            <SelectList list={yearList} onClick={handleYear} active={active} onClose={onClose} />
          )}
          label={'Год'}
          value={currentYear}
        />
        <ActionIcon
          action={() => changeMonth(1)}
          size={'small'}
          rotate={-90}
          opacity={0.56}
          disabled={isAfter(addMonths(currentDate, 1), maxDate)}
        >
          <ArrowIcon />
        </ActionIcon>
      </CalendarControls>
      <CalendarHeader>{fillDaysOfWeek()}</CalendarHeader>
      <CalendarDates>{fillValues()}</CalendarDates>
      {withFooter && (
        <Footer>
          <Button
            hollow
            onClick={() => {
              handleClose && handleClose();
            }}
          >
            Отмена
          </Button>
          <Button onClick={onApply}>Применить</Button>
        </Footer>
      )}
    </CalendarWrapper>
  );
};

const Footer = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const CalendarControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  margin-top: 12px;
`;

const CalendarWrapper = styled.div`
  width: 328px;
  padding: 12px;
`;
const CalendarHeader = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.montserrat.semibold};
  gap: 12px;
  margin-bottom: 12px;
`;
const CalendarHeaderContent = styled.div`
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CalendarDates = styled.div`
  display: flex;
  gap: 4px 12px;
  flex-wrap: wrap;
`;
const CalendarDay = styled.div`
  cursor: pointer;
`;
const CalendarRange = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
const Separator = styled.div`
  padding: 0 6px;
`;

export { Calendar };
