import { FC } from "react";
import styled from "styled-components";
import {font} from '../../../../../../components/styled/mixins/fonts'

interface Props {
    count?: number;
}

const IndicatorsModalHeader: FC<Props> = ({ count }) => {
    return (
        <ComponentContainer>
            <Label>
                {count ? `${count} показ.` : ''}
            </Label>
        </ComponentContainer>
    )
}

const ComponentContainer = styled.div`
    padding-top: 6px;
`
const Label = styled.div`
  ${({theme}) => font({color: theme.colors.dark})};
  white-space: nowrap;
`

export default IndicatorsModalHeader;
