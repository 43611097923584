import styled from 'styled-components';
import { font } from '../styled/mixins/fonts';

const EmptyData = () => {
  return <ComponentContainer>Нет данных</ComponentContainer>;
};

const ComponentContainer = styled.div`
  text-align: center;
  ${({ theme }) => font({ family: theme.fonts.montserrat.bold })};
`;

export default EmptyData;
