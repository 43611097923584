import ComplexTable from '../../../components/ComplexTable';
import Card from '../../../components/Card';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { AppStoreContext } from '../../../stores/app';
import { getSocPosition } from '../../../actions';
import { isNumber } from 'lodash';
import Text, { TextFamily } from '../../../components/Text';
import { observer } from 'mobx-react-lite';
import { Loader } from '../../../components/Loader';

interface Props {
  filter: any;
}

const DevelopmentSocialStatus: FC<Props> = observer(({ filter }) => {

  const { selectedOmsu } = useContext(AppStoreContext);

  const [data, setData] = useState(null);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedOmsu) {
      const fetchData = async () => {
        setDataLoading(true);
        try {
          const response = await getSocPosition({
            omsuId: selectedOmsu.id,
            sphereId: filter?.type?.id,
            // periodId: filter?.selectedYear?.id
          });
          setData(response.data.items);
        } catch (e) {
          //
        } finally {
          setDataLoading(false);
        }
      };
      fetchData();
    }
  }, [selectedOmsu, filter]);

  const tableConfig = useMemo(() => {
    
    let tableData = [];
    let tableFields = [];

    if (data) {

      const fillData = (itm) => {
        console.log(itm.yearOpen);
        
        return {
          title: itm?.title || ' ',
          yearOpen: <Text data={isNumber(itm.yearOpen) && itm.yearOpen !== 0 ? itm.yearOpen.toString() : '-'} font={TextFamily.ROBOTO_CONDENSED_REGULAR} />,
          projectedLoad: isNumber(itm.projectedLoad) ? itm.projectedLoad.toString() : '-',
          factLoad: isNumber(itm.factLoad) ? itm.factLoad.toString() : '-',
          year1Val: isNumber(itm.year1Val) ? itm.year1Val.toString() : '-',
          year2Val: isNumber(itm.year2Val) ? itm.year2Val.toString() : '-',
          year3Val: isNumber(itm.year3Val) ? itm.year3Val.toString() : '-',
          children: itm?.children?.map(item => fillData(item)),
        }
      }

      tableData = data.map((itm) => fillData(itm));

      tableFields = [
        {
          id: 0,
          label: '',
          field: 'title',
          width: '64%',
          sorting: false,
          colspan: 1,
          options: {},
        },
        {
          id: 1,
          label: 'Год открытия',
          field: 'yearOpen',
          width: '6%',
          sorting: false,
          colspan: 1,
          options: {},
        },
        {
          id: 2,
          label: 'Проектная мощность',
          field: 'projectedLoad',
          width: '6%',
          sorting: false,
          colspan: 1,
          options: {},
        },
        {
          id: 3,
          label: 'Фактическая загрузка',
          field: 'factLoad',
          width: '6%',
          sorting: false,
          colspan: 1,
          options: {
            'background-color': 'rgba(100, 108, 170, 0.08)',
            'border-left': '1px solid rgba(100, 108, 170, 0.24)',
            'border-right': '1px solid rgba(100, 108, 170, 0.24)',
            'text-align': 'center',
          },
        },
        {
          id: 4,
          label: `${new Date().getFullYear()}`,
          field: 'year1Val',
          width: '6%',
          sorting: false,
          colspan: 1,
          options: {},
        },
        {
          id: 5,
          label: `${new Date().getFullYear() + 1}`,
          field: 'year2Val',
          width: '6%',
          sorting: false,
          colspan: 1,
          options: {},
        },
        {
          id: 6,
          label: `${new Date().getFullYear() + 2}`,
          field: 'year3Val',
          width: '6%',
          sorting: false,
          colspan: 1,
          options: {},
        },
      ]
    }



    return {
      tableData,
      tableFields
    };
  }, [data]);

  return (
    <Card>
      {dataLoading ? <Loader/> :
        <ComplexTable
          fields={[[...tableConfig?.tableFields || []]]}
          data={tableConfig.tableData}
          loading={dataLoading}
        />
      }
    </Card>
  );
});

export default DevelopmentSocialStatus;
