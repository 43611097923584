import Card from '../../../components/Card';
import BarChart from '../../../components/BarChart';
import styled from 'styled-components';
import DynamicBudget from './DynamicBudget';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  getBudgetExecution,
  getDinamBudgetExecutionTableMain,
  getDynamicBudget,
} from '../../../actions';
import { Loader } from '../../../components/Loader';
import ComplexTable from '../../../components/ComplexTable';
import { get } from 'lodash';
import DynamicBudgetModal from './DynamicBudget/DynamicBudgetModal';
import { initialBudgetDynamicFilterValues } from './consts';
import { useFilter } from '../../../hooks/useFilter';

interface Props {
  filter: any;
}

const BudgetExecution: FC<Props> = ({ filter }) => {
  const [dynamicBudget, setDynamicBudget] = useState(null);
  const [dynamicBudgetLoading, setDynamicBudgetLoading] = useState(false);
  const [executionInfo, setExecutionInfo] = useState(null);
  const [executionLoading, setExecutionLoading] = useState(false);
  const [dinamBudgetExecutionTable, setDinamBudgetExecutionTable] = useState([]);
  const [dinamBudgetExecutionTableLoading, setDinamBudgetExecutionTableLoading] = useState(false);

  const { filter: executionBudgetFilter, setFilterCallback: setExecutionBudgetFilter } = useFilter({
    initialFilterValues: { ...initialBudgetDynamicFilterValues },
    name: 'budgetDynamicFilters',
  });

  useEffect(() => {
    if (filter?.selectedMonth) {
      const fetchData = async () => {
        setExecutionLoading(true);
        try {
          const response = await getBudgetExecution({
            periodId: filter.selectedMonth?.id,
          });
          setExecutionInfo(response.data.items);
        } catch (e) {
          //
        } finally {
          setExecutionLoading(false);
        }
      };
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    if (filter?.selectedMonth) {
      const fetchData = async () => {
        setDynamicBudgetLoading(true);
        try {
          const response = await getDynamicBudget({
            periodId: filter.selectedMonth?.id,
            periodType: executionBudgetFilter?.selectedPeriodType?.value,
            ciogvId: executionBudgetFilter?.ciogv,
            fundingSource: executionBudgetFilter?.fundingSource,
          });
          setDynamicBudget(response.data.items);
        } catch (e) {
          //
        } finally {
          setDynamicBudgetLoading(false);
        }
      };
      fetchData();
    }
  }, [filter, executionBudgetFilter]);

  useEffect(() => {
    if (filter?.selectedMonth) {
      const fetchData = async () => {
        setDinamBudgetExecutionTableLoading(true);
        try {
          const response = await getDinamBudgetExecutionTableMain({
            periodId: filter.selectedMonth?.id,
            periodType: executionBudgetFilter?.selectedPeriodType?.value,
            ciogvId: executionBudgetFilter?.ciogv,
            fundingSource: executionBudgetFilter?.fundingSource,
          });
          setDinamBudgetExecutionTable(response.data.items);
        } catch (e) {
          //
        } finally {
          setDinamBudgetExecutionTableLoading(false);
        }
      };
      fetchData();
    }
  }, [filter, executionBudgetFilter]);

  const chartConfig = useMemo(() => {
    if (executionInfo) {
      const fillData = (idx) => {
        return {
          amount: executionInfo[idx].indicatorValue,
          overlayAmount: executionInfo[idx].indicatorFedValue,
        };
      };
      return [
        {
          id: 0,
          label: 'Касса',
          data: {
            ...fillData(0),
            color: '#172B75',
          },
        },
        {
          id: 1,
          label: 'Контракт',
          data: {
            ...fillData(1),
            color: '#646CAA',
          },
        },
        {
          id: 2,
          label: 'БА',
          data: {
            ...fillData(2),
            color: '#646CAA',
          },
        },
      ];
    }
    return [];
  }, [executionInfo]);

  const chartMeta = useMemo(() => {
    return {
      from: 'ФБ',
      to: 'млрд. руб.',
    };
  }, []);

  const tableFields = useMemo(() => {
    return [
      [
        {
          id: 0,
          label: '',
          field: '',
          width: '24%',
          sorting: false,
          colspan: 1,
          options: {},
        },
        {
          id: 1,
          label: get(dinamBudgetExecutionTable, '[0].valuesByPeriod[0].period.name', ''),
          field: '',
          width: '40%',
          sorting: false,
          colspan: 4,
          options: {},
        },
        {
          id: 2,
          label: get(dinamBudgetExecutionTable, '[0].valuesByPeriod[1].period.name', ''),
          width: '15%',
          field: '',
          sorting: false,
          colspan: 4,
          options: {},
        },
        {
          id: 3,
          label: get(dinamBudgetExecutionTable, '[0].valuesByPeriod[2].period.name', ''),
          width: '15%',
          field: '',
          sorting: false,
          colspan: 4,
          options: {},
        },
      ],
      [
        {
          id: 4,
          label: '',
          field: 'name',
          sorting: false,
          width: '40%',
          options: {},
        },
        {
          id: 5,
          label: 'План Первонач. млрд руб.',
          field: 'valuesByPeriod[0].originalPlanValue',
          sorting: false,
          width: '5%',
          options: {},
        },
        {
          id: 6,
          label: 'План Уточненный млрд руб.',
          field: 'valuesByPeriod[0].correctedPlanValue',
          sorting: false,
          width: '5%',
          options: {},
        },
        {
          id: 7,
          label: 'Исполнение',
          field: 'valuesByPeriod[0].factValue',
          sorting: false,
          width: '5%',
          options: {},
        },
        {
          id: 8,
          label: '%',
          field: 'valuesByPeriod[0].performance',
          sorting: false,
          width: '5%',
          options: {
            color: 'rgba(0, 0, 0, 0.56)',
          },
        },
        {
          id: 9,
          label: 'План Первонач. млрд руб.',
          field: 'valuesByPeriod[1].originalPlanValue',
          sorting: false,
          width: '5%',
          options: {},
        },
        {
          id: 10,
          label: 'План Уточненный млрд руб.',
          field: 'valuesByPeriod[1].correctedPlanValue',
          sorting: false,
          width: '5%',
          options: {},
        },
        {
          id: 11,
          label: 'Исполнение',
          field: 'valuesByPeriod[1].factValue',
          sorting: false,
          width: '5%',
          options: {},
        },
        {
          id: 12,
          label: '%',
          field: 'valuesByPeriod[1].performance',
          sorting: false,
          width: '5%',
          options: {
            color: 'rgba(0, 0, 0, 0.56)',
          },
        },
        {
          id: 13,
          label: 'План Первонач. млрд руб.',
          field: 'valuesByPeriod[2].originalPlanValue',
          sorting: false,
          width: '5%',
          options: {},
        },
        {
          id: 14,
          label: 'План Уточненный млрд руб.',
          field: 'valuesByPeriod[2].correctedPlanValue',
          sorting: false,
          width: '5%',
          options: {},
        },
        {
          id: 15,
          label: 'Исполнение',
          field: 'valuesByPeriod[2].factValue',
          sorting: false,
          width: '5%',
          options: {},
        },
        {
          id: 16,
          label: '%',
          field: 'valuesByPeriod[2].performance',
          sorting: false,
          width: '5%',
          options: {
            color: 'rgba(0, 0, 0, 0.56)',
          },
        },
      ],
    ];
  }, [filter, dinamBudgetExecutionTable]);

  return (
    <>
      <Card>
        <Content>
          {executionLoading ? (
            <Loader />
          ) : (
            <BarChart data={chartConfig} meta={chartMeta} hideMoreBtn />
          )}
        </Content>
        <DynamicBudgetWrapper>
          {/*{dynamicBudgetLoading ? (*/}
          {/*  <Loader />*/}
          {/*) : (*/}
          <DynamicBudget
            data={dynamicBudget}
            filter={executionBudgetFilter}
            setFilter={setExecutionBudgetFilter}
          />
          {/*)}*/}
        </DynamicBudgetWrapper>
        <Card maxHeight borderless>
          <ComplexTable
            fields={tableFields}
            data={dinamBudgetExecutionTable}
            loading={dinamBudgetExecutionTableLoading}
          />
        </Card>
      </Card>
      <DynamicBudgetModal />
    </>
  );
};

const Content = styled.div`
  padding: 24px;
`;
const DynamicBudgetWrapper = styled.div`
  padding: 0 24px;
`;

export default BudgetExecution;
