export const legendConfig = [
  {
    id: 0,
    color: '#646CAA',
    label: 'Законтрактовано',
  },
  {
    id: 1,
    color: '#838ABE',
    label: 'Плановое финансирование',
  },
  {
    id: 2,
    color: '#A3A9D4',
    label: 'Фактическое финансирование',
  },
];
