import styled from 'styled-components';
import { FC, ReactElement } from 'react';
import { font } from '../styled/mixins/fonts';

interface Props {
  label: string;
  data: string | ReactElement;
}

const HorizontalLabeledText: FC<Props> = ({ label, data }) => {
  return (
    <ComponentContainer>
      <Label>{label}</Label>
      <Data>{data}</Data>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  align-items: center;
`;
const Label = styled.div`
  margin-right: 12px;
  ${({ theme }) => font({ color: theme.colors.dark })};
`;
const Data = styled.div`
  ${font()};
`;

export default HorizontalLabeledText;
