export const fieldsExample = [
  [
    {
      id: 0,
      label: '',
      field: '',
      width: '24%',
      sorting: false,
      colspan: 1,
      options: {},
    },
    {
      id: 1,
      label: 'На 1 апреля 2022',
      field: '',
      width: '40%',
      sorting: false,
      colspan: 6,
      options: {},
    },
    {
      id: 2,
      label: '2021',
      width: '15%',
      field: '',
      sorting: false,
      colspan: 3,
      options: {},
    },
    {
      id: 3,
      label: '2021',
      width: '15%',
      field: '',
      sorting: false,
      colspan: 3,
      options: {},
    },
  ],
  [
    {
      id: 4,
      label: '',
      width: '20%',
      field: '',
      sorting: false,
      colspan: 1,
      options: {},
    },
    {
      id: 5,
      label: 'План',
      width: '20%',
      field: '',
      sorting: false,
      colspan: 3,
      options: {},
    },
    {
      id: 6,
      label: 'Исполнение',
      width: '20%',
      field: '',
      sorting: false,
      colspan: 3,
      options: {},
    },
    {
      id: 7,
      label: '% исполнения',
      width: '20%',
      field: '',
      sorting: false,
      colspan: 6,
      options: {},
    },
  ],
  [
    {
      id: 8,
      label: <div>наименование + млрд. руб.</div>,
      field: 'title',
      width: '24%',
      sorting: false,
      colspan: 1,
      options: {
        'text-align': 'left',
      },
    },
    {
      id: 9,
      label: 'Всего',
      field: 'totalPlan',
      width: '6%',
      sorting: true,
      colspan: 1,
      options: {
        color: 'pink',
      },
    },
    {
      id: 10,
      label: 'ФБ',
      field: 'FBPlan',
      width: '6%',
      sorting: true,
      colspan: 1,
      options: {
        color: 'green',
      },
    },
    {
      id: 11,
      label: 'ОБ',
      field: 'OBPlan',
      width: '6%',
      sorting: true,
      colspan: 1,
      options: {},
    },
    {
      id: 12,
      label: 'Всего',
      field: 'totalExecution',
      width: '6%',
      sorting: true,
      colspan: 1,
      options: {},
    },
    {
      id: 13,
      label: 'ФБ',
      field: 'FBExecution',
      width: '6%',
      sorting: true,
      colspan: 1,
      options: {},
    },
    {
      id: 14,
      label: 'ОБ',
      field: 'OBExecution',
      width: '6%',
      sorting: true,
      colspan: 1,
      options: {},
    },
    {
      id: 14,
      label: 'Всего',
      field: 'totalPercent',
      width: '6%',
      sorting: true,
      colspan: 1,
      options: {},
    },
    {
      id: 15,
      label: 'ФБ',
      field: 'FBPercent',
      width: '6%',
      sorting: true,
      colspan: 1,
      options: {},
    },
    {
      id: 16,
      label: 'ОБ',
      field: 'OBPercent',
      width: '6%',
      sorting: true,
      colspan: 1,
      options: {},
    },
    {
      id: 17,
      label: 'Всего',
      field: 'totalPercent1',
      width: '6%',
      sorting: true,
      colspan: 1,
      options: {},
    },
    {
      id: 18,
      label: 'ФБ',
      field: 'FBPercent1',
      width: '6%',
      sorting: true,
      colspan: 1,
      options: {},
    },
    {
      id: 19,
      label: 'ОБ',
      field: 'OBPercent1',
      width: '6%',
      sorting: true,
      colspan: 1,
      options: {},
    },
  ],
];
