import { useContext, useEffect, useState } from 'react';
import { Modal } from '../../../../components/Modal';
import { connectModal } from '../../../../hocs/Modal';
import BudgetExecutionModalHeader from './BudgetExecutionModalHeader';
import CashType from './CashType';
import ExecutionType from './ExecutionType';
import GPType from './GPType';
import MainExpensesType from './MainExpensesType';
import MainPlanType from './MainPlanType';
import NPType from './NPType';
import PlanType from './PlanType';
import TypeType from './TypeType';
import styled from 'styled-components';
import { NsiStoreContext } from '../../../../stores/nsi';
import { useFilter } from '../../../../hooks/useFilter';
import { initialExecutionBudgetModalFilterValues } from './consts';

const BudgetExecutionModal = connectModal('budget-execution-modal')(({ handleHide, initialMonth }) => {

  const { BudgetExecutionContext } = useContext(NsiStoreContext);

  const { filter, setFilterCallback: setFilter } = useFilter({
    initialFilterValues: { ...initialExecutionBudgetModalFilterValues },
    name: 'executionBudgetModalFilter',
    dateFields: ['fromDate', 'toDate'],
    disableCache: true,
  });

  useEffect(() => {
    if(initialMonth){
      setFilter('selectedMonth', initialMonth)
    }
  }, [])
  

  const [type, setType] = useState(BudgetExecutionContext[0]);

  const renderComponent = (type, filter) => {
    switch (type.code) {
      case '10001':
        return <CashType filter={filter} />;
      case '10002':
        return <ExecutionType filter={filter} />;
      case '10003':
        return <GPType filter={filter} />;
      case '10004':
        return <NPType filter={filter} />;
      case '10005':
        return <PlanType filter={filter} />;
      case '10006':
        return <MainPlanType filter={filter} />;
      case '10007':
        return <TypeType filter={filter} />;
      case '10008':
        return <MainExpensesType filter={filter} />;
    }
  };

  return (
    <Modal
      title={'Исполнение бюджета'}
      onClose={handleHide}
      header={
        <BudgetExecutionModalHeader setType={setType} type={type} filter={filter} setFilter={setFilter} />
      }
      width={'75%'}
    >
      <Content>{renderComponent(type, filter)}</Content>
    </Modal>
  );
});

const Content = styled.div`
  padding: 0 24px 24px 24px;
  width: 100%;
`;

export default BudgetExecutionModal;
