import { FC } from 'react';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { font } from '../styled/mixins/fonts';

export enum TextFamily {
  MONTSERRAT_BOLD,
  MONTSERRAT_SEMIBOLD,
  MONTSERRAT_MEDIUM,
  MONTSERRAT_REGULAR,
  ROBOTO_CONDENSED_REGULAR,
  ROBOTO_CONDENSED_BOLD,
}

interface Props {
  data: string;
  font?: TextFamily;
  color?: string;
  size?: string;
}

const Text: FC<Props> = ({
  data,
  font = TextFamily.MONTSERRAT_MEDIUM,
  color = '#000',
  size = '14px',
}) => {
  const calculateFont = (font) => {
    switch (font) {
      case TextFamily.MONTSERRAT_REGULAR:
        return theme.fonts.montserrat.regular;
      case TextFamily.MONTSERRAT_MEDIUM:
        return theme.fonts.montserrat.medium;
      case TextFamily.MONTSERRAT_SEMIBOLD:
        return theme.fonts.montserrat.semibold;
      case TextFamily.MONTSERRAT_BOLD:
        return theme.fonts.montserrat.bold;
      case TextFamily.ROBOTO_CONDENSED_REGULAR:
        return theme.fonts.robotoCondensed.regular;
      case TextFamily.ROBOTO_CONDENSED_BOLD:
        return theme.fonts.robotoCondensed.bold;
    }
  };

  return (
    <ComponentContainer family={calculateFont(font)} color={color} size={size}>
      {data}
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div<{ family: string; color: string; size: string }>`
  ${({ theme, family, color, size }) => font({ family, color, size })};
`;

export default Text;
