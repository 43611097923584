import { FC, ReactNode, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import SmallArrowIcon from '../../assets/icons/SmallArrowIcon';
import { font } from '../styled/mixins/fonts';
import SelectList from '../Calendar/SelectList';
import { ListPosition } from '../Select';
import Icon from '../Icon';
import { useOutsideClick } from '../../hooks/useOutsideClick';

interface ListItem {
  id: any;
  label: any;
  value: any;
}

interface Props {
  value?: any;
  label?: string;
  onChange?: ({ value, label, id }: { value: string; label: string; id: number }) => void;
  placeholder?: string;
  list?: ListItem[];
  renderList?: ({ active, onClose }: { active: boolean; onClose: () => void }) => ReactNode;
}

const SelectField: FC<Props> = ({ value, onChange, label, placeholder, list, renderList }) => {
  const [active, setActive] = useState(false);
  const ref = useOutsideClick(() => setActive(false));

  const toggleActive = useCallback(() => {
    setActive((active) => !active);
  }, []);

  const onClose = useCallback(() => {
    setActive(false);
  }, []);

  return (
    <ComponentContainer ref={ref}>
      <Label>{label}</Label>
      <Select onClick={toggleActive} active={active}>
        {typeof value === 'object' && value?.label && <Value>{value?.label}</Value>}
        {(typeof value === 'number' || typeof value === 'string') && value && (
          <Value>{value}</Value>
        )}
        {!value && placeholder && <Placeholder>{placeholder}</Placeholder>}
        <Icon rotate={active ? -180 : 0}>
          <SmallArrowIcon />
        </Icon>
      </Select>
      <ListVisibility active={active}>
        {renderList ?
          renderList({ active, onClose })
          :
          <SelectList list={list} onClick={onChange} onClose={onClose} />
        }
      </ListVisibility>
    </ComponentContainer>
  );
};

const ListVisibility = styled.div<{
  active: boolean;
  fullHeight?: boolean;
  position?: ListPosition;
}>`
  display: ${({ active }) => !active && 'none'};
  position: absolute;
  right: 0;
  margin-top: 6px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.02);
  box-shadow: 0 8px 16px rgba(8, 35, 48, 0.2);
  border-radius: 8px;
  max-height: ${({ fullHeight }) => (fullHeight ? 'auto' : '300px')};
  overflow-y: ${({ fullHeight }) => (fullHeight ? 'visible' : 'auto')};
  overflow-x: ${({ fullHeight }) => (fullHeight ? 'visible' : 'hidden')};
  z-index: 1111;
  ${({ position }) =>
    position === ListPosition.TOP &&
    css`
      bottom: calc(100% + 6px);
    `};
`;

const ComponentContainer = styled.div`
  position: relative;
`;
const Label = styled.div`
  margin-bottom: 4px;
  ${({ theme }) => font({ color: theme.colors.dark, size: theme.fonts.sizes.sm })}
`;
const Select = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid
    ${({ theme, active }) => (active ? theme.colors.primary : theme.colors.fieldBorder)};
  padding: 6px 8px 6px 12px;
  border-radius: ${({ theme }) => theme.styles.borderRadius};
  cursor: pointer;
  background-color: #fff;
  position: relative;
  ${({ active }) =>
    active &&
    css`
      box-shadow: 0 0 0 4px rgba(23, 43, 117, 0.12);
    `}
`;
const Value = styled.div`
  flex: 1 1 auto;
  ${font({})};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Placeholder = styled.div`
  flex: 1 1 auto;
  ${({ theme }) => font({ color: theme.colors.placeholder })};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export default SelectField;
