import styled from 'styled-components';
import LoaderAnimatedIcon from '../../Loader/LoaderAnimatedIcon';

const TableLoader = () => {
  return (
    <ComponentContainer>
      <LoaderAnimatedIcon />
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
`;

export default TableLoader;
