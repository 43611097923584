import React, { FC, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { font } from '../../styled/mixins/fonts';
import SortDown from '../../../assets/icons/SortDown';
import SortUp from '../../../assets/icons/SortUp';

interface Props {
  fields: any[];
  sorting: any;
  setSortingCallback?: (val: any) => void;
  layer: number;
  sticky: boolean;
}

const THead: FC<Props> = ({ fields, sorting, setSortingCallback, layer, sticky }) => {
  const onSortingClick = useCallback(
    (item) => {
      if (sorting) {
        let direction = sorting.direction;
        if (sorting.field === item.field) {
          if (sorting.direction === 'DESC') {
            direction = 'ASC';
          } else {
            direction = 'DESC';
          }
        }
        setSortingCallback({ field: item.field, direction });
      }
    },
    [setSortingCallback, sorting],
  );

  return (
    <THeadRow layer={layer} sticky={sticky}>
      {fields?.map((item, idx) => {
        const selected = item && sorting.field === item.field;
        return (
          <THeadCell
            width={item.width}
            key={`t-head-${idx}`}
            // backgroundColor={item.colspan}
            colSpan={item.colspan}
            options={item.options}
          >
            <CellWrapper options={item.options} justifyContent={item.justifyContent}>
              <THeadCellLabel onClick={() => onSortingClick(item)} cursor={!!setSortingCallback} options={item.options}>
                {item.label}
              </THeadCellLabel>
              {selected &&
                item.sorting &&
                (sorting.direction === 'DESC' ? <SortUp /> : <SortDown />)}
            </CellWrapper>
          </THeadCell>
        );
      })}
    </THeadRow>
  );
};

const THeadRow = styled.tr<{ layer: number; sticky: boolean }>`
  position: ${({ sticky }) => (sticky ? 'sticky' : 'static')};
  //background-color: #fff;
  top: ${({ layer }) => 60 + (layer - 1) * 44}px;
  z-index: 1;
`;

const THeadCell = styled.th<{ width: string; options: any }>`
  width: ${({ width }) => width};
  font-weight: normal;
  text-align: ${({ options }) => options?.text || 'center'};
  vertical-align: middle;
  z-index: 10;
  padding: 8px 12px;
  ${({ theme }) =>
    font({
      family: theme.fonts.robotoCondensed.regular,
      color: theme.colors.dark,
      size: theme.fonts.sizes.sm,
    })};
  background-color: ${({ options }) => (options?.fill ? 'rgba(100, 108, 170, 0.08)' : '#fff')};
  border-left: ${({ options }) => (options?.first ? '1px solid rgba(100, 108, 170, 0.24)' : '0')};
  border-right: ${({ options }) => (options?.last ? '1px solid rgba(100, 108, 170, 0.24)' : '0')};
`;

const CellWrapper = styled.div<{ justifyContent: string; options: any }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
  text-align: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
`;

const THeadCellLabel = styled.div<{ cursor: boolean; options: any }>`
  ${({ options }) => (options?.headLabelWidth ? `width: ${options?.headLabelWidth};` : '')}
  cursor: ${({ cursor }) => (cursor ? 'pointer' : 'auto')};
`;

export default THead;
