import styled from 'styled-components';
import { FC } from 'react';
import { theme } from '../../styles/theme';
import { font } from '../styled/mixins/fonts';

export enum ZoneType {
  RED = 0,
  GREEN = 1,
  FILLED = 2,
  NOT_FILLED = 3,
}
interface Props {
  type: ZoneType;
}

export const calculateType = (type) => {
  switch (type) {
    case ZoneType.RED:
      return { color: theme.colors.status.overdue, label: 'Красная зона' };
    case ZoneType.GREEN:
      return { color: theme.colors.status.done, label: 'Зеленая зона' };
    case ZoneType.NOT_FILLED:
      return { color: theme.colors.status.overdue, label: 'Не заполнено' };
    case ZoneType.FILLED:
      return { color: theme.colors.status.done, label: 'Заполнено' };
  }
};

const TableZone: FC<Props> = ({ type }) => {

  return (
    <ComponentContainer>
      <Zone type={calculateType(type).color} />
      <Label>{calculateType(type).label}</Label>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
const Label = styled.div`
  ${font()};
`;
const Zone = styled.div<{ type: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ type }) => type};
`;

export default TableZone;
