import Select from '../../../components/Select';
import SelectList from '../../../components/Calendar/SelectList';
import styled from 'styled-components';
import { FC, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { NsiStoreContext } from '../../../stores/nsi';

interface Props {
  filter: any;
  setFilterCallback: any;
}

const DevelopmentSocialStatusAddition: FC<Props> = observer(({ filter, setFilterCallback }) => {
  const { WorkType } = useContext(NsiStoreContext);

  useEffect(() => {
    if (!filter.type && WorkType) {
      setFilterCallback('type', WorkType[0]);
    }
  }, [WorkType]);

  // useEffect(() => {
  //   if (years?.length && !filter.selectedYear) {
  //     const currentYear =
  //       years.find((el) => el.appliesToYear === new Date().getFullYear()) || years[0];
  //     onYearClick(currentYear);
  //   }
  // }, [years, filter.selectedYear]);

  // const onYearClick = useCallback((e: any) => {
  //   setFilterCallback('selectedYear', e);
  // }, []);

  // useEffect(() => {
  //   if (years?.length && !filter.selectedYear) {
  //     const currentYear =
  //       years.find((el) => el.appliesToYear === new Date().getFullYear()) || years[0];
  //     onYearClick(currentYear);
  //   }
  // }, [years, filter.selectedYear]);

  return (
    <ComponentContainer>
      <Select
        renderList={({ active, onClose }) => (
          <SelectList
            active={active}
            onClose={onClose}
            list={WorkType}
            onClick={(el) => setFilterCallback('type', el)}
          />
        )}
        label={''}
        value={filter.type}
        fieldFullsize={false}
      />
      {/* <Select
        key={filter.selectedYear?.id}
        renderList={({ active, onClose }) => (
          <SelectList active={active} onClose={onClose} list={years} onClick={onYearClick} />
        )}
        label={'Все года открытия'}
        value={filter.selectedYear}
        fieldFullsize={false}
      /> */}
    </ComponentContainer>
  );
});

const ComponentContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export default DevelopmentSocialStatusAddition;
