import styled, { useTheme } from 'styled-components';
import { FC } from 'react';
import { CustomTheme, theme } from '../../styles/theme';

export enum StatusType {
  NONE,
  DONE,
  OVERDUE,
  PROGRESS,
  DONE_WITH_DELAY,
  PLANNED,
}

interface Props {
  status: StatusType;
}

export const renderStatus = (status) => {
  switch (status) {
    case StatusType.PROGRESS:
      return {
        value: 'В работе',
        color: theme.colors.status.progress,
      };
    case StatusType.DONE:
      return {
        value: 'Выполнено',
        color: theme.colors.status.done,
      };
    case StatusType.OVERDUE:
      return {
        value: 'Просрочено',
        color: theme.colors.status.overdue,
      };
    case StatusType.PLANNED:
      return {
        value: 'Запланировано',
        color: theme.colors.status.planned,
      };
    case StatusType.DONE_WITH_DELAY:
      return {
        value: 'Выполнено с просрочкой',
        color: theme.colors.status.done_with_delay,
      };
    default:
      return {
        value: '',
        color: '',
      };
  }
};

const Status: FC<Props> = ({ status }) => {
  return (
    <StatusWrapper status={renderStatus(status).color}>{renderStatus(status).value}</StatusWrapper>
  );
};

const StatusWrapper = styled.div<{ status: string }>`
  background-color: ${({ status }) => status + '1F'};
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: ${({ status }) => status};
  font-size: 14px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.montserrat.semibold};
`;

export default Status;
