import styled, { css } from 'styled-components';
import { FC } from 'react';
import { Filter } from '../index';

interface Props {
  onClick: (object) => void;
  active: boolean;
}

const FilterItem: FC<Filter & Props> = ({
  id,
  title,
  value,
  amount,
  onClick,
  active,
  ...props
}) => {
  return (
    <FilterItemWrapper active={active} onClick={() => onClick({ id, title, value, ...props })}>
      {title}
      {amount}
    </FilterItemWrapper>
  );
};

const FilterItemWrapper = styled.div<{ active: boolean }>`
  font-size: 14px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.montserrat.medium};
  cursor: pointer;
  padding: 6px 16px;
  color: rgba(0, 0, 0, 0.56);
  border-radius: 8px;
  border: 1px solid transparent;
  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.primary};
      background-color: rgba(0, 19, 92, 0.12);
      border: 1px solid #fff;
    `}
`;

export default FilterItem;
