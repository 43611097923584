import { FC } from 'react';
import styled from 'styled-components';
import { AdditionalItem } from '../index';
import { font } from '../../styled/mixins/fonts';

const RatingAdditional: FC<AdditionalItem> = ({
  id,
  amount,
  amountMeasure,
  sublabel,
  label,
  titleMeasure,
  title,
}) => {
  return (
    <ComponentContainer>
      <TitleWrapper>
        <Title>{title}</Title>
        <TitleMeasure>{titleMeasure}</TitleMeasure>
      </TitleWrapper>
      <AmountWrapper>
        <Amount>{amount}</Amount>
        <AmountMeasure>{amountMeasure}</AmountMeasure>
      </AmountWrapper>
      <Label>{label}</Label>
      <Sublabel>{sublabel}</Sublabel>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xxlg,
      family: theme.fonts.montserrat.semibold,
      lineHeight: '38px',
      color: theme.colors.secondary,
    })};
`;
const TitleMeasure = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.sm,
      family: theme.fonts.montserrat.semibold,
      lineHeight: '26px',
      color: theme.colors.secondary,
    })};
`;
const Amount = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xxmd,
      family: theme.fonts.montserrat.semibold,
      lineHeight: '24px',
    })};
`;
const AmountMeasure = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.sm,
      family: theme.fonts.montserrat.semibold,
      lineHeight: '18px',
    })};
  margin-left: 4px;
`;
const Label = styled.div`
  ${({ theme }) =>
    font({
      color: theme.colors.dark,
    })};
  text-align: center;
`;
const Sublabel = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.sm,
      color: theme.colors.placeholder,
    })};
  text-align: center;
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
`;
const AmountWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 2px;
`;

export default RatingAdditional;
