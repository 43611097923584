import { FC } from 'react';
import styled from 'styled-components';
import { Rating } from '../index';
import Stats from '../../Stats';
import { font } from '../../styled/mixins/fonts';

const CardRating: FC<Rating> = ({ list, amount, amountMeasure, place, placeDifference }) => {
  return (
    <ComponentContainer>
      <Content>
        <Place>
          <PlaceNumber>{place}</PlaceNumber>
          <PlaceLabel>место</PlaceLabel>
          <StatsWrapper>{placeDifference && <Stats progress={placeDifference} />}</StatsWrapper>
        </Place>
        <AmountWrapper>
          <Amount>{amount}</Amount>
          <AmountMeasure>{amountMeasure}</AmountMeasure>
        </AmountWrapper>
      </Content>
      <List>
        {list.map(({ place, id, label }) => (
          <ListItem key={id}>
            <ItemPlace>{place} место</ItemPlace>
            <ItemLabel>{label}</ItemLabel>
          </ListItem>
        ))}
      </List>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const Place = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
`;
const PlaceNumber = styled.div`
  ${({ theme }) =>
    font({ size: theme.fonts.sizes.xmlg, color: theme.colors.secondary, lineHeight: '32px' })};
`;
const PlaceLabel = styled.div`
  ${({ theme }) => font({ size: theme.fonts.sizes.sm, color: theme.colors.secondary })};
`;
const Amount = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xxmd,
      family: theme.fonts.montserrat.semibold,
      lineHeight: '24px',
    })};
  margin-right: 4px;
`;
const AmountMeasure = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.sm,
      family: theme.fonts.montserrat.semibold,
      lineHeight: '18px',
    })};
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 2px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const ListItem = styled.div`
  display: flex;
  > div {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
const ItemPlace = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.sm,
      color: theme.colors.placeholder,
      family: theme.fonts.robotoCondensed.regular,
    })};
  width: 50px;
  text-align: center;
`;
const ItemLabel = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.sm,
      color: theme.colors.placeholder,
    })};
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
const AmountWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;
const StatsWrapper = styled.div`
  position: absolute;
  //right: -10px;
  top: -8px;
  left: calc(100% - 10px);
  width: 100%;
`;

export default CardRating;
