import { Fragment, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from '../config/routes';
import { Loader } from '../components/Loader';
import { AuthStore } from '../stores/auth';
import Storage from '../utils/storage';
import { setAuthToken } from '../utils/axios';
import Page404 from '../pages/Page404';
import { differenceInSeconds, parseISO } from 'date-fns';

const Routes = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkToken = async () => {
      try {
        const token = Storage.getToken();
        const refreshToken = Storage.get('refresh_token');
        const expired = Storage.get('token_expired');
        const expiredDate = parseISO(expired);
        const difference = differenceInSeconds(expiredDate, new Date()); //sec
        if(difference > 0){
          AuthStore.startRefrashTimer(expired);
        }else{
          await AuthStore.refrashToken();
        }
        setAuthToken(token, refreshToken);
        await AuthStore.whoAmI();
      } catch (e) {
        //
      } finally {
        setLoading(false);
      }
    };
    checkToken();
  }, []);

  if (loading) return <Loader />;

  return (
    <Switch>
      {Object.keys(routes).map((routeKey) => {
        const route = routes[routeKey];
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component: any = route.component;

        return (
          <Route
            key={`route-${route.path}`}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  <Component {...props} />
                </Layout>
              </Guard>
            )}
          />
        );
      })}
      <Route path='*' render={Page404} />
    </Switch>
  );
};

export default Routes;
