import styled from 'styled-components';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FC, memo, useMemo } from 'react';
import { font } from '../styled/mixins/fonts';

interface Props {
  categories: string[];
  data: number[][];
}
const CombinedChart: FC<Props> = memo(({data = [], categories = []}) => {
  const chartOptions = useMemo(() => {
    return {
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      xAxis: {
        categories,
        labels: {
          style: {
            fontFamily: 'Roboto Condensed Regular',
            fontSize: 12,
          },
        },
      },
      yAxis: {
        visible: false,
      },
      legend: {
        margin: 20,
        itemMarginBottom: 6,
        itemStyle: {
          fontSize: 12,
          fontFamily: 'Montserrat Medium',
          opacity: 0.5,
          fontWeight: 'normal',
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          pointPadding: 0,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: [
        {
          type: 'column',
          name: 'Прирост долга',
          data: data[0],
          stack: 'male',
          color: '#172B75',
          dataLabels: {
            enabled: true,
            format: '<div style="margin-left: 100px; font-size: 14px;">' + '{point.y}</div>',
            useHTML: true,
            align: 'left',
            style: {
              fontFamily: 'Montserrat Bold',
              color: '#000',
            },
          },
        },
        {
          type: 'column',
          name: 'Прочие доходы',
          color: '#A3A9D4',
          data: data[1],
          stack: 'male',
          dataLabels: {
            enabled: true,
            format: '<div style="font-size: 16px">' + '{point.y}</div>',
            useHTML: true,
            align: 'center',
            style: {
              fontFamily: 'Montserrat Bold',
              color: '#fff',
            },
          },
        },
        {
          type: 'column',
          name: 'Собственные доходы',
          color: '#646CAA',
          data: data[2],
          stack: 'male',
          dataLabels: {
            enabled: true,
            format: '<div style="font-size: 16px">' + '{point.y}</div>',
            useHTML: true,
            align: 'center',
            style: {
              fontFamily: 'Montserrat Bold',
              color: '#fff',
            },
          },
        },
        // {
        //   type: 'column',
        //   name: '',
        //   color: 'rgba(242, 153, 74, 0.24)',
        //   data: [188, 284, 293, 343, 369],
        //   stack: 'female',
        //   dataLabels: {
        //     enabled: true,
        //     format: '<div style="font-size: 16px">' + '{point.y}</div>',
        //     useHTML: true,
        //     align: 'center',
        //     style: {
        //       fontFamily: 'Montserrat Bold',
        //       color: '#000',
        //     },
        //   },
        // },
        {
          type: 'line',
          name: 'Объем долга',
          data: data[3],
          color: '#F2994A',
          marker: {
            lineWidth: 2,
            lineColor: '#F2994A',
            fillColor: 'white',
            symbol: 'circle',
          },
          dataLabels: {
            enabled: true,
            inside: true,
            format: '<div style="font-size: 16px; margin-left: 25px">' + '{point.y}</div>',
            useHTML: true,
            align: 'left',
            style: {
              fontFamily: 'Montserrat Bold',
              color: '#000',
            },
          },
        },
        {
          type: 'line',
          name: 'Рыночный долг',
          data: data[4],
          color: '#EB5757',
          marker: {
            lineWidth: 2,
            lineColor: '#EB5757',
            fillColor: 'white',
          },
          dataLabels: {
            enabled: true,
            format:
              '<div style="font-size: 16px; margin-left: 25px; color: #EB5757">' +
              '{point.y}</div>',
            useHTML: true,
            align: 'left',
            style: {
              fontFamily: 'Montserrat Bold',
              color: '#000',
            },
          },
        },
      ],
    };
  }, [categories, data]);

  return (
    <ComponentContainer>
      <Heading>
        <Title>Расходы</Title>
        <Subtitle>млрд. руб.</Subtitle>
      </Heading>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </ComponentContainer>
  );
});

const ComponentContainer = styled.div``;
const Title = styled.div`
  ${({ theme }) => font({ size: theme.fonts.sizes.xxmd, family: theme.fonts.montserrat.semibold })};
`;
const Subtitle = styled.div`
  ${({ theme }) => font({ color: theme.colors.dark })};
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export default CombinedChart;
