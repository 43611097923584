import Card from '../../../components/Card';
import ComplexTable from '../../../components/ComplexTable';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getSocNeeds } from '../../../actions';
import { AppStoreContext } from '../../../stores/app';
import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Loader } from '../../../components/Loader';

interface Props {
  filter: any;
}

const DevelopmentSocialNeeds: FC<Props> = observer(({ filter }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selectedOmsu } = useContext(AppStoreContext);

  const fetchData = useCallback(async () => {
    if (selectedOmsu) {
      try {
        setLoading(true);
        const params = {
          omsuId: selectedOmsu.id,
          periodId: filter.selectedYear?.id,
        };
        // console.log(params);
        const response = await getSocNeeds(params);
        setData(response.data?.items || []);
      } catch (e) {
        //
      } finally {
        setLoading(false);
      }
    }
  }, [selectedOmsu, filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData, selectedOmsu]);

  const tableConfig = useMemo(() => {
    
    let tableData = [];
    let tableFields = [];

    if (data) {

      const fillData = (itm) => {
        return {
          title: itm?.title || ' ',
          year1Val: isNumber(itm.year1Val) ? itm.year1Val.toString() : '-',
          year2Val: isNumber(itm.year2Val) ? itm.year2Val.toString() : '-',
          year3Val: isNumber(itm.year3Val) ? itm.year3Val.toString() : '-',
          children: itm?.children?.map(item => fillData(item)),
        }
      }

      tableData = data.map((itm) => fillData(itm));

      tableFields = [
        {
          id: 0,
          label: '',
          field: 'title',
          width: '82%',
          sorting: false,
          colspan: 1,
          options: {},
        },
        {
          id: 1,
          label: `${filter?.selectedYear?.appliesToYear ? filter?.selectedYear?.appliesToYear : '-'}`,
          field: 'year1Val',
          width: '6%',
          sorting: false,
          colspan: 1,
          options: {},
        },
        {
          id: 2,
          label: `${filter?.selectedYear?.appliesToYear ? filter?.selectedYear?.appliesToYear + 1 : '-'}`,
          field: 'year2Val',
          width: '6%',
          sorting: false,
          colspan: 1,
          options: {},
        },
        {
          id: 3,
          label: `${filter?.selectedYear?.appliesToYear ? filter?.selectedYear?.appliesToYear + 2 : '-'}`,
          field: 'year3Val',
          width: '6%',
          sorting: false,
          colspan: 1,
          options: {},
        },
      ]
    }

    return {
      tableData,
      tableFields
    };
  }, [data]);

  return (
    <Card>
      {loading ? <Loader/> :
        <ComplexTable
          fields={[[...tableConfig.tableFields || []]]}
          data={tableConfig.tableData}
          loading={loading}
        />
      }
    </Card>
  );
});

export default DevelopmentSocialNeeds;
