import { Modal } from '../../../../../components/Modal';
import { connectModal } from '../../../../../hocs/Modal';
import IndicatorsModalHeader from './IndicatorsModalHeader';
import { fields } from './fields';
import styled from 'styled-components';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppStoreContext } from '../../../../../stores/app';
import { getExecutionEconomicIndicatorDetails } from '../../../../../actions';
import { observer } from 'mobx-react-lite';
import ComplexTable from '../../../../../components/ComplexTable';
import Text from '../../../../../components/Text';
import TextWithDefinition, { TWD } from '../../../../../components/TextWithDefinition';
import TablePeriod from './TablePeriod';
import { history } from '../../../../../routes/history';
import { indicatorPath } from '../../../../../config/routes';
import { Loader } from '../../../../../components/Loader';
import { prepareNumber } from '../../../../../utils/prepareNumber';

const IndicatorsModal = connectModal('indicators-modal')(
  observer(({ handleHide, group, cellType, title }) => {
    const { selectedOmsu, selectedYear, selectedCiogv } = useContext(AppStoreContext);
    // const { selectedOmsu } = useContext(AppStoreContext);

    const [tableInfo, setTableInfo] = useState([]);
    const [tableInfoLoading, setTableInfoLoading] = useState<boolean>(true);

    useEffect(() => {
      const fetchData = async () => {
        setTableInfoLoading(true);
        try {
          const response = await getExecutionEconomicIndicatorDetails({
            omsuId: selectedOmsu.id,
            group,
            filterType: cellType,
            groupName: title,
            periodId: selectedYear.id,
            ciogv: selectedCiogv.id,
          });
          setTableInfo(response.data.items);
        } catch (e) {
          //
        } finally {
          setTableInfoLoading(false);
        }
      };
      fetchData();
      }, [selectedOmsu, selectedYear, selectedCiogv]);
    // }, [selectedOmsu, group]);

    const tableConfig = useMemo(() => {
      let tableData = [];

      if (tableInfo) {   
        tableData = tableInfo.map((item) => ({
          id: item.id,
          name: <Text data={item?.name} />,
          plan: (
            <TextWithDefinition
              text={prepareNumber(item?.planValue, item?.precision)}
              definition={item?.unit}
              position={TWD.CENTER}
            />
          ),
          done: <TextWithDefinition text={prepareNumber(item?.progress, 0)} definition={'%'} position={TWD.CENTER} />,
          period: (
            <TablePeriod
              period={item?.period}
              dynamics={item?.dynamics}
              factText={item?.factValue}
              factDefinition={item?.unit}
              precision={item?.precision}
            />
          ),
          options: {
            styles: {
              name: {
                padding: '8px 0',
              },
            },
          },
        }));
      }

      return tableData;
    }, [tableInfo]);

    const onRowClick = useCallback(
      (item) => {
        history.push(`${indicatorPath}/${item.id}?omsuId=${selectedOmsu?.id}&periodId=${selectedYear.id}`);
      },
      [selectedOmsu],
    );

    return (
      <Modal
        title={title}
        activateLineClamp
        onClose={handleHide}
        header={<IndicatorsModalHeader count={tableInfo?.length} />}
      >
        <Content>
          {tableInfoLoading ? <Loader/> :
            <ComplexTable
              fields={[fields]}
              data={tableConfig}
              sticky={false}
              loading={tableInfoLoading}
              onRowClick={onRowClick}
            />
          }
        </Content>
      </Modal>
    );
  }),
);

const Content = styled.div`
  width: 100%;
  padding: 24px;
`;

export default IndicatorsModal;
