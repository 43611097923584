import styled from 'styled-components';
import SelectList from '../../../components/Calendar/SelectList';
import Select from '../../../components/Select';
import { TableTypes } from '..';
import { FC, useContext, useEffect, useState } from 'react';
import { getIndicatorTotal } from '../../../actions';

interface Props {
  indicatorId: number | string;
  tableType: TableTypes;
  filter: any;
  setFilter: any;
  setInnerFilterCallbacks?: ({
    id,
    callback,
    list,
  }: {
    id: number | string;
    callback: any;
    list: any;
  }) => void;
}

const SectionAddition: FC<Props> = ({
  indicatorId,
  tableType,
  filter,
  setFilter,
  setInnerFilterCallbacks,
}) => {
  const [filterData, setFilterData] = useState([]);
  const [filterDataLoading, setFilterDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setFilterDataLoading(true);
      try {
        const response = await getIndicatorTotal(indicatorId);
        const list = response?.data?.items?.map((item) => ({
          ...item,
          id: item.id,
          value: item.id,
          label: item.name,
        }));

        setFilterData(list);
        if (setInnerFilterCallbacks) {
          setInnerFilterCallbacks({ id: indicatorId, callback: setFilter, list });
        }
      } catch (e) {
        //
      } finally {
        setFilterDataLoading(false);
      }
    };
    fetchData();
  }, [setInnerFilterCallbacks, indicatorId, setFilter]);

  useEffect(() => {
    if (!filter?.viewType && filterData) {
      setFilter('viewType', filterData[0]);
    }
  }, [filterData]);

  return (
    <ComponentContainer>
      <Select
        renderList={({ active, onClose }) => (
          <SelectList
            active={active}
            onClose={onClose}
            list={filterData}
            onClick={(el) => setFilter('viewType', el)}
          />
        )}
        label={''}
        value={filter?.viewType}
        fieldFullsize={false}
      />
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div``;

export default SectionAddition;
