import { FC } from 'react';
import styled from 'styled-components';
import { font } from '../styled/mixins/fonts';

export enum TWD {
  LEFT,
  CENTER,
  RIGHT,
}

interface Props {
  text: string;
  definition: string;
  position?: TWD;
}

const calcPosition = (position: TWD) => {
  switch (position) {
    case TWD.CENTER:
      return 'center';
    case TWD.LEFT:
      return 'left';
    case TWD.RIGHT:
      return 'right';
    default:
      return 'left';
  }
};

const TextWithDefinition: FC<Props> = ({ text, definition, position = TWD.LEFT }) => {
  return (
    <ComponentContainer position={calcPosition(position)}>
      <Text>{text}</Text>
      <Definition>{definition}</Definition>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div<{ position: string }>`
  display: flex;
  align-items: flex-end;
  justify-content: ${({ position }) => position};
`;
const Text = styled.div`
  ${({ theme }) => font({ family: theme.fonts.robotoCondensed.regular })};
  margin-right: 4px;
`;
const Definition = styled.div`
  ${({ theme }) =>
    font({
      family: theme.fonts.robotoCondensed.regular,
      size: theme.fonts.sizes.sm,
      color: theme.colors.dark,
    })}
`;

export default TextWithDefinition;
