import AreaIcon from '../../../assets/icons/AreaIcon';
import GroupIcon from '../../../assets/icons/GroupIcon';
import WorkerIcon from '../../../assets/icons/WorkerIcon';
import ShopIcon from '../../../assets/icons/ShopIcon';
import ProductionIcon from '../../../assets/icons/ProductionIcon';
import ManagementIcon from '../../../assets/icons/ManagementIcon';
import { ModalsStore } from '../../../stores/modal';

export enum ChartSector {
  RISK = 'risk',
  BEFORE_RISK = 'beforeRisk',
  OPENED = 'opened',
  ACHIEVED = 'achieved'
}

export const infoConfig = [
  {
    id: 0,
    icon: <AreaIcon />,
    data: [
      {
        id: 0,
        amount: 1683.51,
        amountType: 'км²',
        label: 'Площадь',
      },
    ],
  },
  {
    id: 1,
    icon: <GroupIcon />,
    data: [
      {
        id: 0,
        amount: 39303,
        amountType: 'чел.',
        label: 'Население',
      },
    ],
  },
  {
    id: 2,
    icon: <WorkerIcon />,
    data: [
      {
        id: 0,
        amount: 21.0,
        amountType: 'тыс. чел. 53%',
        label: 'Трудоспособное население',
      },
    ],
  },
];

export const specializationConfig = [
  {
    id: 0,
    icon: <ShopIcon />,
    data: [
      {
        id: 0,
        amount: 0.45,
        amountType: '%',
        label: 'Оптовая торговля',
      },
    ],
  },
  {
    id: 1,
    icon: <ProductionIcon />,
    data: [
      {
        id: 0,
        amount: 2.15,
        amountType: '%',
        label: 'Производство металлических изделий',
      },
    ],
  },
  {
    id: 2,
    icon: <ManagementIcon />,
    data: [{ id: 0, amount: 0.69, amountType: '%', label: 'Государственное управление' }],
  },
];

export const risksConfig = [
  {
    id: 0,
    title: 'Объекты',
    data: {
      riskedObjectsAmount: 2,
      preRiskedObjectsAmount: 3,
      dataopenedObjectsAmount: 4,
    },
    onClick: (e) => ModalsStore.showModal('risks-objects-modal', { chartSector: e.options.code })
  },
  {
    id: 1,
    title: 'Показатели',
    data: [],
    onClick: () => ModalsStore.showModal('risks-indicators-modal'),
  },
];

export const generateAgendaObjectsConfig = (arr) => {
  const list = [];
  list.push({
    id: 0,
    code: ChartSector.RISK,
    name: 'Риск',
    y: arr[0],
    color: '#FC5200',
  });
  list.push({
    id: 1,
    code: ChartSector.BEFORE_RISK,
    name: 'Предриск',
    y: arr[1],
    color: '#F2C94C',
  });
  list.push({
    id: 2,
    code: ChartSector.OPENED,
    name: 'Открыто',
    y: arr[2],
    color: '#64AA7A',
  });
  return list;
};

export const generateAgendaIndicatorsConfig = (arr) => {
  const list = [];
  list.push({
    id: 0,
    name: 'Риск',
    code: ChartSector.RISK,
    y: arr[0],
    color: '#FC5200',
  });
  list.push({
    id: 1,
    name: 'Достигнуто',
    code: ChartSector.ACHIEVED,
    y: arr[1],
    color: '#64AA7A',
  });
  return list;
};
