import styled from 'styled-components';
import { FC } from 'react';
import FilterItem from './FilterItem';

export interface Filter {
  id: number;
  title: string;
  value: string;
  amount?: number;
}

interface Props {
  filters: Filter[];
  active: any;
  onChange: (object) => void;
}

const ButtonFilters: FC<Props> = ({ filters, active, onChange }) => {
  return (
    <ButtonFiltersWrapper>
      {filters.map((item) => (
        <FilterItem
          key={item.id}
          {...item}
          active={active?.value === item.value}
          onClick={onChange}
        />
      ))}
    </ButtonFiltersWrapper>
  );
};

const ButtonFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.04);
  flex: 0 0 auto;
`;

export default ButtonFilters;
