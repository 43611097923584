import styled from 'styled-components';
import { FC } from 'react';
import { FileProps } from '../index';
import { font } from '../../styled/mixins/fonts';

const File: FC<FileProps> = ({ name, link, extension }) => {
  return (
    <ComponentContainer>
      <FileLink href={link} target={'_blank'}>
        <Name>{name}</Name>
        {/*<Ext>.{extension}</Ext>*/}
      </FileLink>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div``;
const FileLink = styled.a`
  color: inherit;
  display: flex;
  text-decoration: none;
  padding: 6px 24px 6px 16px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.hover};
  }
`;
const Name = styled.div`
  ${font()};
`;
const Ext = styled.div`
  ${({ theme }) => font({ color: theme.colors.dark })};
`;

export default File;
