import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import EmptyData from '../EmptyData';
import TableBody from './TableBody';
import TableHeader from './TableHeader';
import TableLoader from './TableLoader';

export interface Field {
  id: number;
  label?: string | ReactNode;
  field?: string | ReactNode | Element;
  width?: string;
  sorting?: boolean;
  colspan?: number;
  options?: any;
}

interface Props {
  fields: Field[][];
  data: any[];
  emptyPlaceholder?: string | ReactNode;
  onRowClick?: (el: any) => any;
  sorting?: any;
  onSortingChange?: (el: any) => any;
  hideZero?: boolean;
  total?: any;
  setSortingCallback?: any;
  sticky?: boolean;
  orderedRows?: boolean;
  loading?: boolean;
}

const ComplexTable: FC<Props> = ({
  fields,
  data,
  sorting = {},
  setSortingCallback,
  onSortingChange = () => null,
  onRowClick,
  emptyPlaceholder = <EmptyData />,
  hideZero,
  sticky = true,
  loading = false,
  total,
}) => {
  return (
    <Wrapper>
      {data.length ? (
        <>
          {loading && <TableLoader />}
          <TableWrapper>
            <TableHeader
              fields={fields}
              order={!!data.find((el) => el.options?.order)}
              sticky={sticky}
              sorting={sorting}
              setSortingCallback={setSortingCallback}
            />
            <TableBody
              fields={fields[fields.length - 1]}
              total={total}
              data={data}
              sorting={sorting}
              onRowClick={onRowClick}
            />
          </TableWrapper>
        </>
      ) : (
        <Empty>{emptyPlaceholder}</Empty>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  justify-content: space-between;
`;
const TableWrapper = styled.div`
  display: table;
  width: 100%;
  //border-collapse: collapse;
`;
const Empty = styled.div`
  padding: 30px 30%;
`;

export default ComplexTable;
