import styled from 'styled-components';
import { FC } from 'react';
import { FileType } from '..';
import DownloadIcon from '../../../assets/icons/DownloadIcon';
import { font } from '../../styled/mixins/fonts';

const File: FC<FileType> = ({ name, type, size, path }) => {
  return (
    <FileWrapper href={path} target={'_blank'}>
      <Name>{name}</Name>
      <Info>
        <Type>{type}</Type>
        {!!size && parseInt(size) > 0 && <FileSize>{`${size} Kb`}</FileSize>}
        <InfoItem>
          <DownloadIcon />
        </InfoItem>
      </Info>
    </FileWrapper>
  );
};

const FileWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 6px 12px 6px 16px;
  text-decoration: none;
  color: inherit;
  overflow: hidden;
`;
const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;
const Name = styled.div`
  ${font()};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1 1 auto;
`;
const InfoItem = styled.div`
  ${({ theme }) => font({ size: '0', color: theme.colors.dark })};
`;
const FileSize = styled(InfoItem)`
  width: 60px;
  flex: 0 0 auto;
`;

const Type = styled(InfoItem)`
  width: 40px;
  flex: 0 0 auto;
`;

export default File;
