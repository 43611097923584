import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import { formatDate } from '../../../utils/handleTime';
import { font } from '../../styled/mixins/fonts';
import Icon from '../../Icon';
import ArrowIcon from '../../../assets/icons/ArrowIcon';

interface ContentProps {
  fields: any[];
  uniqKey?: string;
  item: any;
  level?: number;
  hideZero?: boolean;
  isGroup?: boolean;
  isTotal?: boolean;
  open?: boolean;
}

const Content: FC<ContentProps> = ({
  fields,
  item,
  uniqKey,
  level = 0,
  hideZero,
  isGroup,
  isTotal,
  open,
}) => {
  return (
    <>
      {fields.map((element, index) => {
        let content = get(item, element.field, '');
        if (index === 0) {
          content = (
            <FirstColumn options={element.options}>
              {isGroup && (
                <IconWrapper>
                  <Icon opacity={0.56} rotate={open ? -90 : 0}>
                    <ArrowIcon />
                  </Icon>
                </IconWrapper>
              )}
              {content}
            </FirstColumn>
          );
        }
        if (hideZero && content === 0) {
          content = '';
        }
        if (element.handleField) {
          content = element.handleField(item);
        } else if (element.component) {
          content = <element.component item={item} />;
        } else if (element.type === 'date') {
          content = formatDate(content, 'DD.MM.YYYY');
        } else if (element.type === 'datetime') {
          content = formatDate(content, 'DD.MM.YYYY HH:mm:ss');
        } else if (element.options) {
          content = element.options[content] || content;
        }
        return (
          <TBodyCell
            key={`${item[uniqKey]}-${index}`}
            level={index === 0 ? level : 0}
            isTotal={isTotal}
            onClick={() => null}
            options={element.options}
          >
            {content}
          </TBodyCell>
        );
      })}
    </>
  );
};

interface GroupProps {
  fields: any[];
  uniqKey?: string;
  onRowClick?: (el: any) => any;
  item: any;
  childKey?: string;
  level: number;
  hideZero?: boolean;
}

const Group: FC<GroupProps> = ({
  item,
  fields,
  onRowClick,
  uniqKey,
  childKey,
  level,
  hideZero,
}) => {
  const [open, setOpen] = useState(false);
  const childs = item[childKey] || [];
  const isGroup = !!childs.length;
  return (
    <>
      <TBodyRow
        pointer={!!onRowClick}
        onClick={() => (isGroup ? setOpen(!open) : onRowClick(item))}
      >
        <Content
          fields={fields}
          item={item}
          uniqKey={uniqKey}
          level={level}
          hideZero={hideZero}
          isGroup={isGroup}
          open={open}
        />
      </TBodyRow>
      {isGroup &&
        open &&
        childs.map((el) => (
          <Group
            key={el[uniqKey]}
            level={level + 1}
            fields={fields}
            item={el}
            onRowClick={onRowClick}
            uniqKey={uniqKey}
            childKey={childKey}
            hideZero={hideZero}
          />
        ))}
    </>
  );
};

interface TBodyProps {
  fields: any[];
  data: any[];
  uniqKey?: string;
  onRowClick?: (el: any) => any;
  childKey?: string;
  hideZero?: boolean;
  total?: any;
}

const TBody: FC<TBodyProps> = ({ fields, data, onRowClick, childKey, uniqKey, hideZero }) => {
  return (
    <>
      {data.map((item) => {
        return (
          <Group
            key={`group-${item[uniqKey]}`}
            item={item}
            fields={fields}
            onRowClick={onRowClick}
            uniqKey={uniqKey}
            childKey={childKey}
            level={0}
            hideZero={hideZero}
          />
        );
      })}
    </>
  );
};

const TBodyRow = styled.div<{ isTotal?: boolean; pointer: boolean }>`
  display: table-row;
  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${({ theme }) => theme.colors.hover};
      }
    `}
  .show-on-hover {
    display: none;
  }
`;

const TBodyCell = styled.div<{ level: number; isTotal?: boolean; options: any }>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  display: table-cell;
  vertical-align: middle;
  padding: 8px 0;
  text-align: ${({ options }) => options?.text};
  ${({ theme, options }) =>
    font({
      family: options?.fontFamily || theme.fonts.robotoCondensed.regular,
    })};
  &:first-child {
    ${font()};
    letter-spacing: -0.04px;
  }
`;
const FirstColumn = styled.div<{ options: any }>`
  display: flex;
  align-items: center;
  ${({ theme, options }) =>
    font({
      family: options?.fontFamily || theme.fonts.robotoCondensed.regular,
    })};
`;
const IconWrapper = styled.div`
  margin-right: 4px;
`;

export default TBody;
