import Chart from '../../../components/Chart';
import Card from '../../../components/Card';
import styled from 'styled-components';
import Divider from '../../../components/Divider';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { getEvoConsolidatedInformation } from '../../../actions';
import { Loader } from '../../../components/Loader';
import { AppStoreContext } from '../../../stores/app';
import { observer } from 'mobx-react-lite';
import { scrollIntoViewBySelector } from '../../../utils/scrollIntoView';

enum ChartTypes {
  INDUSTRIAL = 1,
  INVESTMENT = 2,
  LANDS = 3,
  NEW_WORK_PLACES = 4,
}

const DevelopmentSummary: FC = observer(() => {
  const { selectedOmsu } = useContext(AppStoreContext);

  const [chartData, setChartData] = useState(null);
  const [chartDataLoading, setChartDataLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedOmsu) {
      const fetchData = async () => {
        setChartDataLoading(true);
        try {
          const response = await getEvoConsolidatedInformation({
            omsuId: selectedOmsu.id,
          });
          setChartData(response.data.items);
        } catch (e) {
          //
        } finally {
          setChartDataLoading(false);
        }
      };
      fetchData();
    }
  }, [selectedOmsu]);

  const chartsConfig = useMemo(() => {
    if (chartData) {
      return chartData.map((itm) => ({
        title: itm.name,
        subtitle: `Всего${itm.units ? `・${itm.units}.` : ''}`,
        total: itm.total,
        id: itm.id,
        onClick: (that) => {
          let anchor,
            withClick = false;
          if (itm.typeView === ChartTypes.NEW_WORK_PLACES) {
            if (that.id === 0) {
              withClick = true;
              anchor = 'new_workplaces_red';
            } else if (that.id === 1) {
              withClick = true;
              anchor = 'new_workplaces_green';
            } else {
              anchor = 'new_workplaces';
            }
          } else if (itm.typeView === ChartTypes.INVESTMENT) {
            if (that.id === 0) {
              withClick = true;
              anchor = 'investments_red';
            } else if (that.id === 1) {
              withClick = true;
              anchor = 'investments_green';
            } else {
              anchor = 'investments';
            }
          } else if (itm.typeView === ChartTypes.INDUSTRIAL) {
            if (that.id === 0) {
              withClick = true;
              anchor = 'industrial_parks_red';
            } else if (that.id === 1) {
              withClick = true;
              anchor = 'industrial_parks_green';
            } else {
              anchor = 'industrial_parks';
            }
          } else if (itm.typeView === ChartTypes.LANDS) {
            if (that.id === 0) {
              withClick = true;
              anchor = 'land_red';
            } else if (that.id === 1) {
              withClick = true;
              anchor = 'land_green';
            } else {
              anchor = 'land';
            }
          }
          if (anchor) {
            scrollIntoViewBySelector(anchor, 40);
            if (withClick) {
              const element = document.getElementById(anchor);
              if (element && element.dataset.open === '0') {
                element.click();
              }
            }
          }
        },
        data: [
          {
            id: 0,
            name: itm.redZoneTitle,
            y: itm.redZone,
            color: '#EB5757',
          },
          {
            id: 1,
            name: itm.greenZoneTitle,
            y: itm.greenZone,
            color: '#27AE60',
          },
        ],
      }));
    }
  }, [chartData]);

  return (
    <Card>
      <Content>
        {chartDataLoading ? (
          <Loader />
        ) : (
          <>
            <Row>
              {chartsConfig?.slice(0, 2).map((el) => (
                <Chart key={el.id} {...el} width={400} />
              ))}
            </Row>
            <Divider />
            <Row>
              {chartsConfig?.slice(2, 4).map((el) => (
                <Chart key={el.id} {...el} width={400} />
              ))}
            </Row>
          </>
        )}
      </Content>
    </Card>
  );
});

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;

  & > div {
    flex: 0 0 50%;
  }

  @media all and (max-width: 1200px) {
    zoom: 0.9;
  }
  @media all and (max-width: 1000px) {
    zoom: 0.7;
  }
  @media all and (max-width: 800px) {
    zoom: 0.6;
  }
  @media all and (max-width: 750px) {
    zoom: 0.5;
  }
  @media all and (max-width: 650px) {
    zoom: 0.4;
  }
`;

export default DevelopmentSummary;
