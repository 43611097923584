import { useCallback, useEffect, useState } from 'react';
import Storage from '../utils/storage';
import {
  getStorageSortingValue,
  getStorageValue,
  setStorageValue,
} from '../utils/handleStorageValue';
import { toJS } from 'mobx';

export const useFilter = ({
  initialFilterValues,
  initialSortingValues = {},
  name,
  sortingName = 'defaultSorting',
  dateFields = [],
  disableCache = false,
}) => {
  const [filter, setFilter] = useState(
    !disableCache ? getStorageValue({ name, dateFields, values: initialFilterValues }) : initialFilterValues,
  );

  const [sorting, setSortIng] = useState(
    !disableCache ? getStorageSortingValue({ name: sortingName, values: initialSortingValues }) : initialSortingValues,
  );

  const setFilterCallback = useCallback((name, value) => {
    setFilter((state) => ({
      ...state,
      [name]: value,
    }));
  }, []);

  const setSortingCallback = useCallback((value) => {
    setSortIng(value);
  }, []);

  const setMassFilterCallback = useCallback((obj) => {
    setFilter((state) => ({
      ...state,
      ...obj,
    }));
  }, []);

  useEffect(() => {
    !disableCache && setStorageValue({ name, values: filter });
  }, [name, filter]);

  useEffect(() => {
    !disableCache && setStorageValue({ name: sortingName, values: sorting });
  }, [name, sorting]);

  return { filter, sorting, setFilterCallback, setMassFilterCallback, setSortingCallback };
};
