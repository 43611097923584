import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import THead from './THead';
import TBody from './TBody';
import NoData from '../NoData';

interface Props {
  fields: any[];
  data: any[];
  emptyPlaceholder?: string | ReactNode;
  uniqKey?: string;
  onRowClick?: (el: any) => any;
  childKey?: string;
  sorting?: any;
  onSortingChange?: (el: any) => any;
  hideZero?: boolean;
  total?: any;
}

const SimpleTable: FC<Props> = ({
  fields,
  data,
  sorting = {},
  onSortingChange = () => null,
  onRowClick,
  childKey = '',
  uniqKey = 'id',
  emptyPlaceholder = <NoData description={'Нет данных'} />,
  hideZero,
  total,
}) => {
  return (
    <Wrapper>
      {data.length ? (
        <TableContent>
          <TableWrapper>
            <THead fields={fields} sorting={sorting} onSortingChange={onSortingChange} />
            <TBody
              total={total}
              uniqKey={uniqKey}
              fields={fields}
              data={data}
              onRowClick={onRowClick}
              childKey={childKey}
              hideZero={hideZero}
            />
          </TableWrapper>
        </TableContent>
      ) : (
        emptyPlaceholder
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
  justify-content: space-between;
`;

const TableContent = styled.div`
  display: flex;
  overflow-y: auto;
`;

const TableWrapper = styled.div`
  display: table;
  width: 100%;
`;

export { SimpleTable };
