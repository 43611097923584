import React, { FC, ReactElement, useCallback, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Menu from './Menu';
import Header from './Header';
import { isMobile } from 'react-device-detect';
import { AppStoreContext } from '../../stores/app';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react-lite';

const MainLayout: FC<{ children: ReactElement }> = observer(({ children }) => {
  const { menuIsOpen, setMenuIsOpen } = useContext(AppStoreContext);
  const currentWidth = useRef(window.innerWidth);
  const timer: any = useRef();
  const toggleOpen = useCallback(() => {
    clearTimeout(timer.current);
    setMenuIsOpen(!menuIsOpen);
    timer.current = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }, [menuIsOpen]);

  useEffect(() => {
    if (isMobile && menuIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMobile, menuIsOpen]);

  useEffect(() => {
    const handleResizeOpen = debounce(() => {
      if (currentWidth.current !== window.innerWidth) {
        clearTimeout(timer.current);
        setMenuIsOpen(window.innerWidth > 1366);
        timer.current = setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 500);
      }
      currentWidth.current = window.innerWidth;
    }, 100);
    window.addEventListener('resize', handleResizeOpen);
    return () => {
      window.removeEventListener('resize', handleResizeOpen);
      clearTimeout(timer.current);
    };
  }, [setMenuIsOpen]);

  return (
    <LayoutWrapper>
      <Menu isOpen={menuIsOpen} toggleOpen={toggleOpen} />
      <Content isOpen={menuIsOpen} isMobile={isMobile}>
        <Header isOpen={menuIsOpen} />
        <ChildrenWrapper id='scrollable-content-wrapper'>{children}</ChildrenWrapper>
      </Content>
    </LayoutWrapper>
  );
});

const LayoutWrapper = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`;
const Content = styled.div<{ isMobile: boolean; isOpen: boolean }>`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding-left: ${({ isMobile, isOpen }) => (isMobile ? '80px' : !isOpen ? '80px' : '280px')};
`;

const ChildrenWrapper = styled.div`
  margin-top: 65px;
  overflow: auto;
`;

export default MainLayout;
