import styled from 'styled-components';
import { FC, useContext, useMemo } from 'react';
import Card, { CardTheme } from '../../../components/Card';
import RegionMap from '../../../components/Map';
import RisksIndicatorsModal from './RisksIndicatorsModal';
import RisksObjectsModal from './RisksObjectsModal';
import { AppStoreContext } from '../../../stores/app';
import { Button } from '../../../components/Button';
import { font } from '../../../components/styled/mixins/fonts';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { observer } from 'mobx-react-lite';
import { ModalsStore } from '../../../stores/modal';
import { useLocation } from 'react-router-dom';
import { NsiStoreContext } from '../../../stores/nsi';
import RatingData from './RatingData';
import RatingInfoData from './RatingInfoData';
import InfoData from './InfoData';
import RiskOMSUData from './RiskOMSUData';
import OMSUData from '../AgendaEconomics/OMSUData';
import Tables from '../AgendaEconomics/Tables';

interface Props {
  noCityDistrictHead?: boolean;
  hideMap?: boolean;
  showOMSUData?: boolean;
  showTables?: boolean;
  tablesWithoutPeriod?: boolean;
}

const AgendaRating: FC<Props> = observer(
  ({
    noCityDistrictHead = false,
    hideMap = false,
    showOMSUData = false,
    showTables = false,
    tablesWithoutPeriod = false,
  }) => {
    const { selectedOmsu } = useContext(AppStoreContext);
    const { currentMonth } = useContext(NsiStoreContext);
    const location = useLocation();

    const filter = useMemo(() => {
      return { selectedMonth: currentMonth };
    }, [currentMonth]);

    return (
      <>
        <Card>
          <CardWrapper>
            <ContentWrapper>
              {location.pathname !== '/agenda' && (
                <Heading>
                  <Title>Рейтинг на {format(new Date(), 'dd MMMM yyyy', { locale: ru })}</Title>
                  <Button hollow onClick={() => ModalsStore.showModal('rating-modal')}>
                    Подробнее...
                  </Button>
                </Heading>
              )}
              <TopContent>
                <Side>
                  <RatingData />
                  <Card theme={CardTheme.DARK}>
                    <Content>
                      <RatingInfoData />
                    </Content>
                  </Card>
                </Side>
                {!hideMap && (
                  <Side>
                    <RegionMap omsuId={selectedOmsu?.id} />
                  </Side>
                )}
              </TopContent>
              <InfoData />
              {showOMSUData && (
                <ComponentWrapper>
                  <OMSUData />
                </ComponentWrapper>
              )}
              {showTables && (
                <ComponentWrapper>
                  <Tables
                    filter={filter}
                    hideIndustriesTaxes={true}
                    tablesWithoutPeriod={tablesWithoutPeriod}
                  />
                </ComponentWrapper>
              )}
              <BottomContent>
                <RiskOMSUData />
              </BottomContent>
            </ContentWrapper>
          </CardWrapper>
        </Card>
        <RisksObjectsModal />
        <RisksIndicatorsModal />
      </>
    );
  },
);

const ComponentWrapper = styled.div`
  margin-bottom: 24px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Side = styled.div`
  flex: 1 0 calc(50% - 24px);
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const TopContent = styled.div`
  display: flex;
  margin-bottom: 32px;
  flex-wrap: wrap;
  > div {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }
  @media all and (max-width: 1200px) {
    flex-direction: column;
    > div {
      margin-right: 0;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const ContentWrapper = styled.div`
  padding: 24px 24px 0 24px;
`;
const Content = styled.div`
  padding: 16px;
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;
const Title = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xxmd,
      family: theme.fonts.montserrat.semibold,
      lineHeight: '34px',
    })}
`;

export default AgendaRating;
