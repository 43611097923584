import styled from 'styled-components';
import SelectList from '../../../components/Calendar/SelectList';
import Select from '../../../components/Select';
import FiltersList from './FiltersList';
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { NsiStoreContext } from '../../../stores/nsi';
import { risksEnum } from '../consts';
import SearchList from '../../../components/SearchList';
import SelectField from '../../../components/SelectField';
import { toJS } from 'mobx';
import { da } from 'date-fns/locale';

interface Props {
  filter: any;
  setFilter: any;
  setMassFilterCallback: any;
  data: any[];
  loading?: boolean;
}

const handleCount = (data, field, value, meta) => {
  data.forEach((el) => {
    if (el.children) {
      handleCount(el.children, field, value, meta);
    } else {
      if (el[field] === value) {
        meta.count += 1;
      }
    }
  });
};

const countSum = (arr) => arr.slice(1).reduce((acc, currentValue) => acc + currentValue.count, 0);

const ObjectsAddition: FC<Props> = observer(
  ({ filter, setFilter, setMassFilterCallback, data, loading = false }) => {
    const { Sphere, WorkType, EntityType, years, months } = useContext(NsiStoreContext);
    const [windowWidth, setWindowWidth] = useState(0);
    const additionsRef = useRef<HTMLInputElement>(null);

    const resizeWindow = useCallback(() => {
      setWindowWidth(window.innerWidth);
    }, []);

    useEffect(() => {
      resizeWindow();
      window.addEventListener('resize', resizeWindow);
      return () => window.removeEventListener('resize', resizeWindow);
    }, [resizeWindow]);

    const preparedSphere = useMemo(() => {
      if (data && Sphere.length && !loading) {
        const arr = Sphere.map((el) => {
          const meta = { count: 0 };
          handleCount(data, 'sphereId', el.id, meta);
          return {
            ...el,
            count: meta.count,
          };
        });
        arr[0].count = countSum(arr);
        return arr;
      }
      return [];
    }, [Sphere, data, loading]);

    const preparedEntityType = useMemo(() => {
      if (data && EntityType.length && !loading) {
        const arr = EntityType.map((el) => {
          const meta = { count: 0 };
          handleCount(data, 'entityTypeId', el.id, meta);
          return {
            ...el,
            count: meta.count,
          };
        });
        arr[0].count = countSum(arr);
        return arr;
      }
      return [];
    }, [EntityType, data, loading]);

    const preparedWorkType = useMemo(() => {
      if (data && WorkType.length && !loading) {
        const arr = WorkType.map((el) => {
          const meta = { count: 0 };
          handleCount(data, 'workTypeId', el.id, meta);
          return {
            ...el,
            count: meta.count,
          };
        });
        arr[0].count = countSum(arr);
        return arr;
      }
      return [];
    }, [WorkType, data, loading]);

    const compileYearFilter = useCallback(() => {
      return years?.length && Object.keys(months)?.length ? (
        <>
          {filter.selectedYear && filter.selectedYear?.id !== '-1' && (
            <Select
              renderList={({ active, onClose }) => (
                <SelectList
                  active={active}
                  onClose={onClose}
                  list={months[filter.selectedYear?.id]}
                  onClick={(e: any) => {
                    setFilter('selectedMonth', e);
                  }}
                />
              )}
              label={'Все месяцы'}
              value={filter.selectedMonth}
              fieldFullsize={false}
            />
          )}
          <Select
            key={filter.selectedYear?.id}
            renderList={({ active, onClose }) => (
              <SelectList active={active} onClose={onClose} list={years} onClick={onYearClick} />
            )}
            label={'Все года открытия'}
            value={filter.selectedYear}
            fieldFullsize={false}
          />
        </>
      ) : null;
    }, [years, months, filter.selectedYear, filter.selectedMonth, filter, windowWidth]);

    const listOfFilters = useMemo(() => {
      const arr = [
        {
          element: compileYearFilter(),
          priority: 425,
        },
        {
          element: (
            <Select
              renderList={({ active, onClose }) => (
                <SelectList
                  active={active}
                  onClose={onClose}
                  list={risksEnum}
                  onClick={(e: any) => {
                    setMassFilterCallback(e.filter);
                  }}
                />
              )}
              label={filter.isRisk ? 'Риск' : filter.isBeforeRisk ? 'Предриск' : 'Открытые'}
              value={''}
              fieldFullsize={false}
            />
          ),
          customField: (
            <SelectField
              onChange={(e: any) => setMassFilterCallback(e.filter)}
              list={risksEnum}
              value={filter.isRisk ? 'Риск' : filter.isBeforeRisk ? 'Предриск' : 'Открытые'}
              label={'Выберите риск'}
              placeholder={'Выберите риск'}
            />
          ),
          priority: 1025,
        },
      ];
      if (preparedSphere.length && !loading) {
        arr.push({
          element: (
            <Select
              renderList={({ active, onClose }) => (
                <SearchList
                  active={active}
                  onClose={onClose}
                  list={preparedSphere}
                  onClick={(e: any) => {
                    setFilter('sphere', e);
                  }}
                  withCount={true}
                  withTotalElement={true}
                />
              )}
              label={'Сфера'}
              value={filter.sphere}
              fieldFullsize={false}
            />
          ),
          customField: (
            <SelectField
              placeholder={'Сфера'}
              label={'Сфера'}
              value={filter.sphere}
              renderList={({ active, onClose }) => (
                <SearchList
                  active={active}
                  onClose={onClose}
                  list={preparedSphere}
                  onClick={(e: any) => {
                    setFilter('sphere', e);
                  }}
                  withCount={true}
                  withTotalElement={true}
                />
              )}
            />
          ),
          priority: 575,
        });
      }
      if (preparedEntityType.length && !loading) {
        arr.push({
          element: (
            <Select
              renderList={({ active, onClose }) => (
                <SearchList
                  active={active}
                  onClose={onClose}
                  list={preparedEntityType}
                  onClick={(e: any) => setFilter('entityType', e)}
                  withCount={true}
                  withTotalElement={true}
                />
              )}
              label={'Тип объекта'}
              value={filter.entityType}
              fieldFullsize={false}
            />
          ),
          customField: (
            <SelectField
              placeholder={'Тип объекта'}
              label={'Тип объекта'}
              value={filter.entityType}
              renderList={({ active, onClose }) => (
                <SearchList
                  active={active}
                  list={preparedEntityType}
                  onClick={(e: any) => {
                    setFilter('entityType', e);
                  }}
                  onClose={onClose}
                  withCount={true}
                  withTotalElement={true}
                />
              )}
            />
          ),
          priority: 875,
        });
      }
      if (preparedWorkType.length && !loading) {
        arr.push({
          element: (
            <Select
              renderList={({ active, onClose }) => (
                <SearchList
                  active={active}
                  onClose={onClose}
                  list={preparedWorkType}
                  onClick={(e: any) => setFilter('workType', e)}
                  withCount={true}
                  withTotalElement={true}
                />
              )}
              label={'Вид работ'}
              value={filter.workType}
              fieldFullsize={false}
            />
          ),
          customField: (
            <SelectField
              placeholder={'Вид работ'}
              label={'Вид работ'}
              value={filter.workType}
              renderList={({ active, onClose }) => (
                <SearchList
                  active={active}
                  onClose={onClose}
                  list={preparedWorkType}
                  onClick={(e: any) => setFilter('workType', e)}
                  withCount={true}
                  withTotalElement={true}
                />
              )}
            />
          ),
          priority: 725,
        });
      }
      return arr;
    }, [
      years,
      months,
      filter,
      windowWidth,
      preparedSphere,
      preparedEntityType,
      preparedWorkType,
      loading,
    ]);

    const sortFilters = useCallback(() => {
      const wrapperElement = additionsRef?.current;
      if (wrapperElement) {
        const parentWrapper = wrapperElement.parentElement.parentElement.parentElement;
        // return listOfFilters;
        return listOfFilters.filter((item) => item.priority <= parentWrapper.clientWidth);
      }
    }, [years, months, filter, windowWidth, listOfFilters]);

    const sortCustomFilters = useCallback(() => {
      const wrapperElement = additionsRef?.current;
      if (wrapperElement) {
        const parentWrapper = wrapperElement.parentElement.parentElement.parentElement;
        // return listOfFilters;
        return listOfFilters.filter((item) => item.priority >= parentWrapper.clientWidth);
      }
    }, [years, months, filter, windowWidth, listOfFilters]);

    const onYearClick = useCallback(
      (e: any) => {
        setFilter('selectedYear', e);
        if (e.id === '-1') {
          setFilter('selectedMonth', '');
        } else {
          setFilter('selectedMonth', months[e.id][0]);
        }
      },
      [months],
    );

    useEffect(() => {
      if (
        years?.length &&
        Object.keys(months)?.length &&
        !filter.selectedMonth &&
        !filter.selectedYear
      ) {
        const currentYear =
          years.find((el) => el.appliesToYear === new Date().getFullYear()) || years[0];
        onYearClick(currentYear);
      }
    }, [years, months, filter.selectedYear, filter.selectedMonth]);

    return (
      <ObjectsAdditionWrapper ref={additionsRef}>
        {sortFilters()?.map((item) => item.element)}
        <Select
          fullHeight
          renderList={({ onClose }) => (
            <FiltersList
              filter={filter}
              onClose={onClose}
              setMassFilterCallback={setMassFilterCallback}
              customFilters={sortCustomFilters()}
            />
          )}
          label={'Все фильтры'}
          value={''}
          fieldFullsize={false}
        />
      </ObjectsAdditionWrapper>
    );
  },
);

const ObjectsAdditionWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export default ObjectsAddition;
