import styled from 'styled-components';
import { FC } from 'react';
import Icon from '../Icon';
import CheckboxIcon from '../../assets/icons/CheckboxIcon';
import CheckboxActiveIcon from '../../assets/icons/CheckboxActiveIcon';
import { font } from '../styled/mixins/fonts';

interface Props {
  label: string;
  name: string;
  onChange: ({ check, name }: { check: boolean; name: string }) => void;
  checked: boolean;
}

const Checkbox: FC<Props> = ({ label, name, onChange, checked }) => {
  const handleChange = () => {
    onChange({ check: !checked, name });
  };

  return (
    <ComponentContainer onClick={handleChange}>
      <IconWrapper>
        <Icon opacity={checked ? 1 : 0.3}>
          {checked ? <CheckboxActiveIcon /> : <CheckboxIcon />}
        </Icon>
      </IconWrapper>
      <Label>{label}</Label>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  cursor: pointer;
  padding: 4px 0;
`;
const Label = styled.div`
  ${font()};
  margin-top: 2px;
  margin-left: 12px;
`;
const IconWrapper = styled.div`
  flex: 0 0 auto;
`;

export default Checkbox;
