import React, { FC, useContext, useEffect, useState } from 'react';
import Avatar, { AvatarSize } from '../../../components/Avatar';
import TextField from '../../../components/TextField';
import EmailIcon from '../../../assets/icons/EmailIcon';
import PhoneIcon from '../../../assets/icons/PhoneIcon';
import styled from 'styled-components';
import { font } from '../../../components/styled/mixins/fonts';
import { observer } from 'mobx-react-lite';
import { AppStoreContext } from '../../../stores/app';
import { Loader } from '../../../components/Loader';
import { getMunicipalityInfo } from '../../../actions';
import testAvatar from '../../../assets/images/avatar.svg';
import { toJS } from 'mobx';

const CityDistrictHead: FC = observer(() => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (selectedOmsu?.id && selectedOmsu?.idx !== 0) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await getMunicipalityInfo(selectedOmsu.id);
          setInfo(response.data?.items?.[0] || null);
        } catch (e) {
          //
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [selectedOmsu?.idx, selectedOmsu?.id]);

  if (loading) {
    return <Loader />;
  }

  if (!selectedOmsu?.id || selectedOmsu?.idx === 0 || !info) {
    return null;
  }

  return (
    <HeadContainer>
      <HeadInfo>
        <User>
          <AvatarWrapper>
            <Avatar avatar={testAvatar} size={AvatarSize.NORMAL_PLUS} />
          </AvatarWrapper>
          <UserInfo>
            <JobTitle>Глава городского округа</JobTitle>
            <Name>{info.fio}</Name>
          </UserInfo>
        </User>
        <Contact>
          <TextField
            label={'Электронная почта'}
            value={info.email}
            icon={<EmailIcon />}
            type={'email'}
            valueBold
          />
          <TextField
            label={'Телефон'}
            value={info.number}
            icon={<PhoneIcon />}
            type={'phone'}
            valueBold
          />
        </Contact>
      </HeadInfo>
    </HeadContainer>
  );
});

const HeadInfo = styled.div`
  display: flex;
  align-items: center;
`;
const HeadContainer = styled.div`
  padding: 24px;
`;
const User = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.colors.border};
  flex: 1 1 auto;
`;
const UserInfo = styled.div``;
const JobTitle = styled.div`
  ${({ theme }) => font({ color: theme.colors.dark })};
  margin-bottom: 2px;
`;
const Name = styled.div`
  ${({ theme }) => font({ family: theme.fonts.montserrat.bold, size: theme.fonts.sizes.xmd })};
`;
const AvatarWrapper = styled.div`
  margin-right: 16px;
`;
const Contact = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
  > a {
    margin-right: 50px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export default CityDistrictHead;
