import styled from 'styled-components';
import { FC } from 'react';
import Avatar, { AvatarSize } from '../../Avatar';
import { User } from '..';
import { font } from '../../styled/mixins/fonts';
import { AuthStore } from '../../../stores/auth';

const UserPanel: FC<User> = ({ avatar, contact, username }) => {
  return (
    <ComponentContainer>
      <AvatarWrapper>
        <Avatar avatar={avatar} size={AvatarSize.BIG} />
      </AvatarWrapper>
      <UserName>{username}</UserName>
      {contact && (
        <Contacts href={`mailto:${contact}`}>
          <a>{contact}</a>
        </Contacts>
      )}
      <UserMenu>
        <MenuItem onClick={() => AuthStore.logout()}>Выйти</MenuItem>
      </UserMenu>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px 8px 16px;
`;
const UserName = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xmd,
      family: theme.fonts.montserrat.semibold,
      lineHeight: '20px',
    })};
  text-align: center;
  margin-bottom: 4px;
`;
const Contacts = styled.a`
  display: block;
  ${({ theme }) => font({ size: theme.fonts.sizes.sm, color: theme.colors.dark })};
  margin-bottom: 12px;
  text-decoration: none;
`;
const AvatarWrapper = styled.div`
  margin-bottom: 12px;
`;
const UserMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
`;

const MenuItem = styled.div`
  cursor: pointer;
  padding: 6px 16px;
  ${({ theme }) => font({ color: theme.colors.status.overdue })};
  flex: 1 1 auto;
  text-align: center;
`;

export default UserPanel;
