import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { AppStoreContext } from '../../../stores/app';
import { observer } from 'mobx-react-lite';
import { Loader } from '../../../components/Loader';
import HorizontalList from '../../../components/HorizontalList';
import RatingColumn from '../../../components/RatingColumn';
import { getIndicatorsOMSU } from '../../../actions';

interface Props {
  filter?: any;
}

const OmsuData: FC<Props> = observer(({ filter }) => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const [indicatorsOMSUData, setIndicatorsOMSUData] = useState([]);
  const [loadingIndicatorsOMSUData, setLoadingIndicatorsOMSUData] = useState(false);

  const fetchTopIndustriesTaxesData = useCallback(async () => {
    if (selectedOmsu) {
      try {
        setLoadingIndicatorsOMSUData(true);
        const params = {
          omsuId: selectedOmsu.id,
          periodId: filter?.selectedMonth?.id,
        };
        const response = await getIndicatorsOMSU(params);
        const responseArray = [];
        for (let i = 0; i < response.data?.items.length; i += 2) {
          responseArray.push([response.data?.items[i], response.data?.items[i + 1]]);
        }
        setIndicatorsOMSUData(responseArray || []);
      } catch (e) {
        //
      } finally {
        setLoadingIndicatorsOMSUData(false);
      }
    }
  }, [selectedOmsu, filter?.selectedMonth]);

  useEffect(() => {
    fetchTopIndustriesTaxesData();
  }, [fetchTopIndustriesTaxesData, filter?.selectedMonth]);

  return (
    <>
      {loadingIndicatorsOMSUData ? (
        <Loader />
      ) : (
        <HorizontalList
          title={'Показатели'}
          list={indicatorsOMSUData.map((element, idx) => ({
            id: idx,
            additional: element.map((item) => ({
              id: item.id,
              title: new Intl.NumberFormat('ru-RU', {
                minimumFractionDigits: item.precision,
              }).format(item.factValue),
              titleMeasure: item.unit === 'процент' ? ' %' : ' ' + item.unit,
              // amount: 1047.54,
              // amountMeasure: 'млн руб.',
              label: item.name,
            })),
          }))}
        >
          {(item) => <RatingColumn {...item} />}
        </HorizontalList>
      )}
    </>
  );
});

export default OmsuData;
