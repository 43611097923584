import styled from 'styled-components';
import { FC } from 'react';
import { StatsItem as Stats } from '../index';
import StatsItem from '../../../../components/StatsItem';
import { font } from '../../../../components/styled/mixins/fonts';

const StatsList: FC<Stats> = ({ list, period }) => {
  return (
    <ComponentContainer>
      <Info>
        <Title>Значения по Московской области</Title>
        <Period>
          {period ? period : ''}
        </Period>
      </Info>
      <List>
        {list.map((item) => (
          <StatsItem key={item.id} {...item} />
        ))}
      </List>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div``;
const Title = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xxmd,
      lineHeight: '34px',
      family: theme.fonts.montserrat.semibold,
    })};
  margin-right: 12px;
`;
const Period = styled.div`
  ${({ theme }) =>
    font({
      lineHeight: '30px',
      color: theme.colors.dark,
    })};
`;
const Info = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;
`;
const List = styled.div`
  display: flex;
  justify-content: space-around;
`;

export default StatsList;
