import { PageWrapper } from '../../components/styled/PageWrapper';
import Accordion, { Position } from '../../components/Accordion';
import { FC, useEffect, useMemo } from 'react';
import DevelopmentSummary from './DevelopmentSummary';
import { v4 as uuidv4 } from 'uuid';
import DevelopmentWorkplacesCollapsedContent from './DevelopmentWorkplacesCollapsedContent';
import DevelopmentWorkplaces from './DevelopmentWorkplaces';
import { useContext } from 'react';
import { DevelopmentStoreContext } from '../../stores/development';
import { observer } from 'mobx-react-lite';
import DevelopmentIndustrial from './DevelopmentIndustrial';
import DevelopmentLandPlotsAddition from './DevelopmentLandPlotsAddition';
import DevelopmentLandPlots from './DevelopmentLandPlots';
import DevelopmentLandPlotsRentAddition from './DevelopmentLandPlotsRentAddition';
import DevelopmentLandPlotsRent from './DevelopmentLandPlotsRent';
import { useFilter } from '../../hooks/useFilter';
import {
  initialRentLandFilterValues,
  initialSocialNeedsFilterValues,
  initialSocialStatusFilterValues,
} from './consts';
import DevelopmentSocialNeedsAddition from './DevelopmentSocialNeedsAddition';
import DevelopmentSocialNeeds from './DevelopmentSocialNeeds';
import DevelopmentSocialStatusAddition from './DevelopmentSocialStatusAddition';
import DevelopmentSocialStatus from './DevelopmentSocialStatus';
import DevelopmentInvestmentsCollapsedContent from './DevelopmentInvestmentsCollapsedContent';
import DevelopmentInvestments from './DevelopmentInvestments';
import { NsiStoreContext } from '../../stores/nsi';
import DevelopmentIndustrialCollapsedContent from './DevelopmentIndustrialCollapsedContent';
import DevelopmentLandPlotsCollapsedContent from './DevelopmentLandPlotsCollapsedContent';

const DevelopmentPage: FC = observer(() => {
  const { developmentHeaderFilter } = useContext(DevelopmentStoreContext);
  const { RentObjects } = useContext(NsiStoreContext);

  const { filter: rentFilter, setFilterCallback: setRentFilterCallback } = useFilter({
    initialFilterValues: { ...initialRentLandFilterValues },
    name: 'developmentRentLandAdditionFilter',
  });

  const { filter: socialNeedsFilter, setFilterCallback: setSocialNeedsFilterCallback } = useFilter({
    initialFilterValues: { ...initialSocialNeedsFilterValues },
    name: 'developmentSocialNeedsFilter',
  });

  const { filter: socialStatusFilter, setFilterCallback: setSocialStatusFilterCallback } =
    useFilter({
      initialFilterValues: { ...initialSocialStatusFilterValues },
      name: 'developmentSocialStatusFilter',
    });

  useEffect(() => {
    if (RentObjects) {
      if (!rentFilter.selectedType) {
        setRentFilterCallback(
          'selectedType',
          RentObjects.find((itm) => itm.id === -1),
        );
      }
    }
  }, [RentObjects]);

  const summaryInfo = useMemo(() => {
    return {
      id: uuidv4(),
      header: {
        title: 'Сводная информация',
      },
      content: <DevelopmentSummary />,
    };
  }, []);

  const newWorkPlaces = useMemo(() => {
    return {
      id: uuidv4(),
      header: {
        title: 'Новые рабочие места',
        collapsedContent: <DevelopmentWorkplacesCollapsedContent />,
        selector: 'new_workplaces',
        collapsedAdditionVisible: false,
      },
      content: <DevelopmentWorkplaces />,
    };
  }, []);

  const industrial = useMemo(() => {
    return {
      id: uuidv4(),
      header: {
        title: 'Индустриальные парки, технопарки, промплощадки',
        collapsedContent: <DevelopmentIndustrialCollapsedContent />,
        selector: 'industrial_parks',
        collapsedAdditionVisible: false,
      },
      content: <DevelopmentIndustrial />,
      
    };
  }, []);

  const industrialLand = useMemo(() => {
    return {
      id: uuidv4(),
      header: {
        title: 'Земельные участки',
        addition: <DevelopmentLandPlotsAddition />,
        additionPosition: Position.LEFT,
        collapsedContent: <DevelopmentLandPlotsCollapsedContent />,
        selector: 'land',
        collapsedAdditionVisible: false,
        collapsedContentPosition: Position.RIGHT,
      },
      content: <DevelopmentLandPlots />,
    };
  }, []);

  const rentLand = useMemo(() => {
    return {
      id: uuidv4(),
      header: {
        title: 'Аренда земельных участков без торгов',
        addition: (
          <DevelopmentLandPlotsRentAddition
            filter={rentFilter}
            setFilterCallback={setRentFilterCallback}
          />
        ),
        collapsedAdditionVisible: false,
      },
      content: <DevelopmentLandPlotsRent filter={rentFilter} />,
    };
  }, [rentFilter, setRentFilterCallback]);

  const investment = useMemo(() => {
    return {
      id: uuidv4(),
      header: {
        title: 'Инвестиции',
        collapsedContent: <DevelopmentInvestmentsCollapsedContent />,
        selector: 'investments',
        collapsedAdditionVisible: false,
      },
      content: <DevelopmentInvestments />,
    };
  }, []);

  const developmentSocialNeeds = useMemo(() => {
    return {
      id: uuidv4(),
      header: {
        title: 'Потребность',
        addition: (
          <DevelopmentSocialNeedsAddition
            filter={socialNeedsFilter}
            setFilterCallback={setSocialNeedsFilterCallback}
          />
        ),
        collapsedAdditionVisible: false,
      },
      content: <DevelopmentSocialNeeds filter={socialNeedsFilter} />,
    };
  }, [socialNeedsFilter, setSocialNeedsFilterCallback]);

  const developmentSocialStatus = useMemo(() => {
    return {
      id: uuidv4(),
      header: {
        title: 'Существующее положение',
        addition: (
          <DevelopmentSocialStatusAddition
            filter={socialStatusFilter}
            setFilterCallback={setSocialStatusFilterCallback}
          />
        ),
        collapsedAdditionVisible: false,
      },
      content: <DevelopmentSocialStatus filter={socialStatusFilter} />,
    };
  }, [socialStatusFilter, setSocialStatusFilterCallback]);

  const items = useMemo(() => {
    let arr = [];
    switch (developmentHeaderFilter.value) {
      case 'investment':
        arr = [summaryInfo, newWorkPlaces, investment, industrial, industrialLand, rentLand];
        break;
      case 'social':
        arr = [developmentSocialNeeds, developmentSocialStatus];
        break;
    }
    return arr;
  }, [
    developmentHeaderFilter,
    summaryInfo,
    newWorkPlaces,
    investment,
    industrial,
    industrialLand,
    rentLand,
    socialNeedsFilter,
    socialStatusFilter,
  ]);

  return (
    <PageWrapper>
      <Accordion items={items} />
    </PageWrapper>
  );
});

export default DevelopmentPage;
