import React, { FC, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { font } from '../../styled/mixins/fonts';

interface Props {
  fields: any[];
  sorting: any;
  onSortingChange: (el: any) => any;
}

const THead: FC<Props> = ({ fields, sorting, onSortingChange }) => {
  const lastField = useRef(sorting?.field || null);
  const onSortingClick = useCallback(
    (item) => {
      if (item.sorting) {
        let direction = '';
        if (lastField.current !== item.field || !sorting.direction) {
          direction = 'asc';
        } else if (sorting.direction === 'asc') {
          direction = 'desc';
        }
        if (!direction) {
          onSortingChange({});
        } else {
          onSortingChange({
            field: item.field,
            direction,
          });
        }
        lastField.current = item.field;
      }
    },
    [onSortingChange, sorting.direction],
  );

  return (
    <THeadRow>
      {fields.map((item, idx) => {
        const selected = item.sorting && sorting.field === item.field;
        return (
          <THeadCell
            width={item.width}
            key={`t-head-${idx}`}
            onClick={() => onSortingClick(item)}
            options={item.options}
          >
            <>
              <THeadCellLabel>{item.label}</THeadCellLabel>
              {selected && (sorting.direction ? 'up' : 'down')}
            </>
          </THeadCell>
        );
      })}
    </THeadRow>
  );
};

const THeadRow = styled.div`
  display: table-row;
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const THeadCell = styled.div<{ width: string; options: any }>`
  display: table-cell;
  width: ${({ width }) => width};
  vertical-align: middle;
  padding: 8px 0;
  text-align: ${({ options }) => options?.text};
  ${({ theme, options }) =>
    font({
      family: options?.fontFamily || theme.fonts.robotoCondensed.regular,
      color: theme.colors.dark,
      size: theme.fonts.sizes.sm,
    })};
`;

const THeadCellLabel = styled.div``;

export default THead;
