import styled from 'styled-components';
import { List } from '../../../components/List';
import LabeledText from '../../../components/LabeledText';
import Divider from '../../../components/Divider';
import Row from '../../../components/structure/Row';
import FinanceChart from './FinanceChart';
import UserInfo from './UserInfo';
import React, { useContext, useMemo } from 'react';
import { ObjectStoreContext } from '../../../stores/object';
import { observer } from 'mobx-react-lite';
import testAvatar from '../../../assets/images/avatar.svg';
import EmailIcon from '../../../assets/icons/EmailIcon';
import InfoIcon from '../../../assets/icons/InfoIcon';
import RisksAddition from '../../Objects/RisksAddition';
import Card from '../../../components/Card';
import Risks from '../../Objects/Risks';
import Accordion from '../../../components/Accordion';

const ObjectInfo = observer(() => {
  const { ObjectData } = useContext(ObjectStoreContext);

  const programList = [
    {
      id: 0,
      label: 'Программа',
      text: ObjectData?.programm?.name,
    },
    {
      id: 1,
      label: 'Подпрограмма',
      text: ObjectData?.subProgramm?.name,
    },
    {
      id: 2,
      label: 'Мероприяние',
      text: ObjectData?.govEvent?.name,
    },
  ];

  const contacts = (value) => {
    if (value) {
      return [
        {
          id: 0,
          icon: <EmailIcon />,
          label: 'Электронная почта',
          value: value,
          type: 'email',
        },
      ];
    }
    return null;
  };

  const unitList = useMemo(() => {
    const arr = [];
    if (ObjectData?.omsu?.name) {
      arr.push({
        id: 0,
        label: 'ОМСУ',
        text: ObjectData.omsu.name,
      });
    }
    if (ObjectData?.object?.address) {
      arr.push({
        id: 1,
        label: 'Адрес',
        text: ObjectData.object.address,
      });
    }
    if (ObjectData?.ciogv?.name) {
      arr.push({
        id: 2,
        label: 'Ведомство',
        text: ObjectData.ciogv.name,
      });
    }
    arr.push({
      id: 3,
      label: 'Куратор',
      text: ObjectData?.curator?.fio || '',
      icon: <InfoIcon />,
      content: (
        <UserInfo
          username={ObjectData?.curator?.fio || ''}
          avatar={testAvatar}
          contacts={contacts(ObjectData?.curator?.email)}
        />
      ),
    });
    if (ObjectData?.responsible?.fio) {
      arr.push({
        id: 4,
        label: 'Ответственный за ДК',
        text: ObjectData.responsible.fio,
        icon: <InfoIcon />,
        content: (
          <UserInfo
            username={ObjectData?.responsible?.fio || ''}
            avatar={testAvatar}
            contacts={contacts(ObjectData?.responsible?.email)}
          />
        ),
      });
    }
    return arr;
  }, [ObjectData]);

  return (
    <ObjectInfoWrapper>
      <Accordion
        items={[
          {
            id: 1,
            header: {
              title: ObjectData?.object?.title,
              isOpen: false,
            },
            content: (
              <List
                list={programList}
                renderItem={(item) => <LabeledText key={item.id} {...item} />}
                gap={8}
              ></List>
            ),
          },
        ]}
      />
      <Divider margin={'24px 0'} />
      <Row>
        <ListWrapper>
          <List
            list={unitList}
            renderItem={(item) => <LabeledText key={item.id} {...item} />}
            gap={8}
          ></List>
        </ListWrapper>
        <Divider margin={'0 24px 0 16px'} />
        <ListWrapper>
          <FinanceChart />
        </ListWrapper>
      </Row>
    </ObjectInfoWrapper>
  );
});

const ListWrapper = styled.div`
  flex: 0 0 48%;
`;

const ObjectInfoWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
`;
const Title = styled.div`
  font-size: 22px;
  line-height: 30px;
  font-family: ${({ theme }) => theme.fonts.montserrat.semibold};
  margin-bottom: 16px;
`;

export default ObjectInfo;
