import styled from 'styled-components';
import { FC } from 'react';
import RatingRowItem from '../RatingRow/RatingRowItem';
import {RatingType} from "../RatingRow";

interface Props {
  config: any[];
  type: RatingType;
}

const RatingCols: FC<Props> = ({ config, type }) => {
  return (
    <ContentRow type={type}>
      <Stripe />
      <Items>
        {config?.map((item) => (
          <RatingRowItem key={item.id} {...item} />
        ))}
      </Items>
    </ContentRow>
  );
};

const ContentRow = styled.div<{type: RatingType;}>`
  display: flex;
  flex: ${({type}) => type === RatingType.NORMAL ? '1 1 auto' : '0 0 auto'};
  position: relative;
  width: 100%;
`;
const Stripe = styled.div`
  position: absolute;
  height: 1px;
  top: 31px;
  left: 35px;
  right: 35px;
  background-image: repeating-linear-gradient(
      -2deg,
      #e0e0e0,
      #e0e0e0 4px,
      transparent 4px,
      transparent 8px,
      #e0e0e0 8px
    ),
    repeating-linear-gradient(
      88deg,
      #e0e0e0,
      #e0e0e0 4px,
      transparent 4px,
      transparent 8px,
      #e0e0e0 8px
    ),
    repeating-linear-gradient(
      178deg,
      #e0e0e0,
      #e0e0e0 4px,
      transparent 4px,
      transparent 8px,
      #e0e0e0 8px
    ),
    repeating-linear-gradient(
      268deg,
      #e0e0e0,
      #e0e0e0 4px,
      transparent 4px,
      transparent 8px,
      #e0e0e0 8px
    );
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
`;
const Items = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  width: 100%;
`;

export default RatingCols;
