import styled from 'styled-components';
import { FC, memo, useMemo } from 'react';
import Card from '../../../components/Card';
import { ModalsStore } from '../../../stores/modal';
import { getPluralLabel } from '../../../utils/getPluralLabel';
import {
  isAfter,
  format,
  setYear,
  setMonth,
  endOfMonth,
  startOfYear,
  setDate,
  startOfMonth,
  endOfYear,
  endOfDay,
} from 'date-fns';
import { ru } from 'date-fns/locale';
import { prepareAdditionalParams, prepareParams } from '../utils';
import { Loader } from '../../../components/Loader';
import ComplexTable from '../../../components/ComplexTable';
import { prepareNumber } from '../../../utils/prepareNumber';

interface CellProps {
  row: any;
  fieldName: string;
  isBeforeRisk?: boolean;
  isRisk?: boolean;
  isCurrentYearOpen?: boolean;
  isPrice?: boolean;
  justifyContent?: string;
}

const CellItem: FC<CellProps> = ({
  row,
  fieldName,
  isBeforeRisk,
  isCurrentYearOpen,
  justifyContent,
}) => {
  const colorType = isCurrentYearOpen ? 'done' : isBeforeRisk ? 'done_with_delay' : 'overdue';
  // const number = parseFloat(row[fieldName + 'Risk']);
  return (
    <>
      <CellContent justifyContent={justifyContent}>
        <CellLeft>
          {new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 0 }).format(
            parseFloat(row[fieldName]),
          )}
        </CellLeft>
        {!(fieldName === 'objectsOpenedCurrentYear' && isCurrentYearOpen) &&
        row[fieldName + 'Risk'] ? (
          <>
            <Circle />
            <CellRight
              colorType={colorType}
              data-for={'1'}
              data-tip={`${row[fieldName + 'Risk']} ${getPluralLabel(row[fieldName + 'Risk'], [
                'объект',
                'объекта',
                'объектов',
              ])} ${isBeforeRisk ? 'с предрисками' : isCurrentYearOpen ? 'открыто' : 'с рисками'}`}
            >
              {prepareNumber(row[fieldName + 'Risk'], 0)}
            </CellRight>
          </>
        ) : null}
      </CellContent>
    </>
  );
};

const prepareDates = (filter, num?, isYear?) => {
  let fromDate, toDate;
  if (filter.selectedMonth.parentId !== '-1') {
    const abstractDate = endOfDay(
      setYear(
        setMonth(new Date(), filter.selectedMonth.idx - 1),
        filter.selectedYear.appliesToYear,
      ),
    );
    if (num === 1) {
      fromDate = startOfMonth(abstractDate);
      toDate = setDate(abstractDate, 7);
    } else if (num === 2) {
      fromDate = setDate(abstractDate, 8);
      toDate = setDate(abstractDate, 14);
    } else if (num === 3) {
      fromDate = setDate(abstractDate, 15);
      toDate = setDate(abstractDate, 21);
    } else if (num === 4) {
      fromDate = setDate(abstractDate, 22);
      toDate = endOfMonth(abstractDate);
    } else if (isYear) {
      fromDate = startOfYear(abstractDate);
      toDate = endOfYear(abstractDate);
    } else {
      fromDate = startOfMonth(abstractDate);
      toDate = endOfMonth(abstractDate);
    }
  } else {
    const abstractDate = endOfDay(setYear(new Date(), filter.selectedYear.appliesToYear));
    if (num === 1) {
      fromDate = startOfYear(abstractDate);
      toDate = endOfMonth(setMonth(abstractDate, 2));
    } else if (num === 2) {
      fromDate = startOfMonth(setMonth(abstractDate, 3));
      toDate = endOfMonth(setMonth(abstractDate, 5));
    } else if (num === 3) {
      fromDate = startOfMonth(setMonth(abstractDate, 6));
      toDate = endOfMonth(setMonth(abstractDate, 8));
    } else if (num === 4) {
      fromDate = startOfMonth(setMonth(abstractDate, 9));
      toDate = endOfYear(abstractDate);
    } else if (isYear) {
      fromDate = startOfYear(abstractDate);
      toDate = endOfYear(abstractDate);
    } else {
      fromDate = startOfYear(abstractDate);
      toDate = endOfYear(abstractDate);
    }
  }
  return { fromDate, toDate };
};

const generateFields = (filter) => {
  const { selectedYear, selectedMonth, isRisk, isBeforeRisk, isCurrentYearOpen } = filter;
  const periodEnd = new Date(selectedMonth?.periodEnd);
  const date = !selectedMonth?.periodEnd || isAfter(periodEnd, new Date()) ? new Date() : periodEnd;
  return [
    generateCustomTheadFields(filter),
    [
      {
        id: 0,
        label: '',
        field: 'title',
        sorting: false,
        width: '20%',
      },
      {
        id: 1,
        label: 'Всего',
        field: 'objectsTotal',
        sorting: true,
        width: '8%',
        handleField: (row) => (
          <CellItem
            row={row}
            fieldName='objectsTotal'
            isBeforeRisk={isBeforeRisk}
            isRisk={isRisk}
            isCurrentYearOpen={isCurrentYearOpen}
          />
        ),
        onCellClick: (row) => {
          ModalsStore.showModal('selected-objects-modal', {
            row,
            filter: { ...filter, ...prepareAdditionalParams({ row, filter }) },
            count: row.objectsTotal,
            riskCount: row.objectsTotalRisk,
            isTotal: true,
          });
        },
      },
      {
        id: 2,
        label: `Открытие в ${selectedYear?.label} г.`,
        field: 'objectsPlannedOpenCurrentYear',
        sorting: true,
        width: '8%',
        handleField: (row) => (
          <CellItem
            row={row}
            fieldName='objectsPlannedOpenCurrentYear'
            isBeforeRisk={isBeforeRisk}
            isRisk={isRisk}
            isCurrentYearOpen={isCurrentYearOpen}
          />
        ),
        onCellClick: (row) => {
          const preparedDates = prepareDates(filter, undefined, true);
          ModalsStore.showModal('selected-objects-modal', {
            row,
            filter: {
              ...filter,
              isOpenPlan: true,
              ...preparedDates,
              ...prepareAdditionalParams({ row, filter }),
            },
            count: row.objectsPlannedOpenCurrentYear,
            riskCount: row.objectsPlannedOpenCurrentYearRisk,
          });
        },
      },
      {
        id: 3,
        label: 'Открыто',
        field: 'objectsOpenedCurrentYear',
        sorting: true,
        width: '8%',
        handleField: (row) => (
          <CellItem
            row={row}
            fieldName='objectsOpenedCurrentYear'
            isBeforeRisk={isBeforeRisk}
            isRisk={isRisk}
            isCurrentYearOpen={isCurrentYearOpen}
          />
        ),
        onCellClick: (row) => {
          ModalsStore.showModal('selected-objects-modal', {
            row,
            filter: {
              ...filter,
              IsCurrentYearOpen: true,
              ...prepareAdditionalParams({ row, filter }),
            },
            count: row.objectsOpenedCurrentYear,
            riskCount: row.objectsOpenedCurrentYearRisk,
          });
        },
      },
      {
        id: 4,
        label: 'Всего',
        field: 'objectsAmountSelectedMonth',
        sorting: true,
        width: '8%',
        options: {
          fill: true,
          first: true,
          'background-color': '#f3f4f9',
          'border-left': '1px solid #d1d4e6',
        },
        handleField: (row) => (
          <CellItem
            row={row}
            fieldName='objectsAmountSelectedMonth'
            isBeforeRisk={isBeforeRisk}
            isRisk={isRisk}
            isCurrentYearOpen={isCurrentYearOpen}
          />
        ),
        onCellClick: (row) => {
          const preparedDates = prepareDates(filter);
          ModalsStore.showModal('selected-objects-modal', {
            row,
            filter: {
              ...filter,
              ...preparedDates,
              ...prepareAdditionalParams({ row, filter }),
              isOpenPlan: true,
            },
            count: row.objectsAmountSelectedMonth,
            riskCount: row.objectsAmountSelectedMonthRisk,
          });
        },
      },
      {
        id: 5,
        label: '1',
        field: 'objectsAmount1Week',
        sorting: true,
        width: '8%',
        options: {
          fill: true,
          'background-color': '#f3f4f9',
        },
        handleField: (row) => (
          <CellItem
            row={row}
            fieldName='objectsAmount1Week'
            isBeforeRisk={isBeforeRisk}
            isRisk={isRisk}
            isCurrentYearOpen={isCurrentYearOpen}
          />
        ),
        onCellClick: (row) => {
          const preparedDates = prepareDates(filter, 1);
          ModalsStore.showModal('selected-objects-modal', {
            row,
            filter: {
              ...filter,
              ...preparedDates,
              ...prepareAdditionalParams({ row, filter }),
              isOpenPlan: true,
            },
            count: row.objectsAmount1Week,
            riskCount: row.objectsAmount1WeekRisk,
          });
        },
      },
      {
        id: 6,
        label: '2',
        field: 'objectsAmount2Week',
        sorting: true,
        width: '8%',
        options: {
          fill: true,
          'background-color': '#f3f4f9',
        },
        handleField: (row) => (
          <CellItem
            row={row}
            fieldName='objectsAmount2Week'
            isBeforeRisk={isBeforeRisk}
            isRisk={isRisk}
            isCurrentYearOpen={isCurrentYearOpen}
          />
        ),
        onCellClick: (row) => {
          const preparedDates = prepareDates(filter, 2);
          ModalsStore.showModal('selected-objects-modal', {
            row,
            filter: {
              ...filter,
              ...preparedDates,
              ...prepareAdditionalParams({ row, filter }),
              isOpenPlan: true,
            },
            count: row.objectsAmount2Week,
            riskCount: row.objectsAmount2WeekRisk,
          });
        },
      },
      {
        id: 7,
        label: '3',
        field: 'objectsAmount3Week',
        sorting: true,
        width: '8%',
        options: {
          fill: true,
          'background-color': '#f3f4f9',
        },
        handleField: (row) => (
          <CellItem
            row={row}
            fieldName='objectsAmount3Week'
            isBeforeRisk={isBeforeRisk}
            isRisk={isRisk}
            isCurrentYearOpen={isCurrentYearOpen}
          />
        ),
        onCellClick: (row) => {
          const preparedDates = prepareDates(filter, 3);
          ModalsStore.showModal('selected-objects-modal', {
            row,
            filter: {
              ...filter,
              ...preparedDates,
              ...prepareAdditionalParams({ row, filter }),
              isOpenPlan: true,
            },
            count: row.objectsAmount3Week,
            riskCount: row.objectsAmount3WeekRisk,
          });
        },
      },
      {
        id: 8,
        label: '4',
        field: 'objectsAmount4Week',
        sorting: true,
        width: '8%',
        options: {
          fill: true,
          last: true,
          'background-color': '#f3f4f9',
          'border-right': '1px solid #d1d4e6',
        },
        handleField: (row) => (
          <CellItem
            row={row}
            fieldName='objectsAmount4Week'
            isBeforeRisk={isBeforeRisk}
            isRisk={isRisk}
            isCurrentYearOpen={isCurrentYearOpen}
          />
        ),
        onCellClick: (row) => {
          const preparedDates = prepareDates(filter, 4);
          ModalsStore.showModal('selected-objects-modal', {
            row,
            filter: {
              ...filter,
              ...preparedDates,
              ...prepareAdditionalParams({ row, filter }),
              isOpenPlan: true,
            },
            count: row.objectsAmount4Week,
            riskCount: row.objectsAmount4WeekRisk,
          });
        },
      },
      {
        id: 9,
        label: 'План',
        field: 'plannedFunding',
        sorting: true,
        width: '8%',
        justifyContent: 'end',
        handleField: (row) => (
          <CellItem
            row={row}
            fieldName='plannedFunding'
            isBeforeRisk={isBeforeRisk}
            isRisk={isRisk}
            isCurrentYearOpen={isCurrentYearOpen}
            isPrice={true}
            justifyContent={'end'}
          />
        ),
        options: {
          'text-align': 'right',
        },
      },
      {
        id: 10,
        label: (
          <>
            Факт <br /> на {format(new Date(), 'dd', { locale: ru })}
            {format(new Date(), 'MMM', {
              locale: ru,
            })}{' '}
            {format(new Date(), 'yyyy', { locale: ru })}
          </>
        ),
        field: 'actualFunding',
        sorting: true,
        width: '8%',
        justifyContent: 'end',
        handleField: (row) => (
          <CellItem
            row={row}
            fieldName='actualFunding'
            isBeforeRisk={isBeforeRisk}
            isRisk={isRisk}
            isCurrentYearOpen={isCurrentYearOpen}
            isPrice={true}
            justifyContent={'end'}
          />
        ),
        options: {
          'text-align': 'right',
        },
      },
    ],
  ];
};

const generateCustomTheadFields = (filter) => {
  return [
    {
      id: 0,
      label: '',
      sorting: false,
      width: '20%',
      options: {
        'border-radius': '22px 0px 0px 0px;',
      },
    },
    {
      id: 1,
      label: '',
      sorting: false,
      width: '8%',
    },
    {
      id: 2,
      label: '',
      sorting: false,
      width: '8%',
    },
    {
      id: 3,
      label: '',
      sorting: false,
      width: '8%',
    },
    {
      id: 4,
      label:
        'План・' +
        (filter.selectedMonth.label === 'Все месяцы'
          ? 'За год'
          : `За ${filter.selectedMonth.label} месяц`),
      sorting: false,
      colspan: 5,
      width: '40%',
      options: {
        fill: true,
        first: true,
        last: true,
        'background-color': '#f3f4f9',
        'border-right': '1px solid #d1d4e6',
        'border-left': '1px solid #d1d4e6',
      },
    },
    {
      id: 5,
      label: 'Финансирование・тыс. руб.',
      sorting: false,
      colspan: 2,
      width: '16%',
      options: {
        'border-radius': '0px 22px 0px 0px;',
      },
    },
  ];
};

interface Props {
  filter: any;
  sorting: any;
  setSortingCallback: any;
  data: any[];
}

const ObjectsList: FC<Props> = memo(({ filter, sorting, setSortingCallback, data }) => {
  const fields = useMemo(() => {
    return generateFields(filter);
  }, [filter]);

  const total = useMemo(() => {
    const obj = {};
    fields[1].forEach((el, idx) => {
      obj[el.field] = idx === 0 ? 'Объекты' : 0;
      obj[el.field + 'Risk'] = idx === 0 ? 'Объекты' : 0;
    });
    data.forEach((item) => {
      fields[1].forEach((el, idx) => {
        if (idx !== 0) {
          obj[el.field] += item[el.field];
          if (item[el.field + 'Risk']) {
            obj[el.field + 'Risk'] += item[el.field + 'Risk'];
          }
        }
      });
    });
    obj['options'] = {};
    obj['options'].styles = {};
    obj['options'].styles.title = {
      'background-color': 'rgba(0, 19, 92, 0.04);',
      'border-top': '1px solid rgba(0,0,0,0.12)',
      'font-weight': 'bold',
    };
    obj['options'].styles.objectsTotal = {
      'background-color': 'rgba(0, 19, 92, 0.04);',
      'border-top': '1px solid rgba(0,0,0,0.12)',
      'font-weight': 'bold',
    };
    obj['options'].styles.objectsPlannedOpenCurrentYear = {
      'background-color': 'rgba(0, 19, 92, 0.04);',
      'border-top': '1px solid rgba(0,0,0,0.12)',
      'font-weight': 'bold',
    };
    obj['options'].styles.objectsOpenedCurrentYear = {
      'background-color': 'rgba(0, 19, 92, 0.04);',
      'border-top': '1px solid rgba(0,0,0,0.12)',
      'font-weight': 'bold',
    };
    obj['options'].styles.objectsAmountSelectedMonth = {
      'background-color': 'rgba(0, 19, 92, 0.04);',
      'border-top': '1px solid rgba(0,0,0,0.12)',
      'font-weight': 'bold',
    };
    obj['options'].styles.objectsAmount1Week = {
      'background-color': 'rgba(0, 19, 92, 0.04);',
      'border-top': '1px solid rgba(0,0,0,0.12)',
      'font-weight': 'bold',
    };
    obj['options'].styles.objectsAmount2Week = {
      'background-color': 'rgba(0, 19, 92, 0.04);',
      'border-top': '1px solid rgba(0,0,0,0.12)',
      'font-weight': 'bold',
    };
    obj['options'].styles.objectsAmount3Week = {
      'background-color': 'rgba(0, 19, 92, 0.04);',
      'border-top': '1px solid rgba(0,0,0,0.12)',
      'font-weight': 'bold',
    };
    obj['options'].styles.objectsAmount4Week = {
      'background-color': 'rgba(0, 19, 92, 0.04);',
      'border-top': '1px solid rgba(0,0,0,0.12)',
      'font-weight': 'bold',
    };
    obj['options'].styles.plannedFunding = {
      'background-color': 'rgba(0, 19, 92, 0.04);',
      'border-top': '1px solid rgba(0,0,0,0.12)',
      'font-weight': 'bold',
    };
    obj['options'].styles.actualFunding = {
      'background-color': 'rgba(0, 19, 92, 0.04);',
      'border-top': '1px solid rgba(0,0,0,0.12)',
      'font-weight': 'bold',
    };
    return obj;
  }, [data]);

  return (
    <>
      <Card maxHeight>
        <ComplexTable
          fields={fields}
          data={data}
          total={total}
          sorting={sorting}
          setSortingCallback={setSortingCallback}
        />
      </Card>
    </>
  );
});

const CellContent = styled.div<{ justifyContent: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
`;

const CellLeft = styled.div``;

const CellRight = styled.div<{ colorType: string }>`
  color: ${({ theme, colorType }) => {
    return theme.colors.status[colorType];
  }};
`;

const Circle = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: black;
  margin-left: 5px;
  margin-right: 5px;
`;

export default ObjectsList;
