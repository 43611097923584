import { TableTypes } from './index';
import { tableFieldsConfigs } from './consts';

export const generateIndicatorsChartConfig = (arr) => {
  const list = [];
  list.push({
    id: 0,
    name: 'Риск',
    y: arr[0],
    color: '#FC5200',
  });
  list.push({
    id: 2,
    name: 'Достигнуто',
    y: arr[1],
    color: '#64AA7A',
  });
  return list;
};

export const generateIndicatorsRadialChartConfig = (arr) => {
  const row = [
    {
      name: 'График',
      data: [
        {
          name: 'Законтрактовано',
          y: arr[3] ? Math.abs(arr[0] - arr[3]) : arr[0],
          color: '#646CAA',
        },
        {
          name: 'Плановое финансирование',
          y: arr[4] ? Math.abs(arr[1] - arr[4]) : arr[1],
          color: '#848ABE',
        },
        {
          name: 'Фактическое финансирование',
          y: arr[5] ? Math.abs(arr[2] - arr[5]) : arr[2],
          color: '#A3A9D4',
        },
      ],
    },
  ];
  if (arr[3] !== undefined) {
    row.push({
      name: 'График',
      data: [
        {
          name: 'Законтрактовано',
          y: parseFloat(arr[3]),
          color: 'url(#custom-pattern64)',
        },
        {
          name: 'Плановое финансирование',
          y: parseFloat(arr[4]),
          color: 'url(#custom-pattern84)',
        },
        {
          name: 'Фактическое финансирование',
          y: parseFloat(arr[5]),
          color: 'url(#custom-patternA3)',
        },
      ],
    });
  }
  return row;
};

export const getTableFieldsConfig = (tableType, onCellClick?) => {
  const params: any = {};
  if (onCellClick) {
    params.onCellClick = onCellClick;
  }
  switch (tableType) {
    case TableTypes.FIVE_COLUMNS:
      return tableFieldsConfigs.fiveColumns(params);
    case TableTypes.SIX_COLUMNS:
      return tableFieldsConfigs.sixColumns(params);
    case TableTypes.TRIPLE_COLUNMS_TYPE_1:
      return tableFieldsConfigs.tripleColumnsType1(params);
    case TableTypes.TRIPLE_COLUNMS_TYPE_2:
      return tableFieldsConfigs.tripleColumnsType2(params);
  }
};
