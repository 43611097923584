import React, { FC, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { font } from '../../styled/mixins/fonts';
import SortDown from '../../../assets/icons/SortDown';
import SortUp from '../../../assets/icons/SortUp';
import get from 'lodash/get';

interface Props {
  fields: any[];
  data: any;
}

const TBodyTotal: FC<Props> = ({ fields, data }) => {
  return (
    <TBodyRow>
      {fields.map((item, idx) => {
        const content = get(data, item.field, '');
        return (
          <TBodyCell width={item.width} key={`t-row-${idx}`} options={item.options}>
            <CellWrapper options={item.options} justifyContent={item.justifyContent}>
              {content}
            </CellWrapper>
          </TBodyCell>
        );
      })}
    </TBodyRow>
  );
};

const TBodyRow = styled.tr``;

const TBodyCell = styled.td<{ width: string; options: any }>`
  width: ${({ width }) => width};
  height: 44px;
  font-weight: normal;
  text-align: ${({ options }) => options?.text || 'center'};
  vertical-align: middle;
  z-index: 10;
  padding: 0 12px;
  &:nth-child(1) {
    ${({ theme }) => font({ family: theme.fonts.montserrat.bold, size: theme.fonts.sizes.xmd })};
  }
  ${({ theme }) => font({ family: theme.fonts.robotoCondensed.bold })};
  background-color: ${({ options }) => (options?.fill ? 'rgba(100, 108, 170, 0.08)' : '#fff')};
  border-left: ${({ options }) => (options?.first ? '1px solid rgba(100, 108, 170, 0.24)' : '0')};
  border-right: ${({ options }) => (options?.last ? '1px solid rgba(100, 108, 170, 0.24)' : '0')};
`;

const CellWrapper = styled.div<{ justifyContent: string; options: any }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
  text-align: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
`;

export default TBodyTotal;
