import React, { FC } from 'react';
import get from 'lodash/get';
import Icon from '../../../../Icon';
import ArrowIcon from '../../../../../assets/icons/ArrowIcon';
import { formatDate } from '../../../../../utils/handleTime';
import styled, { css } from 'styled-components';
import { generateStyles } from '../../../TableHeader/HeaderCell';
import { font } from '../../../../styled/mixins/fonts';

interface ContentProps {
  fields: any[];
  uniqKey?: string;
  item: any;
  level?: number;
  hideZero?: boolean;
  isGroup?: boolean;
  isTotal?: boolean;
  open?: boolean;
  onClick?: (item) => void;
  autoOpen?: boolean;
  order: number;
  lastRow?: boolean;
  hasChildren?: boolean;
}

const RowCell: FC<ContentProps> = ({
  fields,
  item,
  level = 0,
  hideZero,
  isGroup,
  isTotal,
  open,
  onClick,
  autoOpen = false,
  order,
  lastRow,
  hasChildren,
}) => {
  return (
    <>
      {fields.map((element, index) => {
        let content = get(item, element.field, '');
        if (index === 0) {
          content = (
            <>
              {isGroup && !autoOpen && (
                <IconWrapper level={index === 0 ? level + 1 : 0}>
                  <Icon opacity={0.56} rotate={open ? 0 : -90} size={20}>
                    <ArrowIcon />
                  </Icon>
                </IconWrapper>
              )}
              {content}
            </>
          );
        }
        if (hideZero && content === 0) {
          content = '';
        } else if (element.handleField) {
          content = element.handleField(item);
        } else if (element.component) {
          content = <element.component item={item} />;
        } else if (element.type === 'date') {
          content = formatDate(content, 'DD.MM.YYYY');
        } else if (element.type === 'datetime') {
          content = formatDate(content, 'DD.MM.YYYY HH:mm:ss');
        } else if (Array.isArray(element.field)) {
          content = element.field.map((field) => item[field]).join(', ');
          // } else if (element.options) {
          //   content = element.options[content] || content;
        } else if (!isNaN(get(item, element.field))) {
          if (element.ignoreHandleNumber) {
            content = get(item, element.field);
          } else {
            content = new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2 }).format(
              parseFloat(get(item, element.field)),
            );
          }
        }

        const params = {} as any;

        if (element.onCellClick && !!content) {
          params.onClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            element.onCellClick(item);
          };
        }

        if (!content) return null;

        return (
          <TBodyCell
            firstColumn={index === 0}
            key={element.id}
            level={index === 0 ? level + 1 : 0}
            isTotal={isTotal}
            clickable={!!content && !!element.onCellClick}
            autoOpen={autoOpen}
            width={element.width}
            options={{ ...element.options, ...item.options?.styles?.[element.field] }}
            colSpan={item?.options?.colspan?.[element.field]}
            isGroup={isGroup}
            onClick={onClick || params.onClick}
            order={!!order}
            lastRow={lastRow}
            hasChildren={hasChildren}
            open={open}
            {...params}
          >
            {content}
          </TBodyCell>
        );
      })}
    </>
  );
};

const TBodyCell = styled.td<{
  level: number;
  isTotal?: boolean;
  clickable?: boolean;
  width?: string;
  options?: any;
  isGroup?: boolean;
  autoOpen: boolean;
  order: boolean;
  firstColumn: boolean;
  lastRow: boolean;
  open: boolean;
  hasChildren: boolean;
}>`
  position: relative;
  text-align: center;
  ${({ firstColumn }) =>
    firstColumn
      ? css`
          ${() => font()};
          text-align: left;
        `
      : css`
          ${({ theme }) => font({ family: theme.fonts.robotoCondensed.regular })};
        `};
  ${({ lastRow }) =>
    !lastRow &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    `}
  ${({ lastRow, open }) =>
    lastRow &&
    open &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    `}
  ${({ isGroup, clickable, autoOpen }) =>
    ((isGroup && !autoOpen) || clickable) &&
    css`
      cursor: pointer;
    `};
  padding: 8px 6px 8px
    ${({ level, firstColumn }) => (firstColumn ? 6 + 24 * (level - 1) : 6 + 24 * level)}px;
  ${generateStyles};
`;
const IconWrapper = styled.div<{
  level: number;
}>`
  left: ${({ level }) => 24 * (level - 2)}px;
  margin-right: 4px;
  position: absolute;
  top: 8px;
`;

export default RowCell;
