import styled, { css } from 'styled-components';
import { FC, ReactNode } from 'react';

export enum CardTheme {
  LIGHT,
  DARK,
}

interface Props {
  children: ReactNode;
  theme?: CardTheme;
  maxHeight?: boolean;
  overflow?: boolean;
  borderless?: boolean;
}

const Card: FC<Props> = ({ children, theme = CardTheme.LIGHT, maxHeight = false, overflow = true, borderless = false }) => {
  const renderTheme = (theme) => {
    switch (theme) {
      case CardTheme.DARK:
        return {
          border: '1px solid rgba(23, 43, 117, 0.08)',
          backgroundColor: 'rgba(23, 43, 117, 0.04)',
          borderRadius: '16px',
        };
      case CardTheme.LIGHT:
        return {
          backgroundColor: '#fff',
          border: '1px solid #f2f2f2',
          borderRadius: '22px',
        };
      default:
        return {
          backgroundColor: '#fff',
          border: '1px solid #f2f2f2',
          borderRadius: '22px',
        };
    }
  };

  return (
    <CardWrapper styles={{ ...renderTheme(theme) }} maxHeight={maxHeight} overflow={overflow} borderless={borderless}>
      {children}
    </CardWrapper>
  );
};

const CardWrapper = styled.div<{ styles: any; maxHeight: boolean; overflow: boolean; borderless: boolean; }>`
  clip-path: inset(0% 0% 0% 0% round ${({styles}) => styles.borderRadius});
  ${({overflow}) => !overflow && css`
    overflow: hidden;
  `};
  @media all and (max-width: 1365px) {
    ${({ maxHeight }) =>
      maxHeight &&
      css`
        max-height: calc(100vh - 136px);
        overflow-x: auto;
      `}
  }
  ${({ styles }) =>
    styles &&
    css`
      background-color: ${styles.backgroundColor};
      border: ${styles.border};
      border-radius: ${styles.borderRadius};
    `}
  ${({borderless}) => borderless && css`
    border: 1px solid transparent;
  `};
`;

export default Card;
