import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Loader } from '../../../components/Loader';
import HorizontalList, { Item } from '../../../components/HorizontalList';
import ListItem from '../../../components/HorizontalList/ListItem';
import { observer } from 'mobx-react-lite';
import { getInfoOMSU } from '../../../actions';
import { AppStoreContext } from '../../../stores/app';
import styled from 'styled-components';

const InfoData: FC = observer(() => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const [infoData, setInfoData] = useState([]);
  const [loadingInfoData, setLoadingInfoData] = useState(false);

  const fetchInfoData = useCallback(async () => {
    if (selectedOmsu) {
      try {
        setLoadingInfoData(true);
        const params = { omsuId: selectedOmsu.id, limit: 9999, offset: 0 };
        const response = await getInfoOMSU(params);
        setInfoData(response.data?.items || []);
      } catch (e) {
        //
      } finally {
        setLoadingInfoData(false);
      }
    }
  }, [selectedOmsu]);

  useEffect(() => {
    fetchInfoData();
  }, [fetchInfoData]);

  return (
    <>
      {loadingInfoData ? (
        <Loader />
      ) : (
        <Row>
          <HorizontalList
            title={'Информация'}
            list={infoData
              .filter((item) => item.partitionId === 15)
              .map((item) => ({
                id: item.id,
                iconURL: item.iconURL,
                data: [
                  {
                    id: item.partitionId,
                    amount: new Intl.NumberFormat('ru-RU', {
                      minimumFractionDigits: item.precision,
                    }).format(item.value),
                    amountType: item.unit,
                    label: item.name,
                  },
                ],
              }))}
          >
            {(item: Item) => <ListItem key={item.id} {...item} />}
          </HorizontalList>
          <HorizontalList
            title={'Текущая специализация'}
            list={infoData
              .filter((item) => item.partitionId === 16)
              .map((item) => ({
                id: item.id,
                iconURL: item.iconURL,
                data: [
                  {
                    id: item.partitionId,
                    amount: new Intl.NumberFormat('ru-RU', {
                      minimumFractionDigits: item.precision,
                    }).format(item.value),
                    amountType: item.unit,
                    label: item.name,
                  },
                ],
              }))}
          >
            {(item: Item) => <ListItem key={item.id} {...item} />}
          </HorizontalList>
        </Row>
      )}
    </>
  );
});

const Row = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 32px;
  @media all and (max-width: 1200px) {
    flex-wrap: wrap;
  }

  & > div {
    flex: 1 1 100%;
  }
`;

export default InfoData;
