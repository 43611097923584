import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Loader } from '../../../components/Loader';
import IndicatorsCharts from './IndicatorsCharts';
import { observer } from 'mobx-react-lite';
import { getExecutionIndicatorWithFinancing } from '../../../actions';
import { AppStoreContext } from '../../../stores/app';

interface Props {
  innerFilterCallbacks?: {
    [key: string]: any;
  };
}

const IndicatorSummary: FC<Props> = observer(({ innerFilterCallbacks }) => {
  const { selectedOmsu, selectedYear, selectedCiogv } = useContext(AppStoreContext);
  const [summaryData, setSummaryData] = useState([]);
  const [loadingSummaryData, setLoadingSummaryData] = useState(false);

  const fetchSummaryData = useCallback(async () => {
    if (selectedOmsu) {
      try {
        setLoadingSummaryData(true);
        const params = {
          omsuId: selectedOmsu.id,
          workingYearId: selectedYear.id,
          ciogvId: selectedCiogv.id,
        };
        const response = await getExecutionIndicatorWithFinancing(params);
        setSummaryData(response.data?.items || []);
      } catch (e) {
        //
      } finally {
        setLoadingSummaryData(false);
      }
    }
  }, [selectedOmsu, selectedYear, selectedCiogv]);

  useEffect(() => {
    fetchSummaryData();
  }, [fetchSummaryData]);

  const summaryContent = useMemo(() => {
    if (loadingSummaryData) {
      return <Loader />;
    }
    return <IndicatorsCharts data={summaryData} innerFilterCallbacks={innerFilterCallbacks} />;
  }, [loadingSummaryData, innerFilterCallbacks, summaryData]);

  return <>{summaryContent}</>;
});

export default IndicatorSummary;
