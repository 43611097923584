import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Loader } from '../../../components/Loader';
import HorizontalList, { HorizontalListVerticalPosition } from '../../../components/HorizontalList';
import { format } from 'date-fns';
import { RatingTypes } from '../../../components/Rating';
import RatingRow, { RatingType } from '../../../components/RatingRow';
import { routes } from '../../../config/routes';
import { getRatingInfoOMSU } from '../../../actions';
import { groupBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import { AppStoreContext } from '../../../stores/app';

const RatingInfoData: FC = observer(() => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const [ratingInfoData, setRatingInfoData] = useState({});
  const [loadingRatingInfoData, setLoadingRatingInfoData] = useState(false);

  const fetchRatingInfoData = useCallback(async () => {
    if (selectedOmsu) {
      try {
        setLoadingRatingInfoData(true);
        const params = { omsuId: selectedOmsu.id, limit: 9999, offset: 0 };
        const response = await getRatingInfoOMSU(params);
        setRatingInfoData(response.data ? groupBy(response.data.items, (item) => item.id) : []);
      } catch (e) {
        //
      } finally {
        setLoadingRatingInfoData(false);
      }
    }
  }, [selectedOmsu]);

  useEffect(() => {
    fetchRatingInfoData();
  }, [fetchRatingInfoData]);

  return (
    <>
      {loadingRatingInfoData ? (
        <Loader />
      ) : (
        <HorizontalList
          list={Object.keys(ratingInfoData).map((key) => ({
            id: new Intl.NumberFormat('ru-RU').format(
              ratingInfoData[key].find(
                (item) => item.calendarRaiting.name === format(new Date(), 'yyyy'),
              ).id,
            ),
            rating: new Intl.NumberFormat('ru-RU').format(
              ratingInfoData[key].find(
                (item) => item.calendarRaiting.name === format(new Date(), 'yyyy'),
              ).currentPosition,
            ),
            differenceRating: new Intl.NumberFormat('ru-RU').format(
              ratingInfoData[key].find(
                (item) => item.calendarRaiting.name === format(new Date(), 'yyyy'),
              ).dynamics,
            ),
            title: ratingInfoData[key].find(
              (item) => item.calendarRaiting.name === format(new Date(), 'yyyy'),
            ).name,
            ratingType: RatingTypes.SECONDARY,
            cols: ratingInfoData[key]
              .slice(0, -1)
              .map((item) => ({
                id: item.id,
                rating: new Intl.NumberFormat('ru-RU').format(item.currentPosition),
                differenceRating: new Intl.NumberFormat('ru-RU').format(item.dynamics),
                year: item.calendarRaiting.appliesToYear,
              }))
              .filter((item) => item.year !== 'Текущий год'),
          }))}
          maxItemsInRow={4}
          mobileItemsInRow={2}
          verticalAlign={HorizontalListVerticalPosition.BOTTOM}
        >
          {(item) => (
            <RatingRow
              key={item.id}
              config={item}
              type={RatingType.SMALL}
              link={`${routes.indicator.path.replace(':indicatorId', item?.id)}?omsuId=${
                selectedOmsu?.id
              }`}
            />
          )}
        </HorizontalList>
      )}
    </>
  );
});

export default RatingInfoData;
