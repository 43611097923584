import styled from 'styled-components';
import { FC } from 'react';
import Rating, { RatingTypes } from '../Rating';
import RatingCols from '../RatingCols';
import RatingAdditional from './RatingAdditional';

export interface AdditionalItem {
  id: number;
  title: string;
  titleMeasure: string;
  amount?: number | string;
  amountMeasure?: string;
  label: string;
  sublabel?: string;
}

interface Props {
  id: number;
  rating: number;
  title: string;
  differenceRating: number;
  timeline: {
    id: number;
    rating: number;
    ratingDifference: number;
    year: number;
  }[];
  additional: AdditionalItem[];
}

const RatingColumn: FC<Props> = ({ id, differenceRating, rating, additional, timeline, title }) => {
  return (
    <ComponentContainer>
      {/*<Rating*/}
      {/*  title={title}*/}
      {/*  rating={rating}*/}
      {/*  progress={differenceRating}*/}
      {/*  type={RatingTypes.DEFAULT}*/}
      {/*/>*/}
      {/*<RatingCols config={timeline} />*/}
      {additional.map((item) => (
        <RatingAdditional key={item.id} {...item} />
      ))}
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

export default RatingColumn;
