import { Modal } from '../../../../../components/Modal';
import { connectModal } from '../../../../../hocs/Modal';
import DynamicBudgetModalHeader from './DynamicBudgetModalHeader';
import ComplexTable from '../../../../../components/ComplexTable';
import styled from 'styled-components';
import { font } from '../../../../../components/styled/mixins/fonts';
import { useEffect, useMemo, useState } from 'react';
import { initialBudgetDynamicModalFilterValues } from './consts';
import { useFilter } from '../../../../../hooks/useFilter';
import { theme } from '../../../../../styles/theme';
import Text, { TextFamily } from '../../../../../components/Text';
import { get, isNumber } from 'lodash';
import { getDinamBudgetExecutionTable } from '../../../../../actions';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Table } from '../../../../../components/Table';
import { Loader } from '../../../../../components/Loader';
import { toJS } from 'mobx';
import { prepareNumber } from '../../../../../utils/prepareNumber';

const SOURCES_CONTEXT_CODE = '10005';
const MANAGERS_CONTEXT_CODE = '10006';

const DynamicBudgetModal = connectModal('dynamic-budget-modal')(
  ({ handleHide, initialFilterState }) => {
    const { filter, setFilterCallback } = useFilter({
      initialFilterValues: { ...initialBudgetDynamicModalFilterValues, ...initialFilterState },
      name: 'budgetDynamicModalFilters',
      disableCache: true,
    });

    const [sourcesTableInfo, setSourcesTableData] = useState(null);
    const [managersTableInfo, setManagersTableData] = useState(null);
    const [sourcesTableDataLoading, setSourcesTableDataLoading] = useState<boolean>(false);
    const [managersTableDataLoading, setManagersTableDataLoading] = useState<boolean>(false);

    useEffect(() => {
      const fetchData = async () => {
        setSourcesTableDataLoading(true);
        try {
          const response = await getDinamBudgetExecutionTable({
            ExecutionContextCode: SOURCES_CONTEXT_CODE,
            periodType: filter?.selectedPeriodType?.value,
            ciogvId: filter?.ciogv,
            fundingSource: filter?.fundingSource,
          });
          setSourcesTableData(response.data.items);
        } catch (e) {
          //
        } finally {
          setSourcesTableDataLoading(false);
        }
      };
      fetchData();
    }, [filter]);

    useEffect(() => {
      const fetchData = async () => {
        setManagersTableDataLoading(true);
        try {
          const response = await getDinamBudgetExecutionTable({
            ExecutionContextCode: MANAGERS_CONTEXT_CODE,
            periodType: filter?.selectedPeriodType?.value,
            ciogvId: filter?.ciogv,
            fundingSource: filter?.fundingSource,
          });
          setManagersTableData(response.data.items);
        } catch (e) {
          //
        } finally {
          setManagersTableDataLoading(false);
        }
      };
      fetchData();
    }, [filter]);

    const sourcesTableConfig = useMemo(() => {
      let tableData = [];
      let tableFields = [];
      let tableFieldsCustom = [];

      if (sourcesTableInfo) {
        const periodLength = get(sourcesTableInfo, '[0].periodValues', []).length;

        tableFieldsCustom = [
          {
            label: '',
            width: '28%',
          },
          ...get(sourcesTableInfo, '[0].periodValues', []).map((itm, idx) => ({
            label:
              idx === 0
                ? format(new Date(itm?.period?.periodStart), 'На d MMMM yyyy', { locale: ru })
                : itm?.period?.name,
            colspan: 4,
            width: '24%',
          })),
        ];

        tableFields = [
          {
            label: (
              <NameTitleContainer>
                <Text
                  data='Наименование'
                  color={theme.colors.dark}
                  font={TextFamily.ROBOTO_CONDENSED_REGULAR}
                  size={'12px'}
                />
                <Text
                  data='млрд руб.'
                  color={theme.colors.emptyData}
                  font={TextFamily.ROBOTO_CONDENSED_REGULAR}
                  size={'12px'}
                />
              </NameTitleContainer>
            ),
            field: 'name',
            width: '28%',
            options: {
              text: 'left',
              levelStep: 10,
              firstLevelPadding: 0,
              secondLevelPadding: 0,
              headLabelWidth: '100%',
            },
            justifyContent: 'flex-start',
          },
          ...get(sourcesTableInfo, '[0].periodValues', [])
            .map((itm, idx) => [
              {
                label: 'План',
                field: `plan${idx}`,
                width: '6%',
                justifyContent: 'flex-end',
              },
              {
                label: 'БО',
                field: `bo${idx}`,
                width: '6%',
                justifyContent: 'flex-end',
              },
              {
                label: 'Исполнение',
                field: `execution${idx}`,
                width: '6%',
                justifyContent: 'flex-end',
              },
              {
                label: '%',
                field: `percent${idx}`,
                width: '6%',
              },
            ])
            .flat(),
        ];

        tableData = sourcesTableInfo.map((item) => ({
          code: item?.index || ' ',
          name: item?.name || ' ',
          ...Array(periodLength)
            .fill(0)
            .reduce((acc, arrItm, idx) => {
              const itm = get(item, `periodValues[${idx}]`);
              return {
                ...acc,
                [`plan${idx}`]: isNumber(itm?.originalPlanValue)
                  ? prepareNumber(itm.originalPlanValue)
                  : '-',
                [`bo${idx}`]: isNumber(itm?.correctedPlanValue)
                  ? prepareNumber(itm.correctedPlanValue)
                  : '-',
                [`execution${idx}`]: isNumber(itm?.factValue) ? prepareNumber(itm.factValue) : '-',
                [`percent${idx}`]: (
                  <Text
                    data={isNumber(itm?.performance) ? prepareNumber(itm.performance, 2) : '-'}
                    color={theme.colors.dark}
                    font={
                      item?.isBold
                        ? TextFamily.ROBOTO_CONDENSED_BOLD
                        : TextFamily.ROBOTO_CONDENSED_REGULAR
                    }
                  />
                ),
              };
            }, {}),
          ...(item?.isBold
            ? {
                options: {
                  family: theme.fonts.robotoCondensed.bold,
                  styles: {
                    name: {
                      family: theme.fonts.montserrat.bold,
                    },
                  },
                },
              }
            : {}),
        }));
      }

      return {
        tableData,
        tableFields,
        tableFieldsCustom,
      };
    }, [sourcesTableInfo]);

    const managersTableConfig = useMemo(() => {
      let tableData = [];
      let tableFields = [];
      let tableFieldsCustom = [];

      if (managersTableInfo) {
        const periodLength = get(managersTableInfo, '[0].periodValues', []).length;

        const fillData = (item, idx) => ({
          code: item?.index || ' ',
          name: item?.name || ' ',
          ...Array(periodLength)
            .fill(0)
            .reduce((acc, arrItm, idx) => {
              const itm = get(item, `periodValues[${idx}]`);
              return {
                ...acc,
                [`plan${idx}`]: isNumber(itm?.originalPlanValue)
                  ? prepareNumber(itm.originalPlanValue)
                  : '-',
                [`bo${idx}`]: isNumber(itm?.correctedPlanValue)
                  ? prepareNumber(itm.correctedPlanValue)
                  : '-',
                [`execution${idx}`]: isNumber(itm?.factValue) ? prepareNumber(itm.factValue) : '-',
                [`percent${idx}`]: (
                  <Text
                    data={isNumber(itm?.performance) ? prepareNumber(itm.performance, 2) : '-'}
                    color={theme.colors.dark}
                    font={
                      itm?.isBold
                        ? TextFamily.ROBOTO_CONDENSED_BOLD
                        : TextFamily.ROBOTO_CONDENSED_REGULAR
                    }
                  />
                ),
              };
            }, {}),
          children: item?.children?.map((mItm, mIdx) => fillData(mItm, mIdx)),
          ...(item?.isBold
            ? {
                options: {
                  family: theme.fonts.robotoCondensed.bold,
                  styles: {
                    name: {
                      family: theme.fonts.montserrat.bold,
                    },
                  },
                },
              }
            : {}),
        });

        tableFieldsCustom = [
          {
            label: '',
            width: '28%',
          },
          ...get(managersTableInfo, '[0].periodValues', []).map((itm, idx) => ({
            label:
              idx === 0
                ? format(new Date(itm?.period?.periodStart), 'На d MMMM yyyy', { locale: ru })
                : itm?.period?.name,
            colspan: 4,
            width: '24%',
          })),
        ];

        tableFields = [
          {
            label: (
              <NameTitleContainer>
                <Text
                  data='Наименование'
                  color={theme.colors.dark}
                  font={TextFamily.ROBOTO_CONDENSED_REGULAR}
                  size={'12px'}
                />
                <Text
                  data='млрд руб.'
                  color={theme.colors.emptyData}
                  font={TextFamily.ROBOTO_CONDENSED_REGULAR}
                  size={'12px'}
                />
              </NameTitleContainer>
            ),
            field: 'name',
            width: '28%',
            options: {
              text: 'left',
              levelStep: 10,
              firstLevelPadding: 0,
              secondLevelPadding: 4,
              headLabelWidth: '100%',
            },
            justifyContent: 'flex-start',
          },
          ...get(managersTableInfo, '[0].periodValues', [])
            .map((itm, idx) => [
              {
                label: 'План',
                field: `plan${idx}`,
                width: '6%',
                justifyContent: 'flex-end',
              },
              {
                label: 'БО',
                field: `bo${idx}`,
                width: '6%',
                justifyContent: 'flex-end',
              },
              {
                label: 'Исполнение',
                field: `execution${idx}`,
                width: '6%',
                justifyContent: 'flex-end',
              },
              {
                label: '%',
                field: `percent${idx}`,
                width: '6%',
              },
            ])
            .flat(),
        ];

        tableData = managersTableInfo.map((itm, idx) => fillData(itm, idx));
      }

      return {
        tableData,
        tableFields,
        tableFieldsCustom,
      };
    }, [managersTableInfo]);

    return (
      <Modal
        title={'Динамика исполнения бюджета'}
        onClose={handleHide}
        header={<DynamicBudgetModalHeader filter={filter} setFilter={setFilterCallback} />}
        width={'1120px'}
      >
        <Content>
          <Section>
            <Title>Источники финансирования</Title>
            {sourcesTableDataLoading ? (
              <Loader />
            ) : (
              <Table
                fields={sourcesTableConfig.tableFields}
                data={sourcesTableConfig.tableData}
                customTheadFields={sourcesTableConfig.tableFieldsCustom}
                sticky={false}
                childKey={'children'}
              />
            )}
          </Section>
          <Section>
            <Title>Распорядители средств</Title>
            {managersTableDataLoading ? (
              <Loader />
            ) : (
              <Table
                fields={managersTableConfig.tableFields}
                data={managersTableConfig.tableData}
                customTheadFields={managersTableConfig.tableFieldsCustom}
                sticky={false}
                childKey={'children'}
              />
            )}
          </Section>
        </Content>
      </Modal>
    );
  },
);

const NameTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.div`
  ${({ theme }) => font({ family: theme.fonts.montserrat.semibold, size: theme.fonts.sizes.xxmd })};
  margin-bottom: 8px;
`;
const Content = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`;
const Section = styled.div``;

export default DynamicBudgetModal;
