import { format } from 'date-fns';
import styled from 'styled-components';
import TextField from '../../../components/TextField';
import { ru } from 'date-fns/locale';
import { font } from '../../../components/styled/mixins/fonts';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { AppStoreContext } from '../../../stores/app';
import { getAgendaBudgetData, getDebtOMSU } from '../../../actions';
import { Loader } from '../../../components/Loader';

export const numberWithSpaces = (x) => {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};

interface Props {
  filter: any;
}

const AgendaBudgetCollapsedContent: FC<Props> = ({ filter }) => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const [currentData, setCurrentData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCurrentData = useCallback(async () => {
    if (selectedOmsu) {
      try {
        setLoading(true);
        const params = {
          omsuId: selectedOmsu.id,
          periodId: filter.selectedMonth?.id,
        };
        const response = await getAgendaBudgetData(params);
        setCurrentData(response.data?.items || []);
      } catch (e) {
        //
      } finally {
        setLoading(false);
      }
    }
  }, [selectedOmsu]);

  useEffect(() => {
    fetchCurrentData();
  }, [fetchCurrentData]);

  return (
    <ComponentContainer>
      <DateLabel>
        На
        {filter.selectedMonth
          ? ` ${filter.selectedMonth.name} ${filter.selectedMonth.appliesToYear}`
          : ''}
        :
      </DateLabel>
      {/*<TextField label={'Доходы'} value={numberWithSpaces(1234000)} valueBold />*/}
      {/*<TextField label={'Расходы'} value={numberWithSpaces(1234000)} valueBold />*/}
      {/*<TextField label={'Дефицит'} value={numberWithSpaces(1234000)} valueBold />*/}
      {/*<TextField label={'Долг'} value={numberWithSpaces(1234000)} valueBold />*/}
      {loading ? (
        <Loader />
      ) : (
        <>
          {currentData.map((item) => (
            <TextField
              key={item.id}
              label={item.name}
              value={new Intl.NumberFormat('ru-RU', {
                minimumFractionDigits: 2,
              }).format(item.factValue)}
              valueBold
            />
          ))}
        </>
      )}
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;
const DateLabel = styled.div`
  ${({ theme }) => font({ color: theme.colors.dark, lineHeight: '32px' })};
`;

export default AgendaBudgetCollapsedContent;
