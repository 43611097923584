import { serverDate } from '../../utils/dates';
import { toJS } from 'mobx';

export const prepareParams = ({ filter, selectedOmsu = null, isRisks = false }) => {
  const params = {
    isRisk: filter.isRisk,
    isBeforeRisk: filter.isBeforeRisk,
    isCurrentYearOpen: filter.isCurrentYearOpen,
    hasFederalFunding: filter.hasFederalFunding,
    isNationalProject: filter.isNationalProject,
    IsMunFunding: filter.isMunicipalBudget,
  } as any;
  if (filter.entityType?.id) params.entityTypeId = filter.entityType.id;
  if (filter.fundingType?.id) params.fundingTypeId = filter.fundingType.id;
  if (filter.sphere?.id) params.sphereId = filter.sphere.id;
  if (filter.workType?.id) params.workTypeId = filter.workType.id;
  if (!isRisks) {
    if (filter.selectedYear || filter.selectedMonth) {
      params.calendarId = filter.selectedMonth?.id || filter.selectedYear?.id;
    }
    params.isCurrentYearToOpen = filter.isCurrentYearToOpen;
    if (filter.view) params.view = filter.view.map((el) => el.id).join('|');
  } else {
    if (filter.fromDate) {
      params.fromDate = serverDate(filter.fromDate);
    }
    if (filter.toDate) {
      params.toDate = serverDate(filter.toDate);
    }
    params.isOpenPlan = filter.isOpenPlan;
    params.isCurrentYearOpen = filter.isCurrentYearOpen;
    params.IsCurrentYearOpen = filter.IsCurrentYearOpen;
  }
  if (filter.fundingType?.id) params.fundingTypeId = filter.fundingType.id;
  if (filter.curator?.id) params.curatorId = filter.curator?.id;
  if (selectedOmsu?.id) params.omsuId = selectedOmsu.id;

  return params;
};

export const prepareAdditionalParams = ({ row, filter }) => {
  const additionalFilters = {} as any;
  let levels;
  if (!row.objectLevel) {
    levels = 1;
  } else {
    levels = row.objectLevel + 1 > 3 ? 3 : row.objectLevel + 1;
  }
  Array(levels)
    .fill(0)
    .forEach((_, idx) => {
      const viewEl = filter.view[idx];
      if (viewEl.id === '1') {
        additionalFilters.workType = row.workTypeId ? { id: row.workTypeId } : filter.workType;
      } else if (viewEl.id === '2') {
        additionalFilters.sphere = row.sphereId ? { id: row.sphereId } : filter.sphere;
      } else if (viewEl.id === '3') {
        additionalFilters.entityType = row.entityTypeId
          ? { id: row.entityTypeId }
          : filter.entityType;
      }
    });

  return additionalFilters;
};
