import Select from '../../../components/Select';
import SelectList from '../../../components/Calendar/SelectList';
import styled from 'styled-components';
import React, { FC, useCallback, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { NsiStoreContext } from '../../../stores/nsi';

interface Props {
  setFilterCallback: any;
  filter: any;
}

const DevelopmentSocialNeedsAddition: FC<Props> = observer(({ filter, setFilterCallback }) => {
  const { years } = useContext(NsiStoreContext);

  useEffect(() => {
    if (years?.length && !filter.selectedYear) {
      const currentYear =
        years.find((el) => el.appliesToYear === new Date().getFullYear()) || years[0];
      onYearClick(currentYear);
    }
  }, [years, filter.selectedYear]);

  const onYearClick = useCallback((e: any) => {
    setFilterCallback('selectedYear', e);
  }, []);

  useEffect(() => {
    if (years?.length && !filter.selectedYear) {
      const currentYear =
        years.find((el) => el.appliesToYear === new Date().getFullYear()) || years[0];
      onYearClick(currentYear);
    }
  }, [years, filter.selectedYear]);

  return (
    <ComponentContainer>
      <Select
        key={filter.selectedYear?.id}
        renderList={({ active, onClose }) => (
          <SelectList active={active} onClose={onClose} list={years} onClick={onYearClick} />
        )}
        label={'Все года открытия'}
        value={filter.selectedYear}
        fieldFullsize={false}
      />
    </ComponentContainer>
  );
});

const ComponentContainer = styled.div``;

export default DevelopmentSocialNeedsAddition;
