import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ColumnChart from '../../../../../components/ColumnChart';
import { Table } from '../../../../../components/Table';
import { gpFields } from './gpFields';
import { font } from '../../../../../components/styled/mixins/fonts';
import BudgetModalFilters, { FilterTypes } from '../../../../../components/BudgetModalFilters';
import { getBudgetExecutionDiagram, getDinamBudgetExecutionTable } from '../../../../../actions';
import { Loader } from '../../../../../components/Loader';
import Text, { TextFamily } from '../../../../../components/Text';
import { theme } from '../../../../../styles/theme';
import { useFilter } from '../../../../../hooks/useFilter';
import { initialCashFilterValues } from '../consts';
import { isNumber } from 'lodash';
import { prepareNumber, prepareNumberForTable } from '../../../../../utils/prepareNumber';
import { AppStoreContext } from '../../../../../stores/app';
import { observer } from 'mobx-react-lite';

interface Props {
  filter: any;
}

const CONTEXT_CODE = '10001';

const CashType: FC<Props> = observer(({ filter }) => {
  const { selectedOmsu } = useContext(AppStoreContext);

  const { filter: cashFilter, setFilterCallback: setCashFilterCallback } = useFilter({
    initialFilterValues: { ...initialCashFilterValues },
    name: 'budgetExecutionCashFilters',
  });

  const [chartData, setChartData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [chartDataLoading, setChartDataLoading] = useState<boolean>(false);
  const [tableDataLoading, setTableDataLoading] = useState<boolean>(false);

  useEffect(() => {
    if (filter?.selectedMonth) {
      const fetchData = async () => {
        setChartDataLoading(true);
        try {
          const response = await getBudgetExecutionDiagram({
            executionContextId: CONTEXT_CODE,
            periodId: filter?.selectedMonth?.id,
            omsuId: selectedOmsu?.id,
            ciogvId: cashFilter[FilterTypes.CIOGV],
            nacProjectId: cashFilter[FilterTypes.NATIONAL_PROJECT],
            programId: cashFilter[FilterTypes.GP],
            finSourceId: cashFilter[FilterTypes.FUNDING_SOURCE],
            // sourceType: "string"
          });
          setChartData(response?.data?.items?.slice(0, 9));
        } catch (e) {
          //
        } finally {
          setChartDataLoading(false);
        }
      };
      fetchData();
    }
  }, [filter, cashFilter]);

  useEffect(() => {
    if (filter?.selectedMonth) {
      const fetchData = async () => {
        setTableDataLoading(true);
        try {
          const response = await getDinamBudgetExecutionTable({
            ExecutionContextCode: CONTEXT_CODE,
            periodId: filter?.selectedMonth?.id,
            ciogvId: cashFilter[FilterTypes.CIOGV],
            nacProjectId: cashFilter[FilterTypes.NATIONAL_PROJECT],
            gp: cashFilter[FilterTypes.GP],
            fundingSource: cashFilter[FilterTypes.FUNDING_SOURCE],
          });
          setTableData(response.data.items);
        } catch (e) {
          //
        } finally {
          setTableDataLoading(false);
        }
      };
      fetchData();
    }
  }, [filter, cashFilter]);

  const chartConfig = useMemo(() => {
    let result = {};

    if (chartData) {
      // const maxDataLevel = Math.max(...chartData.map((itm) => itm.percents));
      // const maxChartLevel = Math.ceil(maxDataLevel / 10) * 10 + 10;

      result = {
        xAxis: {
          categories: chartData?.map((itm) => itm.name),
          gridLineWidth: 1,
          gridLineDashStyle: 'dash',
          tickmarkPlacement: 'on',
        },
        yAxis: {
          visible: false,
          min: 0,
          // max: maxChartLevel,
        },
        legend: {
          enabled: false,
        },
        title: {
          text: '',
        },
        plotOptions: {
          column: {
            minPointLength: 40,
          },
          series: {
            pointPadding: 0.05,
            groupPadding: 0,
          },
        },
        series: [
          {
            type: 'column',
            name: '',
            data: chartData?.map((itm) => ({
              y: itm.percents,
              value: prepareNumber(itm.indicatorValue),
            })),
            color: '#646CAA',
            dataLabels: [
              {
                inside: false,
                enabled: true,
                // format: '<div style="font-size: 16px;">' + '{point.y}%</div>',
                formatter: function () {
                  return `${prepareNumber(this.point.y)}%`
                },
                useHTML: true,
                align: 'center',
                style: {
                  fontFamily: 'Montserrat Bold',
                  color: '#000',
                  fontSize: '16px',
                },
              },
              {
                inside: true,
                enabled: true,
                format:
                  '<div style="font-size: 16px; white-space: normal; text-align: center; line-height: .8;">' +
                  '<span style="white-space: nowrap;">{point.value}</span> <span style="white-space: nowrap; font-size: 12px; line-height: .5;">млрд руб.</span></div>',
                useHTML: true,
                align: 'center',
                style: {
                  fontFamily: 'Roboto Condensed Regular',
                  fontWeight: 'normal',
                  color: '#FFF',
                },
              },
            ],
          },
        ],
      };
    }

    return result;
  }, [chartData]);

  const tableConfig = useMemo(() => {
    let result = [];

    if (tableData) {
      result = tableData.map((item) => ({
        name: item?.name,
        ba: isNumber(item?.ba) ? prepareNumberForTable(item?.ba) : '-',
        bafb: isNumber(item?.vtchFB1) ? prepareNumberForTable(item?.vtchFB1) : '-',
        bo: isNumber(item?.bo) ? prepareNumberForTable(item?.bo) : '-',
        bofb: isNumber(item?.vtchFB2) ? prepareNumberForTable(item?.vtchFB2) : '-',
        bopercent: (
          <Text
            data={isNumber(item?.percent1) ? prepareNumberForTable(item?.percent1) : '-'}
            color={theme.colors.dark}
            font={TextFamily.ROBOTO_CONDENSED_REGULAR}
          />
        ),
        fbpercent: (
          <Text
            data={isNumber(item?.percentFB1) ? prepareNumberForTable(item?.percentFB1) : '-'}
            color={theme.colors.dark}
            font={TextFamily.ROBOTO_CONDENSED_REGULAR}
          />
        ),
        register: isNumber(item?.kassa) ? prepareNumberForTable(item?.kassa) : '-',
        registerfb: isNumber(item?.vtchFB3) ? prepareNumberForTable(item?.vtchFB3) : '-',
        registerpercent: (
          <Text
            data={isNumber(item?.percent2) ? prepareNumberForTable(item?.percent2) : '-'}
            color={theme.colors.dark}
            font={TextFamily.ROBOTO_CONDENSED_REGULAR}
          />
        ),
        fbpercent1: (
          <Text
            data={isNumber(item?.percentFB2) ? prepareNumberForTable(item?.percentFB2) : '-'}
            color={theme.colors.dark}
            font={TextFamily.ROBOTO_CONDENSED_REGULAR}
          />
        ),
      }));
    }

    return result;
  }, [tableData]);

  const chartContent = useMemo(() => {
    if (chartDataLoading) {
      return <Loader />;
    }
    return <ColumnChart config={chartConfig} />;
  }, [chartDataLoading, chartConfig]);

  const tableContent = useMemo(() => {
    if (tableDataLoading) {
      return <Loader />;
    }
    return <Table fields={gpFields} data={tableConfig} sticky={false} />;
  }, [tableDataLoading, tableConfig]);

  return (
    <ComponentContainer>
      {chartContent}
      <TableWrapper>
        <Heading>
          <Title>ГП</Title>
          <Addition>
            <BudgetModalFilters
              filter={cashFilter}
              setFilter={setCashFilterCallback}
              fundingSourceMultiple
            />
          </Addition>
        </Heading>
        {tableContent}
      </TableWrapper>
    </ComponentContainer>
  );
});

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Title = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xxmd,
      family: theme.fonts.montserrat.semibold,
      lineHeight: '34px',
    })};
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Addition = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default CashType;
