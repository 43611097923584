import styled, { css } from 'styled-components';
import { FC } from 'react';
import { font } from '../styled/mixins/fonts';
import { darken } from 'polished';

export enum ChartType {
  COLUMN,
  ROW,
}

interface Props {
  config: {
    id: number;
    label: string;
    amount: number;
    onClick?: VoidFunction;
  }[];
  type?: ChartType;
  max: number;
}

const RowChart: FC<Props> = ({ config, type = ChartType.COLUMN, max }) => {
  const calculatePosition = (last, type) => {
    switch (type) {
      case ChartType.COLUMN:
        return {
          top: last ? 'auto' : '-22px',
          bottom: last ? '-22px' : 'auto',
        };
      case ChartType.ROW:
        return {
          top: '-22px',
          bottom: 'auto',
        };
    }
  };

  return (
    <ComponentContainer type={type}>
      {config.map(({ id, label, amount, onClick }, idx) => (
        <ChartRow key={id} type={type} last={idx === config.length - 1}>
          <Info position={calculatePosition(idx, type)}>
            <Label>{label}</Label>
            <Amount>{amount}</Amount>
          </Info>
          <LineWrapper position={calculatePosition(idx === config.length - 1, type)}>
            <BackgroundLine />
            <Line width={(max && amount) ? max / amount : 0} secondary={idx === config.length - 1} onClick={onClick} />
          </LineWrapper>
        </ChartRow>
      ))}
      <Separator />
    </ComponentContainer>
  );
};
const Separator = styled.div`
  height: 38px;
  width: 1px;
  background-color: #000;
  position: absolute;
  left: 0;
  top: 18px;
`;
const ComponentContainer = styled.div<{ type: ChartType }>`
  padding-top: 22px;
  padding-bottom: 22px;
  position: relative;
  display: flex;
  flex-direction: ${({ type }) => (type === ChartType.ROW ? 'row' : 'column')};
  gap: 2px;
  flex: 1 1 auto;
  ${({ type }) =>
    type === ChartType.ROW &&
    css`
      ${Separator} {
        top: 0;
        left: calc(50% + 1px);
      }
    `}
`;
const Line = styled.div<{ width: number; secondary: boolean }>`
  width: ${({ width }) => width ? `calc(100% / ${width})` : '0'};
  height: 12px;
  border-radius: 0 8px 8px 0;
  background-color: ${({ theme, secondary }) =>
    secondary ? theme.colors.status.overdue : theme.colors.status.done};
  &:hover {
    background-color: ${({ theme, secondary }) =>
      secondary ? darken(0.1, theme.colors.status.overdue) : darken(0.1, theme.colors.status.done)};
  }
  cursor: pointer;
  z-index: 1;
`;
const BackgroundLine = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  height: 12px;
  border-radius: 0 8px 8px 0;
  background-color: ${({ theme }) => theme.colors.hover};
`;
const Label = styled.div`
  ${({ theme }) =>
    font({ size: theme.fonts.sizes.sm, color: theme.colors.dark, lineHeight: '16px' })};
`;
const Amount = styled.div`
  ${({ theme }) => font({ family: theme.fonts.montserrat.semibold, lineHeight: '18px' })};
`;
const Info = styled.div<{ position: { top: string; bottom: string } }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: ${({ position }) => position.top};
  bottom: ${({ position }) => position.bottom};
`;
const LineWrapper = styled.div<{ position: { top: string; bottom: string } }>`
  position: relative;
  display: flex;
`;
const ChartRow = styled.div<{ type: ChartType; last: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 2px;
  flex: 1 1 auto;
  ${({ type, last }) =>
    type === ChartType.ROW &&
    css`
      ${Info} {
        flex-direction: ${last && 'row-reverse'};
      }
      ${Line} {
        border-radius: ${!last && '8px 0 0 8px'};
      }
      ${BackgroundLine} {
        border-radius: ${!last && '8px 0 0 8px'};
      }
      ${LineWrapper} {
        flex-direction: ${!last && 'row-reverse'};
      }
    `}
`;

export default RowChart;
