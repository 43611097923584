import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Loader } from '../../../components/Loader';
import HorizontalList, { ChartItem } from '../../../components/HorizontalList';
import Select from '../../../components/Select';
import SelectList from '../../../components/Calendar/SelectList';
import { generateAgendaIndicatorsConfig, generateAgendaObjectsConfig } from './configs';
import { ModalsStore } from '../../../stores/modal';
import Chart from '../../../components/Chart';
import { observer } from 'mobx-react-lite';
import { getDiagrammsDataByOMSUid, getRiskOMSU } from '../../../actions';
import { AppStoreContext } from '../../../stores/app';
import { useFilter } from '../../../hooks/useFilter';
import { fundingTypes, initialAgendaRaitingRisksFilter } from './consts';

const RiskOmsuData: FC = observer(() => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const [riskOMSUData, setRiskOMSUData] = useState<any>();
  const [data, setData] = useState<any>();
  const [loadingRiskOMSUData, setLoadingRiskOMSUData] = useState(true);

  const { filter: agendaRaitingRisksFilter, setFilterCallback: setAgendaRaitingRisksFilter } =
    useFilter({
      initialFilterValues: { ...initialAgendaRaitingRisksFilter },
      name: 'agendaRaitingRisksFilter',
    });

  const fetchRiskOMSUData = useCallback(async () => {
    if (selectedOmsu && agendaRaitingRisksFilter?.type) {
      try {
        setLoadingRiskOMSUData(true);
        const params = {
          omsuId: selectedOmsu.id,
          budgetType: agendaRaitingRisksFilter?.type?.id,
        };
        const response = await getRiskOMSU(params);
        setRiskOMSUData(response.data || {});
        const responseObjects = await getDiagrammsDataByOMSUid(selectedOmsu?.id);
        setData(responseObjects.data?.items?.[0] || {});
      } catch (e) {
        //
      } finally {
        setLoadingRiskOMSUData(false);
      }
    }
  }, [selectedOmsu, agendaRaitingRisksFilter]);

  useEffect(() => {
    fetchRiskOMSUData();
  }, [fetchRiskOMSUData]);

  return (
    <>
      {loadingRiskOMSUData ? (
        <Loader />
      ) : (
        <HorizontalList
          autoItemWidth
          itemWidth={'50%'}
          title={'Риски'}
          addition={
            <Select
              renderList={({ active, onClose }) => (
                <SelectList
                  list={fundingTypes}
                  onClick={(e) => setAgendaRaitingRisksFilter('type', e)}
                  onClose={onClose}
                  active={active}
                />
              )}
              label={''}
              value={agendaRaitingRisksFilter.type}
            />
          }
          list={[
            {
              id: 0,
              title: 'Объекты',
              data: generateAgendaObjectsConfig([
                data?.riskedObjectsAmount,
                data?.preRiskedObjectsAmount,
                data?.openedObjectsAmount,
              ]),
              total: data?.objectsTotal,
              onClick: (e) =>
                ModalsStore.showModal('risks-objects-modal', { chartSector: e.options.code }),
            },
            {
              id: 1,
              title: 'Показатели',
              data: generateAgendaIndicatorsConfig([
                riskOMSUData?.riskIndicatorCount,
                riskOMSUData?.inIndicatorCount,
              ]),
              total: riskOMSUData?.allIndicatorCount,
              onClick: (e) =>
                ModalsStore.showModal('risks-indicators-modal', { chartSector: e.options.code }),
            },
          ]}
        >
          {(item: ChartItem) => <Chart {...item} />}
        </HorizontalList>
      )}
    </>
  );
});

export default RiskOmsuData;
