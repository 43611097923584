import styled, { css } from 'styled-components';
import { FC, useCallback } from 'react';
import { ListItem as List } from '../../Select';
import { isMobile } from 'react-device-detect';

interface Props {
  onClick: (object) => void;
  active?: boolean;
  idx?: number;
}

const ListItem: FC<Props & List> = ({ id, label, value, onClick, active = false, ...props }) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      onClick({ value, label, id, ...props });
    },
    [value, label, id],
  );

  return (
    <ListItemWrapper active={active} onClick={handleClick} isMobile={isMobile}>
      {label}
    </ListItemWrapper>
  );
};

const ListItemWrapper = styled.div<{ active: boolean; isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '13px 24px 13px 16px' : '7px 24px 7px 16px')};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fonts.sizes.md};
  font-family: ${({ theme }) => theme.fonts.montserrat.regular};
  //white-space: nowrap;
  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.colors.hover};
    `}
`;

export default ListItem;
