import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { theme } from '../../styles/theme';

interface Props {
  grade?: number;
  isPrice?: boolean;
}

const Grade: FC<Props> = ({ grade: gradeProps, isPrice = false }) => {
  const grade = useMemo(() => {
    if (!gradeProps && gradeProps === 0) {
      return '–';
    } else if (isPrice) {
      return new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2 }).format(gradeProps);
    }
    return gradeProps;
  }, [gradeProps]);

  return (
    <ComponentContainer color={gradeProps >= 0 ? '#000' : theme.colors.status.overdue}>
      {grade ? grade : '–'}
    </ComponentContainer>
  );
};

const ComponentContainer = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;

export default Grade;
