import { FC } from 'react';
import styled from 'styled-components';
import { font } from '../../../../../components/styled/mixins/fonts';
import { theme } from '../../../../../styles/theme';

interface Props {
  text: string;
}

const Note: FC<Props> = ({ text }) => {
  return (
    <NoteContent>
      <Title>Примечание</Title>
      <Text>{text}</Text>
    </NoteContent>
  );
};

const Title = styled.div`
  ${font({ color: 'rgba(0, 0, 0, 0.56)', size: theme.fonts.sizes.xs })};
  text-transform: uppercase;
  margin-bottom: 8px;
`;

const Text = styled.div`
  ${font()};
`;

const NoteContent = styled.div`
  padding: 8px 12px;
  max-width: 558px;
`;

export default Note;
