import { renderProgress } from '../Rating';
import styled from 'styled-components';
import { font } from '../styled/mixins/fonts';
import { FC } from 'react';

export enum StatsSizes {
  SMALL,
  NORMAL,
  BIG,
}

interface Props {
  progress: number | string;
  definition?: string;
  size?: StatsSizes;
  textNoWrap?: boolean;
  precision?: number;
}

const Stats: FC<Props> = ({ progress, definition, size, textNoWrap = true, precision }) => {
  const calculateSize = (size) => {
    switch (size) {
      case StatsSizes.SMALL:
        return '12px';
      case StatsSizes.NORMAL:
        return '14px';
      case StatsSizes.BIG:
        return '30px';
      default:
        return '14px';
    }
  };

  return (
    <Progress>
      <Text color={renderProgress(progress, precision).color} size={calculateSize(size)} noWrap={textNoWrap}>
        {renderProgress(progress, precision).text}
      </Text>
      <Definition color={renderProgress(progress, precision).color} size={calculateSize(size)}>
        {definition}
      </Definition>
    </Progress>
  );
};

const Progress = styled.div`
  position: relative;
  z-index: 1;
  flex: 0 0 auto;
  display: flex;
  align-items: flex-end;
`;
const Definition = styled.div<{ color: string; size: string }>`
  ${({ theme, color, size }) => font({ family: theme.fonts.montserrat.semibold, color, lineHeight: size })};
`;
const Text = styled.div<{ color: string; size: string; noWrap: boolean; }>`
  ${({ theme, color, size }) => font({ family: theme.fonts.montserrat.semibold, color, size, lineHeight: size })};
  white-space: ${({noWrap}) => noWrap ? 'nowrap' : 'normal'};
`;

export default Stats;
