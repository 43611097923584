import styled from 'styled-components';
import { FC } from 'react';
import { RatingItem } from '../index';
import { font } from '../../styled/mixins/fonts';
import { renderProgress } from '../../Rating';

const RatingRowItem: FC<RatingItem> = ({ rating, differenceRating, mark, year }) => {
  return (
    <ComponentContainer>
      <Rating>{rating}</Rating>
      <Dot />
      <Difference color={renderProgress(differenceRating).color}>
        {renderProgress(differenceRating).text}
      </Difference>
      <Year>{year}</Year>
      {mark && <Mark>({mark} показ.)</Mark>}
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  flex: 1 1 auto;
  min-width: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Rating = styled.div`
  ${({ theme }) => font({ family: theme.fonts.montserrat.semibold })};
  margin-bottom: 8px;
`;
const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #e0e0e0;
`;
const Difference = styled.div<{ color: string }>`
  ${({ theme, color }) => font({ family: theme.fonts.montserrat.semibold, color })};
  margin-top: 8px;
  margin-bottom: 2px;
`;
const Year = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.sm,
      family: theme.fonts.robotoCondensed.regular,
      color: theme.colors.dark,
    })};
`;
const Mark = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.sm,
      family: theme.fonts.robotoCondensed.regular,
      color: theme.colors.dark,
    })};
`;

export default RatingRowItem;
