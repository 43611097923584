import Card from '../../../components/Card';
import styled from 'styled-components';
import CombinedChart from '../../../components/CombinedChart';
import { font } from '../../../components/styled/mixins/fonts';
import { memo, useEffect, useMemo, useState } from 'react';
import { getBudgetDataExpensesDynamics } from '../../../actions';
import { Loader } from '../../../components/Loader';

const BudgetSummary = memo(() => {
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getBudgetDataExpensesDynamics({
          limit: 999,
          offset: 0,
        });
        setMeta(response.data.items);
      } catch (e) {
        //
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const preparedData = useMemo(() => {
    if (!meta) return {};
    return {
      categories: meta.map((el) => el.period.name),
      data: [
        meta.map((el) => el.growthRateDebt),
        meta.map((el) => el.otherIncome),
        meta.map((el) => el.ownIncome),
        meta.map((el) => el.debtVolume),
        meta.map((el) => el.debtMarket),
      ],
    };
  }, [meta]);

  return (
    <Card>
      <Content>
        <Heading>
          <Title>В условиях ограничений коммерческих заимствований (25% доходов)</Title>
          <Subtitle>Вызов: привлечение ресурсов, федеральная поддержка</Subtitle>
        </Heading>
        {loading ? (
          <Loader />
        ) : (
          <CombinedChart categories={preparedData?.categories} data={preparedData.data} />
        )}
      </Content>
    </Card>
  );
});

const Content = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const Heading = styled.div``;
const Title = styled.div`
  ${({ theme }) =>
    font({ size: theme.fonts.sizes.xxxmd, family: theme.fonts.montserrat.semibold })};
  margin-bottom: 4px;
`;
const Subtitle = styled.div`
  ${({ theme }) => font({ color: theme.colors.dark })};
`;

export default BudgetSummary;
