import React, { FC, ReactNode, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useKeyClick } from '../../hooks/useKeyClick';
import CloseIcon from '../../assets/icons/CloseIcon';
import ActionIcon from '../ActionIcon';

interface Props {
  title: string;
  activateLineClamp?: boolean;
  onClose: () => void;
  children: ReactNode;
  width?: string;
  footer?: ReactNode;
  header?: ReactNode;
  contentOverflow?: boolean;
}

const Modal: FC<Props> = ({ title, activateLineClamp, onClose, children, width = '765px', footer, header, contentOverflow = true }) => {
  const ref = useRef(null);
  useKeyClick(onClose, 27);

  useEffect(() => {
    return () => onClose();
  }, [onClose]);

  return (
    <ModalContainer ref={ref}>
      <Wrapper width={width}>
        <ModalHeader>
          {title && <Title lineClamp={activateLineClamp}>{title}</Title>}
          <Header>{header}</Header>
          {header && <HeaderSeparator />}
          <ActionIcon action={onClose}>
            <CloseIcon />
          </ActionIcon>
        </ModalHeader>
        <Content data-component={"Modal-Content"} contentOverflow={contentOverflow}>{children}</Content>
        {footer && <Footer>{footer}</Footer>}
      </Wrapper>
    </ModalContainer>
  );
};

const HeaderSeparator = styled.div`
  width: 1px;
  height: 28px;
  background-color: rgba(0, 0, 0, 0.12);
  margin: 6px 16px 0 24px;
`;

const Header = styled.div`
  flex: 1 1 auto;
  display: flex;
  margin-top: 5px;
`;

const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 11;
`;

const Wrapper = styled.div<{ width: string }>`
  position: absolute;
  background: #fff;
  box-shadow: 0 24px 32px rgba(8, 35, 48, 0.12);
  border-radius: 22px;
  max-width: ${({ width }) => width};
  width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  z-index: 1;
  max-height: 90%;
  margin: 0 50px;
  @media all and (max-width: 1200px) {
    left: 0;
    margin: 0 5%;
    max-width: 95%;
    width: calc(100% - 10%);
  }
`;

const ModalHeader = styled.div`
  display: flex;
  padding: 16px 16px 16px 24px;
  justify-content: space-between;
  flex: 0 0 auto;
  //align-items: center;
`;

const Content = styled.div<{contentOverflow: boolean}>`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  align-items: flex-start;
  overflow: ${({contentOverflow}) => contentOverflow ? 'auto' : 'hidden'};
`;

const Footer = styled.div`
  display: flex;
  padding: 0 20px 20px 20px;
`;

const Title = styled.div<{ lineClamp?: boolean }>`
  font-size: 22px;
  line-height: 38px;
  font-family: ${({ theme }) => theme.fonts.montserrat.semibold};
  padding-right: 16px;
  ${({lineClamp}) => lineClamp && css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2; 
    -webkit-box-orient: vertical;
  `}
`;

export { Modal };
