import React, { FC, memo, MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components';
import { font } from '../styled/mixins/fonts';

interface Props {
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit';
  disabled?: boolean;
  hollow?: boolean;
  opacity?: number;
  color?: string;
}

const Button: FC<Props> = memo(
  ({ children, onClick, type = 'button', disabled = false, hollow = false }: Props) => {
    return (
      <Wrapper onClick={onClick} type={type} disabled={disabled} hollow={hollow}>
        {children}
      </Wrapper>
    );
  },
);

const TextButton: FC<Props> = memo(
  ({ children, onClick, disabled = false, opacity = 1, color }: Props) => {
    return (
      <TextButtonWrapper onClick={onClick} disabled={disabled} opacity={opacity} color={color}>
        {children}
      </TextButtonWrapper>
    );
  },
);

const AuthButton: FC<Props> = memo(({ children, onClick, disabled = false }: Props) => {
  return (
    <AuthButtonWrapper onClick={onClick} disabled={disabled}>
      {children}
    </AuthButtonWrapper>
  );
});

const Wrapper = styled.button<{ disabled: boolean; type: string; hollow: boolean }>`
  ${({ hollow, theme }) => font({ color: hollow ? theme.colors.primary : '#fff' })};
  padding: 6px 16px;
  border-radius: ${({ theme }) => theme.styles.borderRadius};
  border: none;
  background-color: ${({ theme, hollow }) => (hollow ? 'transparent' : theme.colors.primary)};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
`;

const TextButtonWrapper = styled.button<{ disabled: boolean; opacity: number; color: string }>`
  ${({ theme, disabled, color }) =>
    font({ color: disabled ? theme.colors.disabled : color ? color : theme.colors.primary })};
  padding: 6px 10px;
  border-radius: ${({ theme }) => theme.styles.borderRadius};
  border: none;
  background-color: transparent;
  cursor: pointer;
  opacity: ${({ opacity }) => opacity};
  &:hover {
    background-color: ${({ theme }) => theme.colors.hover};
    opacity: 1;
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.primary12};
    opacity: 1;
  }
`;

const AuthButtonWrapper = styled.button`
  ${({ theme }) => font({ color: theme.colors.primary })};
  background-color: ${({disabled}) => disabled ? 'rgba(255,255,255,.3)' : '#fff'};
  border-radius: 56px;
  border: 0;
  padding: 11px 16px;
  cursor: pointer;
`;

export { Button, TextButton, AuthButton };
