import styled, { css } from 'styled-components';
import Icon from '../Icon';
import SmallArrowIcon from '../../assets/icons/SmallArrowIcon';
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { font } from '../styled/mixins/fonts';
import { AnchorPopupStore } from '../../stores/anchorPopup';

export enum ListPosition {
  TOP,
  BOTTOM,
}

export interface ListItem {
  value: any;
  label: string;
  id: number | string;
  count?: number;
}

interface Props {
  renderList: ({ active, onClose }: { active: string | number; onClose: () => void }) => ReactNode;
  label: string;
  value: any;
  fullHeight?: boolean;
  fieldFullsize?: boolean;
  position?: 'left' | 'right';
  listPosition?: ListPosition;
  isAnchorPopup?: boolean;
}

const Select: FC<Props> = ({
  label,
  value,
  renderList,
  fullHeight,
  fieldFullsize = true,
  position,
  isAnchorPopup = true,
  // listPosition = ListPosition.BOTTOM,
}) => {
  const [active, setActive] = useState(false);
  const selectRef = useRef<any>();

  const onClose = useCallback(() => {
    setActive(false);
    if (isAnchorPopup) {
      AnchorPopupStore.hidePopup();
    }
  }, []);

  const PopupComponent = (
    <ListVisibility fullHeight={fullHeight}>
      {renderList({ active: value, onClose })}
    </ListVisibility>
  );

  useEffect(() => {
    if (active && isAnchorPopup) {
      AnchorPopupStore.changeComponent(PopupComponent);
    }
  }, [PopupComponent, active]);

  return (
    <SelectWrapper ref={selectRef}>
      <SelectField
        active={active}
        onClick={(e) => {
          e.stopPropagation();
          setActive(!active);
          if (isAnchorPopup && active) {
            AnchorPopupStore.hidePopup();
          }
          if (isAnchorPopup && !active) {
            AnchorPopupStore.showPopup({
              position: position,
              component: PopupComponent,
              anchorRef: selectRef.current,
              onClose: () => setActive(false),
            });
          }
        }}
      >
        <Label size={fieldFullsize}>{value?.label || label}</Label>
        <Icon rotate={active ? -180 : 0} size={18}>
          <SmallArrowIcon />
        </Icon>
      </SelectField>
      {!isAnchorPopup && active && (
        <ListWrapper>{renderList({ active: value, onClose: onClose })}</ListWrapper>
      )}
    </SelectWrapper>
  );
};

const ListWrapper = styled.div`
  position: absolute;
  background-color: #fff;
  box-shadow: 0 8px 16px rgb(8 35 48 / 20%);
  border-radius: 8px;
`;

const SelectWrapper = styled.div`
  position: relative;
`;
const SelectField = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 4px 6px 10px;
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.hover};
  }

  ${({ active }) =>
    active &&
    css`
      background-color: rgba(0, 19, 92, 0.12) !important;
    `}
`;
const Label = styled.div<{ size: boolean }>`
  ${({ theme }) => font({ color: theme.colors.primary, lineHeight: '20px' })};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: ${({ size }) => (size ? 'auto' : '150px')};
`;
export const ListVisibility = styled.div<{
  fullHeight?: boolean;
}>`
  margin-top: 6px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.02);
  box-shadow: 0 8px 16px rgba(8, 35, 48, 0.2);
  border-radius: 8px;
  max-height: ${({ fullHeight }) => (fullHeight ? 'auto' : '300px')};
  overflow-y: ${({ fullHeight }) => (fullHeight ? 'visible' : 'auto')};
  overflow-x: ${({ fullHeight }) => (fullHeight ? 'visible' : 'hidden')};
  z-index: 1111;
`;

export default Select;
