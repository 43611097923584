import ca from 'date-fns/esm/locale/ca/index.js';
import { FC, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import Icon from '../Icon';
import { font } from '../styled/mixins/fonts';

interface Props {
  label: string;
  value: string;
  icon?: ReactElement;
  type?: string;
  valueBold?: boolean;
  valueColor?: string;
}

const TextField: FC<Props> = ({ label, value, icon, type, valueBold, valueColor = '#000' }) => {

  const TextFieldWrapperByType = (props: { fieldType: string, fieldValue?: string, children: any }) => {
    switch (props.fieldType) {
      case 'email': return (
        <TextFieldWrapperLinked {...props} href={`mailto:${props.fieldValue}`}>
          {props.children}
        </TextFieldWrapperLinked>
      )
      case 'phone': return (
        <TextFieldWrapperLinked {...props} href={`tel:${props.fieldValue}`}>
          {props.children}
        </TextFieldWrapperLinked>
      )
      default: return (
        <TextFieldWrapper {...props}>
          {props.children}
        </TextFieldWrapper>
      ) 
    }
  }

  return (
    <TextFieldWrapperByType fieldType={type} fieldValue={value}>
      {icon && (
        <IconWrapper>
          <Icon>{icon}</Icon>
        </IconWrapper>
      )}
      <Content>
        <Label>{label}</Label>
        <Value color={valueColor} bold={valueBold}>
          {value}
        </Value>
      </Content>
    </TextFieldWrapperByType>
  );
};

const TextFieldWrapperLinked = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
`;
const TextFieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Label = styled.div`
  ${({ theme }) =>
    font({
      color: theme.colors.dark,
      family: theme.fonts.montserrat.regular,
      size: theme.fonts.sizes.sm,
    })};
`;
const Value = styled.div<{ bold: boolean }>`
  ${({ theme, bold, color }) =>
    font({
      family: bold ? theme.fonts.montserrat.semibold : theme.fonts.montserrat.regular,
      color,
    })};
  min-height: 20px;
`;
const Content = styled.div``;
const IconWrapper = styled.div`
  margin-right: 16px;
`;

export default TextField;
