import { Modal } from '../../../../components/Modal';
import { connectModal } from '../../../../hocs/Modal';
import styled from 'styled-components';
import { SimpleTable } from '../../../../components/SimpleTable';
import { fields } from './fields';
import { data } from './data';
import RisksIndicatorsModalHeader from './RisksIndicatorsModalHeader';
import { history } from '../../../../routes/history';
import { routes } from '../../../../config/routes';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AppStoreContext } from '../../../../stores/app';
import { getAgendaIndicatorReestr } from '../../../../actions';
import ComplexTable from '../../../../components/ComplexTable';
import Text from '../../../../components/Text';
import TextWithDefinition from '../../../../components/TextWithDefinition';
import TablePeriod from '../../../Indicators/IndicatorsSummary/IndicatorsCharts/IndicatorsModal/TablePeriod';
import { Loader } from '../../../../components/Loader';
import { ChartSector } from '../configs';
import { prepareNumber } from '../../../../utils/prepareNumber';

const RisksIndicatorsModal = connectModal('risks-indicators-modal')(
  ({ handleHide, chartSector }) => {
    const { selectedOmsu } = useContext(AppStoreContext);

    const [tableInfo, setTableInfo] = useState([]);
    const [tableInfoLoading, setTableInfoLoading] = useState<boolean>(false);
    const [amount, setAmount] = useState();

    useEffect(() => {
      const fetchData = async () => {
        setTableInfoLoading(true);
        try {
          const response = await getAgendaIndicatorReestr({
            omsuId: selectedOmsu.id,
            isRisk: chartSector === ChartSector.RISK,
          });
          setAmount(response?.data?.info?.total);
          setTableInfo(response.data.items);
        } catch (e) {
          //
        } finally {
          setTableInfoLoading(false);
        }
      };
      fetchData();
    }, [selectedOmsu]);

    const tableConfig = useMemo(() => {
      let tableData = [];

      if (tableInfo) {
        tableData = tableInfo.map((item) => ({
          name: <Text data={item?.name} />,
          plan: <TextWithDefinition text={prepareNumber(item?.planValue, item?.precision)} definition={item?.unit} />,
          done: <TextWithDefinition text={prepareNumber(item?.progress, 0)} definition={'%'} />,
          period: (
            <TablePeriod
              period={item?.period}
              dynamics={item?.dynamics}
              factText={item?.factValue}
              factDefinition={item?.unit}
              precision={item?.precision}
            />
          ),
          indecatorId: item?.id,
        }));
      }

      return tableData;
    }, [tableInfo]);

    return (
      <Modal
        title={chartSector === ChartSector.RISK ? 'Показатели с риском' : 'Показатели'}
        onClose={handleHide}
        header={<RisksIndicatorsModalHeader amount={amount} />}
      >
        <Content>
          {tableInfoLoading ? (
            <Loader />
          ) : (
            <SimpleTable
              fields={fields}
              data={tableConfig}
              onRowClick={(item) =>
                history.push(
                  `${routes.indicator.path.replace(':indicatorId', item?.indecatorId)}?omsuId=${selectedOmsu?.id}`,
                )
              }
            />
          )}
        </Content>
      </Modal>
    );
  },
);

const Content = styled.div`
  width: 100%;
  padding: 24px;
`;
export default RisksIndicatorsModal;
