import styled from 'styled-components';
import PieChart from '../../../modules/PieChart';
import Card from '../../../components/Card';
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import RadialChart from '../../../modules/RadialChart';
import { font } from '../../../components/styled/mixins/fonts';
import { ListVisibility } from '../../../components/Select';
import Legend from '../../../components/Legend';
import { legendConfig } from './legendConfig';
import { observer } from 'mobx-react-lite';
import { getDiagrammsDataByOMSUid } from '../../../actions';
import { AppStoreContext } from '../../../stores/app';
import { generateObjectsChartConfig, generateRadialChartConfig } from './utils';
import SelectList from '../../../components/Calendar/SelectList';
import { Loader } from '../../../components/Loader';
import { ListsStore } from '../../../stores/lists';
import { api } from '../../../config/api';
import CityDistrictHead from './CityDistrictHead';
import Divider from '../../../components/Divider';
import Icon from '../../../components/Icon';
import ArrowBigIcon from '../../../assets/icons/ArrowBigIcon';
import { AnchorPopupStore } from '../../../stores/anchorPopup';
import Chart from '../../../components/Chart';
import { scrollIntoViewBySelector } from '../../../utils/scrollIntoView';

const options = [
  {
    id: 0,
    value: true,
    label: 'Национальные проекты',
  },
  {
    id: 1,
    value: false,
    label: 'Федеральный бюджет',
  },
];

interface Props {
  setMassFilterCallback?: any;
  noCityDistrictHead?: boolean;
}

const SummaryInfo: FC<Props> = observer(
  ({ setMassFilterCallback = () => null, noCityDistrictHead = false }) => {
    const { selectedOmsu } = useContext(AppStoreContext);
    const [isNationalProject, setIsNationalProject] = useState(options[0]);
    const [data, setData] = useState({}) as any;
    const [loading, setLoading] = useState(true);
    const anchorContainer = useRef<any>();
    const zoomedContainer = useRef<any>();
    const [isOpen, setIsOpen] = useState(false);

    const fetchData = useCallback(async () => {
      setLoading(true);
      try {
        if (selectedOmsu?.id) {
          const response = await getDiagrammsDataByOMSUid(selectedOmsu?.id);
          setData(response.data?.items?.[0] || {});
        }
      } catch (e) {
        //
      } finally {
        setLoading(false);
      }
    }, [selectedOmsu?.id]);

    useEffect(() => {
      fetchData();
    }, [fetchData]);

    const handleSelect = useCallback((el) => {
      setIsNationalProject(el);
      AnchorPopupStore.hidePopup();
    }, []);

    const handleChartClick = useCallback((that, isNational?, isMunicipal?) => {
      if (that.id === 0 || that.id === 1) {
        ListsStore.setFirstPage(api.getObjectsReester);
        const params: any = {};
        if (isNational) {
          params.isNationalProject = true;
          params.hasFederalFunding = false;
          params.isMunicipalBudget = false;
        } else if (isNational !== undefined) {
          params.isNationalProject = false;
          params.hasFederalFunding = true;
          params.isMunicipalBudget = false;
        } else if (isMunicipal !== undefined) {
          params.isNationalProject = false;
          params.hasFederalFunding = false;
          params.isMunicipalBudget = true;
        } else {
          params.isNationalProject = false;
          params.hasFederalFunding = false;
          params.isMunicipalBudget = false;
        }
        setMassFilterCallback({
          isBeforeRisk: that.id === 1,
          isRisk: that.id === 0,
          offset: 0,
          fromDate: null,
          toDate: null,
          ...params,
        });
        scrollIntoViewBySelector('risks-wrapper');
      }
    }, []);

    const handleNationalChartClick = useCallback((e) => {
      handleChartClick(e, true);
    }, []);

    const handleFederalChartClick = useCallback((e) => {
      handleChartClick(e, false);
    }, []);

    const PopupComponent = (
      <ListVisibility>
        <SelectList list={options} onClick={handleSelect} active={false} />
      </ListVisibility>
    );

    const handleOpen = (e) => {
      setIsOpen(!isOpen);
      if (!isOpen) {
        AnchorPopupStore.showPopup({
          component: PopupComponent,
          anchorRef: anchorContainer.current,
          zoomedRef: zoomedContainer.current,
          onClose: () => setIsOpen(false),
          position: 'left',
        });
      } else {
        AnchorPopupStore.hidePopup();
      }
    };

    if (loading) return <Loader />;

    return (
      <Card>
        <SummaryWrapper>
          <Charts ref={zoomedContainer}>
            <Container>
              <Chart
                title={'Объекты'}
                data={generateObjectsChartConfig([
                  data.riskedObjectsAmount,
                  data.preRiskedObjectsAmount,
                  data.openedObjectsAmount,
                ])}
                total={data.objectsTotal}
                onClick={handleChartClick}
              />
              <ChartWrapper>
                <RadialChart
                  data={generateRadialChartConfig([
                    data.contractedFunding,
                    data.plannedFunding,
                    data.actualFunding,
                    data.plannedFundingFromFed,
                    data.contractedFundingFromFed,
                    data.actualFundingFromFed,
                  ])}
                />
              </ChartWrapper>
            </Container>
            <Container>
              <Heading pointer>
                <SelectHeaderWrapper onClick={handleOpen} ref={anchorContainer}>
                  {isNationalProject.label}
                  <IconWrapper>
                    <Icon opacity={1}>
                      <ArrowBigIcon />
                    </Icon>
                  </IconWrapper>
                </SelectHeaderWrapper>
              </Heading>
              <ChartWrapper>
                {isNationalProject.value ? (
                  <PieChart
                    key={'national-chart'}
                    data={generateObjectsChartConfig([
                      data.riskedObjectsWithProjectFunding,
                      data.preRiskedObjectsWithProjectFunding,
                      data.openedObjectsWithProjectFunding,
                    ])}
                    total={data.objectsWithProjectFunding}
                    onClick={handleNationalChartClick}
                  />
                ) : (
                  <PieChart
                    key={'federal-chart'}
                    data={generateObjectsChartConfig([
                      data.riskedObjectsWithFederalFunding,
                      data.preRiskedObjectsWithFederalFunding,
                      data.openedObjectsWithFederalFunding,
                    ])}
                    total={data.objectsWithFederalFunding}
                    onClick={handleFederalChartClick}
                  />
                )}
              </ChartWrapper>
              <Divider margin={'0 18%'} color={'#000'} />
              <Label>Всего</Label>
              <ChartWrapper>
                {isNationalProject.value ? (
                  <RadialChart
                    data={generateRadialChartConfig([
                      data.contractedFundingWithProject,
                      data.plannedFundingWithProject,
                      data.actualFundingWithProject,
                      data.plannedFundingWithProjectFromFed,
                      data.contractedFundingWithProjectFromFed,
                      data.actualFundingWithProjectFromFed,
                    ])}
                  />
                ) : (
                  <RadialChart
                    data={generateRadialChartConfig([
                      data.contractedFundingWithFederal,
                      data.plannedFundingWithFederal,
                      data.actualFundingWithFederal,
                    ])}
                  />
                )}
              </ChartWrapper>
            </Container>
            <Container>
              <Heading>Муниципальный бюджет</Heading>
              <ChartWrapper>
                <PieChart
                  data={generateObjectsChartConfig([
                    data.riskedObjectsWithMunicipalFunding,
                    data.preRiskedObjectsWithMunicipalFunding,
                    data.openedObjectsWithMunicipalFunding,
                  ])}
                  total={data.objectsWithMunicipalFunding}
                  onClick={e => handleChartClick(e, undefined, true)}
                />
              </ChartWrapper>
              <Divider margin={'0 18%'} color={'#000'} />
              <Label>Всего</Label>
              <ChartWrapper>
                <RadialChart
                  data={generateRadialChartConfig([
                    data.contractedFundingWithMunicipal,
                    data.plannedFundingWithMunicipal,
                    data.actualFundingWithMunicipal,
                    data.plannedFundingWithMunicipalFromFed,
                    data.contractedFundingWithMunicipalFromFed,
                    data.actualFundingWithMunicipalFromFed,
                  ])}
                />
              </ChartWrapper>
            </Container>
          </Charts>
          <Legend list={legendConfig} />
        </SummaryWrapper>
        {!noCityDistrictHead && selectedOmsu?.idx !== 0 && (
          <Wrapper>
            <Divider />
            <CityDistrictHead />
          </Wrapper>
        )}
      </Card>
    );
  },
);

const SelectHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const ChartWrapper = styled.div`
  flex: 1 1 33%;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Heading = styled.div<{ pointer?: boolean }>`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xxmd,
      family: theme.fonts.montserrat.semibold,
      lineHeight: '34px',
    })};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    cursor: pointer;
  }
`;
const Charts = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media all and (max-width: 1200px) {
    transform: scale(0.9);
    width: calc(100% / 0.9);
    height: 300px;
    transform-origin: left center;
  }
  @media all and (max-width: 1000px) {
    transform: scale(0.7);
    width: calc(100% / 0.7);
    height: 230px;
    transform-origin: left center;
  }
  @media all and (max-width: 800px) {
    transform: scale(0.6);
    width: calc(100% / 0.6);
    height: 200px;
    transform-origin: left center;
  }
  @media all and (max-width: 750px) {
    transform: scale(0.5);
    width: calc(100% / 0.5);
    height: 170px;
    transform-origin: left center;
  }
  @media all and (max-width: 650px) {
    transform: scale(0.4);
    width: calc(100% / 0.4);
    height: 150px;
    transform-origin: left center;
  }
`;
const Label = styled.div`
  text-align: center;
  ${({ theme }) => font({ size: theme.fonts.sizes.xmd })};
  margin-bottom: 15px;
`;
const IconWrapper = styled.div`
  margin-left: 4px;
`;

export default SummaryInfo;
