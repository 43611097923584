import Chart from '../../../../components/Chart';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import RadialChart from '../../../../modules/RadialChart';
import styled from 'styled-components';
import { legendConfig } from '../../../Objects/SummaryInfo/legendConfig';
import Legend from '../../../../components/Legend';
import { ModalsStore } from '../../../../stores/modal';
import { generateIndicatorsChartConfig, generateIndicatorsRadialChartConfig } from '../../utils';
import { scrollIntoViewBySelector } from '../../../../utils/scrollIntoView';

interface Props {
  data: any[];
  innerFilterCallbacks?: {
    [key: string]: any;
  };
}

const IndicatorsCharts: FC<Props> = ({ data, innerFilterCallbacks }) => {
  const preparedData = useMemo(() => {
    const totalData = { ...data[0], razdelName: 'Всего' };
    data.slice(1).forEach((el) => {
      Object.keys(el).forEach((key) => {
        if (typeof el[key] === 'number') {
          totalData[key] += el[key];
        }
      });
    });
    return [{ ...totalData, id: -1 }, ...data];
  }, [data]);

  const callbacks = useRef({});
  useEffect(() => {
    callbacks.current = innerFilterCallbacks;
  }, [innerFilterCallbacks]);

  const onChartClick = useCallback((e, chartId) => {
    if (chartId !== -1) {
      scrollIntoViewBySelector(`dynamic_table_${chartId}`);
      const meta = callbacks.current[chartId];
      if (meta) {
        const filter = meta.list.find((el) => el.name === e.name);
        if (filter) meta.callback('viewType', filter);
      }
    }
  }, []);

  const content = useMemo(() => {
    return (
      <Charts>
        {preparedData.map((item) => (
          <Container key={item.id}>
            <Chart
              title={item.razdelName}
              data={generateIndicatorsChartConfig([
                item.riskIndicatorTotal,
                item.achievedIndicatorTotal,
              ])}
              total={item.allIndicatorTotal}
              // onClick={() => ModalsStore.showModal('indicators-modal')}
              onClick={(e) => onChartClick(e, item.id)}
            />
            <ChartWrapper>
              <RadialChart
                data={generateIndicatorsRadialChartConfig([
                  item.plannedFunding,
                  item.contractedFunding,
                  item.actualFunding,
                  item.plannedFundingFromFed,
                  item.contractedFundingFromFed,
                  item.actualFundingFromFed,
                ])}
              />
            </ChartWrapper>
          </Container>
        ))}
      </Charts>
    );
  }, [preparedData, onChartClick]);

  return (
    <>
      {content}
      <Legend list={legendConfig} />
      {/* <IndicatorsModal /> */}
    </>
  );
};

const ChartWrapper = styled.div`
  flex: 1 1 33%;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Charts = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media all and (max-width: 1200px) {
    zoom: 0.9;
  }
  @media all and (max-width: 1000px) {
    zoom: 0.7;
  }
  @media all and (max-width: 800px) {
    zoom: 0.6;
  }
  @media all and (max-width: 750px) {
    zoom: 0.5;
  }
  @media all and (max-width: 650px) {
    zoom: 0.4;
  }
`;

export default IndicatorsCharts;
