import { connectModal } from '../../../../hocs/Modal';
import { Modal } from '../../../../components/Modal';
import styled from 'styled-components';
import { SimpleTable } from '../../../../components/SimpleTable';
import { useEffect, useMemo, useState } from 'react';
import { fetchInstance } from '../../../../utils/axios';
import { api } from '../../../../config/api';
import { Loader } from '../../../../components/Loader';
import ComplexTable from '../../../../components/ComplexTable';
import { getObjectFinanceDetails } from '../../../../actions';
import { isNumber } from 'lodash';
import { prepareNumber } from '../../../../utils/prepareNumber';

const fields = [
  {
    label: 'Показатель',
    field: 'rate',
    sorting: false,
    width: '85%',
  },
  {
    label: 'Всего・тыс. руб.',
    field: 'total',
    sorting: false,
    width: '15%',
    options: {
      text: 'center',
    },
  },
];

const FinanceModal = connectModal('finance-modal')(({ handleHide, objectId, actionId }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getObjectFinanceDetails({
          objectId,
          actionId
        });
        setData(response.data);
      } catch (e) {
        //
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [objectId]);

  const { listData, tableData, complexFields } = useMemo(() => {
    const lData = [];
    const tData = [],
      cFields = [];
    if (data) {
      lData.push(
        {
          rate: 'Муниципальная касса',
          total: isNumber(data.municipality) ? prepareNumber(data.municipality, 2) : '-',
        },
        {
          rate: 'Областная касса',
          total: isNumber(data.region) ? prepareNumber(data.region, 2) : '-',
        },
        {
          rate: 'Контракт',
          total: isNumber(data.contract) ? prepareNumber(data.contract, 2) : '-',
        },
        {
          rate: 'Стоимость объекта',
          total: isNumber(data.cost) ? prepareNumber(data.cost, 2) : '-',
        },
        {
          rate: 'Федеральное финансирование',
          total: isNumber(data.federalFunding) ? prepareNumber(data.federalFunding, 2) : '-',
        },
        {
          rate: 'Федеральная касса',
          total: isNumber(data.federalFact) ? prepareNumber(data.federalFact, 2) : '-',
        },
      );

      const years = new Set();

      data.objectAnnualFinanceIndicators.forEach((el) => {
        el.objectAnnualFinanceCollection.forEach((item) => {
          years.add(item.year);
        });
      });
      const uniqYears = Array.from(years).sort();

      data.objectAnnualFinanceIndicators.forEach((el) => {
        const dataItem = {
          name: el.name,
        };
        uniqYears.forEach((year) => {
          const yearData = el.objectAnnualFinanceCollection.find((item) => item.year === year);
          if (yearData) {
            dataItem[`plan${year}`] = yearData.plan;
            dataItem[`fact${year}`] = yearData.fact;
          } else {
            dataItem[`plan${year}`] = 0;
            dataItem[`fact${year}`] = 0;
          }
        });
        tData.push(dataItem);
      });
      cFields.push([
        {
          id: 0,
          label: '',
          sorting: false,
          width: '50%',
        },
        ...uniqYears.map((year) => ({
          id: year,
          label: year,
          sorting: false,
          width: `${50 / uniqYears.length}%`,
          colspan: 2,
        })),
      ]);

      const tableFields = [];
      uniqYears.forEach((year) => {
        tableFields.push({
          id: `plan${year}`,
          field: `plan${year}`,
          sorting: false,
          label: (
            <>
              План
              <br />
              тыс. руб.
            </>
          ),
          options: {
            'text-align': 'right',
          },
        });
        tableFields.push({
          id: `fact${year}`,
          field: `fact${year}`,
          sorting: false,
          label: (
            <>
              Факт
              <br />
              тыс. руб.
            </>
          ),
          options: {
            'text-align': 'right',
          },
        });
      });
      cFields.push([
        {
          id: 1,
          label: 'Показатель по годам',
          sorting: false,
          field: 'name',
          options: {
            'text-align': 'left',
            padding: '8px',
          },
        },
        ...tableFields,
      ]);
    }
    return { listData: lData, tableData: tData, complexFields: cFields };
  }, [data]);

  return (
    <Modal title={'Финансирование'} onClose={handleHide}>
      <Content>
        <TableWrapper>
          {loading ? <Loader /> : <SimpleTable fields={fields} data={listData} />}
        </TableWrapper>
        {loading ? <Loader /> : <ComplexTable fields={complexFields} data={tableData} />}
      </Content>
    </Modal>
  );
});

const Content = styled.div`
  width: 100%;
  padding: 0 24px 24px 24px;
`;
const TableWrapper = styled.div`
  margin-bottom: 32px;
`;

export default FinanceModal;
