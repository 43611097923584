const DownloadIcon = () => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6 8.38125C5.9125 8.38125 5.825 8.3625 5.7375 8.325C5.65 8.2875 5.56875 8.225 5.49375 8.1375L3.1125 5.775C2.9875 5.65 2.925 5.50625 2.925 5.34375C2.925 5.18125 2.9875 5.0375 3.1125 4.9125C3.25 4.775 3.39375 4.70625 3.54375 4.70625C3.69375 4.70625 3.8375 4.775 3.975 4.9125L5.4 6.3375V0.843749C5.4 0.681249 5.45625 0.543749 5.56875 0.431249C5.69375 0.306249 5.8375 0.243749 6 0.243749C6.1625 0.243749 6.3 0.306249 6.4125 0.431249C6.5375 0.543749 6.6 0.681249 6.6 0.843749V6.3375L8.025 4.9125C8.15 4.7875 8.2875 4.725 8.4375 4.725C8.6 4.7125 8.75 4.775 8.8875 4.9125C9.0125 5.0375 9.075 5.18125 9.075 5.34375C9.075 5.49375 9.0125 5.6375 8.8875 5.775L6.50625 8.1375C6.43125 8.225 6.35 8.2875 6.2625 8.325C6.175 8.3625 6.0875 8.38125 6 8.38125ZM1.8 11.625C1.4 11.625 1.0625 11.4875 0.7875 11.2125C0.5125 10.9375 0.375 10.6 0.375 10.2V8.85C0.375 8.6875 0.43125 8.55 0.54375 8.4375C0.66875 8.3125 0.8125 8.25 0.975 8.25C1.1375 8.25 1.275 8.3125 1.3875 8.4375C1.5125 8.55 1.575 8.6875 1.575 8.85V10.2C1.575 10.25 1.6 10.3 1.65 10.35C1.7 10.4 1.75 10.425 1.8 10.425H10.2C10.25 10.425 10.3 10.4 10.35 10.35C10.4 10.3 10.425 10.25 10.425 10.2V8.85C10.425 8.6875 10.4813 8.55 10.5938 8.4375C10.7188 8.3125 10.8625 8.25 11.025 8.25C11.1875 8.25 11.325 8.3125 11.4375 8.4375C11.5625 8.55 11.625 8.6875 11.625 8.85V10.2C11.625 10.6 11.4875 10.9375 11.2125 11.2125C10.9375 11.4875 10.6 11.625 10.2 11.625H1.8Z'
        fill='black'
        fillOpacity='0.56'
      />
    </svg>
  );
};

export default DownloadIcon;
