import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';

class Development {
  constructor() {
    makeAutoObservable(this);
  }

  developmentHeaderFilter = {
    id: 0,
    label: 'Инвестиционное',
    value: 'investment',
  };

  developmentCollapsedHeaderData = {
    workplace: {
      redZoneCount: 0,
      greenZoneCount: 0
    },
    investment: {
      redZoneCount: 0,
      greenZoneCount: 0
    },
    land: {
      notFilled: 0,
      filled: 0
    },
    industrial: {
      notFilled: 0,
      filled: 0
    },
  }

  setDevelopmentHeaderFilter = (filter) => {
    this.developmentHeaderFilter = filter;
  };

  getDevelopmentHeaderFilter = () => {
    return this.developmentHeaderFilter;
  };

  setDevelopmentCollapsedHeaderData = (sectionName, data) => {
    this.developmentCollapsedHeaderData[sectionName] = data;
  }

  getDevelopmentCollapsedHeaderData = (sectionName) => {
    return this.developmentCollapsedHeaderData[sectionName];
  }
}

export const DevelopmentStore = new Development();
export const DevelopmentStoreContext = createContext(DevelopmentStore);
