import styled from 'styled-components';
import { FC, useEffect, useMemo, useState } from 'react';
import {Loader} from "../Loader";

interface Props {
  omsuId: string;
}

const RegionMap: FC<Props> = (omsuId) => {
  const SVG_URL = useMemo(() => {
    return `${process.env.REACT_APP_MAP_BASE_URL}/static/images/omsu/${omsuId.omsuId}.svg`;
  }, [omsuId]);

  const [svg, setSvg] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrored, setIsErrored] = useState(false);

  useEffect(() => {
    fetch(SVG_URL)
      .then((res) => res.text())
      .then(setSvg)
      .catch(setIsErrored)
      .then(() => setIsLoaded(true));
  }, [omsuId]);

  return (
    <ComponentContainer>
      {!isLoaded ? <LoaderWrapper><Loader /></LoaderWrapper> : (
      <div
        dangerouslySetInnerHTML={{ __html: svg }}
      />
      )}
    </ComponentContainer>
  );
};

const LoaderWrapper = styled.div`
  height: 400px !important;
`

const ComponentContainer = styled.div`
  border: 1px solid #f2f2f2;
  border-radius: 16px;
  max-height: 482px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  & > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      width: 100%;
      height: 100%;
      padding: 24px;
      overflow: visible;
    }
  }
`;

export default RegionMap;
