import React, { useContext, FC, ReactNode, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AuthStoreContext } from '../stores/auth';
import AuthPage from '../pages/Auth';
import Welcome from '../pages/Welcome';

export interface Props {
  children: ReactNode;
}

const AuthGuard: FC = observer(({ children }: Props) => {
  const { profile, showWelcome } = useContext(AuthStoreContext);

  if (!profile || showWelcome) {
    return <AuthPage />;
  }

  return <>{children}</>;
});

export { AuthGuard };
