import styled from 'styled-components';
import { FC } from 'react';
import { font } from '../styled/mixins/fonts';

interface Props {
  label: string;
  amount: number;
  onClick?: VoidFunction;
}

const Indicator: FC<Props> = ({ amount, label, onClick }) => {
  return (
    <ComponentContainer>
      <Label>{label}</Label>
      <Amount hasClick={!!onClick} onClick={onClick}>
        {amount}
      </Amount>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const Label = styled.div`
  ${({ theme }) =>
    font({ size: theme.fonts.sizes.sm, color: theme.colors.dark, lineHeight: '16px' })};
  flex: 1 1 auto;
`;
const Amount = styled.div<{ hasClick: boolean }>`
  cursor: ${({ hasClick }) => (hasClick ? 'pointer' : 'default')};
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xxlg,
      family: theme.fonts.montserrat.semibold,
      color: theme.colors.secondary,
      lineHeight: '32px',
    })};
`;

export default Indicator;
