export const gpCustomFields = [
  {
    label: '',
    colspan: 2,
  },
  {
    label: 'План',
    width: '18%',
    colspan: 3,
  },
  {
    label: 'Исполнение',
    width: '18%',
    colspan: 3,
  },
  {
    label: '%исполнения',
    width: '36%',
    colspan: 6,
  },
];
