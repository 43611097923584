import styled from 'styled-components';
import { TextButton } from '../../../components/Button';
import { ModalsStore } from '../../../stores/modal';
import RatingModal from './RatingModal';

const AgendaRatingAddition = () => {
  return (
    <ComponentContainer>
      <TextButton onClick={() => ModalsStore.showModal('rating-modal')}>Подробнее...</TextButton>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div``;

export default AgendaRatingAddition;
