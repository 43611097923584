import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  getBudgetDebt,
  getMunicipalityDebtRaising,
  getMunicipalityDebtRepayment,
} from '../../../actions';
import Card from '../../../components/Card';
import Grade from '../../../components/Grade';
import { Table } from '../../../components/Table';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { AppStoreContext } from '../../../stores/app';
import { observer } from 'mobx-react-lite';
import { initialMunicipalDebtSortingValues } from '../consts';
import { useSorting } from '../../../hooks/useSorting';
import { get, isNumber } from 'lodash';
import { theme } from '../../../styles/theme';
import { Loader } from '../../../components/Loader';
import Paper from '../../../components/Paper';
import BudgetCard from '../../../components/BudgetCard';
import BudgetMunicipalDebtSubtablesAddition from '../BudgetMunicipalDebtSubtablesAddition';
import ComplexTable from '../../../components/ComplexTable';

interface Props {
  filter: any;
}

const BudgetMunicipalDebt: FC<Props> = observer(({ filter }) => {
  const { selectedOmsu } = useContext(AppStoreContext);

  const [tableInfo, setTableInfo] = useState(null);
  const [subtableRaisingInfo, setSubtableRaisingInfo] = useState(null);
  const [subtableRepaymentInfo, setSubtableRepaymentInfo] = useState(null);
  const [tableDataLoading, setTableDataLoading] = useState<boolean>(false);
  const [subtableRaisingDataLoading, setSubtableRaisingDataLoading] = useState<boolean>(false);
  const [subtableRepaymentDataLoading, setSubtableRepaymentDataLoading] = useState<boolean>(false);

  const { sorting, setSortingCallback } = useSorting({
    initialSortingValues: { ...initialMunicipalDebtSortingValues },
    sortingName: 'budgetMunicipalDebtSorting',
  });

  useEffect(() => {
    if ((filter?.selectedYear || filter?.selectedOmsu) && selectedOmsu) {
      const fetchData = async () => {
        setTableDataLoading(true);
        try {
          const response = await getBudgetDebt({
            omsuId: selectedOmsu.id,
            periodId: filter.selectedYear?.id,
            territoriesComparison: filter?.selectedOmsu,
          });
          setTableInfo(response.data.items);
        } catch (e) {
          //
        } finally {
          setTableDataLoading(false);
        }
      };
      fetchData();
    }
  }, [filter, selectedOmsu]);

  useEffect(() => {
    if ((filter?.selectedYear || filter?.selectedOmsu) && selectedOmsu) {
      const fetchData = async () => {
        setSubtableRaisingDataLoading(true);
        try {
          const response = await getMunicipalityDebtRaising({
            omsuId: selectedOmsu.id,
            periodId: filter.selectedYear?.id,
            territoriesComparison: filter?.selectedOmsu,
          });
          setSubtableRaisingInfo(response.data.items);
        } catch (e) {
          //
        } finally {
          setSubtableRaisingDataLoading(false);
        }
      };
      fetchData();
    }
  }, [filter, selectedOmsu]);

  useEffect(() => {
    if ((filter?.selectedYear || filter?.selectedOmsu) && selectedOmsu) {
      const fetchData = async () => {
        setSubtableRepaymentDataLoading(true);
        try {
          const response = await getMunicipalityDebtRepayment({
            omsuId: selectedOmsu.id,
            periodId: filter.selectedYear?.id,
            territoriesComparison: filter?.selectedOmsu,
          });
          setSubtableRepaymentInfo(response.data.items);
        } catch (e) {
          //
        } finally {
          setSubtableRepaymentDataLoading(false);
        }
      };
      fetchData();
    }
  }, [filter, selectedOmsu]);

  const tableConfig = useMemo(() => {
    let tableData = [];
    let tableFields = [];

    if (tableInfo) {
      const periodLength = get(tableInfo, '[0].valuesByPeriod', []).length;

      const fillField = (itm, idx) => [
        {
          label: `${format(new Date(itm?.period?.periodStart), 'dd.MM.yyyy', {
            locale: ru,
          })} млрд.руб.`,
          field: `rub${idx}`,
          sorting: true,
          width: '5%',
          handleField: (row) => <Grade grade={row[`rub${idx}`]} isPrice/>,
        },
        {
          label: `${format(new Date(itm?.period?.periodStart), 'dd.MM.yyyy', { locale: ru })} %`,
          field: `percent${idx}`,
          sorting: false,
          width: '5%',
          handleField: (row) => <Grade grade={row[`percent${idx}`]} isPrice/>,
        },
      ];

      tableFields = [
        {
          label: '',
          field: 'title',
          sorting: false,
          width: '70%',
        },
        ...(get(tableInfo, '[0].valuesByPeriod', [])
          .map((itm, idx) => fillField(itm, idx))
          .flat() || []),
      ];

      const fillData = (itm) => {
        return {
          title: itm?.omsu?.name,
          ...Array(periodLength)
            .fill(0)
            .reduce((acc, arrItem, index) => {
              const item = get(itm, `valuesByPeriod[${index}]`);
              return {
                ...acc,
                [`rub${index}`]: item?.indicatorValue,
                [`percent${index}`]: item?.growthRate,
                ...(selectedOmsu?.id === itm?.omsu?.id
                  ? {
                      options: {
                        styles: {
                          ...acc?.options?.styles,
                          title: {
                            'font-family': theme.fonts.montserrat.bold,
                          },
                          [`rub${index}`]: {
                            'font-family': theme.fonts.montserrat.bold,
                          },
                          [`percent${index}`]: {
                            'font-family': theme.fonts.montserrat.bold,
                          },
                        }
                      },
                    }
                  : {}),
              };
            }, {}),
        };
      };

      tableData = tableInfo.map((itm) => fillData(itm));
    }

    return {
      tableData,
      tableFields: [[], [...tableFields]],
    };
  }, [tableInfo]);

  const fillData = useCallback((itm) => {
    return {
      id: itm?.id,
      label: itm?.name,
      isBold: itm?.isBold,
      amount: [itm?.plan, itm?.fact],
      details: itm?.children?.map((mItem) => fillData(mItem)),
    };
  }, []);

  const subtableRaisingConfig = useMemo(() => {
    let tableData = [];

    if (subtableRaisingInfo) {
      tableData = subtableRaisingInfo.map((itm) => fillData(itm));
    }

    return {
      tableData,
    };
  }, [subtableRaisingInfo]);

  const subtableRepaymentConfig = useMemo(() => {
    let tableData = [];

    if (subtableRepaymentInfo) {
      tableData = subtableRepaymentInfo.map((itm) => fillData(itm));
    }

    return {
      tableData,
    };
  }, [subtableRepaymentInfo]);

  return (
    <ComponentContainer>
      <Card>
        {tableDataLoading ? (
          <Loader />
        ) : (
          <TableWrapper>
            <ComplexTable
              fields={tableConfig.tableFields}
              data={tableConfig.tableData}
              sorting={sorting}
              setSortingCallback={setSortingCallback}
            />
          </TableWrapper>
        )}
        <CardsWrapper>
          {subtableRaisingDataLoading ? (
            <Loader />
          ) : (
            <Paper>
              <BudgetCard
                title={'Привлечение'}
                subtitle={<BudgetMunicipalDebtSubtablesAddition />}
                data={subtableRaisingConfig.tableData}
              />
            </Paper>
          )}
          {subtableRepaymentDataLoading ? (
            <Loader />
          ) : (
            <Paper>
              <BudgetCard
                title={'Погашение'}
                subtitle={<BudgetMunicipalDebtSubtablesAddition />}
                data={subtableRepaymentConfig.tableData}
              />
            </Paper>
          )}
        </CardsWrapper>
      </Card>
    </ComponentContainer>
  );
});

const ComponentContainer = styled.div``;
const TableWrapper = styled.div`
  margin-bottom: 20px;
`;
const CardsWrapper = styled.div`
  display: flex;
  gap: 24px;
  padding: 0 24px 24px 24px;

  > div {
    flex: 1 0 calc(50% - 24px);
  }
`;

export default BudgetMunicipalDebt;
