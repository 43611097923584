const GASULogo = () => (
  <svg width='86' height='86' viewBox='0 0 86 86' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity='0.7'>
      <path d='M35.4697 20.5082V36.3018H31.6313V16.8809H40.6325V20.5082H35.4697Z' fill='white' />
      <path
        d='M46.5582 32.5065H43.6788L43.0856 36.3018H39.0227L42.7487 16.8809H47.4336L51.1855 36.3018H47.1514L46.5582 32.5065ZM44.6117 25.8382L44.2172 28.936H45.9939L45.6282 25.8382L45.2049 21.9034H45.035L44.6117 25.8382Z'
        fill='white'
      />
      <path
        d='M57.8176 16.7129C59.528 16.7129 60.7949 17.1257 61.6127 17.9543C62.4305 18.7828 62.8393 19.8562 62.8393 21.1773C62.8393 21.2713 62.8393 21.3596 62.8393 21.4421C62.8393 21.5247 62.8307 21.613 62.8105 21.7069L59.1133 22.3476C59.0961 21.7724 58.9694 21.2912 58.7361 20.9097C58.5029 20.5282 58.1919 20.3374 57.8003 20.3374C57.5383 20.3374 57.3309 20.3887 57.1726 20.4912C57.0142 20.5937 56.8875 20.7189 56.7954 20.867C56.7032 21.0151 56.637 21.1773 56.5996 21.3539C56.5621 21.5304 56.5449 21.6842 56.5449 21.8151V31.7206C56.5449 31.9826 56.6111 32.2331 56.7407 32.4752C56.8702 32.7172 57.1812 32.8367 57.6678 32.8367C58.0422 32.8367 58.3474 32.6688 58.5806 32.3356C58.8139 31.9997 58.9406 31.4986 58.9607 30.8295L62.753 31.3306C62.7328 32.8738 62.3009 34.1152 61.4543 35.0547C60.6078 35.9943 59.384 36.4641 57.7859 36.4641C56.0179 36.4641 54.7279 36.0541 53.9188 35.237C53.1097 34.4198 52.7065 33.1898 52.7065 31.5526V21.8208C52.7065 20.2776 53.1241 19.0391 53.962 18.1109C54.8028 17.1798 56.087 16.7129 57.8176 16.7129Z'
        fill='white'
      />
      <path
        d='M69.4986 26.1714L71.5862 16.8809H75.1424L71.2206 31.9769C70.9556 33.019 70.6619 33.8418 70.3308 34.4454C70.0025 35.049 69.6311 35.5046 69.2165 35.8121C68.8018 36.1196 68.3641 36.3246 67.9034 36.4271C67.4427 36.5296 66.959 36.5894 66.4493 36.6093H65.2918V33.5115H65.715C66.242 33.5115 66.6221 33.4033 66.8582 33.1898C67.0943 32.9762 67.3131 32.6545 67.5205 32.2274C67.5579 32.1335 67.6011 32.0196 67.6472 31.8772C67.6932 31.7377 67.7364 31.5925 67.7738 31.4444L63.7109 16.9065H67.4629L69.3547 26.1714H69.4986Z'
        fill='white'
      />
      <path
        d='M83.3125 10.469V75.531C83.3125 79.8104 79.7736 83.3125 75.4429 83.3125H21.1768V2.6875H75.4429C79.7736 2.6875 83.3125 6.18675 83.3125 10.469Z'
        stroke='white'
        strokeWidth='4'
        strokeMiterlimit='22.9256'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.1765 2.6875V83.3096H10.557C6.22635 83.3096 2.6875 79.8075 2.6875 75.5281V10.469C2.6875 6.18674 6.22635 2.6875 10.557 2.6875H21.1765Z'
        stroke='white'
        strokeWidth='4'
        strokeMiterlimit='22.9256'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M83.2924 38.6653C83.2924 63.1828 62.9466 83.3009 38.1516 83.3009'
        stroke='white'
        strokeWidth='4'
        strokeMiterlimit='22.9256'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31.6311 61.6245H41.9367'
        stroke='white'
        strokeWidth='4'
        strokeMiterlimit='22.9256'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31.6311 71.5436H51.9399'
        stroke='white'
        strokeWidth='4'
        strokeMiterlimit='22.9256'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31.6311 51.7054H51.9399'
        stroke='white'
        strokeWidth='4'
        strokeMiterlimit='22.9256'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

export default GASULogo;
