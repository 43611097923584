import { createContext } from 'react';
import { makeAutoObservable } from 'mobx';

class AnchorPopup {
  constructor() {
    makeAutoObservable(this);
  }

  component = null;
  anchorRef = null;
  zoomedRef = null;
  onClose = () => null;
  position = null;
  width = null;

  showPopup = ({
    component,
    anchorRef,
    onClose,
    width = null,
    position = null,
    zoomedRef = null,
  }) => {
    this.component = component;
    this.anchorRef = anchorRef;
    this.onClose = onClose;
    this.width = width;
    this.position = position;
    this.zoomedRef = zoomedRef;
  };

  changeComponent = (component) => (this.component = component);

  hidePopup = () => {
    this.component = null;
    this.anchorRef = null;
    this.onClose = () => null;
    this.width = null;
    this.position = null;
    this.zoomedRef = null;
  };
}

export const AnchorPopupStore = new AnchorPopup();
export const AnchorPopupStoreContext = createContext(AnchorPopupStore);
