import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Icon from '../Icon';

interface Props {
  label: string;
  value: string;
  icon?: ReactNode;
  type?: string;
}

const InfoField: FC<Props> = ({ label, value, icon, type }) => {
  return (
    <InfoFieldWrapper href={type === 'email' ? `mailto: ${value}` : ''}>
      {icon && (
        <IconWrapper>
          <Icon>
            <>{icon}</>
          </Icon>
        </IconWrapper>
      )}
      <Info>
        <Label>{label}</Label>
        <Value>{value}</Value>
      </Info>
    </InfoFieldWrapper>
  );
};

const InfoFieldWrapper = styled.a`
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 16px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const IconWrapper = styled.div`
  margin-right: 16px;
  flex: 0 0 auto;
`;
const Info = styled.div``;
const Label = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-family: ${({ theme }) => theme.fonts.montserrat.regular};
  color: rgba(0, 0, 0, 0.56);
`;
const Value = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.montserrat.medium};
`;

export default InfoField;
