import styled from 'styled-components';
import { FC } from 'react';
import Status, { StatusType } from '../../../../../components/Status';

interface Props {
  stage: string;
  status: StatusType;
  roadmapCurrent: number;
  roadmapTotal: number;
}

const PlanModalHeader: FC<Props> = ({ stage, status, roadmapCurrent, roadmapTotal }) => {
  return (
    <PlanModalHeaderWrapper>
      <Heading>
        <Text>Этап:</Text>
        <Stage>{stage}</Stage>
      </Heading>
      <Info>
        <Roadmap>
          Выполнено {roadmapCurrent} из {roadmapTotal}
        </Roadmap>
        <Status status={status} />
      </Info>
    </PlanModalHeaderWrapper>
  );
};

const PlanModalHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
`;
const Text = styled.span`
  font-size: 14px;
  line-height: 38px;
  color: rgba(0, 0, 0, 0.56);
  font-family: ${({ theme }) => theme.fonts.montserrat.medium};
  margin-right: 5px;
`;
const Roadmap = styled.div`
  width: 75px;
  text-align: right;
  margin-right: 16px;
  font-size: 12px;
  line-height: 18px;
  font-family: ${({ theme }) => theme.fonts.montserrat.medium};
  color: rgba(0, 0, 0, 0.56);
`;
const Stage = styled.span`
  font-size: 14px;
  line-height: 38px;
  font-family: ${({ theme }) => theme.fonts.montserrat.semibold};
`;
const Info = styled.span`
  display: flex;
  align-items: center;
`;

export default PlanModalHeader;
