import styled from 'styled-components';
import { FC } from 'react';
import { format, endOfYear } from 'date-fns';
import { ru } from 'date-fns/locale';
import NationalProjectsOfRussiaIcon from '../../../../assets/icons/NationalProjectsOfRussiaIcon';
import EmblemIcon from '../../../../assets/icons/EmblemIcon';
import ReactTooltip from 'react-tooltip';
import { font } from '../../../../components/styled/mixins/fonts';

interface Props {
  item: {
    plannedObjectOpenDate: string;
    omsu: any;
    govAction: any;
    isNationalProject: boolean;
    isFederalFunding: boolean;
    zpmoReason: string;
    objectName?: string;
  };
  onClick: (item) => void;
  showType?: boolean;
}

const ListItem: FC<Props> = ({ item, onClick, showType = true }) => {
  const {
    isNationalProject,
    plannedObjectOpenDate,
    omsu,
    isFederalFunding,
    zpmoReason,
    objectName,
    govAction,
  } = item;

  const date = new Date(plannedObjectOpenDate);
  const nowDate = new Date();

  return (
    <>
      <ListItemWrapper onClick={() => onClick(item)}>
        <LeftSide>
          <DateWrapper>
            <Day>{format(date, 'dd', { locale: ru })}</Day>
            <Month>
              {date > endOfYear(nowDate)
                ? format(date, 'MMM', { locale: ru }).slice(0, -1) +
                  ' ' +
                  format(date, 'yyyy', { locale: ru })
                : format(date, 'MMMM', { locale: ru })}
            </Month>
          </DateWrapper>
          {showType && <Type>Открытие</Type>}
        </LeftSide>
        <RightSide>
          <Addition>
            <Place>{omsu?.name}</Place>
          </Addition>
          <Title>{objectName}</Title>
          <Subtitle>{zpmoReason}</Subtitle>
        </RightSide>
        <IconSet>
          {isFederalFunding && (
            <IconItem data-tip={'Федеральный бюджет'}>
              <EmblemIcon />
            </IconItem>
          )}
          {isNationalProject && (
            <IconItem data-tip={'Национальные проекты России'}>
              <NationalProjectsOfRussiaIcon />
            </IconItem>
          )}
        </IconSet>
      </ListItemWrapper>
    </>
  );
};

const IconSet = styled.div`
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
`;

const IconItem = styled.div`
  flex: 0 0 auto;
`;

const Addition = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3px;
`;

const ListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 24px;
  cursor: pointer;
  > div {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  border-bottom: 1px solid #e0e0e0;
`;
const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Day = styled.div`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.montserrat.semibold};
  line-height: 22px;
`;
const Month = styled.div`
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.montserrat.medium};
  line-height: 18px;
  text-transform: capitalize;
`;
const Type = styled.div`
  font-size: 10px;
  line-height: 18px;
  font-family: ${({ theme }) => theme.fonts.montserrat.medium};
  color: #bdbdbd;
  text-transform: uppercase;
  text-align: center;
`;
const Place = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-family: ${({ theme }) => theme.fonts.montserrat.medium};
  color: #828282;
  margin-right: 12px;
`;
const Title = styled.div`
  ${font()};
  white-space: break-spaces;
`;
const Subtitle = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-family: ${({ theme }) => theme.fonts.montserrat.medium};
  letter-spacing: -0.04em;
  color: #fc5200;
`;
const LeftSide = styled.div`
  //margin-right: 24px;
  flex: 0 0 65px;
`;
const RightSide = styled.div`
  flex: 1 1 auto;
`;

export default ListItem;
