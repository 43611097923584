import { PageWrapper } from '../../components/styled/PageWrapper';
import CityDistrictHead from '../Objects/SummaryInfo/CityDistrictHead';
import styled from 'styled-components';
import Accordion from '../../components/Accordion';
import { mainConfig } from './mainConfig';
import Card from '../../components/Card';
import { useContext } from 'react';
import { AppStoreContext } from '../../stores/app';
import { observer } from 'mobx-react-lite';

const ObjectsPage = observer(() => {
  const { selectedOmsu } = useContext(AppStoreContext);
  return (
    <PageWrapper>
      {selectedOmsu && selectedOmsu?.idx !== 0 && (
        <CardWrapper>
          <Card>
            <CityDistrictHead />
          </Card>
        </CardWrapper>
      )}
      <Accordion items={mainConfig} />
    </PageWrapper>
  );
});

const CardWrapper = styled.div`
  margin-bottom: 32px;
`;

export default ObjectsPage;
