import { endOfYear, startOfYear } from 'date-fns';

export const groupValues = [
  {
    id: '1',
    label: 'Вид работ',
  },
  {
    id: '2',
    label: 'Сфера деятельности',
  },
  {
    id: '3',
    label: 'Тип объекта',
  },
];

export const initialFilterValues = {
  workType: '',
  sphere: '',
  entityType: '',
  isRisk: false,
  isBeforeRisk: false,
  selectedYear: '',
  selectedMonth: '',
  fundingType: '',
  curator: '',
  hasFederalFunding: false,
  isNationalProject: false,
  isCurrentYearOpen: true,
  view: [...groupValues],
};

export const initialSortingValues = {
  field: 'objectsTotal',
  direction: 'DESC',
};

export const risksEnum = [
  {
    id: 0,
    value: 0,
    label: 'Открытые',
    filter: {
      isRisk: false,
      isBeforeRisk: false,
      isCurrentYearOpen: true,
    },
  },
  {
    id: 1,
    value: 1,
    label: 'Риск',
    filter: {
      isRisk: true,
      isBeforeRisk: false,
      isCurrentYearOpen: false,
    },
  },
  {
    id: 2,
    value: 2,
    label: 'Предриск',
    filter: {
      isRisk: false,
      isBeforeRisk: true,
      isCurrentYearOpen: false,
    },
  },
];

export const risksFilterEnum = [
  {
    id: 0,
    value: 0,
    label: 'Все',
    filter: {
      isNationalProject: null,
      hasFederalFunding: null,
      isMunicipalBudget: null,
    },
  },
  {
    id: 1,
    value: 1,
    label: 'Национальный проект',
    filter: {
      isNationalProject: true,
      hasFederalFunding: null,
      isMunicipalBudget: null,
    },
  },
  {
    id: 2,
    value: 2,
    label: 'Федеральное финансирование',
    filter: {
      isNationalProject: null,
      hasFederalFunding: true,
      isMunicipalBudget: null,
    },
  },
  {
    id: 3,
    value: 4,
    label: 'Муниципальный бюджет',
    filter: {
      isNationalProject: null,
      hasFederalFunding: null,
      isMunicipalBudget: true,
    },
  },
];

const date = new Date();

export const initialRisksFilter = {
  ...risksEnum[1].filter,
  ...risksFilterEnum[0].filter,
  // fromDate: startOfYear(date),
  // toDate: endOfYear(date),
};
