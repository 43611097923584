import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Loader } from '../../../components/Loader';
import HorizontalList, { HorizontalListVerticalPosition } from '../../../components/HorizontalList';
import ChartStatistics from '../../../components/ChartStatistics';
import { getExecutionIndicatorWithoutFinancing, getIndicatorTotal } from '../../../actions';
import { observer } from 'mobx-react-lite';
import { AppStoreContext } from '../../../stores/app';
import { scrollIntoViewBySelector } from '../../../utils/scrollIntoView';

interface Props {
  innerFilterCallbacks?: {
    [key: string]: any;
  };
}

const ExecutionIndicators: FC<Props> = observer(({ innerFilterCallbacks }) => {
  const { selectedOmsu, selectedYear, selectedCiogv } = useContext(AppStoreContext);
  const [executionIndicatorWithoutFinancingData, setExecutionIndicatorWithoutFinancingData] =
    useState([]);
  const [
    loadingExecutionIndicatorWithoutFinancingData,
    setLoadingExecutionIndicatorWithoutFinancingData,
  ] = useState(false);

  const [nsi, setNsi] = useState({});

  const fetchExecutionIndicatorWithoutFinancingData = useCallback(async () => {
    if (selectedOmsu) {
      try {
        setLoadingExecutionIndicatorWithoutFinancingData(true);
        const response = await getExecutionIndicatorWithoutFinancing({
          omsuId: selectedOmsu.id,
          workingYearId: selectedYear.id,
          ciogvId: selectedCiogv.id,
        });
        const nsiObj = {};
        for (let i = 0; i < response.data.items.length; i++) {
          const indicatorId = response.data.items[i].id;
          const nsiResponse = await getIndicatorTotal(indicatorId);
          nsiObj[response.data.items[i].id] = nsiResponse.data.items.map((el) => ({
            ...el,
            label: el.name,
            value: el.id,
          }));
        }
        setExecutionIndicatorWithoutFinancingData(response.data.items || []);
        setNsi(nsiObj);
      } catch (e) {
        //
      } finally {
        setLoadingExecutionIndicatorWithoutFinancingData(false);
      }
    }
  }, [selectedOmsu, selectedYear, selectedCiogv]);

  useEffect(() => {
    fetchExecutionIndicatorWithoutFinancingData();
  }, [fetchExecutionIndicatorWithoutFinancingData]);

  const onChartClick = useCallback(
    (selectedNsi, tableId) => {
      const meta = innerFilterCallbacks[tableId];
      if (meta) {
        meta.callback('viewType', selectedNsi);
      }
      scrollIntoViewBySelector(`dynamic_table_${tableId}`);
    },
    [innerFilterCallbacks],
  );

  const executionIndicatorContent = useMemo(() => {
    if (loadingExecutionIndicatorWithoutFinancingData) {
      return <Loader />;
    }
    return (
      <HorizontalList
        maxItemsInRow={4}
        fullHeight={false}
        verticalAlign={HorizontalListVerticalPosition.BOTTOM}
        list={executionIndicatorWithoutFinancingData.map((item) => {
          const currNsi = nsi[item.id];
          return {
            title: item.razdelName,
            indicator: {
              label: 'Всего показателей',
              amount: item.allIndicatorTotal,
              onClick: () => onChartClick(currNsi[0], item.id),
            },
            charts: [
              {
                id: 0,
                config: [
                  {
                    label: currNsi[1].name,
                    amount: item.achievedIndicatorTotal,
                    onClick: () => onChartClick(currNsi[1], item.id),
                  },
                  {
                    id: 1,
                    label: currNsi[2].name,
                    amount: item.riskIndicatorTotal,
                    onClick: () => onChartClick(currNsi[2], item.id),
                  },
                ],
              },
            ],
          };
        })}
      >
        {(item) => <ChartStatistics {...item} />}
      </HorizontalList>
    );
  }, [
    loadingExecutionIndicatorWithoutFinancingData,
    executionIndicatorWithoutFinancingData,
    nsi,
    onChartClick,
  ]);

  return <>{executionIndicatorContent}</>;
});

export default ExecutionIndicators;
