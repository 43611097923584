import Roadmap from './Roadmap';

export const accordionConfig = [
  {
    id: 0,
    header: {
      title: 'Дорожная карта',
      collapse: false,
    },
    content: <Roadmap />,
  },
];
