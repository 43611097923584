import Icon from '../Icon';
import styled, { css } from 'styled-components';
import React, { FC, ReactElement } from 'react';
import ReactTooltip from 'react-tooltip';

interface Props {
  children: ReactElement;
  action: () => void;
  size?: 'normal' | 'small';
  rotate?: number;
  opacity?: number;
  disabled?: boolean;
  tooltip?: string;
  active?: boolean;
  color?: string;
  place?: any;
}

const ActionIcon: FC<Props> = ({
  children,
  action,
  size = 'normal',
  rotate = 0,
  opacity = 0.56,
  disabled = false,
  tooltip,
  active = false,
  color,
  place = 'top',
}) => {
  const handleAction = (e) => {
    e.stopPropagation();
    action();
  };

  return (
    <>
      <ActionIconWrapper
        data-tip={tooltip}
        data-for={tooltip}
        onClick={disabled ? () => null : handleAction}
        size={size}
        disabled={disabled}
        active={active && !disabled}
      >
        <Icon opacity={opacity} rotate={rotate} disabled={disabled} size={40} color={color}>
          {children}
        </Icon>
      </ActionIconWrapper>
      <ReactTooltip id={tooltip} effect={'solid'} className={'b-tooltip'} place={place} />
    </>
  );
};

const ActionIconWrapper = styled.div<{ size: string; disabled: boolean; active: boolean }>`
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.primary12} !important;
      svg {
        fill: ${({ theme }) => theme.colors.primary};
        fill-opacity: 1;
      }
    `};
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.hover};
        svg {
          fill-opacity: 0.56;
        }
      }
      &:active {
        background-color: ${({ theme }) => theme.colors.primary12} !important;
        svg {
          fill: ${({ theme }) => theme.colors.primary};
          fill-opacity: 1;
        }
      }
    `};
`;

export default ActionIcon;
