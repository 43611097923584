import styled from 'styled-components';
import User from '../../../components/User';
import { FC, useContext } from 'react';
import SummaryInfoAddition from '../../../pages/Objects/SummaryInfoAddition';
import { useLocation, matchPath, useParams, useRouteMatch } from 'react-router-dom';
import ActionIcon from '../../../components/ActionIcon';
import TailArrowIcon from '../../../assets/icons/TailArrowIcon';
import { history } from '../../../routes/history';
import { routes } from '../../../config/routes';
import testAvatar from '../../../assets/images/avatar.svg';
import { observer } from 'mobx-react-lite';
import { AuthStoreContext } from '../../../stores/auth';
import { ObjectStoreContext } from '../../../stores/object';
import { toJS } from 'mobx';
import { isMobile } from 'react-device-detect';

interface Props {
  isOpen: boolean;
}

const Header: FC<Props> = observer(({ isOpen }) => {
  const { profile } = useContext(AuthStoreContext);
  const match = useRouteMatch();

  const { ClearObjectData } = useContext(ObjectStoreContext);

  const handleBack = () => {
    history.goBack();
    ClearObjectData();
  };

  const route = Object.values(routes).find((el) => matchPath(el.path, match.path)) as any;

  return (
    <HeaderWrapper isOpen={isOpen} isMobile={isMobile}>
      <Information>
        {route?.withBack && (
          <IconWrapper>
            <ActionIcon action={handleBack}>
              <TailArrowIcon />
            </ActionIcon>
          </IconWrapper>
        )}
        <Title>{route?.title}</Title>
        {route?.withAdditional && (
          <Additional>
            <SummaryInfoAddition OMSUId={route.OMSUKey} />
          </Additional>
        )}
        {route?.addition && <Additional>{route?.addition}</Additional>}
      </Information>
      <User avatar={testAvatar} username={profile?.user_name} contact={profile?.user_email} />
    </HeaderWrapper>
  );
});

const Additional = styled.div`
  display: flex;
  margin-right: 12px;
`;

const Information = styled.div`
  display: flex;
  align-items: flex-end;
`;

const HeaderWrapper = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 24px;
  position: fixed;
  top: 0;
  width: ${({ isOpen, isMobile }) =>
    isMobile ? 'calc(100% - 80px)' : isOpen ? 'calc(100% - 280px)' : 'calc(100% - 80px)'};
  z-index: 2;
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.montserrat.semibold};
  font-size: 24px;
  line-height: 40px;
  margin-right: 14px;
`;

const IconWrapper = styled.div`
  margin-right: 16px;
`;

export default Header;
