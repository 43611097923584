import { FC, useCallback } from 'react';
import styled, { css } from 'styled-components';
import HeadRow from './HeaderCell';
import HeaderCell from './HeaderCell';

interface Props {
  fields: any[];
  order?: boolean;
  sticky: boolean;
  sorting?: any;
  setSortingCallback?: (item) => void;
}

const TableHeader: FC<Props> = ({ fields, order, sticky, sorting, setSortingCallback }) => {
  const onSortingClick = useCallback(
    (item) => {
      if (sorting) {
        let direction = sorting.direction;
        if (sorting.field === item.field) {
          if (sorting.direction === 'DESC') {
            direction = '';
          } else if (sorting.direction === 'ASC') {
            direction = 'DESC';
          } else {
            direction = 'ASC';
          }
        } else {
          direction = 'ASC';
        }
        setSortingCallback && setSortingCallback({ field: item.field, direction });
      }
    },
    [setSortingCallback, sorting],
  );

  return (
    <>
      {fields.map((item, idx) => (
        <HeaderRow key={item.id} sticky={sticky} stickyHeight={idx * 22}>
          {order && <EmptyCell></EmptyCell>}
          {item.map((el) => (
            <HeaderCell
              key={el.id}
              item={el}
              lastRow={fields.length - 1 === idx}
              order={order}
              sorting={sorting}
              setSortingCallback={onSortingClick}
              haveSortingCallback={!!setSortingCallback}
            />
          ))}
        </HeaderRow>
      ))}
    </>
  );
};

const HeaderRow = styled.tr<{ sticky: boolean; stickyHeight: number }>`
  ${({ sticky, stickyHeight }) =>
    sticky &&
    css`
      position: sticky;
      top: ${stickyHeight}px;
      z-index: 1;
      background-color: #fff;
    `}
`;
const EmptyCell = styled.th``;

export default TableHeader;
