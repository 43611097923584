import React, { FC } from 'react';
import MainLayout from '../../layouts/MainLayout';
import ComplexTable from '../../components/ComplexTable';
import { fieldsExample } from '../../components/ComplexTable/fieldsExample';
import { dataExample, emptyData } from '../../components/ComplexTable/dataExample';
import { PageWrapper } from '../../components/styled/PageWrapper';
import Card from '../../components/Card';

const PlaygroundPage: FC = () => {
  return (
    <MainLayout>
      <PageWrapper>
        <Card>
          <ComplexTable fields={fieldsExample} data={dataExample} orderedRows loading={false} />
        </Card>
      </PageWrapper>
    </MainLayout>
  );
};
export default PlaygroundPage;
