import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, {FC} from 'react';
import styled from 'styled-components';
import merge from 'lodash/merge';

interface Props {
  config: any;
}

const ColumnChart: FC<Props> =({ config }) => {

  const localConfig = {
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    plotOptions: {
      series: {
        pointPadding: 0,
        groupPadding: 0.1,
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      margin: 20,
      itemMarginBottom: 6,
      itemStyle: {
        fontSize: 12,
        fontFamily: 'Montserrat Medium',
        opacity: 0.5,
        fontWeight: 'normal',
      },
    },
  };

  return (
    <ComponentContainer>
      <HighchartsReact highcharts={Highcharts} options={merge(localConfig, config)} />
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div``;

export default ColumnChart;
