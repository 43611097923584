import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import RowCell from './RowCell';
import { font } from '../../../styled/mixins/fonts';

interface GroupProps {
  fields: any[];
  uniqKey?: string;
  onRowClick?: (el: any) => any;
  item: any;
  childKey?: string;
  level: number;
  hideZero?: boolean;
  componentOrder?: boolean;
  order?: number;
  lastRow?: boolean;
}

const BodyRow: FC<GroupProps> = ({
  item,
  fields,
  onRowClick,
  level,
  hideZero,
  componentOrder,
  order,
  lastRow,
}) => {
  const [open, setOpen] = useState(false);
  const childs = item['children'] || [];
  const isGroup = !!childs.length;
  const childOrder = item.options?.order;
  const autoOpen = item.options?.autoOpen;
  return (
    <>
      <TBodyRow
        hover={!!onRowClick}
        onClick={() => (isGroup ? setOpen(!open) : onRowClick && onRowClick(item))}
        id={item.selector}
        data-open={open ? 1 : 0}
      >
        {(childOrder || componentOrder) && <Order>{order}</Order>}
        <RowCell
          fields={fields}
          item={item}
          level={level}
          hideZero={hideZero}
          isGroup={isGroup}
          open={open}
          // onClick={() => null}
          autoOpen={autoOpen}
          order={order}
          lastRow={lastRow}
          hasChildren={item?.children?.length}
        />
      </TBodyRow>
      {isGroup &&
        (open || autoOpen) &&
        childs.map((el) => (
          <BodyRow
            key={el.id}
            level={level + 1}
            fields={fields}
            item={el}
            onRowClick={onRowClick}
            hideZero={hideZero}
            componentOrder={childOrder}
          />
        ))}
    </>
  );
};

const TBodyRow = styled.tr<{ isTotal?: boolean; hover: boolean; sticky?: boolean }>`
  ${({ hover }) =>
    hover &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${({ theme }) => theme.colors.hover};
      }
    `}
`;
const Order = styled.td`
  ${({ theme }) => font({ family: theme.fonts.montserrat.semibold })};
  vertical-align: middle;
  text-align: center;
  width: 60px;
`;

export default BodyRow;
