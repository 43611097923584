import styled from 'styled-components';
import TextField from '../../../components/TextField';
import { TableTypes } from '..';
import { FC, useCallback, useMemo } from 'react';
import { getTableFieldsConfig } from '../utils';
import { ConfigItem } from '../consts';
import { isNumber } from 'lodash';

interface Props {
  indicatorId: number | string;
  tableType: TableTypes;
  values?: Array<number>;
}

const SectionCollapsedContent: FC<Props> = ({ indicatorId, tableType, values }) => {
  const getConfig = useCallback(getTableFieldsConfig, []);

  const elements = useMemo(() => {
    const tableFields: Array<ConfigItem> = getConfig(tableType);

    return tableFields
      ? tableFields
          .filter((itm) => itm.field !== 'title')
          .reduce((acc, itm, idx) => {
            return isNumber(values[idx])
              ? [
                  ...acc,
                  <TextField
                    key={`indicator-collpsed-section-${idx}`}
                    label={itm.label}
                    value={values[idx]?.toLocaleString()}
                    valueBold
                    valueColor={itm?.dataOptions?.color}
                  />,
                ]
              : [...acc];
          }, [])
      : [];
  }, [values, indicatorId]);

  return <ComponentContainer>{elements}</ComponentContainer>;
};

const ComponentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export default SectionCollapsedContent;
