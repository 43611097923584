import Select from '../../../components/Select';
import { observer } from 'mobx-react-lite';
import SelectList from '../../../components/Calendar/SelectList';
import styled from 'styled-components';
import { useContext } from 'react';
import { DevelopmentStoreContext } from '../../../stores/development';

const DevelopmentHeaderAddition = observer(() => {
  const { setDevelopmentHeaderFilter, developmentHeaderFilter } =
    useContext(DevelopmentStoreContext);

  const list = [
    {
      id: 0,
      label: 'Инвестиционное',
      value: 'investment',
    },
    {
      id: 1,
      label: 'Социальное',
      value: 'social',
    },
  ];

  return (
    <ComponentContainer>
      <Select
        renderList={({ active, onClose }) => (
          <SelectList
            active={active}
            onClose={onClose}
            list={list}
            onClick={setDevelopmentHeaderFilter}
          />
        )}
        label={''}
        position="left"
        value={developmentHeaderFilter}
        fieldFullsize={false}
      />
    </ComponentContainer>
  );
});

const ComponentContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export default DevelopmentHeaderAddition;
