import { FC } from 'react';
import styled from 'styled-components';
import { Item } from '..';
import ListRow from './ListRow';
import { font } from '../../styled/mixins/fonts';

interface Props {
  options: any;
  length: any;
}

const ListTableSection: FC<Item & Props> = ({ list, title, subtitle, options, length }) => {
  return (
    <ComponentContainer>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {list.map((item) => (
        <ListRow key={item.id} {...item} options={options} length={length} />
      ))}
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div``;
const Title = styled.div`
  ${font()};
  padding: 8px 0;
`;
const Subtitle = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.sm,
      family: theme.fonts.robotoCondensed.regular,
      color: theme.colors.dark,
      lineHeight: '16px',
    })};
  padding: 8px 0 6px 14px;
`;

export default ListTableSection;
