import { Modal } from '../../../../components/Modal';
import { connectModal } from '../../../../hocs/Modal';
import styled, { useTheme } from 'styled-components';
import RatingModalHeader from './RatingModalHeader';
import SelectList from '../../../../components/Calendar/SelectList';
import Select from '../../../../components/Select';
import HorizontalLabeledText from '../../../../components/HorizontalLabeledText';
import { CustomTheme } from '../../../../styles/theme';
import { StatusType } from '../../../../components/Status';
import ColoredText from '../../../../components/ColoredText';
import OrderedListTable from '../../../../components/OrderedListTable';
import { ratingHeaderConfig } from './ratingConfig';
import ListTableSection from '../../../../components/OrderedListTable/ListTableSection';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getCalendarRating, getRatingIndicatorsOMSU } from '../../../../actions';
import { AppStoreContext } from '../../../../stores/app';
import { Loader } from '../../../../components/Loader';
import { observer } from 'mobx-react-lite';
import { font } from '../../../../components/styled/mixins/fonts';
import { formatDate } from '../../../../utils/dates';

const RatingModal = connectModal('rating-modal')(
  observer(({ handleHide }) => {
    const theme = useTheme() as CustomTheme;
    const currentDataIsFetching = useRef(false);
    const calendarRatingIsFetching = useRef(false);

    const { selectedOmsu: selectedOmsuCurrent, omsuList } = useContext(AppStoreContext);
    const [currentData, setCurrentData] = useState([]);
    const [calendarList, setCalendarList] = useState([]);
    const [selectedDate, setSelectedDate] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [selectedOmsu, setSelectedOmsu] = useState(
      selectedOmsuCurrent && selectedOmsuCurrent.label !== 'Все ОМСУ' ?
        selectedOmsuCurrent
        :
        omsuList.find(item => item.label !== 'Все ОМСУ')
      );

    const fetchRating = useCallback(async (params) => {
      const ratingResponse = await getRatingIndicatorsOMSU(params);
      setCurrentData(ratingResponse.data?.items || []);
    }, []);

    const handleSetSeletedDate = useCallback(
      async (date) => {
        setSelectedDate(date);
        try {
          setLoading(true);
          const ratingParams = {
            omsuId: selectedOmsu.id,
            periodId: date?.value,
          };
          await fetchRating(ratingParams);
        } catch (e) {
          //
        } finally {
          setLoading(false);
        }
      },
      [selectedOmsu, fetchRating],
    );

    const fetchCalendarRating = useCallback(async () => {
      if (selectedOmsu) {
        try {
          setLoading(true);
          const params = {
            omsuId: selectedOmsu.id,
          };
          const response = await getCalendarRating(params);
          const lastDate = response.data?.items[0];
          const selectedD = { value: lastDate.id, label: lastDate.periodName };
          const ratingParams = {
            omsuId: selectedOmsu.id,
            periodId: selectedD?.value,
          };
          await fetchRating(ratingParams);
          setCalendarList(
            response.data?.items.map((item) => ({ value: item.id, label: item.periodName })) || [],
          );
          setSelectedDate(selectedD);
        } catch (e) {
          //
        } finally {
          setLoading(false);
        }
      }
    }, [selectedOmsu]);

    useEffect(() => {
      fetchCalendarRating();
    }, [fetchCalendarRating]);

    const ratingData = useMemo(
      () => [
        {
          id: 0,
          name: 'Итоговый рейтинг с учетом веса показателей',
          rating: (
            <ColoredTextWrapper>
              <ColoredText text={currentData[0]?.finalPositionTotalWeight} type={StatusType.DONE} />
            </ColoredTextWrapper>
          ),
        },
        {
          id: 1,
          name: 'Рейтинг',
          rating: (
            <ColoredTextWrapper>
              <ColoredText
                text={currentData[0]?.finalPositionTotal}
                type={StatusType.DONE_WITH_DELAY}
              />
            </ColoredTextWrapper>
          ),
        },
      ],
      [currentData],
    );

    const reportDate = (
      <Select
        renderList={({ active, onClose }) => (
          <SelectList
            list={calendarList}
            onClick={handleSetSeletedDate}
            onClose={onClose}
            active={active}
          />
        )}
        label={'Дата'}
        value={selectedDate}
      />
    );

    const preparedData = useMemo(() => {
      const arr = [];
      currentData.forEach((group) => {
        const withChildren = [];
        group?.children?.forEach((el) => {
          if (el.children) {
            withChildren.push(el);
          }
        });
        if (withChildren.length === 0) {
          arr.push({ ...group, title: group.code });
        } else {
          withChildren.forEach((child, idx) => {
            const item = {
              ...child,
              subtitle: child.code,
            };
            if (idx === 0) {
              item.title = group.code;
            }
            arr.push(item);
          });
        }
      });
      return arr;
    }, [currentData]);

    return (
      <Modal
        title={'Сводная оценка по территории'}
        onClose={handleHide}
        header={<RatingModalHeader setSelectedOmsu={setSelectedOmsu} selectedOmsu={selectedOmsu} />}
      >
        <Content>
          <Period>
            <FieldWrapper>
              <HorizontalLabeledText label={'Отчетный период:'} data={reportDate} />
              <HorizontalLabeledText
                label={'Дата последнего обновления:'}
                data={
                  currentData[0]?.calcDateTime && formatDate(new Date(currentData[0]?.calcDateTime))
                }
              />
            </FieldWrapper>
          </Period>
          {loading ? (
            <Loader />
          ) : (
            <SubContent>
              <TotalRating>
                {ratingData.map(({ rating, name, id }) => (
                  <Line key={id}>
                    <Cell>{name}</Cell>
                    <Cell>{rating}</Cell>
                  </Line>
                ))}
              </TotalRating>
              <Statistics>
                <OrderedListTable
                  header={ratingHeaderConfig}
                  config={preparedData.map((item) => {
                    return {
                      id: item.id,
                      title: item.title,
                      subtitle: item.subtitle,
                      list: item?.children?.map((child) => ({
                        id: 0,
                        data: [
                          {
                            id: 0,
                            info: child.code,
                          },
                          {
                            id: 1,
                            info: child.nameIOGV,
                          },
                          {
                            id: 2,
                            info: (
                              <ColoredText
                                text={child.finalPosition}
                                type={StatusType.DONE_WITH_DELAY}
                              />
                            ),
                          },
                        ],
                      })) ?? [],
                    };
                  })}
                >
                  {(item, options) => (
                    <ListTableSection {...item} options={options} length={null} />
                  )}
                </OrderedListTable>
              </Statistics>
            </SubContent>
          )}
        </Content>
      </Modal>
    );
  }),
);

const Content = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
const SubContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`;
const FieldWrapper = styled.div`
  margin-bottom: 2px;
`;
const Period = styled.div``;
const Statistics = styled.div``;
const TotalRating = styled.div``;
const ColoredTextWrapper = styled.div`
  padding: 0 10px;
`;
const Line = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;
const Cell = styled.div`
  padding: 8px 0;
  ${({ theme }) => font({ family: theme.fonts.montserrat.bold })};
`;

export default RatingModal;
