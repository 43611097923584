import { createContext } from 'react';
import { makeAutoObservable, toJS } from 'mobx';
import { getNsi, getOMSUList } from '../actions';
import orderBy from 'lodash/orderBy';
import { getStorageValue, setStorageValue } from '../utils/handleStorageValue';

const storageName = 'selectedOMSU';

class App {
  constructor() {
    makeAutoObservable(this);
  }

  selectedYear = getStorageValue({ name: 'indicatorsFiltersSelectedYear', values: null });
  setSelectedYear = (selectedYear) => {
    setStorageValue({ name: 'indicatorsFiltersSelectedYear', values: selectedYear });
    this.selectedYear = selectedYear;
  };

  selectedCiogv = getStorageValue({ name: 'indicatorsFiltersCIOGV', values: null });
  setCIOGV = (ciogv) => {
    setStorageValue({ name: 'indicatorsFiltersCIOGV', values: ciogv });
    this.selectedCiogv = ciogv;
  };

  omsuList = [];
  setOmsuList = (omsuList) => (this.omsuList = omsuList);
  selectedOmsu = getStorageValue({ name: storageName, values: null });
  setSelectedOmsu = (selectedOmsu) => {
    setStorageValue({ name: storageName, values: selectedOmsu });
    this.selectedOmsu = selectedOmsu;
  };

  getOmsuList = async (id) => {
    try {
      const response = await getOMSUList(id);
      const mark = response.data.items[0].id === '00000000-0000-0000-0000-000000000000' ? 0 : 1;
      const list = orderBy(response.data.items, ['order']).map((el, idx) => ({
        id: el.id,
        value: el.id,
        label: el.name,
        idx: idx + mark,
      }));
      this.setOmsuList(list);
      if (!this.selectedOmsu || !list.find((item) => item.id === this.selectedOmsu.id))
        this.setSelectedOmsu(list[0]);
    } catch (e) {
      //
    }
  };

  menuIsOpen = window.innerWidth > 1366;
  setMenuIsOpen = (menuIsOpen) => (this.menuIsOpen = menuIsOpen);
}

export const AppStore = new App();
export const AppStoreContext = createContext(AppStore);
