import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Loader } from '../../../components/Loader';
import RatingRow from '../../../components/RatingRow';
import { ModalsStore } from '../../../stores/modal';
import { getRatingOMSU } from '../../../actions';
import { AppStoreContext } from '../../../stores/app';
import { observer } from 'mobx-react-lite';

const RatingData: FC = observer(() => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const [ratingData, setRatingData] = useState([]);
  const [loadingRatingData, setLoadingRatingData] = useState(false);

  const fetchRatingData = useCallback(async () => {
    if (selectedOmsu) {
      try {
        setLoadingRatingData(true);
        const params = { omsuId: selectedOmsu.id };
        const response = await getRatingOMSU(params);
        setRatingData(response.data?.items || []);
      } catch (e) {
        //
      } finally {
        setLoadingRatingData(false);
      }
    }
  }, [selectedOmsu]);

  useEffect(() => {
    fetchRatingData();
  }, [fetchRatingData]);

  return (
    <>
      {loadingRatingData ? (
        <Loader />
      ) : (
        <RatingRow
          onClick={() => ModalsStore.showModal('rating-modal')}
          config={{
            rating: ratingData[0]?.currentPosition,
            differenceRating: ratingData[0]?.dynamics,
            cols: ratingData
              .filter((item, key) => key !== 0)
              .map((item) => ({
                id: item.id,
                rating: item.currentPosition,
                differenceRating: item.dynamics,
                year: item.calendarRaiting.periodName,
                mark: item.countIndicator,
              })),
          }}
        />
      )}
    </>
  );
});

export default RatingData;
