import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    scrollbar-width: thin;
  }

  html, body, #root {
    padding: 0;
    margin: 0;
    height: 100%;
  }
  html {
    background-color: rgba(0,19,92,0.03);
    overflow-x: hidden;
  }

  ::-webkit-scrollbar {
    width: 12px; /* Total width including \`border-width\` of scrollbar thumb */
    height: 0;
  }

  ::-webkit-scrollbar-track {
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.12);   
    border-radius: 50px;
    height: 3rem;
    border-left: 3px solid rgba(0, 0, 0, 0); /* Transparent border together with \`background-clip: padding-box\` does the trick */
    border-right: 3px solid rgba(0, 0, 0, 0); /* Transparent border together with \`background-clip: padding-box\` does the trick */
    border-bottom: 10px solid rgba(0, 0, 0, 0);
    border-top: 10px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 1em;
    //-webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
  }
  
  .body-scroll-margin-top {
    * {
      scroll-margin-top: 60px;
    }
  }
`;
