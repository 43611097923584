const ChartLegendDarkIcon = () => (
  <svg width='24' height='12' viewBox='0 0 24 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='1' y='5' width='22' height='2' rx='1' fill='white' stroke='#172B75' strokeWidth='2' />
    <rect
      x='7.5'
      y='1.5'
      width='9'
      height='9'
      rx='4.5'
      fill='white'
      stroke='#172B75'
      strokeWidth='3'
    />
  </svg>
);

export default ChartLegendDarkIcon;
