import Storage from './storage';

const prepareFilter = (filter, { dateFields }) => {
  const filterCopy = { ...filter };
  dateFields.forEach((key) => {
    if (filterCopy[key]) filterCopy[key] = new Date(filterCopy[key]);
  });
  return filterCopy;
};

export const getStorageValue = ({ name, values, dateFields = [] }) => {
  return Storage.get(name) ? prepareFilter(JSON.parse(Storage.get(name)), { dateFields }) : values;
};

export const getStorageSortingValue = ({ name, values }) => {
  return Storage.get(name) ? JSON.parse(Storage.get(name)) : values;
};

export const setStorageValue = ({ name, values }) => {
  Storage.set(name, JSON.stringify(values));
};
