import {FC} from "react";
import styled from "styled-components";
import ListItem from "./ListItem";

interface IListItem {
    id: number;
    label: string;
    children: {
        id: number;
        label: string;
        value: string;
    }[];
}

interface Props {
    onClick: (item: any) => void;
    active?: any;
    list: IListItem[];
}

const SelectAccordeonList:FC<Props> = ({onClick, active, list}) => {
    return (
        <ComponentContainer>
            {list.map(item => <ListItem key={item.id} {...item} item={item} onClick={onClick} active={active} />)}
        </ComponentContainer>
    )
}

const ComponentContainer = styled.div``

export default SelectAccordeonList;
