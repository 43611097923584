import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { AnchorPopupStoreContext } from '../../stores/anchorPopup';
import styled, { css } from 'styled-components';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { useKeyClick } from '../../hooks/useKeyClick';

const horizontalOffset = 20;

interface Props {
  horizontalAlign?: 'left' | 'right';
}
const AnchorPopup: FC<Props> = observer(({ horizontalAlign = 'left' }) => {
  const AnchorPopupStore = useContext(AnchorPopupStoreContext);
  const timer = useRef<any>();

  useKeyClick(() => AnchorPopupStore.hidePopup(), 27);

  const ref = useOutsideClick((e) => {
    if (!AnchorPopupStore.anchorRef?.contains(e.target)) {
      //Защита от старатывания outsideClick при нажатии anchor элемент
      AnchorPopupStore.onClose();
      AnchorPopupStore.hidePopup();
    }
  });

  const [position, setPosition] = useState(null);

  const hAllign = AnchorPopupStore.position || horizontalAlign;

  const calculatePosition = useCallback(() => {
    if (AnchorPopupStore.anchorRef && ref.current) {
      const box = ref.current.getBoundingClientRect();
      const parentBox = AnchorPopupStore.anchorRef.getBoundingClientRect();
      // console.log('box', box)
      // console.log('parent', parentBox)
      const zoom = 1;
      // if (AnchorPopupStore.zoomedRef) {
      //   zoom = Number(getComputedStyle(AnchorPopupStore.zoomedRef).getPropertyValue('zoom'));
      //   console.log(getComputedStyle(AnchorPopupStore.zoomedRef).getPropertyValue('zoom'))
      // }

      let topPosition, horizontal;
      if (parentBox.top + parentBox.height + box.height < document.body.clientHeight) {
        topPosition = parentBox.top + parentBox.height;
      } else {
        topPosition = parentBox.top - box.height;
      }
      if (box.height >= document.body.clientHeight) {
        ref.current.style.overflowY = 'visible';
        ref.current.style.maxHeight = document.body.clientHeight + 'px';
      } else {
        // ref.current.style.overflowY = 'hidden';
      }
      if (topPosition < 0) {
        topPosition = 0;
      }
      if (topPosition + box.height > document.body.clientHeight) {
        topPosition = document.body.clientHeight - box.height;
      }

      if (hAllign === 'left') {
        if (parentBox.left + box.width < window.innerWidth) {
          horizontal = parentBox.left;
        } else {
          horizontal = parentBox.left + parentBox.width - box.width;
        }
      } else if (hAllign === 'right') {
        if (parentBox.right - box.width > 0) {
          horizontal = window.innerWidth - parentBox.right;
        } else {
          horizontal = window.innerWidth - (parentBox.left + box.width);
        }
      }
      if (horizontal < 0) {
        horizontal = horizontalOffset;
      }
      ref.current.style.visible = 'hidden';
      ref.current.style.display = 'none';
      setPosition({ top: topPosition * zoom, horizontal: horizontal * zoom });
    } else {
      ref.current.style.visible = 'hidden';
      setPosition(null);
    }
  }, [AnchorPopupStore.anchorRef, AnchorPopupStore.width, ref.current, horizontalAlign, hAllign]);

  useLayoutEffect(() => {
    calculatePosition();
  }, [calculatePosition]);

  useLayoutEffect(() => {
    if (position?.top || position?.top === 0) {
      ref.current.style.visible = 'visible';
      ref.current.style.display = 'block';
    }
  }, [position, ref.current]);

  useEffect(() => {
    const onWheel = (event) => {
      // clearTimeout(timer.current);
      // timer.current = setTimeout(() => {
      //   calculatePosition();
      // }, 250);
      AnchorPopupStore.onClose();
      AnchorPopupStore.hidePopup();
    };
    const element = document.getElementById('scrollable-content-wrapper');
    element && element.addEventListener('wheel', onWheel);
    return () => {
      element && element.removeEventListener('wheel', onWheel);
    };
  }, [calculatePosition]);

  return (
    <Container
      ref={ref}
      top={position?.top}
      horizontal={position?.horizontal}
      horizontalAlign={hAllign}
    >
      {AnchorPopupStore.component}
    </Container>
  );
});

const Container = styled.div<{ top?: number; horizontal?: number; horizontalAlign: string }>`
  position: absolute;
  top: ${({ top }) => (top ? top : 0)}px;
  z-index: 11;
  ${({ horizontal, horizontalAlign }) => css`
    ${horizontalAlign}: ${horizontal ?? 0}px;
  `};
  max-width: calc(100% - ${2 * horizontalOffset}px);
`;

export { AnchorPopup };
