import { FC, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import CardRating from './CardRating';
import { font } from '../styled/mixins/fonts';

export interface Rating {
  place: number;
  placeDifference?: number;
  amount: number;
  amountMeasure: string;
  list: {
    id: number;
    place: number;
    label: string;
  }[];
}

interface Props {
  id: number;
  iconURL: string;
  title: string;
  subtitle?: string;
  rating: Rating;
}

const RatingCard: FC<Props> = ({ rating, subtitle, iconURL, title }) => {

  const [svg, setSvg] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  
  useEffect(() => {
    if(iconURL){
      fetch(iconURL)
        .then((res) => res.text())
        .then(setSvg)
        .catch(setIsErrored)
        .then(() => setIsLoaded(true));
    }
  }, [iconURL]);

  return (
    <ComponentContainer>
      <Info>
        {svg && <Icon>{svg && <Icon dangerouslySetInnerHTML={{ __html: svg }} />}</Icon>}
        <Content>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Content>
      </Info>
      <CardRating {...rating} />
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const Icon = styled.div``;
const Title = styled.div`
  ${({ theme }) => font({ size: theme.fonts.sizes.xxmd, family: theme.fonts.montserrat.semibold })};
  margin-bottom: 2px;
`;
const Subtitle = styled.div`
  ${({ theme }) => font({ color: theme.colors.dark })};
`;
const Info = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export default RatingCard;
