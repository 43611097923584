import { Modal } from '../../../../components/Modal';
import { connectModal } from '../../../../hocs/Modal';
import styled from 'styled-components';
import RisksObjectsModalHeader, { Type } from './RisksObjectsModalHeader';
import { List } from '../../../../components/List';
import ListItem from '../../../Objects/Risks/ListItem';
import { useCallback, useContext, useMemo, useState } from 'react';
import { objectsFilters } from '../consts';
import { AppStoreContext } from '../../../../stores/app';
import { observer } from 'mobx-react-lite';
import { ListsStoreContext } from '../../../../stores/lists';
import { api } from '../../../../config/api';
import NoData from '../../../../components/NoData';
import { generatePath } from 'react-router';
import { routes } from '../../../../config/routes';
import { history } from '../../../../routes/history';
import { ChartSector } from '../configs';

const RisksObjectsModal = connectModal('risks-objects-modal')(
  observer(({ handleHide, chartSector }) => {
    const { selectedOmsu } = useContext(AppStoreContext);
    const [type, setType] = useState<Type>(
      objectsFilters[
          chartSector === ChartSector.RISK ? 1
        : chartSector === ChartSector.BEFORE_RISK ? 2
        : 0
      ]);

    const { getTotal } = useContext(ListsStoreContext);

    const total = getTotal('agendaObjectsModalList');

    const handleType = useCallback((type: Type) => {
      setType(type);
    }, []);

    const filters = useMemo(
      () => ({
        isRisk: type.value === 'risks',
        isBeforeRisk: type.value === 'zpmo',
        omsuId: selectedOmsu.id,
      }),
      [selectedOmsu, type],
    );

    return (
      <Modal
        title={'Объекты'}
        onClose={handleHide}
        header={<RisksObjectsModalHeader type={type} setType={handleType} amount={total} />}
        contentOverflow={false}
      >
        <Content>
          <List
              maxHeight={'calc(100vh - 214px)'}
            renderItem={(item) => (
              <ListItem
                item={item}
                onClick={() => {
                  const newPath = generatePath(routes.object.path, {
                    actionId: item.govActiontId,
                    objectId: item.entityId,
                  });
                  history.push(newPath);
                }}
                showType={false}
              />
            )}
            url={api.getObjectsReester}
            filter={filters}
            emptyPlaceholder={<NoData description={'По выбранным условиям объекты отсутствуют'} />}
            limit={5}
            listName={'agendaObjectsModalList'}
          />
        </Content>
      </Modal>
    );
  }),
);

const Content = styled.div`
  width: 100%;
`;
export default RisksObjectsModal;
