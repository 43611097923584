import React, { FC, useMemo } from 'react';
import lodashRange from 'lodash/range';
import styled, { css } from 'styled-components';
import ActionIcon from '../ActionIcon';
import ArrowIcon from '../../assets/icons/ArrowIcon';
import Select, { ListPosition } from '../Select';
import SelectList from '../Calendar/SelectList';
import { toJS } from 'mobx';

const visiblePages = 5;

const decimate = (page, array) => {
  const arrayLength = array.length;
  if (arrayLength <= visiblePages) {
    return array;
  }
  const half = Math.floor(visiblePages / 2);
  if (page <= half) {
    return array.slice(0, visiblePages);
  } else if (page > arrayLength - half) {
    return array.slice(arrayLength - visiblePages, arrayLength);
  }
  const mark = visiblePages % 2 === 1 ? -1 : 0;
  return array.slice(
    arrayLength - (arrayLength - page) - half + mark,
    arrayLength - (arrayLength - page) + half,
  );
};

interface Props {
  meta: any;
  fetchData: (params: any) => void;
}

const Pagination: FC<Props> = ({ meta, fetchData }) => {
  const range = lodashRange(
    meta?.total && meta?.maxItems ? Math.ceil(meta?.total / meta.maxItems) : 1,
  );
  const decimated = decimate(Math.floor(meta?.startIndex / meta?.maxItems) + 1, range);

  const { minPage, maxPage } = useMemo(() => {
    return { minPage: 0, maxPage: range.length - 1 };
  }, [range]);

  const { withMaxPage, withMinPage } = useMemo(() => {
    return {
      withMinPage: Math.min(...decimated) - 1 > minPage,
      withMaxPage: Math.max(...decimated) + 1 < maxPage,
    };
  }, [minPage, maxPage, decimated]);

  return (
    <Wrapper>
      <Controls>
        <ActionIcon
          disabled={meta?.startIndex === 0}
          action={() => fetchData({ offset: meta?.startIndex - meta?.maxItems })}
          rotate={90}
          opacity={0.56}
        >
          <ArrowIcon />
        </ActionIcon>
        {withMinPage && (
          <>
            <Page
              key={`pagination-${minPage}`}
              isActive={false}
              onClick={() => {
                fetchData({ offset: minPage * meta?.maxItems });
              }}
            >
              {minPage + 1}
            </Page>
            ...
          </>
        )}
        {decimated.map((page) => {
          const isActive = page === Math.floor(meta?.startIndex / meta?.maxItems);
          return (
            <Page
              key={`pagination-${page}`}
              isActive={isActive}
              onClick={
                !isActive &&
                (() => {
                  fetchData({ offset: page * meta?.maxItems });
                })
              }
            >
              {page + 1}
            </Page>
          );
        })}
        {withMaxPage && (
          <>
            ...
            <Page
              key={`pagination-${maxPage}`}
              isActive={false}
              onClick={() => {
                fetchData({ offset: maxPage * meta?.maxItems });
              }}
            >
              {maxPage + 1}
            </Page>
          </>
        )}
        <ActionIcon
          disabled={meta?.startIndex + meta?.maxItems >= meta?.total}
          action={() => fetchData({ offset: meta?.startIndex + meta?.maxItems })}
          rotate={-90}
          opacity={0.56}
        >
          <ArrowIcon />
        </ActionIcon>
      </Controls>
      <Controls>
        <Text>Выводить по:</Text>
        <Select
          listPosition={ListPosition.TOP}
          renderList={({ onClose, active }) => (
            <SelectList
              active={active}
              list={[
                {
                  id: 5,
                  value: 5,
                  label: '5',
                },
                {
                  id: 10,
                  value: 10,
                  label: '10',
                },
                {
                  id: 25,
                  value: 25,
                  label: '25',
                },
                {
                  id: 50,
                  value: 50,
                  label: '50',
                },
                {
                  id: 100,
                  value: 100,
                  label: '100',
                },
              ]}
              onClick={(item) => {
                fetchData({ limit: item.value });
                onClose();
              }}
            />
          )}
          label={meta?.maxItems}
          value={meta?.maxItems}
        />
      </Controls>
    </Wrapper>
  );
};

const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.montserrat.medium};
  color: rgba(0, 0, 0, 0.56);
  margin-right: 4px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
`;

const Page = styled.div<{ isActive: boolean }>`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.56);
  font-family: ${({ theme }) => theme.fonts.montserrat.semibold};
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: rgba(23, 43, 117, 0.12);
      color: ${({ theme }) => theme.colors.primary};
    `}
`;

export { Pagination };
