export type CustomTheme = typeof theme;

export const theme = {
  colors: {
    primary: '#172B75',
    secondary: '#646CAA',
    hover: 'rgba(0, 0, 0, 0.04)',
    light: 'rgba(0, 0, 0, 0.02)',
    dark: 'rgba(0, 0, 0, 0.56)',
    border: 'rgba(0, 0, 0, 0.12)',
    fieldBorder: 'rgba(23, 43, 117, 0.12)',
    placeholder: 'rgba(0, 0, 0, 0.3)',
    emptyData: 'rgba(0, 0, 0, 0.3)',
    lightGray: 'rgba(0, 0, 0, 0.2)',
    primary12: 'rgba(23, 43, 117, 0.12)',
    disabled: '#BDBDBD',
    status: {
      done: '#27AE60',
      progress: '#2F80ED',
      overdue: '#EB5757',
      done_with_delay: '#F2994A',
      planned: '#707070',
    },
  },
  styles: {
    borderRadius: '8px',
  },
  fonts: {
    montserrat: {
      medium: "'Montserrat Medium', Arial, sans-serif",
      semibold: "'Montserrat Semibold', Arial, sans-serif",
      bold: "'Montserrat Bold', Arial, sans-serif",
      regular: "'Montserrat Regular', Arial, sans-serif",
    },
    robotoCondensed: {
      regular: "'Roboto Condensed Regular', Arial, sans-serif",
      bold: "'Roboto Condensed Bold', Arial, sans-serif",
    },
    sizes: {
      xs: '10px',
      sm: '12px',
      xsm: '13px',
      md: '14px',
      xmd: '16px',
      xxmd: '18px',
      xxxmd: '20px',
      lg: '22px',
      xlg: '24px',
      xmlg: '28px',
      xxlg: '30px',
      xxxlg: '32px',
    },
  },
  animations: {
    transition: '.3s ease-in-out',
  },
};
