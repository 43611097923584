export const objectsFilters = [
  {
    id: 0,
    title: 'Все',
    value: 'all',
  },
  {
    id: 1,
    title: 'С рисками',
    value: 'risks',
  },
  {
    id: 2,
    title: 'ЗПМО',
    value: 'zpmo',
  },
];

export const fundingTypes = [
  { id: 1, value: 1, label: 'Все' },
  { id: 2, value: 2, label: 'Бюджет' },
  { id: 3, value: 3, label: 'Внебюджет' },
];

export const initialAgendaRaitingRisksFilter = {
  type: fundingTypes[0],
};
