import axios from 'axios';
import { api, BASE_URL } from '../config/api';
import Storage from './storage';
import { refreshToken } from '../actions';
import { history } from '../routes/history';
import { AuthStore } from '../stores/auth';
import { debounce } from 'lodash';
import { add, formatISO } from 'date-fns';

export const fetchInstance = axios.create({
  baseURL: BASE_URL,
});

export const setAuthToken = (token, refreshToken) => {
  if (token) {
    Storage.setToken(token);
    if (refreshToken) Storage.set('refresh_token', refreshToken);
    fetchInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    Storage.removeToken();
    Storage.remove('refresh_token');
    delete fetchInstance.defaults.headers.common['Authorization'];
  }
};

const debounced = debounce(async () => {
  try {
    const rToken = Storage.get('refresh_token');
    setAuthToken(rToken, rToken);
    const response = await refreshToken();
    const { access_token, refresh_token, token_live_seconds } = response.data;
    const expiredString = formatISO(add(new Date(), { seconds: token_live_seconds }));
    Storage.set('token_expired', expiredString);
    setAuthToken(access_token, refresh_token);
    window.location.reload(); //Перезагрузка страницы после получения нового токена.
  } catch (e) {
    setAuthToken();
    Storage.remove('token_expired');
    AuthStore.setProfile(null);
  }
}, 1000);

const handleError = async (error) => {
  if (error?.response?.status === 401 && error?.config?.url !== api.refreshToken) {
    debounced();
  }
  return Promise.reject(error);
};

fetchInstance.interceptors.response.use((response) => response, handleError);
