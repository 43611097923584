import styled from 'styled-components';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { font } from '../../../components/styled/mixins/fonts';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { getAgendaFinanceData } from '../../../actions';
import { AppStoreContext } from '../../../stores/app';
import { observer } from 'mobx-react-lite';
import { Loader } from '../../../components/Loader';

interface Props {
  filter: any;
}

const AgendaEconomicsCollapsedContent: FC<Props> = observer(({ filter }) => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    if (selectedOmsu) {
      try {
        setLoading(true);
        const params = {
          omsuId: selectedOmsu.id,
          periodId: filter.selectedMonth?.id,
        };
        const response = await getAgendaFinanceData(params);
        setData(response.data?.items || []);
      } catch (e) {
        //
      } finally {
        setLoading(false);
      }
    }
  }, [selectedOmsu, filter.selectedMonth]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <ComponentContainer>
      <DateLabel>
        На
        {filter.selectedMonth
          ? ` ${filter.selectedMonth.name} ${filter.selectedMonth.appliesToYear} `
          : ''}
        год:
      </DateLabel>
      <Content>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Data>
              {data
                .reduce((sum, current) => {
                  return current.factValue + sum;
                }, 0)
                .toLocaleString()}
            </Data>
            <Label>тыс руб.</Label>
          </>
        )}
      </Content>
    </ComponentContainer>
  );
});

const ComponentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;
const DateLabel = styled.div`
  ${({ theme }) => font({ color: theme.colors.dark, lineHeight: '32px' })};
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;
const Data = styled.div`
  ${({ theme }) => font({ family: theme.fonts.montserrat.semibold, lineHeight: '32px' })}
`;
const Label = styled.div`
  ${({ theme }) => font({ color: theme.colors.dark, lineHeight: '32px' })}
`;

export default AgendaEconomicsCollapsedContent;
