import { theme } from '../../../styles/theme';

interface Types {
  size?: string;
  family?: string;
  lineHeight?: string | number;
  color?: string;
}

export const font = ({
  size = theme.fonts.sizes.md,
  family = theme.fonts.montserrat.medium,
  lineHeight,
  color = '#000',
}: Types = {}) => `
    font-size: ${size};
    font-family: ${family};
    line-height: ${lineHeight || parseInt(size) * 1.5 + 'px'};
    color: ${color};
`;
