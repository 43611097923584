import styled from 'styled-components';
import Rating, { RatingTypes } from '../../../components/Rating';
import { RatingType } from '../../../components/RatingRow';
import Stats from '../../../components/Stats';
import { useCallback, useContext, useEffect, useState } from 'react';
import { getRatingOMSU } from '../../../actions';
import { AppStoreContext } from '../../../stores/app';
import { Loader } from '../../../components/Loader';

const AgendaRatingCollapsedContent = () => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const [ratingData, setRatingData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchRatingData = useCallback(async () => {
    if (selectedOmsu) {
      try {
        setLoading(true);
        const params = { omsuId: selectedOmsu.id };
        const response = await getRatingOMSU(params);
        setRatingData(response.data?.items || []);
      } catch (e) {
        //
      } finally {
        setLoading(false);
      }
    }
  }, [selectedOmsu]);

  useEffect(() => {
    fetchRatingData();
  }, [fetchRatingData]);

  return (
    <ComponentContainer>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Rating
            rating={ratingData[0]?.currentPosition}
            type={RatingTypes.SUCCESS}
            size={RatingType.TINY}
          />
          <Stats progress={ratingData[0]?.dynamics} />
        </>
      )}
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export default AgendaRatingCollapsedContent;
