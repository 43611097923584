import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import BudgetModalFilters, { FilterTypes } from '../../../../../components/BudgetModalFilters';
import { Table } from '../../../../../components/Table';
import { gpCustomFields } from '../GPType/gpCustomFields';
import { font } from '../../../../../components/styled/mixins/fonts';
import { useFilter } from '../../../../../hooks/useFilter';
import { initialNPFilterValues } from '../consts';
import { getDinamBudgetExecutionTable } from '../../../../../actions';
import { get, isNumber } from 'lodash';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { theme } from '../../../../../styles/theme';
import { Loader } from '../../../../../components/Loader';
import Text, { TextFamily } from '../../../../../components/Text';
import { prepareNumberForTable } from '../../../../../utils/prepareNumber';
import { AppStoreContext } from '../../../../../stores/app';
import { observer } from 'mobx-react-lite';

interface Props {
  filter: any;
}

const CONTEXT_CODE = '10004';

const NPType: FC<Props> = observer(({ filter }) => {
  const { selectedOmsu } = useContext(AppStoreContext);

  const { filter: npFilter, setFilterCallback: setNPFilterCallback } = useFilter({
    initialFilterValues: { ...initialNPFilterValues },
    name: 'budgetExecutionNPFilters',
  });

  const [tableInfo, setTableData] = useState(null);
  const [tableDataLoading, setTableDataLoading] = useState<boolean>(false);

  useEffect(() => {
    if (filter?.selectedMonth) {
      const fetchData = async () => {
        setTableDataLoading(true);
        try {
          const response = await getDinamBudgetExecutionTable({
            ExecutionContextCode: CONTEXT_CODE,
            omsuId: selectedOmsu?.id,
            periodId: filter?.selectedMonth?.id,
            ciogvId: npFilter[FilterTypes.CIOGV],
            nacProjectId: npFilter[FilterTypes.NATIONAL_PROJECT],
            gp: npFilter[FilterTypes.GP],
            fundingSource: npFilter[FilterTypes.FUNDING_SOURCE],
          });
          setTableData(response.data.items);
        } catch (e) {
          //
        } finally {
          setTableDataLoading(false);
        }
      };
      fetchData();
    }
  }, [filter, npFilter]);

  const tableConfig = useMemo(() => {
    let tableData = [];
    let tableFields = [];
    let tableFieldsCustomTop = [];

    if (tableInfo) {
      const periodLength = get(tableInfo, '[0].periodValues', []).length;

      tableFieldsCustomTop = [
        {
          label: '',
          colspan: 2,
        },
        ...get(tableInfo, '[0].periodValues', []).map((itm, idx) => ({
          label:
            idx === 0
              ? format(new Date(itm?.period?.periodStart), 'На d MMMM yyyy', { locale: ru })
              : itm?.period?.name,
          colspan: idx === 0 ? 6 : 3,
          width: idx === 0 ? '36%' : '18%',
        })),
      ];

      tableFields = [
        {
          label: '',
          field: 'code',
          sorting: false,
          width: '1%',
          options: {
            text: 'left',
            family: theme.fonts.montserrat.bold,
            levelStep: 10,
            firstLevelPadding: 0,
            secondLevelPadding: 0,
          },
          justifyContent: 'flex-start',
        },
        {
          label: (
            <NameTitleContainer>
              <Text
                data='Наименование'
                color={theme.colors.dark}
                font={TextFamily.ROBOTO_CONDENSED_REGULAR}
                size={'12px'}
              />
              <Text
                data='млрд руб.'
                color={theme.colors.emptyData}
                font={TextFamily.ROBOTO_CONDENSED_REGULAR}
                size={'12px'}
              />
            </NameTitleContainer>
          ),
          field: 'name',
          width: '28%',
          options: {
            text: 'left',
            family: theme.fonts.montserrat.regular,
            headLabelWidth: '100%',
          },
          justifyContent: 'flex-start',
        },
        ...get(tableInfo, '[0].periodValues', [])
          .map((itm, idx) => [
            ...(itm.plan
              ? [
                  {
                    label: 'Всего',
                    field: `planTotal${idx}`,
                    width: '6%',
                    justifyContent: 'flex-end',
                  },
                  {
                    label: 'ФБ',
                    field: `planFb${idx}`,
                    width: '6%',
                    justifyContent: 'flex-end',
                  },
                  {
                    label: 'ОБ',
                    field: `planOb${idx}`,
                    width: '6%',
                    justifyContent: 'flex-end',
                  },
                ]
              : []),
            {
              label: 'Всего',
              field: `executedTotal${idx}`,
              width: '6%',
              justifyContent: idx === 0 ? 'flex-end' : undefined,
            },
            {
              label: 'ФБ',
              field: `executedFb${idx}`,
              width: '6%',
              justifyContent: idx === 0 ? 'flex-end' : undefined,
            },
            {
              label: 'ОБ',
              field: `executedOb${idx}`,
              width: '6%',
              justifyContent: idx === 0 ? 'flex-end' : undefined,
            },
          ])
          .flat(),
      ];

      tableData = tableInfo.map((item) => ({
        code: item?.index || ' ',
        name: item?.name || ' ',
        ...Array(periodLength)
          .fill(0)
          .reduce((acc, attItm, idx) => {
            const itm = get(item, `periodValues[${idx}]`);
            return {
              ...acc,
              ...(itm?.plan
                ? {
                    [`planTotal${idx}`]: isNumber(itm?.plan?.total)
                      ? prepareNumberForTable(itm?.plan?.total)
                      : '-',
                    [`planFb${idx}`]: isNumber(itm?.plan?.fb)
                      ? prepareNumberForTable(itm?.plan?.fb)
                      : '-',
                    [`planOb${idx}`]: isNumber(itm?.plan?.ob)
                      ? prepareNumberForTable(itm?.plan?.ob)
                      : '-',
                  }
                : {}),
              [`executedTotal${idx}`]: isNumber(itm?.executed?.total)
                ? prepareNumberForTable(itm?.executed?.total)
                : '-',
              [`executedFb${idx}`]: isNumber(itm?.executed?.fb)
                ? prepareNumberForTable(itm?.executed?.fb)
                : '-',
              [`executedOb${idx}`]: isNumber(itm?.executed?.ob)
                ? prepareNumberForTable(itm?.executed?.ob)
                : '-',
            };
          }, {}),
        ...(item?.isBold
          ? {
              options: {
                family: theme.fonts.robotoCondensed.bold,
                styles: {
                  name: {
                    family: theme.fonts.montserrat.bold,
                  },
                },
              },
            }
          : {}),
      }));
    }

    return {
      tableData,
      tableFields,
      tableFieldsCustomTop,
    };
  }, [tableInfo]);

  return (
    <ComponentContainer>
      <Heading>
        <Title>НП</Title>
        <Addition>
          <BudgetModalFilters
            filter={npFilter}
            setFilter={setNPFilterCallback}
            fundingSourceMultiple
          />
        </Addition>
      </Heading>
      {tableDataLoading ? (
        <Loader />
      ) : (
        <Table
          fields={tableConfig.tableFields}
          data={tableConfig.tableData}
          customTheadFields={gpCustomFields}
          customTheadFieldsTop={tableConfig.tableFieldsCustomTop}
          sticky={false}
        />
      )}
    </ComponentContainer>
  );
});

const NameTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ComponentContainer = styled.div``;
const Title = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xxmd,
      family: theme.fonts.montserrat.semibold,
      lineHeight: '34px',
    })};
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Addition = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default NPType;
