import React, { ReactNode, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Pagination } from '../Pagination';
import { Loader } from '../Loader';
import NoData from '../NoData';
import { observer } from 'mobx-react-lite';
import { ListsStoreContext } from '../../stores/lists';
import { FC } from 'react';
import { useFetching } from '../../hooks/useFetching';

interface Props {
  list?: any[];
  renderItem: (el: any, idx: number) => ReactNode;
  pagination?: boolean;
  url?: string;
  emptyPlaceholder?: string | ReactNode;
  uniqKey?: string;
  handleList?: (arr: any[]) => any[];
  gap?: number;
  filter?: any;
  limit?: number;
  listName?: string;
  height?: string;
  maxHeight?: string;
  options?: {
    borderRadius: boolean;
  };
}

const List: FC<Props> = observer(
  ({
    list: listProp = [],
    renderItem,
    pagination = true,
    url,
    emptyPlaceholder = <NoData description={'Нет данных'} />,
    uniqKey = 'id',
    handleList,
    gap = 0,
    // only memoized object
    filter,
    limit = 10,
    listName = '',
    options,
    height,
    maxHeight
  }) => {
    const name = listName || url;
    const ListsStore = useContext(ListsStoreContext);
    const list = ListsStore.data[name] || listProp;
    const meta = ListsStore.meta[name];

    const { loading, fetchData } = useFetching({ filter, limit, url, name });

    useEffect(() => {
      if (name) {
        fetchData();
        ListsStore.setFetch(name, fetchData);
      }
    }, [name, fetchData, ListsStore]);

    if (loading) {
      return <Loader />;
    }

    let listData = list;
    if (handleList) listData = handleList(list);

    return (
      <Wrapper height={height} maxHeight={maxHeight}>
        <Container gap={gap} borderRadius={options?.borderRadius}>
          {listData.length === 0 ? (
            <Empty>{emptyPlaceholder}</Empty>
          ) : (
            <>
              {listData.map((el, idx) => (
                <Item key={`element-${el?.[uniqKey]}`}>{renderItem(el, idx)}</Item>
              ))}
            </>
          )}
        </Container>
        {pagination && meta && Object.keys(meta).length && listData.length !== 0 ? (
          <Pagination fetchData={fetchData} meta={meta} />
        ) : null}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div<{ height?: string; maxHeight: string; }>`
  height: ${({ height }) => (height ? height : 'auto')};
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-between;
  width: 100%;
  max-height: ${({maxHeight}) => maxHeight};
`;

const Item = styled.div``;

const Container = styled.div<{ gap: number; borderRadius: boolean; }>`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: ${({ gap }) => gap}px;
  overflow: auto;
  border-radius: ${({ borderRadius }) => (borderRadius ? '22px 22px 0 0' : '0')};
`;
const Empty = styled.div`
  padding: 30px 30%;
`;

export { List };
