import styled from 'styled-components';
import Select from '../../../../../../components/Select';
import SelectList from '../../../../../../components/Calendar/SelectList';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import SelectMultiple from '../../../../../../components/SelectMultiple';
import { NsiStoreContext } from '../../../../../../stores/nsi';
import { observer } from 'mobx-react-lite';

interface Props {
  filter: any;
  setFilter: any;
}

const DynamicBudgetModalHeader: FC<Props> = observer(({ filter, setFilter }) => {
  const { BudgetCIOGV, FundingSources } = useContext(NsiStoreContext);

  const [listCIOGV, setListCIOGV] = useState(BudgetCIOGV);
  const [listFundingSource, setListFundingSource] = useState(FundingSources);
  const [periodTypeList, setPeriodTypeList] = useState([
    {
      id: 0,
      label: 'За год',
      value: 'year',
      checked: true,
    },
    {
      id: 1,
      label: 'За квартал',
      value: 'quarter',
      checked: false,
    },
    {
      id: 2,
      label: 'За месяц',
      value: 'month',
      checked: false,
    },
  ]);

  const initializeValue = useCallback((list, value) => {
    const valuesArr = value.split(',');
    return list.map((item) =>
      valuesArr.find((itm) => item.id === itm || item.value === itm)
        ? { ...item, checked: true }
        : item,
    );
  }, []);

  //Initialize multiple selects
  useEffect(() => {
    if (BudgetCIOGV && filter?.ciogv) {
      setListCIOGV(initializeValue(BudgetCIOGV, filter?.ciogv));
    }
  }, [BudgetCIOGV, filter]);

  useEffect(() => {
    if (FundingSources && filter?.fundingSource) {
      setListFundingSource(initializeValue(FundingSources, filter?.fundingSource));
    }
  }, [BudgetCIOGV, filter]);

  const handleCIOGVLocalList = ({ check, name }) => {
    const filterList = (list) => {
      return list.map((item) => (item.value === name ? { ...item, checked: check } : item));
    };
    setListCIOGV(filterList(listCIOGV));
  };

  const handleAcceptCIOGVMultipleList = () => {
    const prepareIds = (list) => {
      return list
        .filter((item) => item.checked)
        .map((item) => item.id)
        .join(',');
    };

    setFilter('ciogv', prepareIds(listCIOGV));
  };

  // const onFundingSourcesClick = useCallback((e: any) => {
  //   setFilter('fundingSource', e);
  // }, []);

  const onFundingSourcesClick = useCallback(({ check, name }) => {
    const filterList = (list) => {
      return list.map((item) => (item.value === name ? { ...item, checked: check } : item));
    };
    setListFundingSource(filterList);
  }, []);

  const handleAcceptFundingSourceList = () => {
    const prepareIds = (list) => {
      return list
        .filter((item) => item.checked)
        .map((item) => item.id)
        .join(',');
    };

    setFilter('fundingSource', prepareIds(listFundingSource));
  };

  const onPeriodTypeClick = useCallback((e: any) => {
    setFilter('selectedPeriodType', e);
  }, []);

  return (
    <ComponentContainer>
      <Select
        renderList={({ active, onClose }) => (
          <SelectList
            active={active}
            onClose={onClose}
            list={periodTypeList}
            onClick={onPeriodTypeClick}
          />
        )}
        label={'За год'}
        value={filter?.selectedPeriodType}
        fieldFullsize={false}
      />
      <Select
        renderList={({ active, onClose }) => (
          <SelectMultiple
            onClose={onClose}
            list={listFundingSource}
            onClick={onFundingSourcesClick}
            onAccept={handleAcceptFundingSourceList}
            width={'500px'}
          />
        )}
        label={'Источник финанс-я'}
        value={filter?.fundingSource}
        fieldFullsize={false}
        fullHeight
      />
      <Select
        renderList={({ active, onClose }) => (
          <SelectMultiple
            onClose={onClose}
            list={listCIOGV}
            onClick={(e) => handleCIOGVLocalList(e)}
            onAccept={() => handleAcceptCIOGVMultipleList()}
          />
        )}
        fullHeight
        label={'ЦИОГВ'}
        value={''}
        fieldFullsize={false}
      />
    </ComponentContainer>
  );
});

const ComponentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default DynamicBudgetModalHeader;
