import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Input, InputType } from '../../components/Input';
import { AuthButton } from '../../components/Button';
import { AuthStore, AuthStoreContext } from '../../stores/auth';
import styled from 'styled-components';
import whiteHouse from '../../assets/images/white_house.svg';
import map from '../../assets/images/map_mo.png';
import ProjectLogo from '../../components/ProjectLogo';
import { font } from '../../components/styled/mixins/fonts';
import { observer } from 'mobx-react-lite';
import Welcome from '../Welcome';

const schema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
  deviceId: yup.string(),
});

const AuthPage: FC = observer(() => {
  const { firstAuth, showWelcome } = useContext(AuthStoreContext);
  const ref = useRef(null);
  const timer = useRef(null);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: '',
      password: '',
      deviceId: '',
    },
  });

  useEffect(() => {
    return () => clearTimeout(timer.current);
  }, []);

  const onSubmit = useCallback(async (data) => {
    setLoading(true);
    try {
      await AuthStore.login({
        ...data,
        username: data.username.trim(),
        password: data.password.trim(),
      });
      if ('PasswordCredential' in window) {
        const credential = new (window as any).PasswordCredential({
          id: data.username,
          name: data.username.trim(),
          password: data.password.trim(),
        });

        navigator.credentials.store(credential).then(
          () => {
            console.info("Credential stored in the user agent's credential manager.");
          },
          (err) => {
            console.error('Error while storing the credential: ', err);
          },
        );
      }
    } catch (e) {
      //
    } finally {
      setLoading(false);
    }
  }, []);

  const checkAutofill = useCallback(() => {
    clearTimeout(timer.current);
    if (!firstAuth) {
      timer.current = setTimeout(() => {
        if ([...ref.current.querySelectorAll('input:-webkit-autofill')].length === 2) {
          handleSubmit(onSubmit)();
        }
      }, 200);
    }
  }, [firstAuth]);

  if (showWelcome) {
    return <Welcome />;
  }

  return (
    <ComponentContainer>
      <LogoWrapper>
        <ProjectLogo />
      </LogoWrapper>
      <Title>Авторизация</Title>
      <Form onSubmit={handleSubmit(onSubmit)} onChange={checkAutofill} ref={ref}>
        <Controller
          render={({ field, fieldState }) => {
            return (
              <Input
                {...field}
                {...fieldState}
                placeholder='Логин'
                error={errors?.username?.message}
                autoFocus={true}
              />
            );
          }}
          name={'username'}
          control={control}
        />
        <Controller
          render={({ field, fieldState }) => {
            return (
              <Input
                {...field}
                {...fieldState}
                placeholder='Пароль'
                error={errors?.password?.message}
                type={InputType.PASSWORD}
              />
            );
          }}
          name={'password'}
          control={control}
        />
        <ButtonWrapper>
          <AuthButton type={'submit'} disabled={loading}>
            Войти
          </AuthButton>
        </ButtonWrapper>
      </Form>
      <MapOverlay />
      <BuildingOverlay />
    </ComponentContainer>
  );
});

const ComponentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
`;
const BuildingOverlay = styled.div`
  background: url(${whiteHouse}) no-repeat 50% 100%;
  background-size: auto 100%;
  height: 33%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
`;
const MapOverlay = styled.div`
  background: url(${map}) no-repeat 50% 50%;
  background-size: auto 80%;
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;
const Title = styled.div`
  ${({ theme }) => font({ color: '#fff', size: theme.fonts.sizes.lg })};
  margin-bottom: 24px;
`;
const LogoWrapper = styled.div`
  margin-bottom: 24px;
`;
const Form = styled.form`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 250px;
  > div {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default AuthPage;
