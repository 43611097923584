import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import styled from 'styled-components';
import TextField from '../../../components/TextField';
import { DevelopmentStoreContext } from '../../../stores/development';
import { theme } from '../../../styles/theme';
import { declOfNum } from '../../../utils/declOfNum';

const DevelopmentWorkplacesCollapsedContent = observer(() => {

  const { developmentCollapsedHeaderData: { workplace } } = useContext(DevelopmentStoreContext);
  
  return (
    <ComponentContainer>
      {workplace?.redZoneCount !== 0 &&
        <TextField
          label={'Красная зона'}
          value={`${workplace?.redZoneCount} ${declOfNum(workplace?.redZoneCount, ['территория', 'территории', 'территорий'])}`}
          valueBold
          valueColor={theme.colors.status.overdue}
        />
      }
      {workplace?.greenZoneCount !== 0 &&
        <TextField
          label={'Зеленая зона'}
          value={`${workplace?.greenZoneCount} ${declOfNum(workplace?.greenZoneCount, ['территория', 'территории', 'территорий'])}`}
          valueBold
          valueColor={theme.colors.status.done}
        /> 
      }
    </ComponentContainer>
  );
});

const ComponentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export default DevelopmentWorkplacesCollapsedContent;
