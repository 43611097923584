import { Table } from '../../../../../components/Table';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { getGosDebtDetails } from '../../../../../actions';
import { get, isNumber } from 'lodash';
import Grade from '../../../../../components/Grade';
import { Loader } from '../../../../../components/Loader';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { ViewType } from '../../../BudgetDebtAddition/BudgetDebtModal';
import { AppStoreContext } from '../../../../../stores/app';
import { observer } from 'mobx-react-lite';

interface Props {
  filter: any;
}

const MunicipalDebtTable: FC<Props> = observer(({ filter }) => {
  const { selectedOmsu } = useContext(AppStoreContext);

  const [tableInfo, setTableInfo] = useState(null);
  const [tableDataLoading, setTableDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      if (filter?.selectedYear) {
        setTableDataLoading(true);
        try {
          const response = await getGosDebtDetails({
            omsuId: selectedOmsu.id,
            viewType: ViewType.DEBT_MUNICIPAL,
            periodId: filter?.selectedYear?.id,
          });
          setTableInfo(response.data.items);
        } catch (e) {
          //
        } finally {
          setTableDataLoading(false);
        }
      }
    };
    fetchData();
  }, [filter]);

  const tableConfig = useMemo(() => {
    let tableData = [];
    let tableFields = [];

    if (tableInfo) {
      const periodLength = get(tableInfo, '[0].periodValues', []).length;

      const fillData = (item, idx) => {
        console.log(item);
        return {
          title: item?.name ? `${item?.name}${item?.unit ? ', ' + item.unit : ''}` : ' ',
          ...Array(periodLength)
            .fill(0)
            .reduce((acc, arritm, idx) => {
              const itm = get(item, `periodValues[${idx}]`);
              return {
                ...acc,
                [`period${idx}`]: isNumber(itm?.indicatorValue) ? (
                  <Grade grade={itm?.indicatorValue} />
                ) : (
                  '-'
                ),
              };
            }, {}),
          children: item?.children?.map((mItm, mIdx) => fillData(mItm, mIdx)),
        };
      };

      tableFields = [
        {
          label: 'Наименование',
          field: 'title',
          sorting: false,
          width: '69%',
          options: {
            text: 'left',
            levelStep: 10,
            firstLevelPadding: 0,
            secondLevelPadding: 0,
            headLabelWidth: '100%',
          },
          justifyContent: 'flex-start',
        },
        ...get(tableInfo, '[0].periodValues', [])
          .map((itm, idx) => [
            {
              label: `${format(new Date(itm?.period?.periodStart), 'dd.MM.yyyy', { locale: ru })}`,
              field: `period${idx}`,
              sorting: false,
              width: '6%',
            },
          ])
          .flat(),
      ];

      tableData = tableInfo.map((itm, idx) => fillData(itm, idx));
    }

    return {
      tableData,
      tableFields,
    };
  }, [tableInfo]);

  return (
    <>
      {tableDataLoading ? (
        <Loader />
      ) : (
        <Table fields={tableConfig.tableFields} data={tableConfig.tableData} sticky={false} />
      )}
    </>
  );
});

export default MunicipalDebtTable;
