import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { getIndustrialLand } from '../../../actions';
import Card from '../../../components/Card';
import { useSorting } from '../../../hooks/useSorting';
import { AppStoreContext } from '../../../stores/app';
import { initialIndustrialLandSortingValues } from '../consts';
import { plotsFields } from './plotsFields';
import ComplexTable from '../../../components/ComplexTable';
import { theme } from '../../../styles/theme';
import Text, { TextFamily } from '../../../components/Text';
import TableZone, { ZoneType } from '../../../components/TableZone';
import { declOfNum } from '../../../utils/declOfNum';
import { DevelopmentStoreContext } from '../../../stores/development';

const DevelopmentLandPlots: FC = observer(() => {

  const { selectedOmsu } = useContext(AppStoreContext);
  const { setDevelopmentCollapsedHeaderData } = useContext(DevelopmentStoreContext);

  const { sorting, setSortingCallback } = useSorting({
    initialSortingValues: { ...initialIndustrialLandSortingValues },
    sortingName: 'developmentIndustrialLandSorting',
  });

  const [data, setData] = useState(null);
  const [zones, setZones] = useState(null);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedOmsu) {
      const fetchData = async () => {
        setDataLoading(true);
        try {
          const response = await getIndustrialLand({
            omsuId: selectedOmsu.id,
          });
          setData(response.data.items);
        } catch (e) {
          //
        } finally {
          setDataLoading(false);
        }
      };
      fetchData();
    }
  }, [selectedOmsu]);

  useEffect(() => {
    if (data) {
      const zones = data.reduce(
        (acc, item) => {
          const copy = { ...acc };
          item.zone === ZoneType.RED && copy.red.push(item);
          item.zone === ZoneType.GREEN && copy.green.push(item);
          return copy;
        },
        { red: [], green: [] },
      );
      setZones(zones);
    }
  }, [data]);

  useEffect(() => {
    if (zones) {
      setDevelopmentCollapsedHeaderData('land', {
        notFilled: zones.red.length,
        filled: zones.green.length,
      });
    }
  }, [zones]);

  const tableData = useMemo(() => {
    let result = [];

    if (zones) {
      result = [
        {
          selector: 'land_red',
          title: <TableZone type={ZoneType.NOT_FILLED} />,
          total: (
            <Text
              data={`${zones?.red?.length} ${declOfNum(zones?.red?.length, [
                'территория',
                'территории',
                'территорий',
              ])}`}
              font={TextFamily.ROBOTO_CONDENSED_REGULAR}
              color={theme.colors.dark}
              size={theme.fonts.sizes.sm}
            />
          ),
          options: {
            color: theme.colors.dark,
            colspan: {
              total: 4,
            },
          },
          children: zones.red.map((itm) => {
            return {
              title: itm.omsu?.name || ' ',
              total: isNumber(itm.total) ? itm.total.toString() : '-',
              size: isNumber(itm.size) ? itm.size.toString() : '-',
              occupancy: isNumber(itm.fill) ? itm.fill.toString() : '-',
              infrastructure:  itm.industrial || '-',
              options: {
                styles: {
                  title: {
                    'font-weight': selectedOmsu?.id === itm.omsuId ? 'bold' : 'normal',
                  }
                },
              },
            };
          }),
        },
        {
          selector: 'land_green',
          title: <TableZone type={ZoneType.FILLED} />,
          total: (
            <Text
              data={`${zones?.green?.length} ${declOfNum(zones?.green?.length, [
                'территория',
                'территории',
                'территорий',
              ])}`}
              font={TextFamily.ROBOTO_CONDENSED_REGULAR}
              color={theme.colors.dark}
              size={theme.fonts.sizes.sm}
            />
          ),
          options: {
            colspan: {
              total: 4,
            },
          },
          children: zones.green.map((itm) => ({
            title: itm.omsu?.name || ' ',
            total: isNumber(itm.total) ? itm.total.toString() : '-',
            size: isNumber(itm.size) ? itm.size.toString() : '-',
            occupancy: isNumber(itm.fill) ? itm.fill.toString() : '-',
            infrastructure:  itm.industrial || '-',
            options: {
              styles: {
                title: {
                  'font-weight': selectedOmsu?.id === itm.omsuId ? 'bold' : 'normal',
                }
              },
            },
          })),
        },
      ];
    }

    return result;
  }, [zones, selectedOmsu]);

  return (
    <Card>
      <Content>
        <ComplexTable
          fields={plotsFields}
          data={tableData}
          sorting={sorting}
          setSortingCallback={setSortingCallback}
          loading={dataLoading}
        />
      </Content>
    </Card>
  );
});

const Content = styled.div`
  padding-top: 10px;
`;

const Zone = styled.div<{ type: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ type }) => type};
`;

export default DevelopmentLandPlots;
