import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { getIndustrial } from '../../../actions';
import Card from '../../../components/Card';
import { useSorting } from '../../../hooks/useSorting';
import { AppStoreContext } from '../../../stores/app';
import { theme } from '../../../styles/theme';
import { initialIndustrialSortingValues } from '../consts';
import { industrialFields } from './industrialFields';
import ComplexTable from '../../../components/ComplexTable';
import { declOfNum } from '../../../utils/declOfNum';
import TableZone, { ZoneType } from '../../../components/TableZone';
import Text, { TextFamily } from '../../../components/Text';
import { DevelopmentStoreContext } from '../../../stores/development';

const DevelopmentIndustrial: FC = observer(() => {

  const { selectedOmsu } = useContext(AppStoreContext);
  const { setDevelopmentCollapsedHeaderData } = useContext(DevelopmentStoreContext);

  const { sorting, setSortingCallback } = useSorting({
    initialSortingValues: { ...initialIndustrialSortingValues },
    sortingName: 'developmentIndustrialSorting',
  });

  const [data, setData] = useState(null);
  const [zones, setZones] = useState(null);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedOmsu) {
      const fetchData = async () => {
        setDataLoading(true);
        try {
          const response = await getIndustrial({
            omsuId: selectedOmsu.id,
          });
          setData(response.data.items);
        } catch (e) {
          //
        } finally {
          setDataLoading(false);
        }
      };
      fetchData();
    }
  }, [selectedOmsu]);

  useEffect(() => {
    if (data) {
      const zones = data.reduce(
        (acc, item) => {
          const copy = { ...acc };
          item.zone === ZoneType.RED && copy.red.push(item);
          item.zone === ZoneType.GREEN && copy.green.push(item);
          return copy;
        },
        { red: [], green: [] },
      );
      setZones(zones);
    }
  }, [data]);

  useEffect(() => {
    if (zones) {
      setDevelopmentCollapsedHeaderData('industrial', {
        notFilled: zones.red.length,
        filled: zones.green.length,
      });
    }
  }, [zones]);

  const tableData = useMemo(() => {
    let result = [];

    if (zones) {
      result = [
        {
          selector: 'industrial_parks_red',
          title: <TableZone type={ZoneType.NOT_FILLED} />,
          kpi: (
            <Text
              data={`${zones?.red?.length} ${declOfNum(zones?.red?.length, [
                'территория',
                'территории',
                'территорий',
              ])}`}
              font={TextFamily.ROBOTO_CONDENSED_REGULAR}
              color={theme.colors.dark}
              size={theme.fonts.sizes.sm}
            />
          ),
          options: {
            color: theme.colors.dark,
            colspan: {
              kpi: 4,
            },
          },
          children: zones.red.map((itm) => {
            return {
              title: itm.omsu?.name || ' ',
              kpi: isNumber(itm.kpi) ? itm.kpi.toString() : ' ',
              area: isNumber(itm.area) ? itm.area.toString() : ' ',
              occupancy: isNumber(itm.fill) ? itm.fill.toString() : ' ',
              options: {
                styles: {
                  ...(itm.kpi === 0
                    ? {
                        kpi: {
                          color: theme.colors.emptyData,
                        },
                      }
                    : {}),
                  ...(itm.area === 0
                    ? {
                        area: {
                          color: theme.colors.emptyData,
                        },
                      }
                    : {}),
                  ...(itm.occupancy === 0
                    ? {
                        occupancy: {
                          color: theme.colors.emptyData,
                        },
                      }
                    : {}),
                  title: {
                    'font-weight': selectedOmsu?.id === itm.omsuId ? 'bold' : 'normal',
                  },
                },
              },
            };
          }),
        },
        {
          selector: 'industrial_parks_green',
          title: <TableZone type={ZoneType.FILLED} />,
          kpi: (
            <Text
              data={`${zones?.green?.length} ${declOfNum(zones?.green?.length, [
                'территория',
                'территории',
                'территорий',
              ])}`}
              font={TextFamily.ROBOTO_CONDENSED_REGULAR}
              color={theme.colors.dark}
              size={theme.fonts.sizes.sm}
            />
          ),
          options: {
            colspan: {
              kpi: 4,
            },
          },
          children: zones.green.map((itm) => ({
            title: itm.omsu?.name || ' ',
            kpi: isNumber(itm.kpi) ? itm.kpi.toString() : ' ',
            area: isNumber(itm.area) ? itm.area.toString() : ' ',
            occupancy: isNumber(itm.fill) ? itm.fill.toString() : ' ',
            options: {
              styles: {
                ...(itm.kpi === 0
                  ? {
                      kpi: {
                        color: theme.colors.emptyData,
                      },
                    }
                  : {}),
                ...(itm.area === 0
                  ? {
                      area: {
                        color: theme.colors.emptyData,
                      },
                    }
                  : {}),
                ...(itm.occupancy === 0
                  ? {
                      occupancy: {
                        color: theme.colors.emptyData,
                      },
                    }
                  : {}),
                title: {
                  'font-weight': selectedOmsu?.id === itm.omsuId ? 'bold' : 'normal',
                },
              },
            },
          })),
        },
      ];
    }

    return result;
  }, [zones, selectedOmsu]);

  return (
    <Card>
      <Content>
        <ComplexTable
          fields={industrialFields}
          data={tableData}
          sorting={sorting}
          setSortingCallback={setSortingCallback}
          loading={dataLoading}
        />
      </Content>
    </Card>
  );
});

const Content = styled.div`
  padding-top: 10px;
`;

export default DevelopmentIndustrial;
