import styled from 'styled-components';
import Card from '../../../components/Card';
import { ModalsStore } from '../../../stores/modal';
import PlanModal from './PlanModal';
import CascadeTable from '../../../components/CascadeTable';
import CascadeTableRow from '../../../components/CascadeTable/CascadeTableRow';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { ObjectStoreContext } from '../../../stores/object';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { NsiStoreContext } from '../../../stores/nsi';
import { calculateFileExtension } from '../../../utils/calculateExtension';
import testAvatar from '../../../assets/images/avatar.svg';

const Roadmap = observer(() => {
  const { ObjectData } = useContext(ObjectStoreContext);
  const { ProcedureStatus } = useContext(NsiStoreContext);

  const statuses = ProcedureStatus.reduce((prev, item) => ({ ...prev, [item.id]: item.label }), {});

  const handleMore = (
    e,
    {
      step,
      task,
      status,
      responsibles,
      files,
      current,
      total,
      factResult,
      factDate,
      planResult,
      resonNotPerformance,
      planDate,
      planEndDate,
      correctiveAction,
      deadlineElimination,
    },
  ) => {
    e.stopPropagation();
    ModalsStore.showModal('plan-modal', {
      stage: step,
      status: status,
      title: task,
      roadmapCurrent: current,
      roadmapTotal: total,
      responsible: responsibles,
      files: files,
      planDate,
      planEndDate,
      factResult,
      factDate,
      planResult,
      resonNotPerformance,
      correctiveAction,
      deadlineElimination,
    });
  };

  const calcTableConfig = (procedure, length, level) => {
    return {
      id: procedure?.id,
      status: procedure?.procedureStatusId,
      task: procedure?.name,
      code: procedure.code,
      date:
        [1, 4].includes(procedure.procedureStatusId) &&
        procedure.factDate &&
        new Date(procedure.factDate).getFullYear() !== 1
          ? format(new Date(procedure.factDate), 'd MMMM yyyy', { locale: ru })
          : [2, 3, 5].includes(procedure.procedureStatusId) &&
            procedure.planDate &&
            new Date(procedure.planDate).getFullYear() !== 1
          ? format(new Date(procedure.planDate), 'd MMMM yyyy', { locale: ru })
          : '-',
      factDate:
        procedure.factDate && new Date(procedure.factDate).getFullYear() !== 1
          ? format(new Date(procedure.factDate), 'd MMMM yyyy', { locale: ru })
          : '-',
      factResult: procedure?.factResult,
      planDate:
        procedure.planDate && new Date(procedure.planDate).getFullYear() !== 1
          ? format(new Date(procedure.planDate), 'd MMMM yyyy', { locale: ru })
          : '-',
      planEndDate:
        procedure.planEndDate && new Date(procedure.planEndDate).getFullYear() !== 1
          ? format(new Date(procedure.planEndDate), 'd MMMM yyyy', { locale: ru })
          : '-',
      planResult: procedure.planResult,
      files: procedure.documents
        ? procedure.documents.map((document, idx) => {
            let link = document.DocumentLink;
            if (!document.IsUrl) {
              link = document.DocumentLink.replace('http://', 'https://');
            }
            return {
              id: idx,
              name: document.DocumentName,
              type: calculateFileExtension(document.ContentType),
              size: document.FileSizeKb,
              link,
              path: link,
            };
          })
        : [],
      responsibles: procedure.responsiblePerformers
        ? procedure.responsiblePerformers.map((person) => {
            return {
              id: person?.id,
              name: person?.fio,
              avatar: testAvatar,
              contact: person?.email,
            };
          })
        : [],
      resonNotPerformance: procedure?.resonNotPerformance,
      correctiveAction: procedure?.correctiveAction,
      deadlineElimination:
        procedure.deadlineEliminatio && new Date(procedure.deadlineEliminatio).getFullYear() !== 1
          ? format(new Date(procedure.planDate), 'd MMMM yyyy', { locale: ru })
          : '-',
      subtasks: procedure.children
        ? procedure.children
            .map((item) => calcTableConfig(item, procedure?.children?.length, level + 1))
            .sort((item1, item2) => item1.sort > item2.sort)
        : null,
      lastIndex: length,
      level: level,
    };
  };

  const tableConfig = useMemo(() => {
    return (ObjectData?.procedures || []).map((procedure) =>
      calcTableConfig(procedure, ObjectData?.procedures?.length, 1),
    );
  }, [ObjectData]);

  return (
    <>
      <Card>
        <TableWrapper>
          <CascadeTable config={tableConfig}>
            {(item, idx) => {
              return (
                <CascadeTableRow
                  inColumn={[]}
                  lastRow={tableConfig.length - 1 === idx}
                  depth={1}
                  key={item.id}
                  step={idx + 1}
                  {...item}
                  handleMore={handleMore}
                />
              );
            }}
          </CascadeTable>
        </TableWrapper>
      </Card>
      <PlanModal />
    </>
  );
});

const TableWrapper = styled.div`
  padding: 8px 0;
`;

export default Roadmap;
