import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { font } from '../../../styled/mixins/fonts';
import SortUp from '../../../../assets/icons/SortUp';
import SortDown from '../../../../assets/icons/SortDown';

interface Props {
  item: any;
  lastRow: boolean;
  order: boolean;
  sorting?: any;
  setSortingCallback?: (item) => void;
  haveSortingCallback?: boolean;
}

const HeaderCell: FC<Props> = ({ item, lastRow, order, sorting, setSortingCallback, haveSortingCallback }) => {
  const selected = item && sorting.field === item.field;
  return (
    <Cell
      width={item.width}
      options={item.options}
      colSpan={item.colspan}
      lastRow={lastRow}
      order={order}
      onClick={() => item?.sorting && setSortingCallback(item)}
      cursor={item?.sorting && haveSortingCallback}
      align='center'
    >
      <CellWrapper>
        <THeadCellLabel>{item.label}</THeadCellLabel>
        {item.sorting && (
          <Sorting>
            {selected &&
              item.sorting &&
              (sorting.direction === 'DESC' ? (
                <SortDown />
              ) : sorting.direction === 'ASC' ? (
                <SortUp />
              ) : (
                <></>
              ))}
          </Sorting>
        )}
      </CellWrapper>
    </Cell>
  );
};

export const generateStyles = ({ options = {} }) => {
  const styles = Object.keys(options).map((item) => `${item}: ${options[item]}`);
  return css`
    ${styles.join(';')}
  `;
};

const CellWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Cell = styled.th<{
  options: any;
  lastRow: boolean;
  width: string;
  order: boolean;
  cursor: boolean;
}>`
  ${({lastRow}) => lastRow && css`
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  `};
  position: relative;
  width: ${({ width }) => width};
  ${({ theme }) =>
    font({
      color: theme.colors.dark,
      family: theme.fonts.robotoCondensed.regular,
      size: theme.fonts.sizes.sm,
    })};
  font-weight: normal;
  ${generateStyles};
  vertical-align: ${({ lastRow }) => (lastRow ? 'middle' : 'bottom')};
  height: ${({ lastRow }) => (lastRow ? '28px' : '22px')};
  padding: 0 6px;
  ${({ order, width }) =>
    order &&
    css`
    &:nth-child(2) {
        width: calc(${width} - 60px)};
    }
  `};
  cursor: ${({ cursor }) => (cursor ? 'pointer' : 'initial')};
`;

const THeadCellLabel = styled.span`
  flex: 1 1 auto;
`;
const Sorting = styled.div`
  //position: absolute;
  width: 24px;
  height: 24px;
  //right: -18px;
  //top: 0;
`;

export default HeaderCell;
