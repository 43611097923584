import styled from 'styled-components';
import { FC } from 'react';
import IconButtonAction from '../IconButtonAction';
import { font } from '../styled/mixins/fonts';

interface Props {
  label: string;
  text: string;
  content?: any;
  icon?: any;
  labelWidth?: number;
}

export const renderText = (text) => {
  if (text) {
    const arrText = text.split(' ');
    arrText.splice(2, 0, <br />);
    return arrText.map((el, idx) => (idx === 2 ? el : el + ' '));
  }
  return null;
};

const LabeledText: FC<Props> = ({ label, text, content, icon, labelWidth = 122 }) => {
  return (
    <LabeledTextWrapper tooltip={!!content}>
      <Label width={labelWidth}>{label}:</Label>
      <TextWrapper>
        {text && <Text>{text}</Text>}
        {text && content && <IconButtonAction icon={icon} content={content} />}
      </TextWrapper>
    </LabeledTextWrapper>
  );
};

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
`;

const LabeledTextWrapper = styled.div<{ tooltip: boolean }>`
  display: flex;
  align-items: ${({ tooltip }) => (tooltip ? 'center' : 'flex-start')};
`;
const Label = styled.div<{ width: number }>`
  ${font()};
  color: rgba(0, 0, 0, 0.56);
  margin-right: 12px;
  width: ${({ width }) => width}px;
  flex: 0 0 ${({ width }) => width}px;
  text-align: right;
  white-space: break-spaces;
`;
const Text = styled.div`
  ${font()};
  white-space: break-spaces;
`;

export default LabeledText;
