import styled from 'styled-components';
import Select from '../../../../../components/Select';
import SelectList from '../../../../../components/Calendar/SelectList';
import { FC, useCallback, useContext, useEffect } from 'react';
import { NsiStoreContext } from '../../../../../stores/nsi';

interface Props {
  filter: any;
  setFilter: (name: any, value: any) => void;
}

const BudgetMunicipalDebtModalHeader: FC<Props> = ({ filter, setFilter }) => {

  const { years } = useContext(NsiStoreContext);

  useEffect(() => {
    if (
      years?.length &&
      !filter.selectedYear
    ) {
      const currentYear =
        years.find((el) => el.appliesToYear === new Date().getFullYear()) || years[0];
      onYearClick(currentYear);
    }
  }, [years, filter.selectedYear]);

  const onYearClick = useCallback(
    (e: any) => {
      setFilter('selectedYear', e);
    },
    [],
  );

  return (
    <ComponentContainer>
      <Select
        renderList={({ active, onClose }) => (
          <SelectList active={active} onClose={onClose} list={years} onClick={onYearClick} />
        )}
        label={''}
        value={filter.selectedYear}
        fieldFullsize={false}
      />
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export default BudgetMunicipalDebtModalHeader;
