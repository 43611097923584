import { FC, ReactNode } from 'react';

interface Props {
  list: {
    id: number;
    name: string;
    avatar: string;
    contact: string;
  }[];
  order: (a: any, b: any) => number;
  children: (item: any) => ReactNode;
}

const OrderedList: FC<Props> = ({ list, order, children }) => {
  return <>{list.sort(order).map((item) => children(item))}</>;
};

export default OrderedList;
