import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { Router } from 'react-router-dom';
import { theme } from './styles/theme';
import { GlobalStyles } from './styles/globalStyles';
import { history } from './routes/history';
import Routes from './routes';
import './tooltip.css';
import './fonts.css';
import './toast.css';
import { ToastContainer } from 'react-toastify';
import { AnchorPopup } from './components/AnchorPopup';
import RatingModal from "./pages/Agenda/AgendaRatingAddition/RatingModal";
const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Failed to find the root element');
}
const root = ReactDOM.createRoot(rootElement);
root.render(
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <Router history={history}>
      <Routes />
      <RatingModal />
    </Router>
    <ToastContainer position='bottom-center' theme='dark' limit={1} />
    <AnchorPopup horizontalAlign={'right'} />
  </ThemeProvider>,
);

if (navigator.serviceWorker && location.hostname !== 'localhost') {
  try {
    navigator.serviceWorker.register('./serviceWorker.js');
  } catch (e) {
    console.log('Service Worker failed!');
  }
}
