import Select from '../../../components/Select';
import { observer } from 'mobx-react-lite';
import SelectList from '../../../components/Calendar/SelectList';
import styled from 'styled-components';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IndicatorStoreContext } from '../../../stores/indicator';
import SelectAccordeonList from '../../../components/SelectAccordeonList';
import { find, groupBy, isEmpty, last } from 'lodash';
import { getIndicatorLevelPeriods, getIndicatorLevels } from '../../../actions';
import { getQueryParameterByName } from '../../../utils/getQueryParameterByName';
import { useLocation, useParams } from 'react-router-dom';
import { ru } from 'date-fns/locale';

enum PeriodTypesId {
  YEAR = 1,
  QUARTER = 3,
  MONTH = 4,
  DAY = 5,
}
interface Params {
  indicatorId: string;
}

const IndicatorHeaderAddition = observer(() => {
  const location = useLocation();
  const params = useParams<Params>();
  const { indicatorHeaderFilters, setIndicatorHeaderFilter, clearIndicatorFilters } = useContext(IndicatorStoreContext);

  const [loadingLevels, setLoadingLevels] = useState<boolean>();
  const [periodTypes, setPeriodTypes] = useState();
  const [loadingLevelPeriods, setLoadingLevelPeriods] = useState<boolean>();
  const [levelPeriods, setLevelPeriods] = useState();

  useEffect(() => {
      const fetchData = async () => {
        setLoadingLevels(true);
        try {
          const omsuId = getQueryParameterByName(location.search, 'omsuId');
          const periodId = getQueryParameterByName(location.search, 'periodId');
          const response = await getIndicatorLevels({
            omsuId,
            indicatorId: params?.indicatorId,
            periodId
          });
          
          const adaptedData = response?.data?.items?.map(itm => ({...itm, label: itm?.name, value: itm?.id}));

          setPeriodTypes(adaptedData);

          if(adaptedData && !isEmpty(adaptedData)){
            setIndicatorHeaderFilter('periodType', find(adaptedData, { isLast: true }) || adaptedData[0]);
          }else{
            setIndicatorHeaderFilter('periodType', null);
            setIndicatorHeaderFilter('period', null);
          }

        } catch (e) {
          //
        } finally {
          setLoadingLevels(false);
        }
      };
      fetchData();
  }, []);

  useEffect(() => {
    if(indicatorHeaderFilters?.periodType?.value){
      const fetchData = async () => {
        setLoadingLevelPeriods(true);
        try {
          const omsuId = getQueryParameterByName(location.search, 'omsuId');
          const periodId = getQueryParameterByName(location.search, 'periodId');
          const response = await getIndicatorLevelPeriods({
            omsuId,
            indicatorId: params?.indicatorId,
            periodId,
            level: indicatorHeaderFilters?.periodType?.value
          });

          const adaptedData = response?.data?.items?.map(itm => ({...itm, label: itm?.name, value: itm?.id}));

          setLevelPeriods(adaptedData);

          if(adaptedData && !isEmpty(adaptedData)){
            setIndicatorHeaderFilter('period', find(adaptedData, { isLast: true }) || last(adaptedData))
          }else{
            setIndicatorHeaderFilter('period', null);
          }

        } catch (e) {
          //
        } finally {
          setLoadingLevelPeriods(false);
        }
      };
      fetchData();
    }
  }, [indicatorHeaderFilters.periodType])

  useEffect(() => {
    return () => {
      clearIndicatorFilters();
    }
  }, [])

  const prepareQuarterList = useCallback((values) => {
    const groupedQuarters = groupBy(values, 'appliesToYear');
    return Object.keys(groupedQuarters).map((key, idx) => ({
      id: idx,
      label: key,
      children: [...groupedQuarters[key]]
    }))
  }, [])

  const prepareDayList = useCallback((values) => {
    const fillData = (values, level) => {
      const grouped = groupBy(values, level === 0 ? 'appliesToYear' : 'appliesToMonth');
      return Object.keys(grouped).map((key, idx) => ({
        id: idx,
        label: level === 1 ? ru.localize.month(parseInt(key) - 1, { width: "wide" }) : key,
        children: level < 1 ? fillData(grouped[key], level+1) : grouped[key]
      }))
    }

    return (fillData(values, 0))
  }, [])

  const daysList = useMemo(() => {
    if(indicatorHeaderFilters?.periodType?.value === PeriodTypesId.DAY){
      return prepareDayList(levelPeriods)
    }
  }, [indicatorHeaderFilters?.periodType, levelPeriods])

  const handleChange = useCallback((item) => {
    setIndicatorHeaderFilter('periodType', item);
  }, []);

  const renderList = useCallback(({ active, onClose }) => {
      switch (indicatorHeaderFilters?.periodType?.value) {
        case PeriodTypesId.DAY: return (
          <SelectAccordeonList active={active} list={daysList} onClick={(item) => setIndicatorHeaderFilter('period', item)} />
        )
        case PeriodTypesId.QUARTER: return (
          <SelectAccordeonList active={active} list={prepareQuarterList(levelPeriods)} onClick={(item) => setIndicatorHeaderFilter('period', item)} />
        )
        default: return (
          <SelectList active={active} onClose={onClose} list={levelPeriods} onClick={(item) => setIndicatorHeaderFilter('period', item)} />
        )
      }
  }, [indicatorHeaderFilters?.periodType, levelPeriods])

  return (
    <ComponentContainer>
      {!loadingLevels && !isEmpty(periodTypes) &&
        <Select
          renderList={({ active, onClose }) => (
            <SelectList active={active} onClose={onClose} list={periodTypes} onClick={handleChange} />
          )}
          label={''}
          value={indicatorHeaderFilters?.periodType}
          fieldFullsize={false}
        />
      }
      {!loadingLevelPeriods && !isEmpty(levelPeriods) &&
        <Select
          renderList={renderList}
          label={''}
          value={indicatorHeaderFilters?.period}
          fieldFullsize={false}
        />
      }
    </ComponentContainer>
  );
});

const ComponentContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export default IndicatorHeaderAddition;
