import styled from 'styled-components';
import { ReactElement } from 'react';
import { FC } from 'react';

interface Props {
  children: ReactElement;
  action?: () => void;
  rotate?: number;
  opacity?: number;
  disabled?: boolean;
  size?: number;
  color?: string;
}

const Icon: FC<Props> = ({
  children,
  action,
  rotate = 0,
  disabled = false,
  opacity = 0.56,
  size = 24,
  color = '#000',
}) => {
  return (
    <IconWrapper
      rotate={rotate}
      hover={!!action}
      onClick={action ? action : undefined}
      opacity={opacity}
      disabled={disabled}
      size={size}
      color={color}
    >
      {children}
    </IconWrapper>
  );
};

const IconWrapper = styled.div<{
  rotate: number;
  hover: boolean;
  opacity: number;
  disabled: boolean;
  size: number;
}>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ hover }) => hover && 'pointer'};
  transform: rotate(${({ rotate }) => rotate}deg);
  flex: 0 0 auto;
  font-size: 0;
  svg {
    fill: ${({ color }) => color};
    fill-opacity: ${({ opacity, disabled }) => (disabled ? 0.3 : opacity)};
  }
  &:active {
    svg {
      fill: ${({ theme, color }) => (color ? color : theme.colors.primary)};
    }
  }
`;

export default Icon;
