import { ChangeEventHandler, FC, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { font } from '../styled/mixins/fonts';

interface Props {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder: string;
  disabled?: boolean;
  autoFocus?: boolean;
}

const InputField: FC<Props> = ({ value, onChange, placeholder, disabled, autoFocus }) => {
  const [active, setActive] = useState(false);

  const handleFocus = (status) => {
    setActive(status);
  };

  const inputRef = useRef(null);

  const focusToInput = useCallback(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (autoFocus) {
      focusToInput();
    }
  }, [autoFocus, focusToInput]);

  return (
    <ComponentContainer active={active}>
      <input
        ref={inputRef}
        type='text'
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={() => handleFocus(true)}
        onBlur={() => handleFocus(false)}
        disabled={disabled}
      />
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div<{ active: boolean }>`
  input {
    flex: 1 1 auto;
    ${font({})};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid
      ${({ theme, active }) => (active ? theme.colors.primary : theme.colors.fieldBorder)};
    padding: 6px 8px 6px 12px;
    border-radius: ${({ theme }) => theme.styles.borderRadius};
    cursor: pointer;
    background-color: #fff;
    position: relative;
    outline: none;
    ${({ active }) =>
      active &&
      css`
        box-shadow: 0 0 0 4px rgba(23, 43, 117, 0.12);
      `}
  }
`;

export default InputField;
