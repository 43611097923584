import styled from 'styled-components';
import { FC } from 'react';
import { font } from '../../styled/mixins/fonts';
import { prepareNumber } from '../../../utils/prepareNumber';
import { isNumber } from 'lodash';

interface Props {
  label: string;
  data: {
    amount: number;
    color: string;
    overlayAmount?: number;
    hideOverlayAmount?: boolean;
  };
  width: number;
}

const BarItem: FC<Props> = ({ label, data, width }) => {
  return (
    <BarItemWrapper>
      <Label>{label}</Label>
      <Bar width={width} color={data.color}>
        <Amount strokeColor={data.color}>{prepareNumber(data.amount, 1)}</Amount>
        {isNumber(data.overlayAmount) && (
          <Overlay width={Number(data.amount) / data.overlayAmount} strokeColor={data.color}>
            {!data.hideOverlayAmount && prepareNumber(data.overlayAmount, 1)}
          </Overlay>
        )}
      </Bar>
    </BarItemWrapper>
  );
};

const BarItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const Label = styled.div`
  width: 79px;
  flex: 0 0 auto;
  text-align: right;
  font-size: 14px;
  line-height: 18px;
  font-family: ${({ theme }) => theme.fonts.montserrat.medium};
  color: rgba(0, 0, 0, 0.56);
  margin-right: 12px;
`;
const Bar = styled.div<{ width: number; color: string }>`
  background-color: ${({ color }) => color};
  height: 40px;
  width: ${({ width }) => (width ? `calc(100% / ${width} - 91px)` : '0')};
  padding: 8px 12px;
  text-align: right;
  position: relative;
`;
const Amount = styled.span<{ strokeColor: string }>`
  text-align: right;
  position: relative;
  z-index: 1;
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xxmd,
      family: theme.fonts.montserrat.bold,
      lineHeight: '24px',
      color: '#fff',
    })};
  letter-spacing: 0.02em;
  text-shadow: ${({ strokeColor }) =>
    `-2px 0 ${strokeColor}, 0 2px ${strokeColor}, 2px 0 ${strokeColor}, 0 -2px ${strokeColor}`};
`;
const Overlay = styled.div<{ strokeColor: string; width: number | string }>`
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 12px;
  height: 100%;
  width: ${({ width }) => (width ? `calc(100% / ${width})` : '0')};
  display: flex;
  background: repeating-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0) 0px,
    rgba(255, 255, 255, 0) 10px,
    rgba(255, 255, 255, 1) 10px,
    rgba(255, 255, 255, 1) 13px,
    rgba(255, 255, 255, 0) 13px
  );
  align-items: center;
  text-align: right;
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xxmd,
      family: theme.fonts.montserrat.bold,
      lineHeight: '24px',
      color: '#fff',
    })};
  letter-spacing: 0.02em;
  text-shadow: ${({ strokeColor }) =>
    `-2px 0 ${strokeColor}, 0 2px ${strokeColor}, 2px 0 ${strokeColor}, 0 -2px ${strokeColor}`};
`;

export default BarItem;
