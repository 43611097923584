import { PageWrapper } from '../../components/styled/PageWrapper';
import Accordion from '../../components/Accordion';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AppStoreContext } from '../../stores/app';
import BudgetSummaryInfo from './BudgetSummaryInfo';
import BudgetExecutionAddition from './BudgetExecutionAddition';
import BudgetExecution from './BudgetExecution';
import { v4 as uuidv4 } from 'uuid';
import BudgetIncomeAddition from './BudgetIncomeAddition';
import BudgetIncome from './BudgetIncome';
import BudgetExpensesAddition from './BudgetExpensesAddition';
import BudgetExpenses from './BudgetExpenses';
import { NsiStoreContext } from '../../stores/nsi';
import BudgetDebtAddition from './BudgetDebtAddition';
import BudgetDebt from './BudgetDebt';
import { useFilter } from '../../hooks/useFilter';
import {
  initialBudgetIncomeAdditionFilterValues,
  initialExecutionBudgetFilterValues,
  initialDebtFilterValues
} from './consts';
import BudgetMunicipalDebtAddition from './BudgetMunicipalDebtAddition';
import BudgetMunicipalDebt from './BudgetMunicipalDebt';

const BudgetPage: FC = observer(() => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const { currentMonth } = useContext(NsiStoreContext);

  const [localSelectedOMSU, setLocalSelectedOMSU] = useState(null);

  const debtFilter = useFilter({
    initialFilterValues: {...initialDebtFilterValues},
    name: 'debtFilters',
  });

  const municipalDebtFilter = useFilter({
    initialFilterValues: {...initialDebtFilterValues},
    name: 'municipalDebtFilters',
  });

  const summaryInfo = useMemo(() => {
    return {
      id: uuidv4(),
      header: {
        title: 'Сводная информация',
      },
      content: <BudgetSummaryInfo />,
    };
  }, []);

  const { filter: incomeFilter, setFilterCallback: setIncomeFilterCallback } = useFilter({
    initialFilterValues: { ...initialBudgetIncomeAdditionFilterValues },
    name: 'incomeAdditionBudgetFilter',
    dateFields: ['fromDate', 'toDate'],
  });

  const { filter: expenseFilter, setFilterCallback: setExpenseFilterCallback } = useFilter({
    initialFilterValues: { ...initialBudgetIncomeAdditionFilterValues },
    name: 'expenseAdditionBudgetFilter',
    dateFields: ['fromDate', 'toDate'],
  });

  const { filter: executionBudgetFilter, setFilterCallback: setExecutionBudgetFilter } = useFilter({
    initialFilterValues: { ...initialExecutionBudgetFilterValues },
    name: 'executionBudgetFilter',
    dateFields: ['fromDate', 'toDate'],
  });

  useEffect(() => {
    if (currentMonth) {
      if (!executionBudgetFilter.selectedMonth) {
        setExecutionBudgetFilter('selectedMonth', currentMonth);
      }
    }
  }, [currentMonth]);

  useEffect(() => {
    if (selectedOmsu) {
      setIncomeFilterCallback(
        'selectedLevel',
        initialBudgetIncomeAdditionFilterValues.selectedLevel,
      );
    }
  }, [selectedOmsu]);

  useEffect(() => {
    if (selectedOmsu) {
      setExpenseFilterCallback(
        'selectedLevel',
        initialBudgetIncomeAdditionFilterValues.selectedLevel,
      );
    }
  }, [selectedOmsu]);

  useEffect(() => {
    if (selectedOmsu && localSelectedOMSU && selectedOmsu.id !== localSelectedOMSU.id) {
      setIncomeFilterCallback('selectedOmsu', '');
      setExpenseFilterCallback('selectedOmsu', '');
    }
    setLocalSelectedOMSU(selectedOmsu);
  }, [selectedOmsu]);

  const executionBudget = useMemo(() => {
    if (selectedOmsu?.idx === 0) {
      return {
        id: uuidv4(),
        header: {
          title: 'Исполнение бюджета',
          addition: (
            <BudgetExecutionAddition
              filter={executionBudgetFilter}
              setFilter={setExecutionBudgetFilter}
            />
          ),
        },
        content: <BudgetExecution filter={executionBudgetFilter} />,
      };
    }
    return null;
  }, [selectedOmsu?.idx, executionBudgetFilter, setExecutionBudgetFilter]);

  const incomeBudget = useMemo(() => {
    return {
      id: uuidv4(),
      header: {
        title: 'Доходы',
        addition: (
          <BudgetIncomeAddition filter={incomeFilter} setFilterCallback={setIncomeFilterCallback} />
        ),
      },
      content: <BudgetIncome filter={incomeFilter} />,
    };
  }, [incomeFilter, setIncomeFilterCallback]);

  const expenseBudget = useMemo(() => {
    return {
      id: uuidv4(),
      header: {
        title: 'Расходы',
        addition: (
          <BudgetExpensesAddition
            filter={expenseFilter}
            setFilterCallback={setExpenseFilterCallback}
          />
        ),
      },
      content: <BudgetExpenses filter={expenseFilter} />,
    };
  }, [expenseFilter, setExpenseFilterCallback]);

  const debtBudget = useMemo(() => {
    if (selectedOmsu?.idx === 0) {
      return {
        id: uuidv4(),
        header: {
          title: 'Госдолг',
          addition: (
            <BudgetDebtAddition
              filter={debtFilter.filter}
              setFilter={debtFilter.setFilterCallback}
              setMassFilterCallback={debtFilter.setMassFilterCallback}
            />
          ),
        },
        content: (
          <BudgetDebt
            filter={debtFilter.filter}
          />
        ),
      };
    }
    return null;
  }, [selectedOmsu?.idx, debtFilter.filter, debtFilter.setFilterCallback, debtFilter.setMassFilterCallback]);

  const municipalDebtBudget = useMemo(() => {
    if (selectedOmsu?.idx !== 0) {
      return {
        id: uuidv4(),
        header: {
          title: 'Муниципальный долг',
          addition: (
            <BudgetMunicipalDebtAddition
              filter={municipalDebtFilter.filter}
              setFilter={municipalDebtFilter.setFilterCallback}
              setMassFilterCallback={municipalDebtFilter.setMassFilterCallback}
            />
          ),
        },
        content: (
          <BudgetMunicipalDebt
            filter={municipalDebtFilter.filter}
          />
        ),
      };
    }
    return null;
  }, [selectedOmsu?.idx, municipalDebtFilter.filter, municipalDebtFilter.setFilterCallback, municipalDebtFilter.setMassFilterCallback]);

  const items = useMemo(() => {
    const arr = [summaryInfo, incomeBudget, expenseBudget];
    if (executionBudget) {
      arr.push(executionBudget);
    }
    if (debtBudget) {
      arr.push(debtBudget);
    }
    if (municipalDebtBudget) {
      arr.push(municipalDebtBudget);
    }
    return arr;
  }, [summaryInfo, executionBudget, incomeBudget, expenseBudget, debtBudget, municipalDebtBudget]);

  return (
    <PageWrapper>
      <Accordion items={items} />
    </PageWrapper>
  );
});

export default BudgetPage;
