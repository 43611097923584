import { FC } from 'react';
import styled from 'styled-components';
import { font } from '../styled/mixins/fonts';
import { StatusType } from '../Status';

interface Props {
  step: number;
  progress: number;
  status: StatusType;
}

const Progress: FC<Props> = ({ step, progress, status }) => {
  const calculateProgress = (progress) => {
    switch (progress) {
      case StatusType.DONE:
        return {
          color: '#27AE60',
          background: 'rgba(39, 174, 96, .12)',
        };
      case StatusType.PROGRESS:
        return {
          color: '#2F80ED',
          background: 'rgba(47, 128, 237, .12)',
        };
      case StatusType.PLANNED:
        return {
          color: 'transparent',
          background: 'rgba(0, 0, 0, 0.3)',
        };
      case StatusType.DONE_WITH_DELAY:
        return {
          color: '#F2994A',
          background: 'rgba(242, 153, 74, .12)',
        };
      case StatusType.OVERDUE:
        return {
          color: '#EB5757',
          background: 'rgba(235, 87, 87, .12)',
        };
      default:
        return {
          color: 'rgba(0, 0, 0, 0.3)',
          background: 'rgba(0, 0, 0, 0.3)',
        };
    }
  };

  const config = calculateProgress(status);

  return (
    <ComponentContainer>
      <Overlay color={config.background}>
        <Circle progress={progress} color={config.color}>
          <svg
            id='svg'
            width='28'
            height='28'
            viewBox='0 0 28 28'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle
              id='bar'
              r='12'
              cx='14'
              cy='14'
              fill='transparent'
              strokeDasharray='75'
            ></circle>
          </svg>
        </Circle>
        <Step color={config.color}>{step}</Step>
      </Overlay>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  position: relative;
`;

const Overlay = styled.div`
  margin: 2px;
  flex: 1 1 auto;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Circle = styled.div<{ progress: number; color: string }>`
  position: absolute;
  right: 6px;
  top: 6px;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  width: 28px;
  height: 28px;
  svg {
    circle {
      transition: stroke-dashoffset 1s linear;
      stroke: ${({ color }) => color};
      stroke-width: 3px;
      stroke-dashoffset: ${({ progress }) => `calc(((100 - ${progress}) / 100) * 3.14 * (12 * 2))`};
    }
  }
`;
const Step = styled.div`
  ${({ theme, color }) => font({ family: theme.fonts.montserrat.bold, color })};
  background-color: #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Progress;
