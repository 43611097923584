import styled from 'styled-components';
import { format } from 'date-fns';
import { FC } from 'react';

interface Props {
  day: Date;
  disabled: boolean;
  isAfter: boolean;
  isCurrentMonth: boolean;
  isSelected: boolean;
  isToday: boolean;
  row: number;
  weekday: number;
}

const CalendarCell: FC<Props> = ({ day, isCurrentMonth }) => {
  return (
    <CalendarCellWrapper isCurrentMonth={isCurrentMonth}>{format(day, 'd')}</CalendarCellWrapper>
  );
};

const CalendarCellWrapper = styled.div<{ isCurrentMonth: boolean }>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.montserrat.medium};
  border-radius: 50%;
  color: ${({ isCurrentMonth }) => (isCurrentMonth ? '#000' : 'rgba(0, 0, 0, 0.3)')};
  &:hover {
    background-color: ${({ theme }) => theme.colors.hover};
  }
`;

export default CalendarCell;
