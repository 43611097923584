import ColoredText from '../../../../components/ColoredText';
import { StatusType } from '../../../../components/Status';
import { theme } from '../../../../styles/theme';

export const ratingConfig = [
  {
    id: 0,
    title: 'Оценка населением органов власти',
    subtitle: '',
    list: [
      {
        id: 0,
        data: [
          {
            id: 0,
            info: 'Уровень доверия населения органам власти, %',
          },
          {
            id: 1,
            info: 'ГУТП',
          },
          {
            id: 2,
            info: <ColoredText text={23} type={StatusType.DONE_WITH_DELAY} />,
          },
        ],
      },
      {
        id: 1,
        data: [
          {
            id: 0,
            info: 'Уровень информированности населения в СМИ, ед./ чел.',
          },
          {
            id: 1,
            info: '',
          },
          {
            id: 2,
            info: <ColoredText text={1} type={StatusType.DONE} />,
          },
        ],
      },
      {
        id: 2,
        data: [
          {
            id: 0,
            info: 'Уровень информированности населения в соц. сетях, балл',
          },
          {
            id: 1,
            info: 'ГУИП',
          },
          {
            id: 2,
            info: <ColoredText text={1} type={StatusType.DONE} />,
          },
        ],
      },
    ],
  },
  {
    id: 1,
    title: 'Экономика',
    subtitle: '',
    list: [
      {
        id: 0,
        data: [
          {
            id: 0,
            info: 'Объем инвестиций на душу населения, тыс. руб.',
          },
          {
            id: 1,
            info: 'МИПН',
          },
          {
            id: 2,
            info: <ColoredText text={13} type={StatusType.DONE} />,
          },
        ],
      },
      {
        id: 1,
        data: [
          {
            id: 0,
            info: 'Уровень информированности населения в СМИ, ед./ чел.',
          },
          {
            id: 1,
            info: 'МИПН',
          },
          {
            id: 2,
            info: <ColoredText text={1} type={StatusType.DONE} />,
          },
        ],
      },
      {
        id: 2,
        data: [
          {
            id: 0,
            info: 'Уровень информированности населения в соц. сетях, балл',
          },
          {
            id: 1,
            info: 'МИПН',
          },
          {
            id: 2,
            info: <ColoredText text={10} type={StatusType.DONE} />,
          },
        ],
      },
      {
        id: 3,
        data: [
          {
            id: 0,
            info: 'Уровень информированности населения в соц. сетях, балл',
          },
          {
            id: 1,
            info: 'МИПН',
          },
          {
            id: 2,
            info: <ColoredText text={10} type={StatusType.DONE} />,
          },
        ],
      },
      {
        id: 4,
        data: [
          {
            id: 0,
            info: 'Уровень информированности населения в соц. сетях, балл',
          },
          {
            id: 1,
            info: 'МИПН',
          },
          {
            id: 2,
            info: <ColoredText text={11} type={StatusType.DONE} />,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: 'Государственные и муниципальные услуги',
    subtitle: '',
    list: [
      {
        id: 0,
        data: [
          {
            id: 0,
            info: 'Уровень информированности населения в СМИ, ед./ чел.',
          },
          {
            id: 1,
            info: 'Мингос',
          },
          {
            id: 2,
            info: <ColoredText text={41} type={StatusType.DONE_WITH_DELAY} />,
          },
        ],
      },
      {
        id: 1,
        data: [
          {
            id: 0,
            info: 'Уровень информированности населения в соц. сетях, балл/ чел.',
          },
          {
            id: 1,
            info: 'МИПН',
          },
          {
            id: 2,
            info: <ColoredText text={37} type={StatusType.DONE_WITH_DELAY} />,
          },
        ],
      },
      {
        id: 2,
        data: [
          {
            id: 0,
            info: 'Уровень информированности населения в соц. сетях, балл',
          },
          {
            id: 1,
            info: 'МИПН',
          },
          {
            id: 2,
            info: <ColoredText text={10} type={StatusType.DONE} />,
          },
        ],
      },
      {
        id: 3,
        data: [
          {
            id: 0,
            info: 'Уровень информированности населения в соц. сетях, балл',
          },
          {
            id: 1,
            info: 'МИПН',
          },
          {
            id: 2,
            info: <ColoredText text={10} type={StatusType.DONE} />,
          },
        ],
      },
      {
        id: 4,
        data: [
          {
            id: 0,
            info: 'Уровень информированности населения в соц. сетях, балл',
          },
          {
            id: 1,
            info: 'МИПН',
          },
          {
            id: 2,
            info: <ColoredText text={11} type={StatusType.DONE} />,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: 'Эффективное управление',
    subtitle: '',
    list: [
      {
        id: 0,
        data: [
          {
            id: 0,
            info: 'Стандарт потребительского рынка и услуг, балл/ чел.',
          },
          {
            id: 1,
            info: 'МинС/Х',
          },
          {
            id: 2,
            info: <ColoredText text={35} type={StatusType.DONE_WITH_DELAY} />,
          },
        ],
      },
      {
        id: 1,
        data: [
          {
            id: 0,
            info: 'Доля несостоявшихся закупок, %',
          },
          {
            id: 1,
            info: 'ККП',
          },
          {
            id: 2,
            info: <ColoredText text={1} type={StatusType.DONE} />,
          },
        ],
      },
      {
        id: 2,
        data: [
          {
            id: 0,
            info: 'Исполнение контрактов в ПИК ЕАСУЗ, %',
          },
          {
            id: 1,
            info: 'ГКУ',
          },
          {
            id: 2,
            info: <ColoredText text={52} type={StatusType.OVERDUE} />,
          },
        ],
      },
      {
        id: 3,
        data: [
          {
            id: 0,
            info: 'Доля кладбищ, соответствующих региональному стандарту, %',
          },
          {
            id: 1,
            info: 'ГУРБ',
          },
          {
            id: 2,
            info: <ColoredText text={33} type={StatusType.DONE} />,
          },
        ],
      },
      {
        id: 4,
        data: [
          {
            id: 0,
            info: 'Вовлечение в оборот объектов недвижимого имущества (в т.ч. объектов ИЖС), %',
          },
          {
            id: 1,
            info: 'Минимущества',
          },
          {
            id: 2,
            info: <ColoredText text={1} type={StatusType.DONE} />,
          },
        ],
      },
      {
        id: 5,
        data: [
          {
            id: 0,
            info: 'Эффективность работы по распоряжению земельными участками и имуществом, балл',
          },
          {
            id: 1,
            info: 'Минимущества',
          },
          {
            id: 2,
            info: <ColoredText text={12} type={StatusType.DONE} />,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    title: 'Качество жизни',
    subtitle: 'Социальная сфера',
    list: [
      {
        id: 0,
        data: [
          {
            id: 0,
            info: 'Стандарт потребительского рынка и услуг, балл/ чел.',
          },
          {
            id: 1,
            info: 'МинС/Х',
          },
          {
            id: 2,
            info: <ColoredText text={35} type={StatusType.DONE_WITH_DELAY} />,
          },
        ],
      },
      {
        id: 1,
        data: [
          {
            id: 0,
            info: 'Доля несостоявшихся закупок, %',
          },
          {
            id: 1,
            info: 'ККП',
          },
          {
            id: 2,
            info: <ColoredText text={1} type={StatusType.DONE} />,
          },
        ],
      },
      {
        id: 2,
        data: [
          {
            id: 0,
            info: 'Исполнение контрактов в ПИК ЕАСУЗ, %',
          },
          {
            id: 1,
            info: 'ГКУ',
          },
          {
            id: 2,
            info: <ColoredText text={52} type={StatusType.OVERDUE} />,
          },
        ],
      },
      {
        id: 3,
        data: [
          {
            id: 0,
            info: 'Доля кладбищ, соответствующих региональному стандарту, %',
          },
          {
            id: 1,
            info: 'ГУРБ',
          },
          {
            id: 2,
            info: <ColoredText text={33} type={StatusType.DONE} />,
          },
        ],
      },
      {
        id: 4,
        data: [
          {
            id: 0,
            info: 'Вовлечение в оборот объектов недвижимого имущества (в т.ч. объектов ИЖС), %',
          },
          {
            id: 1,
            info: 'Минимущества',
          },
          {
            id: 2,
            info: <ColoredText text={1} type={StatusType.DONE} />,
          },
        ],
      },
      {
        id: 5,
        data: [
          {
            id: 0,
            info: 'Эффективность работы по распоряжению земельными участками и имуществом, балл',
          },
          {
            id: 1,
            info: 'Минимущества',
          },
          {
            id: 2,
            info: <ColoredText text={12} type={StatusType.DONE} />,
          },
        ],
      },
    ],
  },
];

export const ratingHeaderConfig = [
  {
    id: 0,
    title: 'Направления/показатели',
    options: {
      width: '80%',
    },
  },
  {
    id: 1,
    options: {
      width: '15%',
      textAlign: 'center',
      fontFamily: theme.fonts.robotoCondensed.regular,
    },
    title: 'ЦИОГВ',
  },
  {
    id: 2,
    options: {
      width: '5%',
      textAlign: 'center',
    },
    title: 'Место',
  },
];
