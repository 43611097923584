import styled from 'styled-components';
import ListItem from './ListItem';
import { FC, useCallback, useContext, useMemo } from 'react';
import { List } from '../../../components/List';
import { history } from '../../../routes/history';
import { routes } from '../../../config/routes';
import { api } from '../../../config/api';
import { observer } from 'mobx-react-lite';
import { AppStoreContext } from '../../../stores/app';
import NoData from '../../../components/NoData';
import { prepareParams } from '../utils';
import { generatePath } from 'react-router';

interface Props {
  filter: any;
  listName?: string;
  maxHeight?: string;
}

const Risks: FC<Props> = observer(({ filter: filterProps, listName = '', maxHeight }) => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const handleObject = useCallback((item) => {
    const newPath = generatePath(routes.object.path, {
      actionId: item.govActiontId,
      objectId: item.entityId,
    });
    history.push(newPath);
  }, []);

  const filter = useMemo(() => {
    const filterCopy = { ...filterProps };
    Object.keys(filterCopy).forEach((key) => {
      if (filterCopy[key] === null) {
        delete filterCopy[key];
      }
    });

    return {
      ...prepareParams({ filter: filterCopy, isRisks: true }),
      omsuId: selectedOmsu?.id,
    };
  }, [selectedOmsu?.id, filterProps]);

  return (
    <ListWrapper>
      <List
        options={{
          borderRadius: true,
        }}
        url={api.getObjectsReester}
        renderItem={(item) => <ListItem item={item} onClick={handleObject} />}
        handleList={(list) => list}
        filter={filter}
        maxHeight={maxHeight}
        emptyPlaceholder={
          <NoData
            description={
              filter.isRisk
                ? 'По выбранным условиям риски отсутствуют'
                : 'По выбранным условиям предриски отсутствуют'
            }
          />
        }
        limit={5}
        listName={listName}
      />
    </ListWrapper>
  );
});

const ListWrapper = styled.div`
  border-radius: 22px;
  width: 100%;
`;

export default Risks;
