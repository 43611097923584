import styled from 'styled-components';
import { FC, useCallback } from 'react';
import DateFilter from '../../../components/DateFilter';

interface Props {
  setFilterCallback: any;
  filter: any;
}

const AgendaBudgetAddition: FC<Props> = ({ filter, setFilterCallback }) => {
  const handleSelectMonth = useCallback((el) => {
    setFilterCallback('selectedMonth', el.month);
  }, []);

  const getDateSelectLabel = useCallback(() => {
    return filter?.selectedMonth
      ? `${filter?.selectedMonth?.name} ${filter?.selectedMonth?.appliesToYear}`
      : 'Все года открытия';
  }, [filter]);

  return (
    <RisksAdditionWrapper id={'risks-wrapper'}>
      <DateFilter label={getDateSelectLabel()} onChange={handleSelectMonth} value={filter} />
    </RisksAdditionWrapper>
  );
};

const RisksAdditionWrapper = styled.div`
  display: flex;

  > div {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export default AgendaBudgetAddition;
