import React, { FC, useCallback, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { ListItem as List } from '../../Select';

interface Props {
  onClick: (object) => void;
  active?: boolean;
  isFirstElement?: boolean;
  withTotalElement?: boolean;
}

const ListItemCount: FC<List & Props> = ({
  label,
  value,
  id,
  count,
  onClick,
  active,
  isFirstElement,
  withTotalElement,
  ...props
}) => {
  const isEmpty = useMemo(() => !isFirstElement && count === 0, [isFirstElement, count]);

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      if (!isEmpty) {
        onClick({ value, label, id, ...props });
      }
    },
    [value, label, id, isEmpty],
  );

  return (
    <ListItemCountWrapper isMobile={isMobile} active={active} onClick={handleClick}>
      <LabelWrapper isEmpty={isEmpty}>{label}</LabelWrapper>
      {isFirstElement && withTotalElement ? null : <CountWrapper>{count}</CountWrapper>}
    </ListItemCountWrapper>
  );
};

const ListItemCountWrapper = styled.div<{ active: boolean; isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '13px' : '7px')};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.colors.hover};
    `}
`;

const CountWrapper = styled.div`
  margin-left: 10px;
  font-size: ${({ theme }) => theme.fonts.sizes.sm};
  font-family: ${({ theme }) => theme.fonts.montserrat.regular};
  color: ${({ theme }) => theme.colors.dark};
`;

const LabelWrapper = styled.div<{ isEmpty: boolean }>`
  font-size: ${({ theme }) => theme.fonts.sizes.md};
  font-family: ${({ theme }) => theme.fonts.montserrat.regular};
  color: ${({ theme, isEmpty }) => (isEmpty ? theme.colors.emptyData : '#000')};
`;

export default ListItemCount;
