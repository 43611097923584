import styled, { css } from 'styled-components';
import Rating, { RatingTypes } from '../Rating';
import { FC } from 'react';
import RatingCols from '../RatingCols';
import { NavLink } from 'react-router-dom';

export interface RatingItem {
  id: number;
  rating: number;
  differenceRating: number;
  year: number;
  mark?: number;
}

interface Props {
  config: {
    ratingType?: RatingTypes;
    title?: string;
    rating: number;
    differenceRating: number;
    cols: RatingItem[];
  };
  type?: RatingType;
  link?: string;
  onClick?: () => void;
}

export enum RatingType {
  TINY,
  SMALL,
  NORMAL,
}

const content = ({ config, type, onClick }) => (
  <ComponentContainer type={type} onClick={onClick}>
    <Rating
      type={config.ratingType}
      rating={config.rating}
      progress={config.differenceRating}
      title={config.title}
      size={type}
    />
    <RatingCols config={config.cols} type={type} />
  </ComponentContainer>
);

const RatingRow: FC<Props> = ({ config, type = RatingType.NORMAL, link, onClick }) => {
  return (
    <LinkWrapper>
      {link ? (
        <NavLink to={link}>{content({ config, type, onClick })}</NavLink>
      ) : (
        content({ config, type, onClick })
      )}
    </LinkWrapper>
  );
};

const ComponentContainer = styled.div<{ type: RatingType }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  ${({ type }) =>
    type === RatingType.SMALL &&
    css`
      align-items: flex-start;
      flex-direction: column;
      > div {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    `}
`;

const LinkWrapper = styled.div`
  cursor: pointer;
  a {
    text-decoration: none;
  }
`;

export default RatingRow;
