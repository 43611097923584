import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Progress from '../../Progress';
import AttachIcon from '../../../assets/icons/AttachIcon';
import { font } from '../../styled/mixins/fonts';
import { renderStatus, StatusType } from '../../Status';
import { TextButton } from '../../Button';
import FilesList from '../../FilesList';
import IconButtonAction from '../../IconButtonAction';
import { theme } from '../../../styles/theme';
import EmailIcon from '../../../assets/icons/EmailIcon';
import ResponsiblesList from '../../ResponsiblesLIst';
import { scrollIntoView } from '../../../utils/scrollIntoView';

interface Props {
  id: number;
  status: StatusType;
  task: string;
  date: Date;
  responsibles: {
    id: number;
    name: string;
    avatar: string;
    contact: string;
  }[];
  subtasks: any[];
  step: number;
  files: any[];
  secondary: boolean;
  depth: number;
  last: boolean;
  factResult: string;
  factDate: Date;
  resonNotPerformance: string;
  correctiveAction: string;
  deadlineElimination: Date;
  planDate: Date;
  planEndDate: Date;
  planResult: string;
  lastRow: boolean;
  code;
  lastIndex: number;
  level: number;
  inColumn: number[];
  nextStatus: number;
  handleMore: (
    e,
    {
      step,
      task,
      status,
      responsibles,
      files,
      factResult,
      factDate,
      planDate,
      planEndDate,
      planResult,
      resonNotPerformance,
      correctiveAction,
      deadlineElimination,
      current,
      total,
    }: {
      step: number;
      task: string;
      status: StatusType;
      responsibles: any[];
      files: any[];
      factResult: string;
      factDate: Date;
      planDate: Date;
      planEndDate: Date;
      planResult: string;
      resonNotPerformance: string;
      correctiveAction: string;
      deadlineElimination: Date;
      current: number;
      total: number;
    },
  ) => void;
}

const CascadeTableRow: FC<Props> = ({
  status,
  task,
  responsibles,
  subtasks,
  step,
  files,
  secondary,
  depth,
  last,
  handleMore,
  factResult,
  factDate,
  planDate,
  planEndDate,
  planResult,
  resonNotPerformance,
  correctiveAction,
  deadlineElimination,
  lastRow,
  inColumn,
  nextStatus,
  level,
}) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const taskRef = useRef<HTMLDivElement>();

  const toggleOpen = useCallback((event) => {
    scrollIntoView(event.target);
    setOpen((state) => !state);
  }, []);

  useEffect(() => {
    if (taskRef.current) {
      if (taskRef.current.offsetWidth < taskRef.current.scrollWidth) {
        taskRef.current.setAttribute('data-tip', task);
        // taskRef.current.parentElement.setAttribute('data-for', 'row-' + depth);
      }
    }
  }, [taskRef.current]);

  const renderStripes = ({ depth, last, lastRow, open, inColumn, nextStatus }) => {
    const stripes = [];
    if (!lastRow) {
      if (depth === 1) {
        stripes.push(<Stripe nextStatus={nextStatus} depth={1} />);
      }
      if (depth > 1 && !last && !open) {
        stripes.push(<Stripe nextStatus={nextStatus} depth={1} />);
      }
      if (depth > 1 && !last && open) {
        stripes.push(<CornerStripe depth={depth} />);
        stripes.push(<Stripe nextStatus={nextStatus} depth={1} />);
      }

      if (depth > 1 && inColumn && !open) {
        inColumn.forEach((item) =>
          stripes.push(<Stripe nextStatus={nextStatus} depth={item + 1} />),
        );
      }

      if (depth > 1 && last && !open) {
        stripes.push(<Stripe nextStatus={nextStatus} depth={1} />);
      }
      if (depth > 1 && last && open) {
        stripes.push(<CornerStripe depth={depth} />);
        stripes.push(<Stripe nextStatus={nextStatus} depth={1} />);
      }
    } else {
      if (depth > 1 && !last && open) {
        stripes.push(<CornerStripe depth={depth} />);
      }

      if (depth > 1 && inColumn && !open) {
        inColumn.forEach((item) =>
          stripes.push(<Stripe nextStatus={nextStatus} depth={item + 1} />),
        );
      }

      if (depth > 1 && last && open) {
        stripes.push(<CornerStripe depth={depth} />);
      }
    }
    return stripes;
  };

  const handleHover = useCallback((state) => {
    setHover(state);
  }, []);

  const calculateProgress = useCallback((subtasks) => {
    const statusDone = subtasks?.filter((el) => el.status === 4 || el.subtasks === 1).length;
    return 100 / (subtasks?.length / statusDone);
  }, []);

  const s = useMemo(() => renderStatus(status), []);

  return (
    <>
      <ComponentContainer
        depth={depth}
        onClick={subtasks && toggleOpen}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
      >
        <Hover first={depth === 1} pointer={!!subtasks}>
          {!secondary && (
            <Stage>
              <Progress step={step} status={status} progress={calculateProgress(subtasks)} />
            </Stage>
          )}
          {secondary && (
            <Secondary color={s.color}>{subtasks && <OuterCircle color={s.color} />}</Secondary>
          )}
          <Content>
            <Info>
              <Heading>
                <Step>{step}・</Step>
                <Status color={s.color}>{s.value}</Status>
                <FullDate>
                  ・{[2, 3, 5].includes(status) ? planEndDate.toString() : factDate.toString()}
                </FullDate>
              </Heading>
              <Task ref={taskRef}>{task}</Task>
            </Info>
            <Actions>
              <IconsWrapper>
                {files?.length ? (
                  <IconButtonAction
                    icon={<AttachIcon />}
                    content={<FilesList list={files} />}
                    tooltip={'Вложения'}
                    place={'left'}
                  />
                ) : null}
                {responsibles?.length ? (
                  <IconButtonAction
                    icon={<EmailIcon />}
                    content={<ResponsiblesList list={responsibles} />}
                    tooltip={'Ответственные'}
                    additionalPadding={false}
                    place={'left'}
                  />
                ) : null}
              </IconsWrapper>
              <TextButton
                opacity={hover ? 1 : 0.3}
                color={hover ? theme.colors.primary : theme.colors.disabled}
                onClick={(e) =>
                  handleMore(e, {
                    step,
                    task,
                    status,
                    responsibles,
                    files,
                    factResult,
                    factDate,
                    resonNotPerformance,
                    correctiveAction,
                    deadlineElimination,
                    planDate,
                    planEndDate,
                    planResult,
                    current: subtasks
                      ? subtasks.filter((item) => item.status === StatusType.DONE).length
                      : status === StatusType.DONE
                      ? 1
                      : 0,
                    total: subtasks ? subtasks.length : 1,
                  })
                }
              >
                Подробнее...
              </TextButton>
            </Actions>
          </Content>
        </Hover>
        {renderStripes({
          depth,
          last,
          lastRow,
          open,
          inColumn,
          nextStatus,
        })}
      </ComponentContainer>
      <Subtasks open={open}>
        {subtasks &&
          subtasks.map((item, idx) => {
            return (
              <CascadeTableRow
                last={item.lastIndex - 1 === idx}
                inColumn={idx < item.lastIndex - 1 ? [...inColumn, level] : inColumn}
                nextStatus={subtasks[idx + 1] ? subtasks[idx + 1].status : StatusType.DONE}
                lastRow={lastRow}
                depth={depth + 1}
                secondary={true}
                step={step + '.' + (idx + 1)}
                key={item.id}
                {...item}
                handleMore={handleMore}
              />
            );
          })}
      </Subtasks>
    </>
  );
};

const ComponentContainer = styled.div<{ depth: number }>`
  display: flex;
  align-items: center;
  padding-left: ${({ depth }) => (depth > 1 ? (depth - 1) * 20 + 32 : (depth - 1) * 36)}px;
  position: relative;
`;
const Stage = styled.div`
  margin-right: 3px;
  position: relative;
  z-index: 1;
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1 1 auto;
  overflow: hidden;
`;
const Status = styled.div<{ color: string }>`
  ${({ theme, color }) => font({ color, size: theme.fonts.sizes.sm })}
`;
const Task = styled.div`
  ${font()};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const FullDate = styled.div`
  ${({ theme }) => font({ size: theme.fonts.sizes.sm, color: theme.colors.dark })}
`;
const Info = styled.div`
  overflow: hidden;
  padding-left: 12px;
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;
const Step = styled.div`
  ${({ theme }) =>
    font({
      family: theme.fonts.montserrat.semibold,
      color: theme.colors.primary,
      size: theme.fonts.sizes.sm,
    })}
`;
const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex: 0 0 auto;
`;
const IconsWrapper = styled.div`
  flex: 0 0 auto;
  width: 88px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`;
const Secondary = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: relative;
  flex: 0 0 auto;
  z-index: 1;
`;
const OuterCircle = styled.div<{ color: string }>`
  position: absolute;
  width: 18px;
  height: 18px;
  border: 2px solid ${({ color }) => color};
  border-radius: 50%;
  left: -4px;
  top: -4px;
`;
const Subtasks = styled.div<{ open: boolean }>`
  overflow: ${({ open }) => (open ? 'visible' : 'hidden')};
  height: ${({ open }) => (open ? 'auto' : '0')};
`;
const Stripe = styled.div<{ depth: number; nextStatus: StatusType }>`
  position: absolute;
  top: 0;
  left: ${({ depth }) => (depth > 1 ? depth * 20 + 41 : depth * 45)}px;
  width: 20px;
  height: calc(100% + 2px);
  transform: translateY(50%);
  border-left: 1px ${({ nextStatus }) => (nextStatus === StatusType.PLANNED ? 'dashed' : 'solid')}
    ${({ theme }) => theme.colors.border};
`;
const CornerStripe = styled.div<{ depth: number }>`
  position: absolute;
  top: 0;
  left: ${({ depth }) => (depth >= 1 ? depth * 20 + 41 : depth * 50 + 44)}px;
  width: 20px;
  height: calc(100% + 1px);
  border-radius: 0 0 0 12px;
  transform: translateY(50%);
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;
const Hover = styled.div<{ pointer: boolean; first: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 14px 12px 24px;
  ${({ first }) =>
    first
      ? css`
          box-shadow: 1px 0 0 1px transparent;
        `
      : css`
          border: 1px solid transparent;
        `};
  ${({ pointer, first }) =>
    pointer
      ? first
        ? css`
            &:hover {
              cursor: pointer;
              box-shadow: 0 1px 0 0 ${({ theme }) => theme.colors.border},
                0 -1px 0 0 ${({ theme }) => theme.colors.border};
              background-color: ${({ theme }) => theme.colors.hover};
            }
          `
        : css`
            &:hover {
              cursor: pointer;
              border-radius: 12px 0 0 12px;
              border: 1px solid ${({ theme }) => theme.colors.border};
              border-right: 1px solid transparent;
              background-color: ${({ theme }) => theme.colors.hover};
            }
          `
      : null};
`;

export default CascadeTableRow;
