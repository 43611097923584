import styled from 'styled-components';
import { FC } from 'react';
import StatsList from './StatsList';
import ActionIcon from '../../../components/ActionIcon';
import InfoIcon from '../../../assets/icons/InfoIcon';
import { font } from '../../../components/styled/mixins/fonts';
import { ModalsStore } from '../../../stores/modal';

export interface StatsItem {
  period: string;
  list: {
    id: number;
    data: any;
    definition?: string;
    title: string;
  }[];
}

interface Props {
  title: string;
  subtitle: string;
  stats: StatsItem;
  indicatorId?: string;
}

const IndicatorStats: FC<Props> = ({ title, subtitle, stats, indicatorId }) => {
  return (
    <ComponentContainer>
      <Info>
        <Side>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Side>
        <ActionIcon action={() => ModalsStore.showModal('indicator-info-modal', { indicatorId })}>
          <InfoIcon />
        </ActionIcon>
      </Info>
      <StatsList {...stats} />
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const Title = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xxxmd,
      family: theme.fonts.montserrat.semibold,
      lineHeight: '28px',
    })};
  margin-bottom: 4px;
`;
const Subtitle = styled.div`
  ${({ theme }) =>
    font({
      color: theme.colors.dark,
    })};
`;
const Info = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Side = styled.div``;

export default IndicatorStats;
