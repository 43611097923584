import styled from 'styled-components';
import ChartLegendDarkIcon from './ChartLegendDarkIcon';
import ChartLegendLightIcon from './ChartLegendLightIcon';
import { font } from '../../../../components/styled/mixins/fonts';
import { FC } from 'react';
import { prepareNumber } from '../../../../utils/prepareNumber';

interface DataItem {
  name: string;
  values: Array<string>;
}
interface Props {
  data: Array<DataItem>
}

const LineChartSummary: FC<Props> = ({data}) => {
  return (
    <ComponentContainer>
      {data?.map((item, idx) => (
        <Section key={idx}>
          <LabelWrapper>
            {idx === 0 ? <ChartLegendLightIcon /> : <ChartLegendDarkIcon />}
            <Label>{item.name}</Label>
          </LabelWrapper>
          <List>
            {item?.values?.map((item, valueIdx) => (
              <ListItem key={`${idx}_${valueIdx}`}>{prepareNumber(item, 2)}</ListItem>
            ))}
          </List>
        </Section>
      ))}
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const Label = styled.div`
  ${({ theme }) =>
    font({
      color: theme.colors.dark,
      family: theme.fonts.montserrat.semibold,
      size: theme.fonts.sizes.sm,
    })};
`;
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
`;
const List = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const ListItem = styled.div`
  ${({ theme }) => font({ family: theme.fonts.robotoCondensed.regular })};
  text-align: center;
  flex: 1 1 auto;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

export default LineChartSummary;
