import Card from '../../../components/Card';
import styled from 'styled-components';
import { FC, memo } from 'react';
import CurrentData from './CurrentData';
import OMSUData from './OMSUData';
import Tables from './Tables';

interface Props {
  filter: any;
}

const AgendaEconomics: FC<Props> = memo(({ filter }) => {
  return (
    <Card>
      <Content>
        <CurrentData filter={filter} />
        <OMSUData filter={filter} />
        <Tables filter={filter} />
      </Content>
    </Card>
  );
});

const Content = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default AgendaEconomics;
