import styled, { css } from 'styled-components';
import { FC } from 'react';
import { font } from '../../../components/styled/mixins/fonts';

const BudgetMunicipalDebtSubtablesAddition: FC = () => {

  return (
    <BudgetMunicipalDebtSubtablesAdditionWrapper>
      <ColumnsLabel>План</ColumnsLabel>
      <ColumnsLabel last>Факт</ColumnsLabel>
    </BudgetMunicipalDebtSubtablesAdditionWrapper>
  );
};

const ColumnsLabel = styled.div<{ last?: boolean }>`
    ${({ theme }) => font({
        family: theme.fonts.robotoCondensed.regular,
        lineHeight: '22px',
        size: '14px',
        color: theme.colors.dark
    })};
    ${({ last }) => !last && css`
        margin-right: 16px;
    `}
`;
const BudgetMunicipalDebtSubtablesAdditionWrapper = styled.div`
    display: flex;
    align-items: flex-end
`;

export default BudgetMunicipalDebtSubtablesAddition;
