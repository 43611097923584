const CheckboxActiveIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.3 20.5C4.8 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.2 3.5 18.7V5.3C3.5 4.8 3.675 4.375 4.025 4.025C4.375 3.675 4.8 3.5 5.3 3.5H18.7C19.2 3.5 19.625 3.675 19.975 4.025C20.325 4.375 20.5 4.8 20.5 5.3V18.7C20.5 19.2 20.325 19.625 19.975 19.975C19.625 20.325 19.2 20.5 18.7 20.5H5.3ZM10.6 15.475C10.7167 15.475 10.825 15.4583 10.925 15.425C11.0417 15.375 11.1417 15.3083 11.225 15.225L16.825 9.625C16.9583 9.49167 17.025 9.325 17.025 9.125C17.025 8.925 16.95 8.75 16.8 8.6C16.65 8.45 16.4667 8.375 16.25 8.375C16.05 8.375 15.8833 8.45 15.75 8.6L10.6 13.75L8.25 11.4C8.11667 11.2667 7.95 11.2 7.75 11.2C7.55 11.2 7.375 11.275 7.225 11.425C7.075 11.575 7 11.75 7 11.95C7 12.15 7.075 12.325 7.225 12.475L9.975 15.225C10.0583 15.3083 10.15 15.375 10.25 15.425C10.3667 15.4583 10.4833 15.475 10.6 15.475Z'
      fill='#172B75'
    />
  </svg>
);

export default CheckboxActiveIcon;
