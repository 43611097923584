import React from 'react';
import styled from 'styled-components';

export const withError = (Component) => {
  return (componentProps) => {
    return (
      <Wrapper>
        <Component {...componentProps} />
        {/*{componentProps.error && <Error>{componentProps.error}</Error>}*/}
      </Wrapper>
    );
  };
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Error = styled.div`
  background: red;
`;
