import { ColumnCellTypes } from '.';
import { theme } from '../../styles/theme';

export const initialFilterValues = {
  selectedYear: '',
  ciogv: '',
};

export const initialSectorValues = {
  viewType: '',
};

export interface ConfigItem {
  id: string | number;
  label: string;
  field: string;
  sorting?: boolean;
  width: string;
  dataOptions?: any;
}

export const tableFieldsConfigs = {
  sixColumns: (params) => [
    {
      id: 0,
      label: '',
      field: 'title',
      sorting: false,
      width: '60%',
    },
    {
      id: 1,
      label: 'Всего',
      field: 'total',
      width: '5%',
      ...params,
      onCellClick: (item) => params?.onCellClick(item, ColumnCellTypes.ALL_INDICATOR_CNT),
      ignoreHandleNumber: true,
    },
    {
      id: 2,
      label: 'Достигнуто',
      field: 'achieved',
      width: '5%',
      ...params,
      onCellClick: (item) => params?.onCellClick(item, ColumnCellTypes.ACHIEVED_INDICATOR_CNT),
      ignoreHandleNumber: true,
    },
    {
      id: 3,
      label: 'Риск',
      field: 'risk',
      width: '5%',
      dataOptions: {
        color: theme.colors.status.overdue,
      },
      ...params,
      onCellClick: (item) => params?.onCellClick(item, ColumnCellTypes.RISK_INDICATOR_CNT),
      ignoreHandleNumber: true,
    },
    {
      id: 4,
      label: 'План',
      field: 'planFunding',
      width: '5%',
      ...params,
      onCellClick: (item) => params?.onCellClick(item, ColumnCellTypes.PLAN_FIN),
    },
    {
      id: 5,
      label: 'Факт',
      field: 'actualFunding',
      width: '5%',
      ...params,
      onCellClick: (item) => params?.onCellClick(item, ColumnCellTypes.FACT_FIN),
    },
    {
      id: 6,
      label: 'Законтрактовано',
      field: 'contracted',
      width: '5%',
      ...params,
      onCellClick: (item) => params?.onCellClick(item, ColumnCellTypes.CONTRACT_FIN),
    },
  ],
  tripleColumnsType1: (params) => [
    // {
    //   label: '',
    //   field: 'orderNumber',
    //   sorting: false,
    //   width: '10%',
    // },
    {
      id: 0,
      label: '',
      field: 'title',
      sorting: false,
      width: '79%',
      // options: {
      //   text: 'left',
      //   color: theme.colors.dark,
      // },
    },
    {
      id: 1,
      label: 'Всего',
      field: 'total',
      sorting: false,
      width: '7%',
      ...params,
      onCellClick: (item) => params?.onCellClick(item, ColumnCellTypes.ALL_INDICATOR_CNT),
      ignoreHandleNumber: true,
    },
    {
      id: 2,
      label: 'Достигнуто',
      field: 'achieved',
      sorting: false,
      width: '7%',
      dataOptions: {
        color: theme.colors.status.done,
      },
      ...params,
      onCellClick: (item) => params?.onCellClick(item, ColumnCellTypes.ACHIEVED_INDICATOR_CNT),
      ignoreHandleNumber: true,
    },
    {
      id: 3,
      label: 'Риск',
      field: 'risk',
      sorting: false,
      width: '7%',
      dataOptions: {
        color: theme.colors.status.overdue,
      },
      ...params,
      onCellClick: (item) => params?.onCellClick(item, ColumnCellTypes.RISK_INDICATOR_CNT),
      ignoreHandleNumber: true,
    },
  ],
  tripleColumnsType2: (params) => [
    {
      id: 0,
      label: '',
      field: 'title',
      sorting: false,
      width: '79%',
    },
    {
      id: 1,
      label: 'Всего',
      field: 'total',
      sorting: false,
      width: '7%',
      ...params,
      onCellClick: (item) => params?.onCellClick(item, ColumnCellTypes.ALL_INDICATOR_CNT),
      ignoreHandleNumber: true,
    },
    {
      id: 2,
      label: 'Улучшение',
      field: 'achieved',
      sorting: false,
      width: '7%',
      dataOptions: {
        color: theme.colors.status.done,
      },
      ...params,
      onCellClick: (item) => params?.onCellClick(item, ColumnCellTypes.ACHIEVED_INDICATOR_CNT),
      ignoreHandleNumber: true,
    },
    {
      id: 3,
      label: 'Ухудшение',
      field: 'risk',
      sorting: false,
      width: '7%',
      dataOptions: {
        color: theme.colors.status.overdue,
      },
      ...params,
      onCellClick: (item) => params?.onCellClick(item, ColumnCellTypes.RISK_INDICATOR_CNT),
      ignoreHandleNumber: true,
    },
  ],
  fiveColumns: (params) => [
    {
      id: 0,
      label: '',
      field: 'title',
      sorting: false,
      width: '75%',
    },
    {
      id: 1,
      label: 'Всего',
      field: 'total',
      sorting: false,
      width: '5%',
      ...params,
      onCellClick: (item) => params?.onCellClick(item, ColumnCellTypes.ALL_INDICATOR_CNT),
      ignoreHandleNumber: true,
    },
    {
      id: 2,
      label: 'Лидер',
      field: 'leader',
      sorting: false,
      width: '5%',
      dataOptions: {
        color: theme.colors.status.done,
      },
      ...params,
      onCellClick: (item) => params?.onCellClick(item, ColumnCellTypes.LEADER_INDICATOR_CNT),
      ignoreHandleNumber: true,
    },
    {
      id: 3,
      label: 'Аутсайдер',
      field: 'outsider',
      sorting: false,
      width: '5%',
      dataOptions: {
        color: theme.colors.status.overdue,
      },
      ...params,
      onCellClick: (item) => params?.onCellClick(item, ColumnCellTypes.OUTSIDER_INDICATOR_CNT),
      ignoreHandleNumber: true,
    },
    {
      id: 4,
      label: 'Прорыв',
      field: 'breakthrough',
      sorting: false,
      width: '5%',
      dataOptions: {
        color: theme.colors.status.done,
      },
      ...params,
      onCellClick: (item) => params?.onCellClick(item, ColumnCellTypes.BREAK_THROUGH_INDICATOR_CNT),
      ignoreHandleNumber: true,
    },
    {
      id: 5,
      label: 'Провал',
      field: 'failure',
      sorting: false,
      width: '5%',
      dataOptions: {
        color: theme.colors.status.overdue,
      },
      ...params,
      onCellClick: (item) => params?.onCellClick(item, ColumnCellTypes.FAILURE_INDICATOR_CNT),
      ignoreHandleNumber: true,
    },
  ],
};
