import React, { FC, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

const TooltipModule: FC = () => {
  useEffect(() => {
    const timer = setInterval(() => {
      ReactTooltip.rebuild();
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  return <ReactTooltip id={'1'} effect={'solid'} className={'b-tooltip'} />;
};

export default TooltipModule;
