import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { getRentLand } from '../../../actions';
import Card from '../../../components/Card';
import { useSorting } from '../../../hooks/useSorting';
import { AppStoreContext } from '../../../stores/app';
import { initialRentLandSortingValues } from '../consts';
import { rentFields } from './rentFields';
import ComplexTable from '../../../components/ComplexTable';
import { theme } from '../../../styles/theme';

interface Props {
  filter: any;
}

const DevelopmentLandPlotsRent: FC<Props> = observer(({ filter }) => {
  const { selectedOmsu } = useContext(AppStoreContext);

  const { sorting, setSortingCallback } = useSorting({
    initialSortingValues: { ...initialRentLandSortingValues },
    sortingName: 'developmentIndustrialLandSorting',
  });

  const [data, setData] = useState(null);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedOmsu) {
      const fetchData = async () => {
        setDataLoading(true);
        try {
          const response = await getRentLand({
            omsuId: selectedOmsu.id,
            rentObjectId: filter?.selectedType?.id,
          });
          setData(response.data.items);
        } catch (e) {
          //
        } finally {
          setDataLoading(false);
        }
      };
      fetchData();
    }
  }, [selectedOmsu, filter]);

  const tableData = useMemo(() => {
    let result = [];

    if (data) {
      result = data.map((itm) => ({
        title: itm.omsu?.name || ' ',
        total: isNumber(itm.total) ? itm.total.toString() : '-',
        median: isNumber(itm.performObligations) ? itm.performObligations.toString() : '-',
        taxes: isNumber(itm.taxes) ? itm.taxes.toString() : '-',
        pm: isNumber(itm.pm) ? itm.pm.toString() : '-',
        investment: isNumber(itm.investment) ? itm.investment.toString() : '-',
        options: {
          styles: {
            title: {
              'font-weight': selectedOmsu?.id === itm.omsuId ? 'bold' : 'normal',
            },
          },
        },
      }));
    }

    return result;
  }, [data, selectedOmsu]);

  return (
    <Card>
      <Content>
        <ComplexTable
          fields={rentFields}
          data={tableData}
          sorting={sorting}
          setSortingCallback={setSortingCallback}
          loading={dataLoading}
        />
      </Content>
    </Card>
  );
});

const Content = styled.div`
  padding-top: 10px;
`;

export default DevelopmentLandPlotsRent;
