import styled from 'styled-components';
import Icon from '../../Icon';
import DragIcon from '../../../assets/icons/DragIcon';
import React, { FC } from 'react';
import { font } from '../../styled/mixins/fonts';
import { Draggable } from 'react-beautiful-dnd';

interface Props {
  id: string;
  label: string;
  idx: number;
}

const GroupElement: FC<Props> = ({ id, label, idx }) => {
  return (
    <Draggable draggableId={id} index={idx}>
      {(provided) => {
        let params = {};
        if (provided) {
          params = {
            ref: provided.innerRef,
            ...provided.draggableProps,
            ...provided.dragHandleProps,
          };
        }
        return (
          <ComponentContainer {...params}>
            <Label>{label}</Label>
            <Icon opacity={0.3}>
              <DragIcon />
            </Icon>
          </ComponentContainer>
        );
      }}
    </Draggable>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: move;
  padding: 4px 0;
  &:hover {
    background-color: ${({ theme }) => theme.colors.hover};
  }
`;
const Label = styled.div`
  flex: 1 1 auto;
  ${font()};
`;

export default GroupElement;
