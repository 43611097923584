import styled from 'styled-components';
import { font } from '../styled/mixins/fonts';

const ErrorMessage = () => {
  return (
    <ComponentContainer>
      <Title>Ошибка!</Title>
      <Subtitle>Проверьте правильность введённых данных</Subtitle>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div``;
const Title = styled.div`
  ${({ theme }) => font({ color: '#fff', family: theme.fonts.montserrat.semibold })};
`;
const Subtitle = styled.div`
  ${({ theme }) => font({ color: '#fff', size: theme.fonts.sizes.sm })};
`;

export default ErrorMessage;
