export const industrialFields = [
  [
    {
      id: 0,
      label: '',
      field: 'title',
      sorting: false,
      width: '85%',
    },
    {
      id: 1,
      label: (
        <>
          KPI
          <br />
          га
        </>
      ),
      field: 'kpi',
      sorting: true,
      width: '3%',
    },
    {
      id: 2,
      label: (
        <>
          Площадь
          <br />
          га
        </>
      ),
      field: 'area',
      sorting: true,
      width: '5%',
    },
    {
      id: 3,
      label: (
        <>
          Заполняемость
          <br />%
        </>
      ),
      field: 'occupancy',
      sorting: true,
      width: '5%',
    },
  ],
];
