import styled from 'styled-components';
import { ListItem as List } from '../../Select';
import { FC, useCallback } from 'react';
import ListItem from '../../SearchList/ListItem';

interface Props {
  list: List[];
  onClick: ({ value, label }: { value: string | number; label: string | number }) => void;
  active?: any;
  onClose?: () => void;
}

const SelectList: FC<Props> = ({ list, onClick, active, onClose }) => {
  const handleClick = useCallback((val) => {
    onClick(val);
    onClose && onClose();
  }, []);

  return (
    <SelectListWrapper>
      {list.map((item, idx) => {
        return (
          <ListItem
            active={active?.value === item.value}
            key={item.id}
            {...item}
            idx={idx}
            onClick={handleClick}
          />
        );
      })}
    </SelectListWrapper>
  );
};

const SelectListWrapper = styled.div`
  padding: 8px 0;
`;

export default SelectList;
