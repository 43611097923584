import React, { ChangeEvent, FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { withError } from '../../hocs/Error';
import { font } from '../styled/mixins/fonts';
import Icon from '../Icon';
import EyeIcon from '../../assets/icons/EyeIcon';
import EyeOffIcon from '../../assets/icons/EyeOffIcon';

export enum InputType {
  TEXT,
  PASSWORD,
  NUMBER,
}

interface Props {
  type?: InputType;
  placeholder?: string;
  onBlur?: () => void;
  autoFocus?: boolean;
  onChange: (value: string | number) => void;
  value: string | number;
}

const InputComponent: FC<Props> = ({
  type = InputType.TEXT,
  placeholder = '',
  onBlur = () => null,
  autoFocus = false,
  onChange,
  value,
}) => {
  const getInputType = (inputType: InputType) => {
    switch (inputType) {
      case InputType.NUMBER:
        return 'number';
      case InputType.PASSWORD:
        return 'password';
      case InputType.TEXT:
        return 'text';
      default:
        return 'text';
    }
  };

  const inputRef = useRef(null);
  const [inputType, setInputType] = useState(() => getInputType(type));

  const focusToInput = useCallback(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (autoFocus) {
      focusToInput();
    }
  }, [autoFocus, focusToInput]);

  const togglePassword = useCallback(() => {
    setInputType((state) => (state === 'password' ? 'text' : 'password'));
  }, []);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <Wrapper>
      <InputElement
        ref={inputRef}
        type={inputType}
        placeholder={placeholder}
        onBlur={onBlur}
        value={value}
        onChange={handleChange}
        field={getInputType(type)}
      />
      {type === InputType.PASSWORD && (
        <IconWrapper onClick={togglePassword}>
          <Icon color={'#fff'} opacity={0.7}>
            {inputType === 'text' ? <EyeOffIcon /> : <EyeIcon />}
          </Icon>
        </IconWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const InputElement = styled.input<{ field: string }>`
  background-color: ${({ theme }) => theme.colors.primary};
  border: 1px solid #fff;
  border-radius: 56px;
  padding: ${({ field }) => (field === 'password' ? '11px 50px 11px 16px' : '11px 16px 11px 16px')};
  ${() => font({ color: '#fff' })};
  outline: none;
  width: 100%;
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px ${({ theme }) => theme.colors.primary} inset !important;
    -webkit-text-fill-color: #fff !important;
  }
  &::-ms-reveal {
    display: none;
  }
`;
const IconWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 0;
`;

export const Input = withError(memo(InputComponent));
