import { useCallback, useEffect, useState } from 'react';
import {
  getStorageSortingValue,
  setStorageValue,
} from '../utils/handleStorageValue';

//Урезанная версия useFilter для таблиц где необходимо иметь только сортировку, и где необходимо сортировать без перезагрузки данных
export const useSorting = ({
  initialSortingValues = {},
  sortingName = 'defaultSorting',
}) => {

  const [sorting, setSortIng] = useState(
    getStorageSortingValue({ name: sortingName, values: initialSortingValues }),
  );

  const setSortingCallback = useCallback((value) => {
    setSortIng(value);
  }, []);

  useEffect(() => {
    setStorageValue({ name: sortingName, values: sorting });
  }, [sortingName, sorting]);

  return { sorting, setSortingCallback };
};
