import Card from '../../../components/Card';
import styled from 'styled-components';
import Paper from '../../../components/Paper';
import BudgetCard from '../../../components/BudgetCard';
import AgendaBudgetTable from './AgendaBudgetTable';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useFilter } from '../../../hooks/useFilter';
import { initialBudgetCardFilterValues } from '../consts';
import { getDebtOMSU } from '../../../actions';
import { AppStoreContext } from '../../../stores/app';
import { observer } from 'mobx-react-lite';
import AgendaBudgetAddition from '../AgendaBudgetAddition';
import { NsiStoreContext } from '../../../stores/nsi';
import { format, isAfter, isBefore, isSameDay } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Loader } from '../../../components/Loader';

interface Props {
  filter: any;
}

const AgendaBudget: FC<Props> = observer(({ filter }) => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const [currentData, setCurrentData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { filter: budgetCardFilter, setFilterCallback: setBudgetCardFilterCallback } = useFilter({
    initialFilterValues: { ...initialBudgetCardFilterValues },
    name: 'agendaBudgetCardFilters',
    dateFields: ['fromDate', 'toDate'],
  });

  const { monthsRazdel } = useContext(NsiStoreContext);

  const findCurrentMonth = () => {
    return monthsRazdel[format(new Date(), 'yyyy', { locale: ru })]
      ? monthsRazdel[format(new Date(), 'yyyy', { locale: ru })].find(
          (item) =>
            (isAfter(new Date(), new Date(item.periodStart)) &&
              isBefore(new Date(), new Date(item.periodEnd))) ||
            isSameDay(new Date(), new Date(item.periodEnd)) ||
            isSameDay(new Date(), new Date(item.periodStart)),
        )
      : '';
  };

  useEffect(() => {
    if (!budgetCardFilter.selectedMonth) {
      setBudgetCardFilterCallback('selectedMonth', findCurrentMonth());
    }
  }, [monthsRazdel]);

  const fetchCurrentData = useCallback(async () => {
    if (selectedOmsu) {
      try {
        setLoading(true);
        const params = {
          periodId: budgetCardFilter.selectedMonth?.id,
          omsuId: selectedOmsu.id,
        };
        const response = await getDebtOMSU(params);
        setCurrentData(response.data?.items || []);
      } catch (e) {
        //
      } finally {
        setLoading(false);
      }
    }
  }, [selectedOmsu, budgetCardFilter.selectedMonth]);

  useEffect(() => {
    fetchCurrentData();
  }, [fetchCurrentData]);

  return (
    <Card>
      <TableWrapper>
        <AgendaBudgetTable filter={filter} />
      </TableWrapper>

      {loading ? (
        <Loader />
      ) : (
        <CardsWrapper>
          <Paper>
            <BudgetCard
              title={'Задолженность'}
              subtitle={''}
              headerComponent={
                <AgendaBudgetAddition
                  filter={budgetCardFilter}
                  setFilterCallback={setBudgetCardFilterCallback}
                />
              }
              data={currentData
                .filter((item) => item.partitionId === 18)
                .map((item) => ({
                  id: item.id,
                  label: item.name + ', ' + item.unit,
                  amount: item.factValue.toFixed(2),
                  details: item.children.map((child) => ({
                    id: child.id,
                    label: child.name + ', ' + child.unit,
                    amount: child.factValue.toFixed(2),
                  })),
                }))}
            />
          </Paper>
          <Paper>
            <BudgetCard
              title={'Ресурс'}
              subtitle={''}
              data={currentData
                .filter((item) => item.partitionId === 19)
                .map((item) => ({
                  id: item.id,
                  label: item.name + ', ' + item.unit,
                  amount: item.factValue.toFixed(2),
                  details: item.children.map((child) => ({
                    id: child.id,
                    label: child.name + ', ' + child.unit,
                    amount: child.factValue.toFixed(2),
                  })),
                }))}
            />
          </Paper>
        </CardsWrapper>
      )}
    </Card>
  );
});

const TableWrapper = styled.div`
  margin-bottom: 32px;
`;
const CardsWrapper = styled.div`
  display: flex;
  padding: 0 24px 24px 24px;

  > div {
    flex: 1 0 calc(50% - 24px);
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export default AgendaBudget;
