import { FC } from 'react';
import styled from 'styled-components';
import defaultAvatar from '../../assets/images/avatar.svg';

export enum AvatarSize {
  NORMAL,
  NORMAL_PLUS,
  BIG,
}

interface Props {
  avatar: string;
  size?: AvatarSize;
}

const Avatar: FC<Props> = ({ avatar, size = AvatarSize.NORMAL }) => {
  const calculateSize = (size) => {
    switch (size) {
      case AvatarSize.NORMAL:
        return 38;
      case AvatarSize.NORMAL_PLUS:
        return 48;
      case AvatarSize.BIG:
        return 90;
      default:
        return 38;
    }
  };

  return <AvatarWrapper avatar={avatar} size={calculateSize(size)} />;
};

const AvatarWrapper = styled.div<{ avatar: string; size: number }>`
  border-radius: 50%;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background: url(${({ avatar }) => (avatar ? avatar : defaultAvatar)}), #eee;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  border: 1px solid #e0e0e0;
  flex: 0 0 auto;
`;

export default Avatar;
