import styled from 'styled-components';
import Select from '../../../components/Select';
import { useEffect, useContext, useCallback, FC } from 'react';
import SearchList from '../../../components/SearchList';
import { observer } from 'mobx-react-lite';
import { AppStoreContext } from '../../../stores/app';

interface Props {
  OMSUId?: number;
  setSelectedOmsu?: (omsu: any) => void;
  selectedOmsu?: any;
}

const SummaryInfoAddition: FC<Props> = observer(
  ({ OMSUId, setSelectedOmsu: setSelectedOmsuProp, selectedOmsu: selectedOmsuProp }) => {
    const {
      getOmsuList,
      omsuList,
      setSelectedOmsu: setSelectedOmsuStore,
      selectedOmsu: selectedOmsuStore,
    } = useContext(AppStoreContext);

    const selectedOmsu = selectedOmsuProp || selectedOmsuStore;
    const setSelectedOmsu = setSelectedOmsuProp || setSelectedOmsuStore;

    useEffect(() => {
      getOmsuList(OMSUId);
    }, []);

    const onSelect = useCallback((item) => {
      setSelectedOmsu(item);
    }, []);

    return (
      <SummaryInfoAdditionWrapper>
        <Select
          label={'По всем муниципальным образованиям'}
          value={selectedOmsu}
          position={'left'}
          renderList={({ active, onClose }) => (
            <SearchList list={omsuList} onClick={onSelect} active={active} onClose={onClose} />
          )}
          // isAnchorPopup={false}
        />
      </SummaryInfoAdditionWrapper>
    );
  },
);

const SummaryInfoAdditionWrapper = styled.div``;

export default SummaryInfoAddition;
