import styled from 'styled-components';
import { TextButton } from '../../../components/Button';
import { ModalsStore } from '../../../stores/modal';
import BudgetExecutionModal from './BudgetExecutionModal';
import DateFilter from '../../../components/DateFilter';
import { FC, useCallback } from 'react';

interface Props {
  filter: any;
  setFilter: any;
}

const BudgetExecutionAddition: FC<Props> = ({ filter, setFilter }) => {

  const getDateSelectLabel = useCallback(() => {
    return filter?.selectedMonth
      ? `${filter?.selectedMonth?.name} ${filter?.selectedMonth?.appliesToYear}`
      : 'Все года открытия';
  }, [filter]);

  return (
    <ComponentContainer>
      <DateFilter
        label={getDateSelectLabel()}
        onChange={(d) => setFilter('selectedMonth', d.month)}
        value={{ selectedMonth: filter.selectedMonth }}
      />
      <TextButton onClick={() => ModalsStore.showModal('budget-execution-modal', { initialMonth: filter?.selectedMonth })}>
        Подробнее...
      </TextButton>
      <BudgetExecutionModal />
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export default BudgetExecutionAddition;
