import styled from 'styled-components';
import { FC } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useMemo } from 'react';
import { font } from '../styled/mixins/fonts';
import { theme } from '../../styles/theme';
import merge from 'lodash/merge';

interface ChartProps {
  title?: string;
  config: any;
}

const LineChart: FC<ChartProps> = ({ title, config }) => {
  const localConfig = useMemo(() => {
    return {
      legend: {
        margin: 20,
        itemStyle: {
          fontSize: 12,
          fontFamily: 'Montserrat Medium',
          opacity: 0.5,
          fontWeight: 'normal',
        },
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      chart: {
        type: 'line',
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
          },
        },
      },
    };
  }, []);

  return (
    <ComponentContainer>
      <Title>{title}</Title>
      <HighchartsReact highcharts={Highcharts} options={merge(localConfig, config)} />
    </ComponentContainer>
  );
};

const Title = styled.div`
  padding-left: 24px;
  ${font({ size: theme.fonts.sizes.xxmd, family: theme.fonts.montserrat.semibold })};
`;

const ComponentContainer = styled.div`
  div[data-highcharts-chart] {
    overflow: visible !important;
  }
`;

export default LineChart;
