import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ColumnChart from '../../../../../components/ColumnChart';
import BudgetModalFilters, { FilterTypes } from '../../../../../components/BudgetModalFilters';
import { Table } from '../../../../../components/Table';
import { font } from '../../../../../components/styled/mixins/fonts';
import { getBudgetExecutionDiagram, getDinamBudgetExecutionTable } from '../../../../../actions';
import { useFilter } from '../../../../../hooks/useFilter';
import { initialTypeFilterValues } from '../consts';
import { get, isNumber } from 'lodash';
import { theme } from '../../../../../styles/theme';
import Text, { TextFamily } from '../../../../../components/Text';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Loader } from '../../../../../components/Loader';
import { prepareNumber, prepareNumberForTable } from '../../../../../utils/prepareNumber';
import { observer } from 'mobx-react-lite';
import { AppStoreContext } from '../../../../../stores/app';

interface Props {
  filter: any;
}

const CONTEXT_CODE = '10007';

const TypeType: FC<Props> = observer(({ filter }) => {
  const { selectedOmsu } = useContext(AppStoreContext);

  const { filter: typeFilter, setFilterCallback: setTypeFilterCallback } = useFilter({
    initialFilterValues: { ...initialTypeFilterValues },
    name: 'budgetExecutionTypeTypeFilters',
  });

  const [chartData, setChartData] = useState(null);
  const [tableInfo, setTableInfo] = useState(null);
  const [chartDataLoading, setChartDataLoading] = useState<boolean>(false);
  const [tableDataLoading, setTableDataLoading] = useState<boolean>(false);

  useEffect(() => {
    if (filter?.selectedMonth) {
      const fetchData = async () => {
        setChartDataLoading(true);
        try {
          const response = await getBudgetExecutionDiagram({
            executionContextId: CONTEXT_CODE,
            periodId: filter?.selectedMonth?.id,
            omsuId: selectedOmsu?.id,
            ciogvId: typeFilter[FilterTypes.CIOGV],
            nacProjectId: typeFilter[FilterTypes.NATIONAL_PROJECT],
            programId: typeFilter[FilterTypes.GP],
            finSourceId: typeFilter[FilterTypes.FUNDING_SOURCE],
            // sourceType: "string"
          });
          setChartData(response?.data?.items?.slice(0, 9));
        } catch (e) {
          //
        } finally {
          setChartDataLoading(false);
        }
      };
      fetchData();
    }
  }, [filter, typeFilter]);

  useEffect(() => {
    if (filter?.selectedMonth) {
      const fetchData = async () => {
        setTableDataLoading(true);
        try {
          const response = await getDinamBudgetExecutionTable({
            ExecutionContextCode: CONTEXT_CODE,
            periodId: filter?.selectedMonth?.id,
            viewId: typeFilter[FilterTypes.EXPENSES_TYPE]?.id,
            ciogvId: typeFilter[FilterTypes.CIOGV],
            nacProjectId: typeFilter[FilterTypes.NATIONAL_PROJECT],
            gp: typeFilter[FilterTypes.GP],
            fundingSource: typeFilter[FilterTypes.FUNDING_SOURCE],
          });
          setTableInfo(response.data.items);
        } catch (e) {
          //
        } finally {
          setTableDataLoading(false);
        }
      };
      fetchData();
    }
  }, [filter, typeFilter]);

  const chartConfig = useMemo(() => {
    let result = {};

    if (chartData) {
      // const maxDataLevel = Math.max(...chartData.map((itm) => itm.percents));
      // const maxChartLevel = Math.ceil(maxDataLevel / 10) * 10 + 10;

      result = {
        xAxis: {
          categories: chartData?.map((itm) => itm.name),
          gridLineWidth: 1,
          gridLineDashStyle: 'dash',
          tickmarkPlacement: 'on',
        },
        yAxis: {
          visible: false,
          min: 0,
          // max: maxChartLevel,
        },
        legend: {
          enabled: false,
        },
        title: {
          text: '',
        },
        plotOptions: {
          column: {
            minPointLength: 40,
          },
          series: {
            pointPadding: 0.05,
            groupPadding: 0,
          },
        },
        series: [
          {
            type: 'column',
            name: '',
            data: chartData?.map((itm) => ({
              y: itm.percents,
              value: prepareNumber(itm.indicatorValue),
              units: itm.units,
            })),
            color: '#646CAA',
            dataLabels: [
              {
                inside: false,
                enabled: true,
                // format: '<div style="font-size: 16px;">' + '{point.y}%</div>',
                formatter: function () {
                  return `${prepareNumber(this.point.y)}%`;
                },
                useHTML: true,
                align: 'center',
                style: {
                  fontFamily: 'Montserrat Bold',
                  color: '#000',
                  fontSize: '16px',
                },
              },
              {
                inside: true,
                enabled: true,
                format:
                  '<div style="font-size: 16px; white-space: normal; text-align: center; line-height: .8;">' +
                  '<span style="white-space: nowrap;">{point.value}</span> <span style="white-space: nowrap; font-size: 12px; line-height: .5;">{point.units}</span></div>',
                useHTML: true,
                align: 'center',
                style: {
                  fontFamily: 'Montserrat Bold',
                  color: '#FFF',
                },
              },
            ],
          },
        ],
      };
    }

    return result;
  }, [chartData]);

  const tableConfig = useMemo(() => {
    let tableData = [];
    let tableFields = [];
    let tableFieldsCustom = [];

    if (tableInfo) {
      const periodLength = get(tableInfo, '[0].periodValues', []).length;

      tableFieldsCustom = [
        {
          label: '',
          width: '28%',
        },
        ...get(tableInfo, '[0].periodValues', []).map((itm, idx) => ({
          label:
            idx === 0
              ? format(new Date(itm?.period?.periodStart), 'На d MMMM yyyy', { locale: ru })
              : itm?.period?.name,
          colspan: 4,
          width: '24%',
        })),
      ];

      tableFields = [
        {
          label: (
            <NameTitleContainer>
              <Text
                data='Наименование'
                color={theme.colors.dark}
                font={TextFamily.ROBOTO_CONDENSED_REGULAR}
                size={'12px'}
              />
              <Text
                data='млрд руб.'
                color={theme.colors.emptyData}
                font={TextFamily.ROBOTO_CONDENSED_REGULAR}
                size={'12px'}
              />
            </NameTitleContainer>
          ),
          field: 'name',
          width: '28%',
          options: {
            text: 'left',
            levelStep: 10,
            firstLevelPadding: 0,
            secondLevelPadding: 0,
            headLabelWidth: '100%',
          },
          justifyContent: 'flex-start',
        },
        ...get(tableInfo, '[0].periodValues', [])
          .map((itm, idx) => [
            {
              label: 'План',
              field: `plan${idx}`,
              width: '6%',
              justifyContent: 'flex-end',
            },
            {
              label: 'БО',
              field: `bo${idx}`,
              width: '6%',
              justifyContent: 'flex-end',
            },
            {
              label: 'Исполнение',
              field: `execution${idx}`,
              width: '6%',
              justifyContent: 'flex-end',
            },
            {
              label: '%',
              field: `percent${idx}`,
              width: '6%',
            },
          ])
          .flat(),
      ];

      tableData = tableInfo.map((item) => ({
        code: item?.index || ' ',
        name: item?.name || ' ',
        ...Array(periodLength)
          .fill(0)
          .reduce((acc, arrItm, idx) => {
            const itm = get(item, `periodValues[${idx}]`);
            return {
              ...acc,
              [`plan${idx}`]: isNumber(itm?.originalPlanValue)
                ? prepareNumberForTable(itm?.originalPlanValue)
                : '-',
              [`bo${idx}`]: isNumber(itm?.correctedPlanValue)
                ? prepareNumberForTable(itm?.correctedPlanValue)
                : '-',
              [`execution${idx}`]: isNumber(itm?.factValue)
                ? prepareNumberForTable(itm?.factValue)
                : '-',
              [`percent${idx}`]: (
                <Text
                  data={isNumber(itm?.performance) ? prepareNumberForTable(itm?.performance) : '-'}
                  color={theme.colors.dark}
                  font={
                    item?.isBold
                      ? TextFamily.ROBOTO_CONDENSED_BOLD
                      : TextFamily.ROBOTO_CONDENSED_REGULAR
                  }
                />
              ),
            };
          }, {}),
        ...(item?.isBold
          ? {
              options: {
                family: theme.fonts.robotoCondensed.bold,
                styles: {
                  name: {
                    family: theme.fonts.montserrat.bold,
                  },
                },
              },
            }
          : {}),
      }));
    }

    return {
      tableData,
      tableFields,
      tableFieldsCustom,
    };
  }, [tableInfo]);

  const chartContent = useMemo(() => {
    if (chartDataLoading) {
      return <Loader />;
    }
    return <ColumnChart config={chartConfig} />;
  }, [chartDataLoading, chartConfig]);

  const tableContent = useMemo(() => {
    if (tableDataLoading) {
      return <Loader />;
    }
    return (
      <Table
        fields={tableConfig.tableFields}
        data={tableConfig.tableData}
        customTheadFields={tableConfig.tableFieldsCustom}
        sticky={false}
      />
    );
  }, [tableDataLoading, tableConfig]);

  return (
    <ComponentContainer>
      {chartContent}
      <TableWrapper>
        <Heading>
          <Title>Расходы</Title>
          <Addition>
            <BudgetModalFilters
              filter={typeFilter}
              setFilter={setTypeFilterCallback}
              showExpensesType
              fundingSourceMultiple
            />
          </Addition>
        </Heading>
        {tableContent}
      </TableWrapper>
    </ComponentContainer>
  );
});

const NameTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Title = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xxmd,
      family: theme.fonts.montserrat.semibold,
      lineHeight: '34px',
    })};
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Addition = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default TypeType;
