import styled from 'styled-components';
import { investmentFields } from './investmentFields';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import TableZone, { ZoneType } from '../../../components/TableZone';
import { useSorting } from '../../../hooks/useSorting';
import { DevelopmentStoreContext } from '../../../stores/development';
import { initialInvestmentSortingValues } from '../consts';
import { getEvoInvestments } from '../../../actions';
import Text, { TextFamily } from '../../../components/Text';
import { declOfNum } from '../../../utils/declOfNum';
import { theme } from '../../../styles/theme';
import { isNumber } from 'lodash';
import Card from '../../../components/Card';
import { AppStoreContext } from '../../../stores/app';
import { observer } from 'mobx-react-lite';
import ComplexTable from '../../../components/ComplexTable';

const DevelopmentInvestments: FC = observer(() => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const { setDevelopmentCollapsedHeaderData } = useContext(DevelopmentStoreContext);

  const { sorting, setSortingCallback } = useSorting({
    initialSortingValues: { ...initialInvestmentSortingValues },
    sortingName: 'developmentInvestmentSorting',
  });

  const [data, setData] = useState(null);
  const [zones, setZones] = useState(null);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedOmsu) {
      const fetchData = async () => {
        setDataLoading(true);
        try {
          const response = await getEvoInvestments({
            omsuId: selectedOmsu.id,
          });
          setData(response.data.items);
        } catch (e) {
          //
        } finally {
          setDataLoading(false);
        }
      };
      fetchData();
    }
  }, [selectedOmsu]);

  useEffect(() => {
    if (data) {
      const zones = data.reduce(
        (acc, item) => {
          const copy = { ...acc };
          item.zone === ZoneType.RED && copy.red.push(item);
          item.zone === ZoneType.GREEN && copy.green.push(item);
          return copy;
        },
        { red: [], green: [] },
      );
      setZones(zones);
    }
  }, [data]);

  useEffect(() => {
    if (zones) {
      setDevelopmentCollapsedHeaderData('investment', {
        redZoneCount: zones.red.length,
        greenZoneCount: zones.green.length,
      });
    }
  }, [zones]);

  const tableData = useMemo(() => {
    let result = [];

    if (zones) {
      result = [
        {
          selector: 'investments_red',
          title: <TableZone type={ZoneType.RED} />,
          plan: (
            <Text
              data={`${zones?.red?.length} ${declOfNum(zones?.red?.length, [
                'территория',
                'территории',
                'территорий',
              ])}`}
              font={TextFamily.ROBOTO_CONDENSED_REGULAR}
              color={theme.colors.dark}
              size={theme.fonts.sizes.sm}
            />
          ),
          options: {
            color: theme.colors.dark,
            colspan: {
              plan: 4,
            },
          },
          children: zones.red.map((itm) => ({
            title: itm.omsu?.name,
            plan: isNumber(itm.plan) ? itm.plan.toString() : ' ',
            fact: isNumber(itm.fact) ? itm.fact.toString() : ' ',
            prediction: isNumber(itm.forecast) ? itm.forecast.toString() : ' ',
            planPercent: isNumber(itm.execution) ? itm.execution.toString() : ' ',
            options: {
              styles: {
                planPercent: {
                  color: theme.colors.status.done_with_delay,
                },
                title: {
                  'font-weight': selectedOmsu?.id === itm.omsuId ? 'bold' : 'normal',
                },
              },
            },
          })),
        },
        {
          selector: 'investments_green',
          title: <TableZone type={ZoneType.GREEN} />,
          plan: (
            <Text
              data={`${zones?.green?.length} ${declOfNum(zones?.green?.length, [
                'территория',
                'территории',
                'территорий',
              ])}`}
              font={TextFamily.ROBOTO_CONDENSED_REGULAR}
              color={theme.colors.dark}
              size={theme.fonts.sizes.sm}
            />
          ),
          options: {
            color: theme.colors.dark,
            colspan: {
              plan: 4,
            },
          },
          children: zones.green.map((itm) => ({
            title: itm.omsu?.name,
            plan: isNumber(itm.plan) ? itm.plan.toString() : ' ',
            fact: isNumber(itm.fact) ? itm.fact.toString() : ' ',
            prediction: isNumber(itm.forecast) ? itm.forecast.toString() : ' ',
            planPercent: isNumber(itm.execution) ? itm.execution.toString() : ' ',
            options: {
              styles: {
                planPercent: {
                  color: theme.colors.status.done_with_delay,
                },
                title: {
                  'font-weight': selectedOmsu?.id === itm.omsuId ? 'bold' : 'normal',
                },
              },
            },
          })),
        },
      ];
    }

    return result;
  }, [zones, selectedOmsu]);

  return (
    <Card>
      <Content>
        <ComplexTable
          fields={investmentFields}
          data={tableData}
          sorting={sorting}
          setSortingCallback={setSortingCallback}
          loading={dataLoading}
        />
      </Content>
    </Card>
  );
});

const Content = styled.div`
  padding-top: 10px;
`;

export default DevelopmentInvestments;
