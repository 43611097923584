import { Modal } from '../../../../components/Modal';
import { connectModal } from '../../../../hocs/Modal';
import styled from 'styled-components';
import BudgetDebtModalHeader from './BudgetDebtModalHeader';
import { useState } from 'react';
import DebtTable from './DebtTable';
import DebtAndStructureTable from './DebtAndStructure';
import { useFilter } from '../../../../hooks/useFilter';
import { initialBudgetDebtModalFilterValues } from './consts';

export enum ViewType {
  DEBT = '1',
  DEBT_STRUCTURE = '2',
  DEBT_MUNICIPAL = '2',
}

const BudgetDebtModal = connectModal('budget-debt-modal')(({ handleHide, parentFilter }) => {

  const { filter, setFilterCallback: setFilter } = useFilter({
    initialFilterValues: { ...initialBudgetDebtModalFilterValues, ...parentFilter },
    name: 'budgetDebtModalFilter',
    dateFields: ['fromDate', 'toDate'],
    disableCache: true,
  });

  const types = [
    {
      id: 0,
      label: 'Динамика госдолга',
      value: ViewType.DEBT
    },
    {
      id: 1,
      label: 'Динамика и структура госдолга',
      value: ViewType.DEBT_STRUCTURE
    },
  ];

  const [type, setType] = useState(types[0]);

  const renderComponent = (type, filter) => {
    switch (type.value) {
      case ViewType.DEBT:
        return <DebtTable filter={filter} />;
      case ViewType.DEBT_STRUCTURE:
        return <DebtAndStructureTable filter={filter} />;
      default:
        return <DebtTable filter={filter} />;
    }
  };

  return (
    <Modal
      title={'Госдолг'}
      onClose={handleHide}
      width={'75%'}
      header={
        <BudgetDebtModalHeader
          currentType={type}
          typeList={types}
          setType={setType}
          filter={filter}
          setFilter={setFilter}
        />
      }
    >
      <Content>{renderComponent(type, filter)}</Content>
    </Modal>
  );
});

const Content = styled.div`
  padding: 0 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export default BudgetDebtModal;
