import styled from 'styled-components';
import { FC, useCallback, useMemo, useState } from 'react';
import Checkbox from '../Checkbox';
import SearchField from '../SearchField';
import { Button } from '../Button';
import Divider from '../Divider';
import uniqBy from 'lodash/uniqBy';
import { toJS } from 'mobx';

interface ListItem {
  id: number;
  label: string;
  value: string;
  checked: boolean;
}

interface Props {
  list: ListItem[];
  onClose: () => void;
  onAccept?: () => void;
  onClick: (item) => void;
  width?: string;
}

const SelectMultiple: FC<Props> = ({
  list: listProps,
  onClose,
  onClick,
  onAccept = () => ({}),
  width = 'auto',
}) => {

  const list = useMemo(() => {
    return uniqBy(listProps, 'id');
  }, [listProps]);

  const [search, setSearch] = useState<string>('');

  const filterChecked = useCallback((item) => {
    return item.checked;
  }, []);

  const filterUnchecked = useCallback((item) => {
    return !item.checked;
  }, []);

  const filterSearch = useCallback(
    (item) => {
      return item?.label?.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
    [search],
  );

  const sortSearch = useCallback(
    (a, b) => {
      const aIndex = a.label.toLowerCase().indexOf(search);
      const bIndex = b.label.toLowerCase().indexOf(search);
      return aIndex == bIndex ? 0 : aIndex > bIndex ? 1 : -1;
    },
    [search],
  );

  return (
    <ComponentContainer width={width}>
      <SearchField value={search} onChange={setSearch} />
      <List>
        <ListItems>
          {list
            .filter(filterChecked)
            .filter(filterSearch)
            .sort(sortSearch)
            .map(({ label, checked, id, value }) => {
              return (
                <Checkbox
                  key={id}
                  label={label}
                  name={value}
                  onChange={onClick}
                  checked={checked}
                />
              );
            })}
        </ListItems>
        {list.filter(filterUnchecked).filter(filterSearch).length !== 0 &&
          list.filter(filterChecked).filter(filterSearch).length !== 0 && <Divider />}
        <ListItems>
          {list
            .filter(filterUnchecked)
            .filter(filterSearch)
            .sort(sortSearch)
            .map(({ label, checked, id, value }) => {
              return (
                <Checkbox
                  key={id}
                  label={label}
                  name={value}
                  onChange={onClick}
                  checked={checked}
                />
              );
            })}
        </ListItems>
      </List>
      <Footer>
        <Button hollow onClick={onClose}>
          Отмена
        </Button>
        <Button
          onClick={() => {
            onAccept();
            onClose();
          }}
        >
          Применить
        </Button>
      </Footer>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: auto;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 12px 16px;
`;
const ListItems = styled.div`
  padding: 8px 24px 8px 16px;
`;

export default SelectMultiple;
