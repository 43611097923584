import styled from 'styled-components';
import { FC, useCallback, useContext, useState } from 'react';
import OrderedList from '../OrderedList';
import Checkbox from '../Checkbox';
import { Button } from '../Button';
import { font } from '../styled/mixins/fonts';
import { AnchorPopupStore } from '../../stores/anchorPopup';
import { isEmpty } from 'lodash';

interface Props {
  list: any[];
  onCloseCallback?: any;
}

const ResponsiblesList: FC<Props> = ({ list, onCloseCallback }) => {
  const onClose = useCallback(() => {
    AnchorPopupStore.hidePopup();
    onCloseCallback && onCloseCallback();
  }, [onCloseCallback]);

  const [recipients, setRecipients] = useState([]);
  const onSent = useCallback(() => {
    window.location.href = `mailto:${recipients.join(',')}`;
  }, [recipients]);

  const handleAll = useCallback(
    ({ check }) => {
      setRecipients([]);
      if (check) {
        setRecipients(list.map(({ contact }) => contact));
      }
    },
    [list],
  );

  const handleCheck = useCallback(
    (contact) => {
      if (recipients.includes(contact)) {
        setRecipients(recipients.filter((item) => item !== contact));
      } else {
        setRecipients([...recipients, contact]);
      }
    },
    [recipients],
  );

  return (
    <ComponentContainer>
      <Title>Написать ответственным</Title>
      <CheckboxesContainer>
        {list && (
          <>
            <Checkbox
              label={'Выбрать всех'}
              name={'all'}
              onChange={handleAll}
              checked={recipients.length === list.length}
            />
            <OrderedList list={list} order={(a, b) => (a.name > b.name ? 1 : -1)}>
              {(item) => (
                <Checkbox
                  key={item.id}
                  label={item.name}
                  name={item.contact}
                  onChange={() => handleCheck(item.contact)}
                  checked={recipients.includes(item.contact)}
                />
              )}
            </OrderedList>
          </>
        )}
      </CheckboxesContainer>
      <Actions>
        <Button hollow onClick={onClose}>
          Отмена
        </Button>
        <Button onClick={onSent} disabled={isEmpty(recipients)}>
          Применить
        </Button>
      </Actions>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div``;
const Title = styled.div`
  ${({ theme }) => font({ size: theme.fonts.sizes.xmd, family: theme.fonts.montserrat.semibold })}
  padding: 16px;
`;
const Actions = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 16px;
`;
const CheckboxesContainer = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
`;

export default ResponsiblesList;
