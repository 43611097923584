import styled from 'styled-components';
import SelectList from '../../../../components/Calendar/SelectList';
import Select from '../../../../components/Select';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button } from '../../../../components/Button';
import { font } from '../../../../components/styled/mixins/fonts';
import ColumnChart from '../../../../components/ColumnChart';
import { ModalsStore } from '../../../../stores/modal';
import { NsiStoreContext } from '../../../../stores/nsi';
import { observer } from 'mobx-react-lite';
import SelectMultiple from '../../../../components/SelectMultiple';
import { groupBy } from 'lodash';
import { FilterTypes } from '../../../../components/BudgetModalFilters';
import NoData from '../../../../components/NoData';
import { prepareNumber } from '../../../../utils/prepareNumber';

interface Props {
  data: any;
  filter: any;
  setFilter: any;
}

const DynamicBudget: FC<Props> = observer(({ data, filter, setFilter }) => {
  const { BudgetCIOGV, FundingSources } = useContext(NsiStoreContext);

  const [listFundingSource, setListFundingSource] = useState(FundingSources);

  const [listCIOGV, setListCIOGV] = useState(BudgetCIOGV);

  useEffect(() => {
    setListFundingSource(FundingSources);
  }, [FundingSources]);
  useEffect(() => {
    setListCIOGV(BudgetCIOGV);
  }, [BudgetCIOGV]);
  const [periodTypeList, setPeriodTypeList] = useState([
    {
      id: 0,
      label: 'За год',
      value: 'year',
      checked: true,
    },
    {
      id: 1,
      label: 'За квартал',
      value: 'quarter',
      checked: false,
    },
    {
      id: 2,
      label: 'За месяц',
      value: 'month',
      checked: false,
    },
  ]);

  const initializeValue = useCallback((list, value) => {
    const valuesArr = value.split(',');
    return list.map((item) =>
      valuesArr.find((itm) => item.id === itm || item.value === itm)
        ? { ...item, checked: true }
        : item,
    );
  }, []);

  //Initialize multiple selects
  useEffect(() => {
    if (BudgetCIOGV && filter?.ciogv) {
      setListCIOGV(initializeValue(BudgetCIOGV, filter?.ciogv));
    }
  }, [BudgetCIOGV, filter]);

  const handleCIOGVLocalList = ({ check, name }) => {
    const filterList = (list) => {
      return list.map((item) => (item.value === name ? { ...item, checked: check } : item));
    };
    setListCIOGV(filterList(listCIOGV));
  };

  const handleAcceptCIOGVMultipleList = () => {
    const prepareIds = (list) => {
      return list
        .filter((item) => item.checked)
        .map((item) => item.id)
        .join(',');
    };

    setFilter('ciogv', prepareIds(listCIOGV));
  };

  const onFundingSourcesClick = useCallback(({ check, name }) => {
    const filterList = (list) => {
      return list.map((item) => (item.value === name ? { ...item, checked: check } : item));
    };
    setListFundingSource(filterList);
  }, []);

  const handleAcceptFundingSourceList = () => {
    const prepareIds = (list) => {
      return list
        .filter((item) => item.checked)
        .map((item) => item.id)
        .join(',');
    };

    setFilter('fundingSource', prepareIds(listFundingSource));
  };

  const onPeriodTypeClick = useCallback((e: any) => {
    setFilter('selectedPeriodType', e);
  }, []);

  const chartConfig = useMemo(() => {
    let result = {};

    const filledChart = (data || []).some((el) => el.indicatorValue);
    if (data && filledChart) {
      const groupedData = groupBy(data, 'id');
      result = {
        credits: {
          enabled: false,
        },
        title: {
          text: '',
        },
        xAxis: {
          categories: data.reduce(
            (acc, itm) => (acc.includes(itm.period.name) ? acc : [...acc, itm.period.name]),
            [],
          ),
          labels: {
            style: {
              fontFamily: 'Roboto Condensed Regular',
              fontSize: 12,
            },
          },
        },
        legend: {
          verticalAlign: 'top',
          align: 'right',
        },
        yAxis: {
          visible: false,
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: Object.keys(groupedData).map((element, idx) => {
          const el = groupedData[element][0];
          let name = el?.name;
          if (el.id === 336) {
            name = 'План';
          } else if (el.id === 337) {
            name = 'Касса (год)';
          } else if (el.id === 338) {
            name = 'Касса (текущая дата)';
          }
          return {
            type: 'column',
            name,
            color: idx === 0 ? '#172B75' : idx === 1 ? '#646CAA' : '#A3A9D4',
            data: groupedData[element].map((itm) => itm?.indicatorValue),
            stack: idx === 0 ? 'male' : idx === 1 ? 'males' : 'female',
            dataLabels: {
              inside: false,
              enabled: true,
              // format: '<div style="font-size: 16px;">' + '{point.y}</div>',
              formatter: function () {
                return prepareNumber(this.point.y);
              },
              useHTML: true,
              align: 'center',
              style: {
                fontFamily: 'Montserrat Bold',
                color: '#000',
                fontSize: "16px",
              },
            },
          };
        }),
      };
    }

    return result;
  }, [data]);

  return (
    <>
      <ComponentContainer>
        <Heading>
          <Title>Динамика исполнения бюджета</Title>
          <Addition>
            <Select
              renderList={({ active, onClose }) => (
                <SelectList
                  active={active}
                  onClose={onClose}
                  list={periodTypeList}
                  onClick={onPeriodTypeClick}
                />
              )}
              label={'За год'}
              value={filter?.selectedPeriodType}
              fieldFullsize={false}
            />
            <Select
              renderList={({ active, onClose }) => (
                <SelectMultiple
                  onClose={onClose}
                  list={listFundingSource}
                  onClick={onFundingSourcesClick}
                  onAccept={handleAcceptFundingSourceList}
                  width={'500px'}
                />
              )}
              label={'Источник финанс-я'}
              value={filter?.fundingSource}
              fieldFullsize={false}
              fullHeight
            />
            <Select
              renderList={({ active, onClose }) => (
                <SelectMultiple
                  onClose={onClose}
                  list={listCIOGV}
                  onClick={(e) => handleCIOGVLocalList(e)}
                  onAccept={() => handleAcceptCIOGVMultipleList()}
                />
              )}
              fullHeight
              label={'ЦИОГВ'}
              value={''}
              fieldFullsize={false}
            />
            <Button
              hollow
              onClick={() =>
                ModalsStore.showModal('dynamic-budget-modal', { initialFilterState: filter })
              }
            >
              Подробнее...
            </Button>
          </Addition>
        </Heading>
        <Content>
          {Object.keys(chartConfig).length === 0 ? (
            <NoData description={'Нет данных'} />
          ) : (
            <ColumnChart config={chartConfig} />
          )}
        </Content>
      </ComponentContainer>
    </>
  );
});

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Title = styled.div`
  ${({ theme }) =>
    font({
      family: theme.fonts.montserrat.semibold,
      size: theme.fonts.sizes.xxmd,
      lineHeight: '34px',
    })}
`;
const Addition = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Content = styled.div``;

export default DynamicBudget;
