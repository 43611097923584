import React, { FC } from 'react';
import styled from 'styled-components';
import LoaderIcon from '../../../assets/icons/LoaderIcon';

interface Props {
  alternative?: boolean;
}

const LoaderAnimatedIcon: FC<Props> = ({ alternative = false }) => {
  return (
    <IconWrapper>
      <LoaderIcon color={alternative} />
    </IconWrapper>
  );
};

const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export default LoaderAnimatedIcon;
