import Select from '../../../components/Select';
import SelectList from '../../../components/Calendar/SelectList';
import { TextButton } from '../../../components/Button';
import { ModalsStore } from '../../../stores/modal';
import styled from 'styled-components';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import SelectMultiple from '../../../components/SelectMultiple';
import { AppStoreContext } from '../../../stores/app';
import { NsiStoreContext } from '../../../stores/nsi';
import BudgetMunicipalDebtModal from './BudgetMunicipalDebtModal';

interface Props {
  filter: any;
  setFilter: any;
  setMassFilterCallback: any;
}

const BudgetMunicipalDebtAddition: FC<Props> = ({ filter, setFilter, setMassFilterCallback }) => {

  const { years } = useContext(NsiStoreContext);
  const { omsuList } = useContext(AppStoreContext);

  const [listThree, setListThree] = useState([]);

  useEffect(() => {
    setListThree(
      omsuList.map((item) => ({
        ...item,
        checked: filter.selectedOmsu ? filter.selectedOmsu.split(',').includes(item.id) : false,
      })),
    );
  }, [omsuList]);

  useEffect(() => {
    if (omsuList?.length && !filter.selectedOmsu) {
      setFilter('selectedOmsu',
        omsuList?.filter(item => {
          switch (item.label) {
            case 'Балашиха':
            case 'Красногорск':
            case 'Одинцовский':
            case 'Подольск': return true;
          
            default: return false;
          }
        })
        .map((item) => item.id)
        .join(',')
      )
    }
  }, [omsuList])

  useEffect(() => {
    if (
      years?.length &&
      !filter.selectedYear
    ) {
      const currentYear =
        years.find((el) => el.appliesToYear === new Date().getFullYear()) || years[0];
      onYearClick(currentYear);
    }
  }, [years, filter.selectedYear]);

  const onYearClick = useCallback(
    (e: any) => {
      setFilter('selectedYear', e);
    },
    [],
  );

  const handleLocalListThree = useCallback(({ check, name }) => {
    setListThree((list) =>
      list.map((item) => (item.value === name ? { ...item, checked: check } : item)),
    );
  }, []);

  const onListAccept = useCallback((listThree) => {
    const selectedElements = listThree
      .filter((item) => item.checked)
      .map((item) => item.id)
      .join(',');
      setFilter('selectedOmsu', selectedElements);
  }, []);

  const listOfFilters = useMemo(() => {
    
    const selectedOmsuCount = filter?.selectedOmsu.trim() && filter?.selectedOmsu.split(',')?.length;
    
    return [
      {
        element: (
          <Select
            renderList={({ active, onClose }) => (
              <SelectList active={active} onClose={onClose} list={years} onClick={onYearClick} />
            )}
            label={''}
            value={filter.selectedYear}
            fieldFullsize={false}
          />
        )
      },
      {
        element: (
          <Select
            renderList={({ active, onClose }) => (
              <SelectMultiple
                onClose={onClose}
                list={listThree}
                onClick={handleLocalListThree}
                onAccept={() => onListAccept(listThree)}
              />
            )}
            fullHeight
            label={selectedOmsuCount ? `Сравнить - ${selectedOmsuCount}` : 'Сравнить'}
            value={''}
            fieldFullsize={false}
          />
        )
      }
    ]
  }, [filter, years, listThree])


  return (
    <ComponentContainer>
      {listOfFilters?.map(itm => itm.element)}
      <TextButton onClick={() => ModalsStore.showModal('budget-municipal-debt-modal', { parentFilters: filter })}>
        Подробнее...
      </TextButton>
      <BudgetMunicipalDebtModal />
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export default BudgetMunicipalDebtAddition;
