import styled from 'styled-components';
import Select from '../../../components/Select';
import { Calendar } from '../../../components/Calendar';
import CalendarCell from '../../../components/Calendar/CalendarCell';
import SelectList from '../../../components/Calendar/SelectList';
import { calendarConfig } from './calendarConfig';
import { FC, useCallback, useEffect, useState } from 'react';
import YearAndMonthSelector from '../../../components/YearAndMonthSelector';
import { risksEnum, risksFilterEnum } from '../consts';
import { ru } from 'date-fns/locale';
import {
  startOfYear,
  endOfYear,
  addYears,
  setMonth,
  setYear,
  setDate,
  startOfMonth,
  endOfMonth,
  format,
  isSameDay,
} from 'date-fns';
import { monthsNames } from '../../../config/consts';
import { getStorageValue, setStorageValue } from '../../../utils/handleStorageValue';

interface Props {
  setFilterCallback: any;
  setMassFilterCallback: any;
  filter: any;
}

const storageName = 'riskAdditionalDates';

const RisksAddition: FC<Props> = ({ filter, setFilterCallback, setMassFilterCallback }) => {
  // const [financeFilter, setFinanceFilter] = useState();
  const [calendarViewFilter, setCalendarViewFilter] = useState(
    getStorageValue({ name: storageName, values: calendarConfig[0] }),
  );
  const [showCalendarAddition, setShowCalendarAddition] = useState<boolean>(false);

  useEffect(() => {
    setStorageValue({ name: storageName, values: calendarViewFilter });
  }, [calendarViewFilter]);

  useEffect(() => {
    if(!filter?.fromDate && !filter?.toDate){
      setCalendarViewFilter(calendarConfig[0]);
    }
  }, [filter?.fromDate, filter?.toDate])

  // const handleFinanceFilter = useCallback(({ label }) => {
  //   setFinanceFilter(label);
  // }, []);

  const handleCalendarFilter = useCallback((el, onClose) => {
    if (el.id === 0) {
      setMassFilterCallback({
        fromDate: null,
        toDate: null,
      });
      if (onClose) onClose();
    } else if (el.id === 1 || el.id === 2) {
      const date = el.id === 1 ? new Date() : addYears(new Date(), 1);
      setMassFilterCallback({
        fromDate: startOfYear(date),
        toDate: endOfYear(date),
      });
      if (onClose) onClose();
    } else if (el.id === 3 || el.id === 4) {
      setShowCalendarAddition(true);
    }
    setCalendarViewFilter(el);
  }, []);

  const handleSelectMonth = useCallback((el) => {
    const date = setYear(setMonth(setDate(new Date(), 1), Number(el.month.value)), el.year.value);
    setMassFilterCallback({
      fromDate: startOfMonth(date),
      toDate: endOfMonth(date),
    });
    setShowCalendarAddition(false);
  }, []);

  const handleSelectCalendar = useCallback((el) => {
    setMassFilterCallback(el);
    setShowCalendarAddition(false);
  }, []);

  const handleClose = useCallback(() => {
    setShowCalendarAddition(false);
  }, []);

  const getDateSelectLabel = useCallback(() => {
    if (calendarViewFilter.id === 0 || calendarViewFilter.id === 1 || calendarViewFilter.id === 2) {
      return calendarViewFilter.label;
    } else if (calendarViewFilter.id === 3) {
      const date = filter.fromDate ? filter.fromDate : new Date();
      return `${monthsNames[date.getMonth()]} ${date.getFullYear()}`;
    } else if (calendarViewFilter.id === 4) {
      const tempFromDate = filter.fromDate ? filter.fromDate : new Date();
      const tempToDate = filter.toDate ? filter.toDate : new Date();
      if (isSameDay(tempFromDate, filter.toDate)) {
        return `${format(tempFromDate, 'd LLL yyyy', { locale: ru })}`;
      } else {
        return `${format(tempFromDate, 'd LLL yyyy', { locale: ru })} - ${format(
          tempToDate,
          'd LLL yyyy',
          { locale: ru },
        )}`;
      }
    }
    return 'Выберите период';
  }, [calendarViewFilter, filter.fromDate]);

  return (
    <RisksAdditionWrapper id={'risks-wrapper'}>
      <Select
        renderList={({ active, onClose }) => (
          <SelectList
            active={active}
            onClose={onClose}
            list={risksFilterEnum}
            onClick={(e: any) => {
              setMassFilterCallback(e.filter);
            }}
          />
        )}
        label={
          filter.isNationalProject
            ? 'Национальный проект'
            : filter.hasFederalFunding
            ? 'Федеральное финансирование'
            : filter.isMunicipalBudget
            ? 'Муниципальный бюджет'
            : 'Все'
        }
        value={''}
      />
      <Select
        renderList={({ active, onClose }) => (
          <SelectList
            active={active}
            onClose={onClose}
            list={risksEnum.slice(1)}
            onClick={(e: any) => {
              setMassFilterCallback(e.filter);
            }}
          />
        )}
        label={filter.isRisk ? 'Риск' : filter.isBeforeRisk ? 'Предриск' : 'Открытые'}
        value={''}
      />
      <Select
        label={getDateSelectLabel()}
        value={''}
        fullHeight
        renderList={({ onClose, active }) => (
          <>
            {showCalendarAddition && calendarViewFilter.id === 3 && (
              <YearAndMonthSelector
                handleClose={() => {
                  handleClose();
                  onClose();
                }}
                handleSelect={handleSelectMonth}
                initialDate={filter?.fromDate}
              />
            )}
            {showCalendarAddition && calendarViewFilter.id === 4 && (
              <Calendar
                fromDate={filter?.fromDate}
                toDate={filter?.toDate}
                withFooter={true}
                renderItem={(item) => <CalendarCell {...item} />}
                onClick={() => null}
                handleClose={() => {
                  handleClose();
                  onClose();
                }}
                handleSelect={handleSelectCalendar}
              />
            )}
            {!showCalendarAddition && (
              <SelectList list={calendarConfig} active={active} onClick={(e) => handleCalendarFilter(e, onClose)} />
            )}
          </>
        )}
      />
    </RisksAdditionWrapper>
  );
};

const RisksAdditionWrapper = styled.div`
  display: flex;

  > div {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ListWrapper = styled.div`
  padding: 8px 0;
`;

export default RisksAddition;
