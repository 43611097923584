import { createContext } from 'react';
import { makeAutoObservable } from 'mobx';
import { getCalendarExpenseInfo, getCalendarIncomeInfo, getLevelBudget, getNsi } from '../actions';
import orderBy from 'lodash/orderBy';
import { capitalizeFirstLetter } from '../utils/prepareString';
import { forEach, groupBy } from 'lodash';
import { AppStore } from './app';
import { format, isAfter, isBefore, isSameDay } from 'date-fns';
import { ru } from 'date-fns/locale';

class Nsi {
  constructor() {
    makeAutoObservable(this);
  }

  setNsi = (nsiName, data) => {
    this[nsiName] = data;
  };

  Sphere = [];
  EntityType = [];
  WorkType = [];
  FundingType = [];
  ProcedureStatus = [];
  curators = [];
  CIOGV = [];
  levelBudget = [];
  BudgetExecutionContext = [];
  Territories = [];
  Program = [];
  FundingSources = [];
  ExpenseTypes = [];
  RentObjects = [];
  CalendarIncomeInfo = [];
  BudgetProgram = [];
  NacProject = [];
  BudgetCIOGV = [];
  CalendarExpenseInfo = [];
  CalendarYears = [];
  CalendarQuarters = [];
  IndicatorCIOGV = [];

  fetchNsi = async (nsiName) => {
    try {
      const response = await getNsi(nsiName);
      const list = orderBy(response.data?.items || [], ['order']).map((el) => ({
        ...el,
        value: el.id,
        label: el.name,
        id: el.id,
      }));
      this.setNsi(nsiName, list);
      if (nsiName === 'IndicatorCIOGV' && !AppStore.selectedCiogv) AppStore.setCIOGV(list[0]);
    } catch (e) {
      //
    }
  };

  years = [];
  months = {};

  yearsRazdel = [];
  monthsRazdel = [];

  prepareValue = (el) => ({
    ...el,
    label: el.name,
    value: el.id,
    id: el.id,
    appliesToYear: el.appliesToYear,
  });

  fetchCalendar = async () => {
    try {
      const response = await getNsi('Calendar');
      const data = response.data?.items || [];
      const preparedYears = [];
      const preparedMonths = {};
      const allYearsElement = data.find((el) => !el.parentId);
      if (allYearsElement) {
        // preparedYears.push(this.prepareValue(allYearsElement));
        const years = orderBy(
          data.filter((el) => el.parentId === allYearsElement.id),
          ['order'],
        ).map(this.prepareValue);
        preparedYears.push(...years);
        years.forEach((el) => {
          preparedMonths[el.id] = orderBy(
            data
              .filter((item) => item.appliesToYear === el.appliesToYear)
              .map((item, idx) => ({
                ...item,
                name: idx === 0 ? 'Все месяцы' : capitalizeFirstLetter(item.name),
              })),
            ['order'],
          ).map(this.prepareValue);
        });
      }
      this.months = preparedMonths;
      this.years = preparedYears;
      // if (!AppStore.selectedYear) AppStore.setSelectedYear(preparedYears[0]);
    } catch (e) {
      //
    }
  };

  fetchCalendarYears = async () => {
    try {
      const response = await getNsi('CalendarYears');
      const data = response.data?.items || [];
      const list = orderBy(data, ['order']).map(this.prepareValue);
      this.CalendarYears = list;
      if (!AppStore.selectedYear) AppStore.setSelectedYear(list[0]);
    } catch (e) {
      //
    }
  };

  currentMonth = '';

  fetchCalendarRazdel = async () => {
    try {
      const response = await getNsi('CalendarRazdel?razdelId=3');
      const data = response.data?.items || [];
      // console.log(this.yearsRazdel);
      this.yearsRazdel = [];
      forEach(data, (item) => {
        if (!this.yearsRazdel.includes(item.appliesToYear)) {
          this.yearsRazdel.push(item.appliesToYear);
        }
      });
      // console.log(this.yearsRazdel);
      this.yearsRazdel = this.yearsRazdel.map((item) => ({
        id: item,
        label: item,
        value: item,
      }));
      // console.log(this.yearsRazdel);
      this.monthsRazdel = groupBy(data, (item) => item.appliesToYear);
      Object.keys(this.monthsRazdel).forEach(
        (key) =>
          (this.monthsRazdel[key] = this.monthsRazdel[key].map((item, idx) => ({
            idx: idx,
            value: item.id,
            label: item.name,
            ...item,
          }))),
      );
      this.currentMonth = this.monthsRazdel[format(new Date(), 'yyyy', { locale: ru })]
        ? this.monthsRazdel[format(new Date(), 'yyyy', { locale: ru })].find(
            (item) =>
              (isAfter(new Date(), new Date(item.periodStart)) &&
                isBefore(new Date(), new Date(item.periodEnd))) ||
              isSameDay(new Date(), new Date(item.periodEnd)) ||
              isSameDay(new Date(), new Date(item.periodStart)),
          )
        : '';
    } catch (e) {
      //
    }
  };

  fetchCurators = async () => {
    try {
      const response = await getNsi('Curator');
      this.curators = (response.data?.items || []).map((el) => ({
        id: el.id,
        value: el.id,
        label: el.shortName,
      }));
    } catch (e) {
      //
    }
  };

  fetchLevelBudget = async (level) => {
    try {
      const response = await getLevelBudget(level);
      this.levelBudget = (response.data?.items || []).map((el) => ({
        id: el.id,
        value: el.id,
        label: el.name,
      }));
    } catch (e) {
      //
    }
  };
}

export const NsiStore = new Nsi();
export const NsiStoreContext = createContext(NsiStore);
