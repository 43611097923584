import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { ModalsStoreContext } from '../../stores/modal';

export const connectModal = (modalName) => (Component) => {
  return observer((componentProps) => {
    const ModalStore = useContext(ModalsStoreContext);
    if (!ModalStore.modals[modalName] || !ModalStore.modals[modalName].show) {
      return null;
    }

    return (
      <Component
        handleHide={() => ModalStore.hideModal(modalName)}
        {...componentProps}
        {...ModalStore.modals[modalName].props}
      />
    );
  });
};
