import MainObjects from './MainObjects';
import MainAgenda from './MainAgenda';
import MainBudget from './MainBudget';
import MainDevelopment from './MainDevelopment';
import MainIndicators from './MainIndicators';
import IndicatorsMain from '../Indicators/IndicatorsMain';
import React from 'react';

export const mainConfig = [
  {
    id: 0,
    header: {
      title: 'Объекты',
    },
    content: <MainObjects />,
  },
  {
    id: 1,
    header: {
      title: 'Повестка',
    },
    content: <MainAgenda />,
  },
  {
    id: 2,
    header: {
      title: 'Бюджет',
    },
    content: <MainBudget />,
  },
  {
    id: 3,
    header: {
      title: 'Развитие',
    },
    content: <MainDevelopment />,
  },
  {
    id: 4,
    header: {
      title: 'Показатели',
    },
    content: <MainIndicators />,
  },
  {
    id: 5,
    header: {
      title: 'Показатели СЭР',
      collapse: false,
    },
    content: <IndicatorsMain />,
  },
];
