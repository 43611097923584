export const generateObjectsChartConfig = (arr) => {
  const list = [];
  list.push({
    id: 0,
    name: 'Риск',
    y: arr[0],
    color: '#FC5200',
  });
  list.push({
    id: 1,
    name: 'Предриск',
    y: arr[1],
    color: '#F2C94C',
  });
  list.push({
    id: 2,
    name: 'Открыто',
    y: arr[2],
    color: '#64AA7A',
  });
  return list;
};

const prepareY = (y1, y2) => {
  let y = parseFloat(y1);
  if (y2) y = parseFloat(y1) - parseFloat(y2);
  if (y < 0) y = 0;
  return y;
};

export const generateRadialChartConfig = (arr) => {
  const row = [
    {
      name: 'График',
      data: [
        {
          name: 'Всего',
          y: prepareY(arr[0], arr[3]),
          color: '#646CAA',
        },
        {
          name: 'Госконтракт',
          y: prepareY(arr[1], arr[4]),
          color: '#848ABE',
        },
        {
          name: 'Касса',
          y: prepareY(arr[2], arr[5]),
          color: '#A3A9D4',
        },
      ],
    },
  ];
  if (arr[3] !== undefined) {
    row.push({
      name: 'График',
      data: [
        {
          name: 'Всего',
          y: parseFloat(arr[3]),
          color: 'url(#custom-pattern64)',
        },
        {
          name: 'Госконтракт',
          y: parseFloat(arr[4]),
          color: 'url(#custom-pattern84)',
        },
        {
          name: 'Касса',
          y: parseFloat(arr[5]),
          color: 'url(#custom-patternA3)',
        },
      ],
    });
  }
  return row;
};
