import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getAgendaBudgetData } from '../../../actions';
import { observer } from 'mobx-react-lite';
import { AppStoreContext } from '../../../stores/app';
import { monthsNamesShort } from '../../../config/consts';
import ComplexTable from '../../../components/ComplexTable';

interface Props {
  filter: any;
}

const AgendaBudgetTable: FC<Props> = observer(({ filter }) => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fields = useMemo(() => {
    return [
      [
        {
          id: 0,
          label: '',
          sorting: false,
          width: '20%',
        },
        {
          id: 1,
          label: '',
          sorting: false,
          width: '8%',
        },
        {
          id: 2,
          label: '',
          sorting: false,
          width: '8%',
        },
        {
          id: 3,
          label: '',
          sorting: false,
          width: '8%',
        },
        {
          id: 4,
          label: filter.selectedMonth?.appliesToYear,
          sorting: false,
          colspan: 3,
          width: '40%',
          options: {
            'background-color': 'rgba(100, 108, 170, 0.08)',
            'border-left': '1px solid rgba(100, 108, 170, 0.24)',
            'border-radius': '0px 22px 0px 0px;',
            'text-align': 'center',
          },
        },
      ],
      [
        {
          id: 1,
          label: '',
          sorting: false,
          // field: ['name', 'unit'],
          field: 'name',
          width: '55%',
        },
        {
          id: 2,
          label: `Факт ${filter.selectedMonth ? filter.selectedMonth.appliesToYear - 1 : ''}`,
          sorting: false,
          field: 'factPrePreviousValue',
          width: '7.5%',
          options: {
            'text-align': 'right',
          },
        },
        {
          id: 3,
          label: `Факт на 1 ${
            filter.selectedMonth
              ? monthsNamesShort[
                  filter.selectedMonth.idx - 1 === -1 ? 11 : filter.selectedMonth.idx - 1
                ]
              : ''
          } ${filter.selectedMonth ? filter.selectedMonth.appliesToYear - 1 : ''}`,
          sorting: false,
          field: 'factPreviousValue',
          width: '7.5%',
          options: {
            'text-align': 'right',
          },
        },
        {
          id: 4,
          label: `Темп на 1 ${
            filter.selectedMonth
              ? monthsNamesShort[
                  filter.selectedMonth.idx - 1 === -1 ? 11 : filter.selectedMonth.idx - 1
                ]
              : ''
          } ${filter.selectedMonth ? filter.selectedMonth.appliesToYear - 1 : ''}/${
            filter.selectedMonth ? filter.selectedMonth.appliesToYear - 2 : ''
          }`,
          sorting: false,
          field: 'factPreviousDynamics',
          width: '7.5%',
        },
        {
          id: 5,
          label: 'Уточненный план',
          sorting: false,
          field: 'planValue',
          width: '7.5%',
          options: {
            'background-color': 'rgba(100, 108, 170, 0.08)',
            'border-left': '1px solid rgba(100, 108, 170, 0.24)',
            'text-align': 'right',
          },
        },
        {
          id: 6,
          label: `Факт на 1 ${
            filter.selectedMonth
              ? monthsNamesShort[filter.selectedMonth.idx === 12 ? 0 : filter.selectedMonth.idx]
              : ''
          }`,
          sorting: false,
          field: 'factValue',
          width: '7.5%',
          options: {
            'background-color': 'rgba(100, 108, 170, 0.08)',
            'text-align': 'right',
          },
        },
        {
          id: 7,
          label: `Темп на 1 ${
            filter.selectedMonth
              ? monthsNamesShort[filter.selectedMonth.idx === 12 ? 0 : filter.selectedMonth.idx]
              : ''
          } ${filter.selectedMonth ? filter.selectedMonth.appliesToYear : ''}/${
            filter.selectedMonth ? filter.selectedMonth.appliesToYear - 1 : ''
          }`,
          sorting: false,
          field: 'factCurrentDynamics',
          width: '7.5%',
          options: {
            'background-color': 'rgba(100, 108, 170, 0.08)',
          },
        },
      ],
    ];
  }, [filter.selectedMonth, selectedOmsu]);

  const fetchData = useCallback(async () => {
    if (selectedOmsu) {
      try {
        setLoading(true);
        const params = {
          omsuId: selectedOmsu.id,
          periodId: filter.selectedMonth.id,
        };
        const response = await getAgendaBudgetData(params);
        setData(response.data?.items || []);
      } catch (e) {
        //
      } finally {
        setLoading(false);
      }
    }
  }, [selectedOmsu, filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return <ComplexTable data={data} fields={fields} loading={loading} />;
});

export default AgendaBudgetTable;
