import styled from 'styled-components';
import Logo from '../../../assets/icons/Logo';
import MenuIcon from '../../../assets/icons/MenuIcon';
import MenuItem from './MenuItem';
import menuBg from '../../../assets/images/map_mo.png';
import { FC } from 'react';
import ActionIcon from '../../../components/ActionIcon';
import { menu } from '../../../config/routes';
import { observer } from 'mobx-react-lite';
import { isMobile } from 'react-device-detect';

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
}

const Menu: FC<Props> = observer(({ isOpen, toggleOpen }) => {
  return (
    <>
      <MenuWrapper isOpen={isOpen} isMobile={isMobile}>
        <MenuHeader>
          <IconWrapper>
            <ActionIcon action={toggleOpen} color={'#fff'} opacity={1}>
              <MenuIcon />
            </ActionIcon>
          </IconWrapper>
          <LogoWrapper visible={isOpen}>
            <Logo />
          </LogoWrapper>
        </MenuHeader>
        <MenuContent>
          {menu.map((item) => (
            <MenuItem key={`link-${item.path}`} {...item} />
          ))}
        </MenuContent>
      </MenuWrapper>
      {isOpen && isMobile && <Overlay />}
    </>
  );
});

const LogoWrapper = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  font-size: 0;
`;

const MenuContent = styled.div`
  padding: 0 12px;
`;

const IconWrapper = styled.div`
  margin-right: 16px;
`;

const MenuWrapper = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  background: url(${menuBg}), ${({ theme }) => theme.colors.primary};
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 750px;
  width: ${({ isOpen }) => (isOpen ? '280px' : '80px')};
  flex: 0 0 auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
`;

const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px 56px 20px;
`;
const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.01);
`;

export default Menu;
