export const investmentFields = [
  [
    {
      id: 0,
      label: '',
      field: 'title',
      sorting: false,
      width: '85%',
    },
    {
      id: 1,
      label: (
        <>
          План
          <br />
          СЭР
        </>
      ),
      field: 'plan',
      sorting: true,
      width: '3%',
    },
    {
      id: 2,
      label: (
        <>
          Факт
          <br />6 мес.
        </>
      ),
      field: 'fact',
      sorting: true,
      width: '5%',
    },
    {
      id: 3,
      label: (
        <>
          План
          <br />%
        </>
      ),
      field: 'planPercent',
      sorting: true,
      width: '5%',
    },
  ],
];
