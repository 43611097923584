import { FC } from 'react';
import styled from 'styled-components';
import { Item } from '..';
import { font } from '../../styled/mixins/fonts';

const LegendItem: FC<Item> = ({ color, label }) => {
  return (
    <ComponentContainer>
      <Dot color={color} />
      <Label>{label}</Label>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
`;
const Dot = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 4px;
  flex: 0 0 auto;
`;
const Label = styled.div`
  ${({ theme }) => font({ color: theme.colors.dark })};
`;

export default LegendItem;
