const SortUp = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.3'
      d='M9.43592 14C8.83592 14 8.41925 14.2667 8.18592 14.8C7.95258 15.35 8.05258 15.8333 8.48592 16.25L11.0859 18.85C11.2026 18.9833 11.3359 19.075 11.4859 19.125C11.6526 19.1917 11.8276 19.225 12.0109 19.225C12.1776 19.225 12.3359 19.1917 12.4859 19.125C12.6526 19.075 12.8026 18.9833 12.9359 18.85L15.5359 16.25C15.9526 15.8333 16.0442 15.35 15.8109 14.8C15.5776 14.2667 15.1692 14 14.5859 14H9.43592Z'
      fill='black'
      fillOpacity='0.56'
    />
    <path
      d='M9.43592 10C8.83592 10 8.41925 9.73333 8.18592 9.2C7.95258 8.65 8.05258 8.16667 8.48592 7.75L11.0859 5.15C11.2026 5.01667 11.3359 4.925 11.4859 4.875C11.6526 4.80833 11.8276 4.775 12.0109 4.775C12.1776 4.775 12.3359 4.80833 12.4859 4.875C12.6526 4.925 12.8026 5.01667 12.9359 5.15L15.5359 7.75C15.9526 8.16667 16.0442 8.65 15.8109 9.2C15.5776 9.73333 15.1692 10 14.5859 10H9.43592Z'
      fill='black'
      fillOpacity='0.56'
    />
  </svg>
);

export default SortUp;
