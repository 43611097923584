let timer;

export const scrollIntoView = (element, top?) => {
  if (element)
    if (top) {
      clearTimeout(timer);
      document.body.classList.add('body-scroll-margin-top');
      element.scrollIntoView({
        behavior: 'smooth',
      });
      timer = setTimeout(() => {
        document.body.classList.remove('body-scroll-margin-top');
      }, 500);
    } else {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
};

export const scrollIntoViewBySelector = (selector, top?) => {
  const element = document.getElementById(selector);
  scrollIntoView(element, top);
};
