import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import CascadeTableRow from './CascadeTableRow';

interface Props {
  config: any[];
  children: (item: any, idx: number) => ReactNode;
}

const CascadeTable: FC<Props> = ({ config, children }) => {
  return <ComponentContainer>{config.map((item, idx) => children(item, idx))}</ComponentContainer>;
};

const ComponentContainer = styled.div``;

export default CascadeTable;
