import styled from 'styled-components';
import { FC } from 'react';
import Amount from '../../../../../components/Amount';

interface Props {
  amount: number;
}

const RisksIndicatorsModalHeader: FC<Props> = ({ amount }) => {
  return (
    <ComponentContainer>
      {amount && <Amount amount={amount} />}
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
`;

export default RisksIndicatorsModalHeader;
