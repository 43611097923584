import { theme } from '../../../styles/theme';
import Stats from '../../../components/Stats';
import styled from 'styled-components';
import { prepareNumber } from '../../../utils/prepareNumber';

const getColor = (indicator) => {
  if (indicator === -1) {
    return theme.colors.status.overdue;
  } else if (indicator === 0) {
    return theme.colors.status.done_with_delay;
  }
  return theme.colors.status.done;
};

export const fields = (bySubjects, precision) => {
  return [
    {
      label: 'Субъект РФ',
      field: 'name',
      sorting: false,
      width: '70%',
      options: {
        fontFamily: theme.fonts.montserrat.regular,
      },
    },
    {
      label: 'План',
      field: 'plan',
      sorting: false,
      width: '8%',
    },
    {
      label: 'Значение',
      field: 'change',
      sorting: false,
      width: '8%',
      options: {
        text: 'center',
      },
      handleField: (row) => {
        return <Stats progress={row.change} textNoWrap={true} precision={precision} />;
      },
    },
    {
      label: 'Выполнение',
      field: 'progress',
      sorting: false,
      width: '8%',
      options: {
        text: 'center',
      },
    },
    {
      label: bySubjects ? 'Место в РФ' : 'Место в МО',
      field: 'position',
      sorting: false,
      width: '8%',
      options: {
        text: 'center',
      },
      handleField: (row) => {
        return <Position color={getColor(row.positionTonality)}>{row.position}</Position>;
      },
    },
  ];
};

const Position = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;
