import styled from 'styled-components';
import { FC } from 'react';

interface Props {
  margin?: string;
  color?: string;
}

const Divider: FC<Props> = ({ margin = '0', color }) => {
  return <DividerWrapper margin={margin} color={color} />;
};

const DividerWrapper = styled.div<{ margin: string; color: string }>`
  flex: 0 0 1px;
  background-color: ${({ color }) => (color ? color : 'rgba(0, 0, 0, 0.12)')};
  margin: ${({ margin }) => margin};
`;

export default Divider;
