import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { font } from '../styled/mixins/fonts';

interface Props {
  description: string;
  image?: ReactElement;
}

const NoData: FC<Props> = ({ description, image }) => {
  return (
    <Wrapper>
      {image && <Image>{image}</Image>}
      {description}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 8px 16px;
  width: 100%;
  text-align: center;
  ${({ theme }) => font({ color: theme.colors.dark })};
`;

const Image = styled.div`
  margin-bottom: 12px;
`;

export default NoData;
