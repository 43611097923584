export const fields = [
  {
    id: 0,
    label: 'Наименование',
    field: 'name',
    sorting: false,
    width: '50%',
    justifyContent: 'flex-start'
  },
  {
    id: 1,
    label: 'План',
    field: 'plan',
    sorting: false,
    width: '15%',
  },
  {
    id: 2,
    label: 'Выполнено',
    field: 'done',
    sorting: false,
    width: '15%',
  },
  {
    id: 3,
    label: 'Факт・Динамика Период',
    field: 'period',
    sorting: false,
    width: '20%',
  },
];
