import styled from 'styled-components';
import { FC } from 'react';
import Indicator from '../Indicator';
import { font } from '../styled/mixins/fonts';
import RowChart, { ChartType } from '../RowChart';
import Divider from '../Divider';

interface Props {
  title: string;
  indicator: {
    label: string;
    amount: number;
    onClick?: VoidFunction;
  };
  charts: {
    id: number;
    type?: ChartType;
    config: {
      id: number;
      label: string;
      amount: number;
      onClick?: VoidFunction;
    }[];
  }[];
}

const ChartStatistics: FC<Props> = ({ title, charts, indicator }) => {
  return (
    <ComponentContainer>
      <Title>{title}</Title>
      <Indicator {...indicator} />
      <Divider margin={'8px 0 12px 0'} />
      <ChartsWrapper>
        {charts.map((chart) => (
          <RowChart key={chart.id} config={chart.config} type={chart.type} max={indicator.amount} />
        ))}
      </ChartsWrapper>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.div`
  ${({ theme }) => font({ size: theme.fonts.sizes.xxmd, family: theme.fonts.montserrat.semibold })};
  margin-bottom: 16px;
`;
const ChartsWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export default ChartStatistics;
