export const plotsFields = [
  [
    {
      id: 0,
      label: '',
      field: 'title',
      sorting: false,
      width: '74%',
    },
    {
      id: 1,
      label: 'Всего',
      field: 'total',
      sorting: true,
      width: '5%',
    },
    {
      id: 2,
      label: 'Размер',
      field: 'size',
      sorting: true,
      width: '5%',
    },
    {
      id: 3,
      label: 'Заполняемость',
      field: 'occupancy',
      sorting: true,
      width: '8%',
    },
    {
      id: 4,
      label: 'Инфраструктура',
      field: 'infrastructure',
      sorting: false,
      width: '8%',
    },
  ],
];
