import styled from 'styled-components';
import ButtonFilters from '../../../../../components/ButtonFilters';
import { FC } from 'react';
import Amount from '../../../../../components/Amount';
import { objectsFilters } from '../../consts';
import { isNumber } from 'lodash';

export interface Type {
  id?: number;
  title: string;
  value: string;
}

interface Props {
  type: Type;
  amount?: number;
  setType: (type: Type) => void;
}

const RisksObjectsModalHeader: FC<Props> = ({ type, amount, setType }) => {

  return (
    <ComponentContainer amount={amount}>
      {isNumber(amount) && amount !== 0 && <Amount amount={amount} />}
      <ButtonFilters filters={objectsFilters} active={type} onChange={setType} />
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div<{ amount?: number }>`
  display: flex;
  align-items: center;
  justify-content: ${({ amount }) => !isNumber(amount) || amount === 0 ? 'flex-end' : 'space-between'};
  flex: 1 1 auto;
`;

export default RisksObjectsModalHeader;
