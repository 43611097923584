import { createContext } from 'react';
import { makeAutoObservable } from 'mobx';
import { getObjectCard } from '../actions';
import { history } from '../routes/history';

class Object {
  constructor() {
    makeAutoObservable(this);
  }

  ObjectData = {};

  ClearObjectData = () => {
    this.ObjectData = {};
  };
  loading = false;

  fetchObject = async (params) => {
    try {
      this.loading = true;
      const response = await getObjectCard(params.requestParams);
      // const response = await getObjectCard({"objectId" : "000ada82-c5a1-44cd-aef0-cc411912f0a9","actionId" : "ac8cd981-be40-4cac-a30a-339c98abd109" });
      // const response = await getObjectCard({
      //   objectId: '59c0666f-6413-4685-92e2-42ff58e5412c',
      //   actionId: 'd5af5172-3540-44a9-b601-d3d1e2825616',
      // });
      this.ObjectData = response.data;
    } catch (e) {
      history.goBack();
    } finally {
      this.loading = false;
    }
  };
}

export const ObjectStore = new Object();
export const ObjectStoreContext = createContext(ObjectStore);
