import styled from 'styled-components';
import { FC, useCallback, useEffect, useRef } from 'react';
import BarItem from './BarItem';
import { TextButton } from '../Button';
import { ModalsStore } from '../../stores/modal';
import { font } from '../styled/mixins/fonts';
import { getRandomInt } from '../../utils/getRandomInt';

interface Props {
  title?: string;
  data: {
    id: number;
    label: string;
    data: {
      amount: any;
      overlayAmount?: number;
      color: string;
      hideOverlayAmount?: boolean;
    };
  }[];
  meta: {
    from: string;
    to: string;
  };
  hideMoreBtn?: boolean;
  onMoreClick?: () => void;
}

const BarChart: FC<Props> = ({ data, meta, title, hideMoreBtn = false, onMoreClick }) => {
  const calculateMax = (amount) => {
    return (
      data.reduce(function (p, v) {
        return p?.data?.amount > v.data.amount ? p : v;
      }).data.amount / amount
    );
  };

  const handleMore = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    onMoreClick();
  }, []);

  return (
    <BarChartWrapper>
      <Heading>
        <Title>{title}</Title>
        {!hideMoreBtn && <TextButton onClick={handleMore}>Подробнее</TextButton>}
      </Heading>
      <ChartItems>
        {data.map((item) => (
          <BarItem key={item.id} {...item} width={calculateMax(item.data.amount)} />
        ))}
      </ChartItems>
      <BarFooter>
        <Filler />
        <Info>
          <Item>{meta.from}</Item>
          <Item>{meta.to}</Item>
        </Info>
      </BarFooter>
    </BarChartWrapper>
  );
};

const BarChartWrapper = styled.div``;
const ChartItems = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
`;
const BarFooter = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.div`
  ${({ theme }) => font({ size: theme.fonts.sizes.xmd, color: theme.colors.dark })};
`;
const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const Item = styled.div`
  ${({ theme }) => font({ size: theme.fonts.sizes.sm, color: theme.colors.placeholder })};
`;
const Filler = styled.div`
  width: 79px;
  flex: 0 0 auto;
  margin-right: 12px;
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export default BarChart;
