import { connectModal } from '../../../hocs/Modal';
import { Modal } from '../../../components/Modal';
import Risks from '../Risks';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Amount from '../../../components/Amount';
import ButtonFilters from '../../../components/ButtonFilters';
import styled from 'styled-components';
import { ListsStore, ListsStoreContext } from '../../../stores/lists';
import { observer } from 'mobx-react-lite';

interface Props {
  riskCount: number;
  count: number;
  filterOptions?: any;
  active?: any;
  setActive?: any;
}

const SelectedObjectsModalHeader: FC<Props> = observer(
  ({ count, filterOptions, active, setActive, riskCount }) => {
    const { getTotal } = useContext(ListsStoreContext);
    const handleChange = useCallback((el) => {
      setActive(el);
    }, []);

    const riskTotal = getTotal(listNameWithRisks);

    const preparedOptions = useMemo(() => {
      return filterOptions.map((el, idx) => {
        if (idx === 1) {
          return {
            ...el,
            title: `${el.title}: ${riskCount}`,
          };
        }
        return el;
      });
    }, [riskTotal, riskCount]);

    return (
      <SelectObjectsModalHeaderWrapper>
        <Amount amount={count} />

        {filterOptions?.length > 1 ? (
          <ButtonFilters filters={preparedOptions} active={active} onChange={handleChange} />
        ) : null}
      </SelectObjectsModalHeaderWrapper>
    );
  },
);

const SelectObjectsModalHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 auto;
`;

const listName = 'GetObjectsByFilterInModal';
const listNameWithRisks = 'GetObjectsByFilterInModalWithRisks';

const SelectedObjectModal = connectModal('selected-objects-modal')(
  ({ handleHide, filter: filterProps, row, count, riskCount }) => {
    useEffect(() => {
      return () => {
        ListsStore.clearList(listName);
        ListsStore.clearList(listNameWithRisks);
      };
    }, []);

    const filter = useMemo(() => {
      return filterProps;
    }, [filterProps]);

    const filterOptions = useMemo(() => {
      const options = [];
      options.push({
        id: 0,
        title: 'Все',
        value: 'all',
      });
      if (filter.isRisk || filter.isBeforeRisk) {
        options.push({
          id: 1,
          title: filter.isRisk ? 'Риск' : 'Предриск',
          value: filter.isRisk ? 'isRisk' : 'isBeforeRisk',
        });
      } else {
        if (filter.isCurrentYearOpen) {
          options.push({
            id: 1,
            title: 'Открытые',
            value: 'IsCurrentYearOpen',
          });
        }
      }
      return options;
    }, [filter.isRisk, filter.isBeforeRisk, filter.isCurrentYearOpen]);

    const [active, setActive] = useState(
      (filter.isRisk || filter.isBeforeRisk || filter.isCurrentYearOpen) && riskCount
        ? filterOptions[1]
        : filterOptions[0],
    );
    // const additional = {} as any;
    // if (isOpened) additional.isOpenFact = true;

    return (
      <Modal
        title={'Выбранные объекты'}
        onClose={handleHide}
        contentOverflow={false}
        header={
          <SelectedObjectsModalHeader
            count={count}
            riskCount={riskCount}
            filterOptions={filterOptions}
            active={active}
            setActive={setActive}
          />
        }
      >
        {active.value === 'all' ? (
          <Risks
            maxHeight={'calc(100vh - 215px)'}
            filter={{
              ...filter,
              isRisk: false,
              isBeforeRisk: false,
              isCurrentYearOpen: false,
            }}
            listName={listName}
          />
        ) : (
          <Risks maxHeight={'calc(100vh - 215px)'} filter={{ ...filter }} listName={listNameWithRisks} />
        )}
      </Modal>
    );
  },
);

export default SelectedObjectModal;
