import Card from '../../../components/Card';
import styled from 'styled-components';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppStoreContext } from '../../../stores/app';
import { getAgendaFinanceData } from '../../../actions';
import { observer } from 'mobx-react-lite';
import ComplexTable from '../../../components/ComplexTable';

interface Props {
  filter: any;
  sorting: any;
  setSortingCallback: any;
}

const AgendaFinance: FC<Props> = observer(({ filter, sorting, setSortingCallback }) => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fields = useMemo(
    () => [
      [
        {
          id: 1,
          label: '',
          sorting: false,
          field: 'name',
          width: '50%',
        },
        {
          id: 2,
          label: `${
            filter.selectedMonth
              ? filter.selectedMonth.name + ' ' + filter.selectedMonth?.appliesToYear
              : ''
          } тыс руб.`,
          sorting: true,
          field: 'factValue',
          width: '8.5%',
          options: {
            'text-align': 'right',
          },
        },
        {
          id: 3,
          label: `${
            filter.selectedMonth
              ? filter.selectedMonth.name + ' ' + (filter.selectedMonth?.appliesToYear - 1)
              : ''
          } тыс руб.`,
          sorting: true,
          field: 'factPreviousValue',
          width: '8.5%',
          options: {
            'text-align': 'right',
          },
        },
        {
          id: 4,
          label: `Отклонение ${
            filter.selectedMonth ? filter.selectedMonth?.appliesToYear : ''
          } от ${filter.selectedMonth ? filter.selectedMonth?.appliesToYear - 1 : ''} тыс руб.`,
          sorting: true,
          field: 'factDynamics',
          width: '10.5%',
          options: {
            'text-align': 'right',
            'padding-right': '24px',
          },
        },
      ],
    ],
    [filter],
  );

  const fetchData = useCallback(async () => {
    if (selectedOmsu) {
      try {
        setLoading(true);
        const params = {
          omsuId: selectedOmsu.id,
          periodId: filter.selectedMonth?.id,
        };
        const response = await getAgendaFinanceData(params);
        setData(response.data?.items || []);
      } catch (e) {
        //
      } finally {
        setLoading(false);
      }
    }
  }, [selectedOmsu, filter.selectedMonth]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const total = useMemo(() => {
    const obj = {};
    fields[0].forEach((el, idx) => {
      obj[el.field] = idx === 0 ? 'Субсидии из бюджета Московской области ' : 0;
    });
    data.forEach((item) => {
      fields[0].forEach((el, idx) => {
        if (idx !== 0) {
          obj[el.field] += item[el.field];
        }
      });
    });
    obj['options'] = {};
    obj['options'].styles = {};
    obj['options'].styles.name = {
      'font-weight': '700',
      'font-size': '16px',
      'line-height': '20px',
      background: 'rgba(0, 19, 92, 0.04)',
    };
    obj['options'].styles.factValue = {
      'font-weight': '700',
      'font-size': '14px',
      'line-height': '16px',
      background: 'rgba(0, 19, 92, 0.04)',
    };
    obj['options'].styles.factPreviousValue = {
      'font-weight': '700',
      'font-size': '14px',
      'line-height': '16px',
      background: 'rgba(0, 19, 92, 0.04)',
    };
    obj['options'].styles.factDynamics = {
      'font-weight': '700',
      'font-size': '14px',
      'line-height': '16px',
      background: 'rgba(0, 19, 92, 0.04)',
    };
    return obj;
  }, [data]);

  return (
    <Card>
      <TableWrapper>
        <ComplexTable
          total={total}
          data={data}
          fields={fields}
          loading={loading}
          sorting={sorting ? sorting : { name: fields[0][1].field, type: 'DESC' }}
          setSortingCallback={setSortingCallback}
        />
      </TableWrapper>
    </Card>
  );
});

const TableWrapper = styled.div`
  margin-top: 16px;
`;

export default AgendaFinance;
