import {
  getDebtOMSU,
  getExecutionIndicatorRating,
  getExecutionIndicatorWithFinancing,
  getIndicatorsOMSU,
  getInfoOMSU,
  getRatingIndicatorsOMSU,
  getRatingOMSU,
  getRiskOMSU,
  getSocNeeds,
  getTopCompanies,
  getTopIndustriesTaxes,
} from '../actions';

export const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api`;

export const api = {
  auth: '/Security/auth',
  refreshToken: '/Security/refresh',
  whoAmI: '/Security/whoami',

  getObjects: '/ObjectsAggrigate/GetByFilter',
  getNsi: '/nsi',
  getMunicipalityInfo: (id) => `/nsi/MunicipalityInfo/${id}`,

  getObjectCard: '/ObjectCard/GetByFilter',
  getObjectFinanceDetails: '/ObjectCard/GetObjectFinanceDetails',

  getDiagrammsDataByOMSUid: '/Diagramms/GetByOMSUId',

  getObjectsReester: '/ObjectsReester/GetObjectsByFilter',

  getBudgetOMSU: '/BudgetOMSU/GetByFilter',

  getAgendaFinance: '/FinancialSupportOMSU/GetByFilter',

  getRatingOMSU: '/RatingOMSU/GetByFilter',

  getRatingInfoOMSU: '/RatingInfo/GetByFilter',

  getInfoOMSU: '/InfoOMSU/GetByFilter',

  getCurrentPositionOMSU: '/CurrentPositionOMSU/GetByFilter',

  getRatingIndicatorsOMSU: '/RatingIndicatorsOMSU/GetByFilter',

  getCalendarRating: '/CalendarRaiting/GetByFilter',

  getDebtOMSU: '/DebtOMSU/GetByFilter',

  getProblems: '/Problems/GetByFilter',

  getTopCompanies: '/TopCompanies/GetByFilter',

  getTopIndustriesTaxes: '/TopIndustriesTaxes/GetByFilter',

  getIndicatorsOMSU: '/IndicatorsOMSU/GetByFilter',

  getRiskOMSU: '/RiskOMSU/GetByFilter',

  getExecutionIndicatorWithFinancing: '/ExecutionIndicatorWithFinancing/GetByFilter',

  getExecutionIndicatorWithoutFinancing: '/ExecutionIndicatorWithoutFinancing/GetByFilter',

  getExecutionIndicatorRating: '/ExecutionIndicatorRating/GetByFilter',

  //Agenda
  getAgendaObjectsReester: '/AgendaObjectsReester/GetObjectsByFilter',
  getAgendaIndicatorReestr: '/AgendaIndicatorReestr/GetIndicatorReestrByFilter',

  // budget
  getBudgetConsolidatedInformation: '/ConsolidatedInformation/GetByFilter',
  getBudgetExecution: '/BudgetExecution/GetByFilter',
  getDynamicBudgetExecutionDiagramma: '/DinamBudgetExecutionDiagramma/GetByFilter',
  getDinamBudgetExecutionTable: '/DinamBudgetExecutionTable/GetByFilter',
  getDinamBudgetExecutionTableMain: '/DinamBudgetExecutionTable/GetByFilterMain',
  getLevelBudgetNsi: (lbid) => `/nsi/LevelBudget/${lbid}`,
  getBudgetIncome: '/Income/GetByFilter',
  getBudgetExpense: '/Expense/GetByFilter',
  getBudgetDebt: '/Debt/GetByFilter',
  getMunicipalityDebtRaising: '/MunicipalityDebtRaising/GetByFilter',
  getMunicipalityDebtRepayment: '/MunicipalityDebtRepayment/GetByFilter',
  getGosDebtDetails: '/GosDebtDetails/GetByFilter',
  getGrowthRateDebt: '/GrowthRateDebt/GetByFilter',
  getBudgetIncomeCompare: '/BudgetIncomeCompare/GetByFilter',
  getBudgetExpenseCompare: '/BudgetExpenseCompare/GetByFilter',
  getBudgetExecutionDiagram: '/BudgetExecutionDiagram/GetByFilter',
  getBudgetDataExpensesDynamics: "/BudgetDataExpensesDynamics/GetByFilter",

  //development
  getEvoConsolidatedInformation: '/EvoConsolidatedInformation/GetByFilter',
  getNewWorkPlace: '/NewWorkPlace/GetByFilter',
  getEvoInvestments: '/EvoInvestments/GetByFilter',
  getIndustrial: '/Industrial/GetByFilter',
  getIndustrialLand: '/IndustrialLand/GetByFilter',
  getRentLand: '/RentLand/GetByFilter',
  getSocPosition: '/SocPosition/GetByFilter',

  getSocNeeds: '/SocNeeds/GetByFilter',

  //Indicators
  getExecutionIndicators: '/ExecutionIndicators/GetByFilter',
  getExecutionIndicatorsTable: '/ExecutionIndicatorsTable/GetByFilter',
  getExecutionMainEconomicIndicators: '/ExecutionMainEconomicIndicators/GetByFilter',
  getExecutionEconomicIndicatorDetails: '/ExecutionEconomicIndicatorDetails/GetByFilter',

  //Indicator
  getIndicatorById: '/AgendaIndicatorReestr/GetIndicatorById',
  getAgendaIndicatorDescriptionById: '/AgendaIndicatorDescription/GetIndicatorDescriptionById',
  getIndicatorPeriodStatistic: '/AgendaIndicatorReestr/GetIndicatorPeriodStatistic',
  getIndicatorSubjectsStatistic: '/AgendaIndicatorReestr/GetIndicatorSubjectsStatistic',
  getIndicatorOMSUStatistic: '/AgendaIndicatorReestr/GetIndicatorOMSUStatistic',

  getIndicatorLevels: "/CalendarIndicator/GetIndicatorLevels",
  getIndicatorLevelPeriods: "/CalendarIndicator/GetIndicatorLevelPeriods",
};
