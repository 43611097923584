import { observer } from 'mobx-react-lite';
import { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import SelectList from '../../../components/Calendar/SelectList';
import Select from '../../../components/Select';
import { NsiStoreContext } from '../../../stores/nsi';

interface Props {
  filter: any;
  setFilterCallback: (name: string, e: any) => void;
}

const DevelopmentLandPlotsRentAddition: FC<Props> = observer(({ filter, setFilterCallback }) => {

  const { RentObjects } = useContext(NsiStoreContext);

  return (
    <ComponentContainer>
      <Select
        renderList={({ active, onClose }) => (
          <SelectList
            active={active}
            onClose={onClose}
            list={RentObjects}
            onClick={(e: any) => {
              setFilterCallback('selectedType', e);
            }}
          />
        )}
        label={''}
        value={filter?.selectedType}
        fieldFullsize={false}
      />
    </ComponentContainer>
  );
});

const ComponentContainer = styled.div``;

export default DevelopmentLandPlotsRentAddition;
