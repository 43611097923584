import { createContext, useContext, useEffect } from 'react';
import { makeAutoObservable } from 'mobx';
import { login, refreshToken, whoAmI } from '../actions';
import { setAuthToken } from '../utils/axios';
import { sleep } from '../utils/sleep';
import { toast } from 'react-toastify';
import ErrorMessage from '../components/ErrorMessage';
import { NsiStore } from './nsi';
import { add, formatISO, parseISO, differenceInSeconds } from 'date-fns';
import Storage from '../utils/storage';

class Auth {
  constructor() {
    makeAutoObservable(this);
  }

  profile = null;
  showWelcome = false;
  setProfile = (profile) => (this.profile = profile);

  getProfile = () => {
    console.log('/get');
  };

  firstAuth = false;
  refrashTimer = null;

  getNsiList = () => {
    NsiStore.fetchNsi('Sphere');
    NsiStore.fetchNsi('EntityType');
    NsiStore.fetchNsi('WorkType');
    NsiStore.fetchNsi('FundingType');
    NsiStore.fetchNsi('ProcedureStatus');
    NsiStore.fetchNsi('CIOGV');
    NsiStore.fetchNsi('BudgetExecutionContext');
    NsiStore.fetchNsi('Territories');
    NsiStore.fetchNsi('Program');
    NsiStore.fetchNsi('FundingSources');
    NsiStore.fetchNsi('ExpenseTypes');
    NsiStore.fetchNsi('RentObjects');
    NsiStore.fetchNsi('BudgetProgram');
    NsiStore.fetchNsi('NacProject');
    NsiStore.fetchNsi('BudgetCIOGV');
    NsiStore.fetchNsi('IndicatorCIOGV');
    NsiStore.fetchNsi('CalendarQuarters');
    NsiStore.fetchCalendarYears();
    NsiStore.fetchCurators();
    NsiStore.fetchCalendar();
    NsiStore.fetchCalendarRazdel();
  };

  refrashToken = async () => {
    try {
      const rToken = Storage.get('refresh_token');
      setAuthToken(rToken, rToken);
      const response = await refreshToken();
      const { access_token, refresh_token, token_live_seconds } = response.data;
      const expiredString = formatISO(add(new Date(), { seconds: token_live_seconds }));
      Storage.set('token_expired', expiredString);
      setAuthToken(access_token, refresh_token);
      this.startRefrashTimer(expiredString);
    } catch (e) {
      setAuthToken();
      Storage.remove('token_expired');
      AuthStore.setProfile(null);
    }
  }

  startRefrashTimer = (expired) => {
    const expiredDate = parseISO(expired);
    const difference = differenceInSeconds(expiredDate, new Date()); //sec
    if(this.refrashTimer) clearTimeout(this.refrashTimer);
    this.refrashTimer = setTimeout(this.refrashToken, (difference * 1000) - 10000); //disserence - 10sec
  }

  login = async (data) => {
    try {
      const response = await login(data);
      const { access_token, refresh_token, token_live_seconds } = response.data;
      setAuthToken(access_token, refresh_token);
      const expiredString = formatISO(add(new Date(), { seconds: token_live_seconds }));
      Storage.set('token_expired', expiredString);
      this.startRefrashTimer(expiredString);
      this.firstAuth = true;
      await this.whoAmI();
      this.showWelcome = true;

      this.getNsiList();

      await sleep(5000);
      this.showWelcome = false;
    } catch (e) {
      toast.error(<ErrorMessage />);
      toast.clearWaitingQueue();
      throw new Error();
    }
  };

  logout = () => {
    try {
      setAuthToken();
      this.setProfile(null);
      Storage.remove('token_expired');
    } catch (e) {
      //
    }
  };

  whoAmI = async () => {
    try {
      const profileResponse = await whoAmI();
      this.setProfile(profileResponse.data);
      this.firstAuth = true;
      this.getNsiList();
    } catch (e) {
      //
      setAuthToken('');
    }
  };
}

export const AuthStore = new Auth();
export const AuthStoreContext = createContext(AuthStore);
