import { FC } from 'react';
import { Item } from '..';
import styled, { css } from 'styled-components';
import { font } from '../../styled/mixins/fonts';
import { isArray } from 'lodash';

interface Props {
  depth?: number;
  last?: boolean;
  lastRow?: boolean;
}

const renderAmountCell = (amount, lastCell, isBold) => {
  return (
    <Cell lastCell={lastCell} isBold={isBold}>
    {amount
      ? new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2 }).format(
          parseFloat(amount.toString()),
        )
      : '-'}
    </Cell>
  )
}

const CardTable: FC<Item & Props> = ({ label, details, amount, isBold, depth = 0, lastRow, last }) => {
  return (
    <ComponentContainer depth={depth}>
      <Row lastElement={lastRow && last === undefined && !details ? true : (last && lastRow)}>
        <Cell isBold={isBold}>{label}</Cell>
        {isArray(amount) ?
          <CellArrayWrapper>
            {amount.map((itm, idx) => renderAmountCell(itm, idx === amount.length - 1, isBold))}
          </CellArrayWrapper>
        :
          renderAmountCell(amount, true, isBold)
        }
      </Row>
      {details &&
        details.map((item, idx) => (
          <CardTable
            key={item.id}
            {...item}
            depth={depth + 1}
            last={details.length === idx + 1}
            lastRow={lastRow}
          />
        ))}
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div<{ depth: number }>`
  margin-left: ${({ depth }) => depth * 24}px;
`;
const Row = styled.div<{ lastElement: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ lastElement }) =>
    !lastElement &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    `};
  min-height: 44px;
  padding: 12px 16px 12px 0;
  margin-left: 16px;
  gap: 16px;
`;
const Cell = styled.div<{ lastCell?: boolean, isBold: boolean }>`
  ${({ theme, isBold }) => font({ family: isBold ? theme.fonts.robotoCondensed.bold : theme.fonts.robotoCondensed.regular, lineHeight: '20px' })};
  ${({ lastCell }) =>
  !lastCell &&
    css`
      margin-right: 16px;
    `}
`;
const CellArrayWrapper = styled.div`
  display: flex;
`

export default CardTable;
