import styled from 'styled-components';
import ProjectLogo from '../../components/ProjectLogo';
import React, { FC, useContext } from 'react';
import whiteHouse from '../../assets/images/white_house.svg';
import map from '../../assets/images/map_mo.png';
import { font } from '../../components/styled/mixins/fonts';
import { toJS } from 'mobx';
import { Loader } from '../../components/Loader';
import { observer } from 'mobx-react-lite';
import { AuthStoreContext } from '../../stores/auth';
import GASULogo from '../../assets/images/GASULogo';

const Welcome: FC = observer(() => {
  const { profile } = useContext(AuthStoreContext);

  return (
    <ComponentContainer>
      <Content>
        <LogoWrapper>
          <GASULogo />
        </LogoWrapper>
        <Title>
          <Text>Добро пожаловать, {toJS(profile.user_name)}</Text>
        </Title>
        <LoaderWrapper>
          <Loader alternative />
        </LoaderWrapper>
      </Content>
      <MapOverlay />
      <BuildingOverlay />
    </ComponentContainer>
  );
});

const ComponentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
`;
const BuildingOverlay = styled.div`
  background: url(${whiteHouse}) no-repeat 50% 100%;
  background-size: auto 100%;
  height: 33%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
`;
const MapOverlay = styled.div`
  background: url(${map}) no-repeat 50% 50%;
  background-size: auto 80%;
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;
const Title = styled.div`
  padding: 32px 0;
  flex: 1 1 33%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 33%;
  svg {
    width: 50%;
    height: 200px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const LoaderWrapper = styled.div`
  flex: 1 1 33%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Text = styled.div`
  ${({ theme }) => font({ color: '#fff', size: '48px' })};
  opacity: 0.7;
  width: 50%;
  text-align: center;
`;

export default Welcome;
