import styled from 'styled-components';
import BarChart from '../../../../components/BarChart';
import { useCallback, useContext, useMemo } from 'react';
import { ObjectStoreContext } from '../../../../stores/object';
import { observer } from 'mobx-react-lite';
import { ModalsStore } from '../../../../stores/modal';
import { useParams } from 'react-router-dom';

const barMeta = {
  from: 'ФБ',
  to: 'тыс.руб.',
};

const FinanceChart = observer(() => {
  const { ObjectData } = useContext(ObjectStoreContext);
  const params = useParams<{ objectId: string; actionId: string; }>();

  const barConfig = useMemo(() => {
    return [
      {
        id: 0,
        label: 'Касса', //actualFederalFundingAmount
        data: {
          amount: ObjectData?.actualFundingAmount,
          overlayAmount: ObjectData?.actualFederalFundingAmount,
          color: '#172B75',
          hideOverlayAmount: true,
        },
      },
      {
        id: 1,
        label: 'Контракт',
        data: {
          amount: ObjectData?.contractedFundingAmount,
          overlayAmount: ObjectData?.contractedFederalFundingAmount,
          color: '#646CAA',
          hideOverlayAmount: true,
        },
      },
      {
        id: 2,
        label: 'Стоимость объекта',
        data: {
          amount: ObjectData?.plannedFundingAmount,
          overlayAmount: ObjectData?.plannedFederalFundingAmount,
          color: '#A3A9D4',
          hideOverlayAmount: true,
        },
      },
    ];
  }, [ObjectData]);

  const onMoreClick = useCallback(() => {
    ModalsStore.showModal('finance-modal', { objectId: ObjectData?.objectId, actionId: params?.actionId });
  }, [ObjectData]);

  return (
    <FinanceChartWrapper>
      <BarChart
        title={'Финансирование'}
        data={barConfig}
        meta={barMeta}
        onMoreClick={onMoreClick}
      />
    </FinanceChartWrapper>
  );
});

const FinanceChartWrapper = styled.div``;

export default FinanceChart;
