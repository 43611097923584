import styled from 'styled-components';
import Icon from '../../../../components/Icon';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  title: string;
  path: string;
  icon: any;
  exact?: boolean;
}

const MenuItem: FC<Props> = ({ title, path, icon, exact }) => {
  const IconComponent = icon;

  return (
    <ItemWrapper>
      <NavLink
        exact={exact}
        to={path}
        activeStyle={{
          backgroundColor: 'rgba(255, 255, 255, 0.24)',
        }}
      >
        <IconWrapper>
          <Icon color={'#fff'} opacity={1}>
            <IconComponent />
          </Icon>
        </IconWrapper>
        <Title>{title}</Title>
      </NavLink>
    </ItemWrapper>
  );
};

const ItemWrapper = styled.div`
  a {
    margin-bottom: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 12px;
    overflow: hidden;
    text-decoration: none;
    &:hover {
      background-color: rgba(255, 255, 255, 0.24);
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const IconWrapper = styled.div`
  margin-right: 24px;
`;

const Title = styled.div`
  color: #fff;
  font-family: ${({ theme }) => theme.fonts.montserrat.medium};
  font-size: ${({ theme }) => theme.fonts.sizes.md};
  letter-spacing: 0.5px;
`;

export default MenuItem;
