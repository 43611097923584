import Select from '../Select';
import SelectList from '../Calendar/SelectList';
import SelectMultiple from '../SelectMultiple';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { NsiStoreContext } from '../../stores/nsi';

interface Props {
  filter: any;
  setFilter: any;
  showExpensesType?: boolean;
  fundingSourceMultiple?: boolean;
}

export enum FilterTypes {
  EXPENSES_TYPE = 'expnsesType',
  CIOGV = 'ciogv',
  NATIONAL_PROJECT = 'nationalProject',
  GP = 'gp',
  FUNDING_SOURCE = 'fundingSource',
}

const BudgetModalFilters: FC<Props> = observer(
  ({ filter, setFilter, showExpensesType = false, fundingSourceMultiple = false }) => {
    const { ExpenseTypes, BudgetCIOGV, NacProject, BudgetProgram, FundingSources } =
      useContext(NsiStoreContext);

    const [listCIOGV, setListCIOGV] = useState(BudgetCIOGV);
    const [listNationalProject, setListNationalProject] = useState(NacProject);
    const [listGp, setListGp] = useState(BudgetProgram);
    const [listFundingSource, setListFundingSource] = useState(FundingSources);

    const initializeValue = useCallback((list, value) => {
      const valuesArr = value.split(',');
      return list.map((item) =>
        valuesArr.find((itm) => item.id === itm || item.value === itm)
          ? { ...item, checked: true }
          : item,
      );
    }, []);

    //Initialize multiple selects
    useEffect(() => {
      if (BudgetCIOGV && filter[FilterTypes.CIOGV]) {
        setListCIOGV(initializeValue(BudgetCIOGV, filter[FilterTypes.CIOGV]));
      }

      if (NacProject && filter[FilterTypes.NATIONAL_PROJECT]) {
        setListNationalProject(initializeValue(NacProject, filter[FilterTypes.NATIONAL_PROJECT]));
      }

      if (BudgetProgram && filter[FilterTypes.GP]) {
        setListGp(initializeValue(BudgetProgram, filter[FilterTypes.GP]));
      }

      if (FundingSources && filter[FilterTypes.FUNDING_SOURCE]) {
        setListFundingSource(initializeValue(FundingSources, filter[FilterTypes.FUNDING_SOURCE]));
      }
    }, [BudgetCIOGV, NacProject, BudgetProgram, filter, FundingSources]);

    const handleLocalList = (type: FilterTypes, { check, name }) => {
      const filterList = (list) => {
        return list.map((item) => (item.value === name ? { ...item, checked: check } : item));
      };

      switch (type) {
        case FilterTypes.CIOGV:
          setListCIOGV(filterList(listCIOGV));
          break;
        case FilterTypes.NATIONAL_PROJECT:
          setListNationalProject(filterList(listNationalProject));
          break;
        case FilterTypes.GP:
          setListGp(filterList);
          break;
        case FilterTypes.FUNDING_SOURCE:
          setListFundingSource(filterList);
          break;
      }
    };

    const handleAcceptMultipleList = (type: FilterTypes) => {
      const prepareIds = (list) => {
        return list
          .filter((item) => item.checked)
          .map((item) => item.id)
          .join(',');
      };

      const prepareValues = (list) => {
        return list
          .filter((item) => item.checked)
          .map((item) => item.value)
          .join(',');
      };

      switch (type) {
        case FilterTypes.CIOGV:
          setFilter(FilterTypes.CIOGV, prepareIds(listCIOGV));
          break;
        case FilterTypes.NATIONAL_PROJECT:
          setFilter(FilterTypes.NATIONAL_PROJECT, prepareIds(listNationalProject));
          break;
        case FilterTypes.GP:
          setFilter(FilterTypes.GP, prepareValues(listGp));
          break;
        case FilterTypes.FUNDING_SOURCE:
          setFilter(FilterTypes.FUNDING_SOURCE, prepareIds(listFundingSource));
          break;
      }
    };

    const onExpenseTypeClick = useCallback((e: any) => {
      setFilter(FilterTypes.EXPENSES_TYPE, e);
    }, []);

    const onFundingSourcesClick = useCallback((e: any) => {
      setFilter(FilterTypes.FUNDING_SOURCE, e);
    }, []);

    return (
      <>
        {showExpensesType && (
          <Select
            renderList={({ active, onClose }) => (
              <SelectList
                active={active}
                onClose={onClose}
                list={ExpenseTypes}
                onClick={onExpenseTypeClick}
              />
            )}
            label={'Вид'}
            value={filter[FilterTypes.EXPENSES_TYPE]}
            fieldFullsize={false}
          />
        )}
        {listCIOGV.length ? (
          <Select
            renderList={({ active, onClose }) => (
              <SelectMultiple
                onClose={onClose}
                list={listCIOGV}
                onClick={(e) => handleLocalList(FilterTypes.CIOGV, e)}
                onAccept={() => handleAcceptMultipleList(FilterTypes.CIOGV)}
                width={'500px'}
              />
            )}
            fullHeight
            label={'ЦИОГВ'}
            value={''}
            fieldFullsize={false}
          />
        ) : null}
        {listNationalProject.length ? (
          <Select
            renderList={({ active, onClose }) => (
              <SelectMultiple
                onClose={onClose}
                list={listNationalProject}
                onClick={(e) => handleLocalList(FilterTypes.NATIONAL_PROJECT, e)}
                onAccept={() => handleAcceptMultipleList(FilterTypes.NATIONAL_PROJECT)}
                width={'500px'}
              />
            )}
            fullHeight
            label={'Нац. проект'}
            value={''}
            fieldFullsize={false}
          />
        ) : null}
        {listGp.length ? (
          <Select
            renderList={({ active, onClose }) => (
              <SelectMultiple
                onClose={onClose}
                list={listGp}
                onClick={(e) => handleLocalList(FilterTypes.GP, e)}
                onAccept={() => handleAcceptMultipleList(FilterTypes.GP)}
                width={'500px'}
              />
            )}
            fullHeight
            label={'ГП'}
            value={''}
            fieldFullsize={false}
          />
        ) : null}
        {FundingSources.length ? (
          fundingSourceMultiple ? (
            <Select
              renderList={({ active, onClose }) => (
                <SelectMultiple
                  onClose={onClose}
                  list={listFundingSource}
                  onClick={(e) => handleLocalList(FilterTypes.FUNDING_SOURCE, e)}
                  onAccept={() => handleAcceptMultipleList(FilterTypes.FUNDING_SOURCE)}
                  width={'500px'}
                />
              )}
              fullHeight
              label={'Источник финанс-я'}
              value={''}
              fieldFullsize={false}
            />
          ) : (
            <Select
              renderList={({ active, onClose }) => (
                <SelectList
                  active={active}
                  onClose={onClose}
                  list={FundingSources}
                  onClick={onFundingSourcesClick}
                />
              )}
              label={'Источник финанс-я'}
              value={filter[FilterTypes.FUNDING_SOURCE]}
              fieldFullsize={false}
            />
          )
        ) : null}
      </>
    );
  },
);

export default BudgetModalFilters;
