import styled from 'styled-components';
import { FC } from 'react';
import Item from './Item';
import { font } from '../styled/mixins/fonts';
import { Loader } from '../Loader';
import NoData from '../NoData';

export interface ListItem {
  id: number;
  label: string;
  data: any;
  place: number;
}

interface Props {
  id: number;
  title: string;
  subtitle: string;
  list: ListItem[];
  loading?: boolean;
}

const sortList = (a, b) => {
  return a.place > b.place ? 1 : -1;
};

const Top: FC<Props> = ({ title, list, subtitle, loading }) => {
  return (
    <ComponentContainer>
      <Header>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Header>
      <ListWrapper>
        {loading ? (
          <Loader />
        ) : (
          <>
            {list?.length ? (
              <>
                {list.sort(sortList).map((item, idx) => (
                  <Item key={item.id} {...item} last={idx === list.length - 1} />
                ))}
              </>
            ) : (
              <NoData description={'Нет данных'} />
            )}
          </>
        )}
      </ListWrapper>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div``;
const Title = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xmd,
      family: theme.fonts.montserrat.semibold,
    })};
`;
const Subtitle = styled.div`
  ${({ theme }) =>
    font({
      family: theme.fonts.robotoCondensed.regular,
      color: theme.colors.dark,
    })};
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 8px 16px;
  gap: 16px;
  height: 54px;
`;
const ListWrapper = styled.div`
  padding-bottom: 8px;
`;

export default Top;
