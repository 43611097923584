import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Loader } from '../../../components/Loader';
import HorizontalList, { Item } from '../../../components/HorizontalList';
import ListItem from '../../../components/HorizontalList/ListItem';
import { getCurrentPositionOMSU } from '../../../actions';
import { AppStoreContext } from '../../../stores/app';
import { observer } from 'mobx-react-lite';

interface Props {
  filter: any;
}

const CurrentData: FC<Props> = observer(({ filter }) => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const [currentData, setCurrentData] = useState([]);
  const [loadingCurrentData, setLoadingCurrentData] = useState(false);

  const fetchCurrentData = useCallback(async () => {
    if (selectedOmsu) {
      try {
        setLoadingCurrentData(true);
        const params = {
          periodId: filter.selectedMonth?.id,
          omsuId: selectedOmsu.id,
        };
        const response = await getCurrentPositionOMSU(params);
        const responseArray = [];
        const includes = [];
        response.data?.items.forEach((item) => {
          if (!includes.includes(item.id)) {
            const res = response.data?.items.find(
              (elem) => elem.iconURL === item.iconURL && item.name !== elem.name,
            );
            if (res) {
              includes.push(res.id);
              responseArray.push([item, res]);
            } else {
              responseArray.push([item]);
            }
          }
        });
        setCurrentData(responseArray || []);
      } catch (e) {
        //
      } finally {
        setLoadingCurrentData(false);
      }
    }
  }, [selectedOmsu, filter.selectedMonth]);

  useEffect(() => {
    fetchCurrentData();
  }, [fetchCurrentData, filter.selectedMonth]);

  return (
    <>
      {loadingCurrentData ? (
        <Loader />
      ) : (
        <HorizontalList
          title={'Текущее положение'}
          list={currentData.map((item) => ({
            id: item[0].id,
            iconURL: item[0].iconURL,
            data: item.map((elem) => ({
              id: elem.id,
              amount: new Intl.NumberFormat('ru-RU', {
                minimumFractionDigits: elem.precision,
              }).format(elem.factValue),
              amountType: elem.unit,
              label: elem.name,
            })),
          }))}
        >
          {(item: Item) => <ListItem {...item} />}
        </HorizontalList>
      )}
    </>
  );
});

export default CurrentData;
