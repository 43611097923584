export const initialWorkplaceSortingValues = {
  field: 'fact',
  direction: 'DESC',
};

export const initialInvestmentSortingValues = {
  field: 'fact',
  direction: 'DESC',
};

export const initialIndustrialSortingValues = {
  field: 'kpi',
  direction: 'DESC',
};

export const initialIndustrialLandSortingValues = {
  field: 'total',
  direction: 'DESC',
};

export const initialRentLandSortingValues = {
  field: 'total',
  direction: 'DESC',
};

export const initialRentLandFilterValues = {
  selectedType: '',
};

export const initialSocialNeedsFilterValues = {
  selectedYear: '',
};

export const initialSocialStatusFilterValues = {
  type: '',
  selectedYear: ''
};
