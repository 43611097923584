import Select from '../../../components/Select';
import { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { NsiStoreContext } from '../../../stores/nsi';
import SelectList from '../../../components/Calendar/SelectList';
import { AppStoreContext } from '../../../stores/app';
import styled from 'styled-components';
import SearchList from '../../../components/SearchList';

const IndicatorsHeaderAddition = observer(() => {
  const { IndicatorCIOGV, CalendarYears } = useContext(NsiStoreContext);
  const { setSelectedYear, setCIOGV, selectedYear, selectedCiogv } = useContext(AppStoreContext);

  return (
    <ComponentContainer>
      <Select
        key={selectedYear?.id}
        position="left"
        renderList={({ active, onClose }) => (
          <SelectList active={active} onClose={onClose} list={CalendarYears} onClick={setSelectedYear} />
        )}
        label={'Все года открытия'}
        value={selectedYear}
        fieldFullsize={false}
      />
      <Select
        key={selectedCiogv?.id}
        position="left"
        label={'Все ведомства'}
        value={selectedCiogv}
        renderList={({ active, onClose }) => (
          <SearchList list={IndicatorCIOGV} onClick={setCIOGV} active={active} onClose={onClose} />
        )}
      />
    </ComponentContainer>
  );
});

const ComponentContainer = styled.div`
  display: flex;
  > div {
    margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
  }
`

export default IndicatorsHeaderAddition;
