import styled, { css } from 'styled-components';
import { FC, useState } from 'react';
import Icon from '../../Icon';
import ArrowIcon from '../../../assets/icons/ArrowIcon';
import { AccordionItem as AccordionItemProps, Position } from '../index';
import { font } from '../../styled/mixins/fonts';

const AccordionItem: FC<AccordionItemProps> = ({ header, content }) => {
  const {
    isOpen = true,
    collapse = true,
    title,
    amount,
    addition,
    additionPosition = Position.RIGHT,
    collapsedContent,
    collapsedContentPosition = Position.RIGHT,
    collapsedAdditionVisible = true,
    selector,
  } = header;

  const [open, setOpen] = useState(isOpen);

  const handleAccordion = () => {
    setOpen(!open);
  };

  const calculatePosition = (position) => {
    switch (position) {
      case Position.LEFT:
        return 'flex-start';
      case Position.RIGHT:
        return 'flex-end';
      default:
        return 'flex-end';
    }
  };

  const hasInnerComponent =
    (!open && collapsedContent) ||
    (!open && addition && collapsedAdditionVisible) ||
    (open && addition);

  return (
    <AccordionItemWrapper id={selector}>
      <AccordionHeader>
        <Side>
          <Title onClick={collapse ? handleAccordion : null} collapse={collapse}>
            {collapse && (
              <IconWrapper>
                <Icon rotate={!open ? -90 : 0} opacity={1}>
                  <ArrowIcon />
                </Icon>
              </IconWrapper>
            )}
            <span>{title}</span>
          </Title>
          {amount ? <Amount>{new Intl.NumberFormat('ru-RU').format(amount)} шт.</Amount> : null}
        </Side>
        {hasInnerComponent && (
          <Side fullsize position={calculatePosition(!collapsedAdditionVisible && !open ? collapsedContentPosition : additionPosition)}>
            {!open && collapsedContent && collapsedContent}
            {!open && addition && collapsedAdditionVisible && <Addition>{addition}</Addition>}
            {open && addition && <Addition>{addition}</Addition>}
          </Side>
        )}
      </AccordionHeader>
      <AccordionContent open={open}>{content}</AccordionContent>
    </AccordionItemWrapper>
  );
};

const Side = styled.div<{ fullsize?: boolean; position?: string }>`
  display: flex;
  align-items: flex-end;
  justify-content: ${({ position }) => position};
  gap: 12px;
  flex: 0 1 auto;
  ${({ fullsize }) =>
    fullsize &&
    css`
      flex: 1 1 auto;
    `};
`;

const Amount = styled.div`
  ${({ theme }) => font({ color: theme.colors.dark })};
  margin-bottom: 5px;
`;

const AccordionItemWrapper = styled.div`
  margin-bottom: 32px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;
const AccordionContent = styled.div<{ open: boolean }>`
  display: ${({ open }) => !open && 'none'};
`;
const Title = styled.div<{ collapse: boolean }>`
  display: flex;
  align-items: center;
  font-size: 22px;
  line-height: 38px;
  font-family: ${({ theme }) => theme.fonts.montserrat.semibold};
  cursor: ${({ collapse }) => collapse && 'pointer'};
  margin-right: 16px;
`;
const Addition = styled.div`
  flex: 0 0 auto;
  display: flex;
`;

const IconWrapper = styled.div`
  margin-right: 6px;
`;

export default AccordionItem;
