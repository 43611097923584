import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
}

const Paper: FC<Props> = ({ children }) => {
  return <ComponentContainer>{children}</ComponentContainer>;
};

const ComponentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.hover};
  border-radius: 16px;
`;

export default Paper;
