import styled from 'styled-components';
import { FC } from 'react';
import { renderStatus, StatusType } from '../Status';
import { font } from '../styled/mixins/fonts';

interface Props {
  text: string | number;
  type: StatusType;
  font?: string;
}

const ColoredText: FC<Props> = ({ text, type, font }) => {
  return (
    <ComponentContainer color={renderStatus(type).color} family={font}>
      {text}
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div<{ color: string; family: string }>`
  ${({ theme, color, family }) =>
    font({ color, family: family || theme.fonts.robotoCondensed.bold })};
`;

export default ColoredText;
