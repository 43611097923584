import AgendaRating from '../../Agenda/AgendaRating';

const MainAgenda = () => {
  return (
    <AgendaRating
      noCityDistrictHead={true}
      hideMap={true}
      showOMSUData={true}
      showTables={true}
      tablesWithoutPeriod={true}
    />
  );
};

export default MainAgenda;
