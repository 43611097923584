import React, { FC, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import { formatDate } from '../../../utils/handleTime';
import { font } from '../../styled/mixins/fonts';
import Icon from '../../Icon';
import ArrowIcon from '../../../assets/icons/ArrowIcon';

interface ContentProps {
  fields: any[];
  uniqKey?: string;
  item: any;
  level?: number;
  hideZero?: boolean;
  isGroup?: boolean;
  isTotal?: boolean;
  open?: boolean;
  hideGroupArrow?: boolean;
}

const Content: FC<ContentProps> = ({
  fields,
  item,
  uniqKey,
  level = 0,
  hideZero,
  isGroup,
  isTotal,
  open,
  hideGroupArrow = false,
}) => {
  return (
    <>
      {fields.map((element, index) => {
        let content = get(item, element.field, '');
        if (index === 0) {
          content = (
            <FirstColumn>
              {isGroup && !hideGroupArrow && (
                <IconWrapper>
                  <Icon opacity={0.56} rotate={open ? 0 : -90} size={20}>
                    <ArrowIcon />
                  </Icon>
                </IconWrapper>
              )}
              {content}
            </FirstColumn>
          );
        }
        if (hideZero && content === 0) {
          content = '';
        } else if (element.handleField) {
          content = element.handleField(item);
        } else if (element.component) {
          content = <element.component item={item} />;
        } else if (element.type === 'date') {
          content = formatDate(content, 'DD.MM.YYYY');
        } else if (element.type === 'datetime') {
          content = formatDate(content, 'DD.MM.YYYY HH:mm:ss');
        } else if (element.type === 'price') {
          content = new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2 }).format(
            parseFloat(item[element.field]),
          );
        } else if (element.options) {
          content = element.options[content] || content;
        }

        const params = {} as any;

        if (element.onCellClick && !!content) {
          params.onClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            element.onCellClick(item);
          };
        }

        if (!content) return null;

        return (
          <TBodyCell
            key={`${item[uniqKey]}-${index}`}
            level={index === 0 ? level + 1 : 0}
            isTotal={isTotal}
            clickable={!!content && !!element.onCellClick}
            width={element.width}
            options={{
              ...element.options,
              ...item.options,
              ...item.options?.styles?.[element.field],
            }}
            colSpan={item?.options?.colspan?.[element.field]}
            isGroup={isGroup}
            {...params}
          >
            {content}
          </TBodyCell>
        );
      })}
    </>
  );
};

interface GroupProps {
  fields: any[];
  uniqKey?: string;
  onRowClick?: (el: any) => any;
  item: any;
  childKey?: string;
  level: number;
  hideZero?: boolean;
  hideGroupArrow?: boolean;
  groupOpenDefaultState?: boolean;
}

const Group: FC<GroupProps> = ({
  item,
  fields,
  onRowClick,
  uniqKey,
  childKey,
  level,
  hideZero,
  hideGroupArrow = false,
  groupOpenDefaultState = false,
}) => {
  const [open, setOpen] = useState(groupOpenDefaultState);
  const childs = item[childKey] || [];
  const isGroup = !!childs.length;
  return (
    <>
      <TBodyRow hover={!!onRowClick} onClick={() => (isGroup ? setOpen(!open) : onRowClick(item))}>
        <Content
          fields={fields}
          item={item}
          uniqKey={uniqKey}
          level={level}
          hideZero={hideZero}
          isGroup={isGroup}
          open={open}
          hideGroupArrow={hideGroupArrow}
        />
      </TBodyRow>
      {isGroup &&
        open &&
        childs.map((el) => (
          <Group
            key={el[uniqKey]}
            level={level + 1}
            fields={fields}
            item={el}
            onRowClick={onRowClick}
            uniqKey={uniqKey}
            childKey={childKey}
            hideZero={hideZero}
            hideGroupArrow={hideGroupArrow}
            groupOpenDefaultState={groupOpenDefaultState}
          />
        ))}
    </>
  );
};

interface TBodyProps {
  fields: any[];
  data: any[];
  uniqKey?: string;
  onRowClick?: (el: any) => any;
  childKey?: string;
  hideZero?: boolean;
  total?: any;
  sorting?: any;
  sticky?: boolean;
  hideGroupArrow: boolean;
  groupOpenDefaultState: boolean;
}

const TBody: FC<TBodyProps> = ({
  fields,
  data,
  onRowClick,
  childKey,
  uniqKey,
  hideZero,
  total,
  sorting,
  sticky = true,
  hideGroupArrow = false,
  groupOpenDefaultState = false,
}) => {
  const compareFunctions = (firstItem, secondItem) => {
    if (sorting.direction === 'ASC') {
      if (firstItem[sorting.field + 'Risk'] !== secondItem[sorting.field + 'Risk']) {
        if (firstItem[sorting.field + 'Risk'] < secondItem[sorting.field + 'Risk']) return -1;
        if (firstItem[sorting.field + 'Risk'] > secondItem[sorting.field + 'Risk']) return 1;
      } else {
        if (firstItem[sorting.field] < secondItem[sorting.field]) return -1;
        if (firstItem[sorting.field] > secondItem[sorting.field]) return 1;
      }
    } else {
      if (firstItem[sorting.field + 'Risk'] !== secondItem[sorting.field + 'Risk']) {
        if (firstItem[sorting.field + 'Risk'] > secondItem[sorting.field + 'Risk']) return -1;
        if (firstItem[sorting.field + 'Risk'] < secondItem[sorting.field + 'Risk']) return 1;
      } else {
        if (firstItem[sorting.field] > secondItem[sorting.field]) return -1;
        if (firstItem[sorting.field] < secondItem[sorting.field]) return 1;
      }
    }
  };

  const recursiveSort = useCallback(
    (array) => {
      return array
        .map((item) => ({ ...item, children: item.children ? recursiveSort(item.children) : null }))
        .sort(compareFunctions);
    },
    [sorting, data],
  );

  const sortingData = useCallback(() => {
    if (!sorting) {
      return data;
    } else {
      return recursiveSort(data);
    }
  }, [sorting, data]);

  return (
    <TBodyContainer>
      {total ? (
        <TBodyRow hover={!!onRowClick} isTotal={true} data-tabletotal={true} sticky={sticky}>
          <Content
            fields={fields}
            item={total}
            uniqKey={uniqKey}
            hideZero={hideZero}
            isTotal={true}
            hideGroupArrow={hideGroupArrow}
          />
        </TBodyRow>
      ) : null}
      {sortingData().map((item) => {
        return (
          <Group
            key={`group-${item[uniqKey]}`}
            item={item}
            fields={fields}
            onRowClick={onRowClick}
            uniqKey={uniqKey}
            childKey={childKey}
            level={0}
            hideZero={hideZero}
            hideGroupArrow={hideGroupArrow}
            groupOpenDefaultState={groupOpenDefaultState}
          />
        );
      })}
    </TBodyContainer>
  );
};

const TBodyRow = styled.tr<{ isTotal?: boolean; hover: boolean; sticky?: boolean }>`
  background-color: ${({ isTotal }) => (isTotal ? '#f5f6f9' : '#fff')};
  ${({ isTotal }) =>
    isTotal &&
    css`
      // position: ${(sticky) => (sticky ? 'sticky' : 'relative')};
      top: 148px;
      z-index: 1;
    `};
  &:hover {
    background-color: ${({ theme, isTotal, hover }) =>
      !hover ? 'transparent' : isTotal ? '#f5f6f9' : theme.colors.hover};
  }

  .show-on-hover {
    display: none;
  }

  //&:last-child {
  //  & > td {
  //    &:first-child {
  //      border-radius: 0 0 0 22px;
  //    }
  //    &:last-child {
  //      border-radius: 0 0 22px 0;
  //    }
  //  }
  //}
`;

const TBodyCell = styled.td<{
  level: number;
  isTotal?: boolean;
  clickable?: boolean;
  width?: string;
  options?: any;
  isGroup?: boolean;
}>`
  width: ${({ width }) => width};
  cursor: pointer;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  vertical-align: middle;
  padding: 10px 12px;
  text-align: ${({ options }) => options?.text || 'center'};
  ${({ theme, options }) =>
    font({
      family: options?.family || theme.fonts.robotoCondensed.regular,
      color: options?.color,
    })};

  &:first-child {
    ${({ theme, options }) =>
      font({
        family: options?.family || theme.fonts.montserrat.medium,
        color: options?.color,
      })};
    letter-spacing: -0.04px;
    position: relative;
    padding-left: ${({ level, isGroup, options }) => {
      let padding;
      if (level >= 2 && isGroup) {
        padding = level * (options?.levelStep !== undefined ? options?.levelStep : 20) + 26;
      } else if (level >= 2 && !isGroup) {
        padding =
          level * (options?.levelStep !== undefined ? options?.levelStep : 20) +
          (options?.secondLevelPadding !== undefined ? options?.secondLevelPadding : 46);
        console.log(level, options?.levelStep, options?.secondLevelPadding);
      } else if (options?.firstLevelPadding !== undefined) {
        padding = options?.firstLevelPadding;
      } else {
        padding = level * 20 + 6;
      }
      return padding + 'px';
    }};
    
    
    border-left: ${({ options }) => (options?.first ? '1px solid rgba(100, 108, 170, 0.24)' : '0')};
    border-right: ${({ options }) => (options?.last ? '1px solid rgba(100, 108, 170, 0.24)' : '0')};
    ${({ clickable }) =>
      clickable &&
      css`
        &:hover {
          background-color: ${({ theme }) => theme.colors.hover};
        }
      `};
    //background-color: ${({ options }) => (options?.fill ? 'rgba(100, 108, 170, 0.08)' : '#fff')};

    ${({ isTotal }) =>
      isTotal &&
      css`
              //background-color: rgba(0, 19, 92, 0.04);
              &:nth-child(1) {
                padding-left: 30px;
                ${({ theme }) =>
                  font({ family: theme.fonts.montserrat.bold, size: theme.fonts.sizes.xmd })};
              }

              ${({ theme }) => font({ family: theme.fonts.robotoCondensed.bold })}
            }

            ;
            `};
`;
const FirstColumn = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
`;
const IconWrapper = styled.div`
  margin-right: 4px;
`;
const TBodyContainer = styled.tbody`
  overflow-y: auto;
`;

export default TBody;
