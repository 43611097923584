import { fetchInstance } from '../utils/axios';
import { api } from '../config/api';

export const login = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.auth,
    data,
  });
};

export const refreshToken = () => {
  return fetchInstance({
    method: 'GET',
    url: api.refreshToken,
  });
};

export const whoAmI = () => {
  return fetchInstance({
    method: 'GET',
    url: api.whoAmI,
  });
};

export const getNsi = (nsiName) => {
  return fetchInstance({
    method: 'GET',
    url: `${api.getNsi}/${nsiName}`,
  });
};

export const getLevelBudget = (level) => {
  return fetchInstance({
    method: 'GET',
    url: `${api.getNsi}/LevelBudget/${level}`,
  });
};

export const getOMSUList = (id) => {
  return fetchInstance({
    method: 'GET',
    url: `${api.getNsi}/OMSU/${id}`,
  });
};

export const getObjects = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getObjects,
    data,
  });
};

export const getDiagrammsDataByOMSUid = (omsuId) => {
  return fetchInstance({
    method: 'POST',
    url: api.getDiagrammsDataByOMSUid,
    data: {
      omsuId,
    },
  });
};

export const getObjectCard = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getObjectCard,
    data,
  });
};

export const getMunicipalityInfo = (id) => {
  return fetchInstance({
    method: 'GET',
    url: api.getMunicipalityInfo(id),
  });
};

export const getAgendaBudgetData = (params) => {
  return fetchInstance({
    method: 'POST',
    url: api.getBudgetOMSU,
    data: {
      ...params,
    },
  });
};

export const getAgendaFinanceData = (params) => {
  return fetchInstance({
    method: 'POST',
    url: api.getAgendaFinance,
    data: {
      ...params,
    },
  });
};

export const getRatingOMSU = (params) => {
  return fetchInstance({
    method: 'POST',
    url: api.getRatingOMSU,
    data: {
      ...params,
    },
  });
};

export const getRatingInfoOMSU = (params) => {
  return fetchInstance({
    method: 'POST',
    url: api.getRatingInfoOMSU,
    data: {
      ...params,
    },
  });
};

export const getInfoOMSU = (params) => {
  return fetchInstance({
    method: 'POST',
    url: api.getInfoOMSU,
    data: {
      ...params,
    },
  });
};

export const getCurrentPositionOMSU = (params) => {
  return fetchInstance({
    method: 'POST',
    url: api.getCurrentPositionOMSU,
    data: {
      ...params,
    },
  });
};

export const getRatingIndicatorsOMSU = (params) => {
  return fetchInstance({
    method: 'POST',
    url: api.getRatingIndicatorsOMSU,
    data: {
      ...params,
    },
  });
};

export const getCalendarRating = (params) => {
  return fetchInstance({
    method: 'POST',
    url: api.getCalendarRating,
    data: {
      ...params,
    },
  });
};

export const getDebtOMSU = (params) => {
  return fetchInstance({
    method: 'POST',
    url: api.getDebtOMSU,
    data: {
      ...params,
    },
  });
};

export const getProblems = (params) => {
  return fetchInstance({
    method: 'POST',
    url: api.getProblems,
    data: {
      ...params,
    },
  });
};

export const getTopCompanies = (params) => {
  return fetchInstance({
    method: 'POST',
    url: api.getTopCompanies,
    data: {
      ...params,
    },
  });
};

export const getTopIndustriesTaxes = (params) => {
  return fetchInstance({
    method: 'POST',
    url: api.getTopIndustriesTaxes,
    data: {
      ...params,
    },
  });
};

export const getIndicatorsOMSU = (params) => {
  return fetchInstance({
    method: 'POST',
    url: api.getIndicatorsOMSU,
    data: {
      ...params,
    },
  });
};

export const getRiskOMSU = (params) => {
  return fetchInstance({
    method: 'POST',
    url: api.getRiskOMSU,
    data: {
      ...params,
    },
  });
};

export const getExecutionIndicatorWithFinancing = (params) => {
  return fetchInstance({
    method: 'POST',
    url: api.getExecutionIndicatorWithFinancing,
    data: {
      ...params,
    },
  });
};

export const getExecutionIndicatorWithoutFinancing = (params) => {
  return fetchInstance({
    method: 'POST',
    url: api.getExecutionIndicatorWithoutFinancing,
    data: {
      ...params,
    },
  });
};

export const getExecutionIndicatorRating = (params) => {
  return fetchInstance({
    method: 'POST',
    url: api.getExecutionIndicatorRating,
    data: {
      ...params,
    },
  });
};

export const getBudgetConsolidatedInformation = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getBudgetConsolidatedInformation,
    data,
  });
};

export const getBudgetExecution = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getBudgetExecution,
    data,
  });
};

export const getDinamBudgetExecutionTable = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getDinamBudgetExecutionTable,
    data,
  });
};

export const getDinamBudgetExecutionTableMain = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getDinamBudgetExecutionTableMain,
    data,
  });
};

export const getDynamicBudget = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getDynamicBudgetExecutionDiagramma,
    data,
  });
};

export const getLevelBudgetNsi = (lbid) => {
  return fetchInstance({
    method: 'GET',
    url: api.getLevelBudgetNsi(lbid),
  });
};

export const getBudgetIncome = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getBudgetIncome,
    data,
  });
};

export const getBudgetExpense = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getBudgetExpense,
    data,
  });
};

export const getBudgetDebt = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getBudgetDebt,
    data,
  });
};

export const getMunicipalityDebtRaising = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getMunicipalityDebtRaising,
    data,
  });
};

export const getMunicipalityDebtRepayment = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getMunicipalityDebtRepayment,
    data,
  });
};

export const getGrowthRateDebt = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getGrowthRateDebt,
    data,
  });
};

export const getBudgetIncomeCompare = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getBudgetIncomeCompare,
    data,
  });
};

export const getBudgetExpenseCompare = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getBudgetExpenseCompare,
    data,
  });
};

export const getBudgetExecutionDiagram = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getBudgetExecutionDiagram,
    data,
  });
};

export const getBudgetDataExpensesDynamics = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getBudgetDataExpensesDynamics,
    data,
  });
};


export const getEvoConsolidatedInformation = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getEvoConsolidatedInformation,
    data,
  });
};

export const getNewWorkPlace = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getNewWorkPlace,
    data,
  });
};

export const getEvoInvestments = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getEvoInvestments,
    data,
  });
};

export const getIndustrial = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getIndustrial,
    data,
  });
};

export const getIndustrialLand = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getIndustrialLand,
    data,
  });
};

export const getRentLand = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getRentLand,
    data,
  });
};

export const getSocNeeds = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getSocNeeds,
    data,
  });
};

export const getGosDebtDetails = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getGosDebtDetails,
    data,
  });
};

export const getCalendarIncomeInfo = (data) => {
  return fetchInstance({
    method: 'POST',
    url: `${api.getNsi}/CalendarIncomeInfo`,
    data,
  });
};

export const getCalendarExpenseInfo = (data) => {
  return fetchInstance({
    method: 'POST',
    url: `${api.getNsi}/CalendarExpenseInfo`,
    data,
  });
};

export const getIndicatorTotal = (indicatorId) => {
  return fetchInstance({
    method: 'GET',
    url: `${api.getNsi}/IndicatorTotal/${indicatorId}`,
  });
};

export const getIndicatorsTableList = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getExecutionIndicators,
    data,
  });
};

export const getIndicatorTableData = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getExecutionIndicatorsTable,
    data,
  });
};

export const getExecutionMainEconomicIndicators = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getExecutionMainEconomicIndicators,
    data,
  });
};

export const getExecutionEconomicIndicatorDetails = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getExecutionEconomicIndicatorDetails,
    data,
  });
};

export const getAgendaObjectsReester = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getAgendaObjectsReester,
    data,
  });
};

export const getAgendaIndicatorReestr = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getAgendaIndicatorReestr,
    data,
  });
};

export const getAgendaIndicatorById = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getIndicatorById,
    data,
  });
};

export const getAgendaIndicatorDescriptionById = (data?: object) => {
  return fetchInstance({
    method: 'POST',
    url: api.getAgendaIndicatorDescriptionById,
    data,
  });
};

export const getIndicatorPeriodStatistic = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getIndicatorPeriodStatistic,
    data,
  });
};

export const getIndicatorSubjectsStatistic = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getIndicatorSubjectsStatistic,
    data,
  });
};

export const getIndicatorOMSUStatistic = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getIndicatorOMSUStatistic,
    data,
  });
};

export const getSocPosition = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getSocPosition,
    data,
  });
};

export const getObjectFinanceDetails = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getObjectFinanceDetails,
    data,
  });
};

export const getIndicatorLevels = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getIndicatorLevels,
    data,
  });
};

export const getIndicatorLevelPeriods = (data) => {
  return fetchInstance({
    method: 'POST',
    url: api.getIndicatorLevelPeriods,
    data,
  });
};
