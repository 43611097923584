import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { Field } from '..';
import BodyRow from './BodyRow';

interface Props {
  fields: Field[];
  data: any[];
  sorting?: any;
  total?: any;
  onRowClick?: (el: any) => any;
}

const TableBody: FC<Props> = ({ fields, data, sorting, total, onRowClick }) => {
  const compareFunctions = useCallback(
    (firstItem, secondItem) => {
      const isASC = sorting.direction === 'ASC';
      const sortingFieldRisk = sorting.field + 'Risk';
      if (firstItem[sortingFieldRisk] !== secondItem[sortingFieldRisk]) {
        if (firstItem[sortingFieldRisk] < secondItem[sortingFieldRisk]) return isASC ? -1 : 1;
        if (firstItem[sortingFieldRisk] > secondItem[sortingFieldRisk]) return isASC ? 1 : -1;
      } else {
        if (firstItem[sorting.field] < secondItem[sorting.field]) return isASC ? -1 : 1;
        if (firstItem[sorting.field] > secondItem[sorting.field]) return isASC ? 1 : -1;
      }
    },
    [sorting],
  );

  const recursiveSort = useCallback(
    (array) => {
      if (sorting.direction === '') {
        return array;
      }
      return array
        .map((item) => ({ ...item, children: item.children ? recursiveSort(item.children) : null }))
        .sort(compareFunctions);
    },
    [sorting, data],
  );

  const sortingData = useCallback(() => {
    if (!sorting) {
      return data;
    } else {
      return recursiveSort(data);
    }
  }, [sorting, data]);

  return (
    <Body>
      {total && (
        <BodyRow
          key={total.id}
          fields={fields}
          item={total}
          level={1}
          // onRowClick={() => null}
          hideZero={false}
          order={1}
          onRowClick={onRowClick}
        />
      )}
      {sortingData().map((item, idx) => (
        <BodyRow
          key={item.id}
          fields={fields}
          item={item}
          level={1}
          // onRowClick={() => null}
          hideZero={false}
          order={1 + idx}
          lastRow={idx === sortingData().length - 1}
          onRowClick={onRowClick}
        />
      ))}
    </Body>
  );
};

const Body = styled.tbody``;

export default TableBody;
