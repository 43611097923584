import { isNumber } from "lodash";

export const prepareNumber = (num, precision) => {
  if (!num && num !== 0) return '';
  let params = {};

  if(isNumber(precision)){
    params = {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision
    }
  }

  return String(new Intl.NumberFormat('ru-RU', params).format(num));
  // return String(num)
  //   .match(/\d{1,3}(?=(\d{3})*$)/g)
  //   .join(' ');
};

export const prepareNumberForTable = (num) => {
  return num == 0
    ? num.toString()
    : new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
        parseFloat(num),
      );
};
