import {FC, useState} from "react";
import styled from "styled-components";
import { font } from '../../styled/mixins/fonts';
import ArrowIcon from "../../../assets/icons/ArrowIcon";
import ChevronIcon from "../../../assets/icons/ChevronIcon";

interface Props {
    id: number;
    label: string;
    item: any;
    children?: {
        id: number;
        label: string;
        value: string;
    }[];
    onClick: (item: any) => void;
    active?: any;
}

const ListItem:FC<Props> = ({children, onClick, active, id, label, item}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const handleClick = () => {
        if(children?.length) {
            setIsOpen(!isOpen)
        } else {
            onClick(item)
        }
    }

    return (
        <ComponentContainer>
            <Row onClick={handleClick}>
                {!!children?.length && <IconWrapper rotate={isOpen ? 0 : -90}>
                  <ChevronIcon />
                </IconWrapper>}
                <Label additionalMargin={!!children?.length}>{label}</Label>
            </Row>
            <Content isOpen={isOpen}>
                {children?.map(item => <ListItem key={item.id} id={item.id} {...item} item={item} onClick={onClick} />)}
            </Content>
        </ComponentContainer>
    )
}

const ComponentContainer = styled.div`
  
`
const Label = styled.div<{additionalMargin: boolean;}>`
  ${() => font()};
  margin-left: ${({additionalMargin}) => !additionalMargin && '8px'};
`
const Row = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  padding: 0 8px 0 16px;
  min-height: 32px;
  &:hover {
    background-color: ${({theme}) => theme.colors.hover};
  }
`
const IconWrapper = styled.div<{rotate: number;}>`
  font-size: 0;
  transform-origin: 50% 50%;
  rotate: ${({rotate}) => rotate}deg;
  svg {
    fill: ${({theme}) => theme.colors.dark};
  }
`
const Content = styled.div<{isOpen: boolean}>`
  overflow: hidden;
  height: ${({isOpen}) => isOpen ? 'auto' : 0};
`

export default ListItem;
