import { Modal } from '../../../../components/Modal';
import { connectModal } from '../../../../hocs/Modal';
import styled from 'styled-components';
import { useFilter } from '../../../../hooks/useFilter';
import { initialBudgetMunicipalDebtModalFilterValues } from './consts';
import MunicipalDebtTable from './MunicipalDebtTable';
import BudgetMunicipalDebtModalHeader from './BudgetMunicipalDebtModalHeader';

const BudgetMunicipalDebtModal = connectModal('budget-municipal-debt-modal')(({ handleHide, parentFilters }) => {

  const { filter, setFilterCallback: setFilter } = useFilter({
    initialFilterValues: { ...initialBudgetMunicipalDebtModalFilterValues, ...parentFilters },
    name: 'budgetMunicipalDebtModalFilter',
    dateFields: ['fromDate', 'toDate'],
    disableCache: true,
  });

  return (
    <Modal
      title={'Муниципальный долг'}
      onClose={handleHide}
      width={'75%'}
      header={
        <BudgetMunicipalDebtModalHeader
          filter={filter}
          setFilter={setFilter}
        />
      }
    >
      <Content>
        <MunicipalDebtTable filter={filter} />
      </Content>
    </Modal>
  );
});

const Content = styled.div`
  padding: 0 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export default BudgetMunicipalDebtModal;
