import styled from 'styled-components';
import Grouping from '../../../../components/Grouping';
import SelectField from '../../../../components/SelectField';
import { Button } from '../../../../components/Button';
import Checkbox from '../../../../components/Checkbox';
import { FC, useCallback, useContext, useState } from 'react';
import { font } from '../../../../components/styled/mixins/fonts';
import { observer } from 'mobx-react-lite';
import { NsiStoreContext } from '../../../../stores/nsi';
import { toJS } from 'mobx';
import { initialFilterValues } from '../../consts';

interface Props {
  filter: any;
  setMassFilterCallback: any;
  onClose?: any;
  customFilters: any[];
}

const getFilterValues = (filterProps) => ({
  fundingType: filterProps.fundingType,
  curator: filterProps.curator,
  hasFederalFunding: filterProps.hasFederalFunding,
  isNationalProject: filterProps.isNationalProject,
  isCurrentYearToOpen: filterProps.isCurrentYearToOpen,
  view: filterProps.view,
});

const FiltersList: FC<Props> = observer(
  ({ filter: filterProps, setMassFilterCallback, onClose, customFilters }) => {
    const { FundingType, curators } = useContext(NsiStoreContext);
    const [filter, setFilter] = useState(getFilterValues(filterProps));

    const setFilterCallback = useCallback((name, value) => {
      setFilter((state) => ({
        ...state,
        [name]: value,
      }));
    }, []);

    const onApply = useCallback(() => {
      setMassFilterCallback(filter);
      onClose();
    }, [filter, onClose]);

    const onReset = useCallback(() => {
      const initialFilter = getFilterValues(initialFilterValues);
      setFilter(initialFilter);
      setMassFilterCallback(initialFilter);
    }, []);

    return (
      <ComponentContainer>
        <Header>
          <Title>Все фильтры</Title>
          <Clear onClick={onReset}>Сбросить</Clear>
        </Header>
        <Content>
          <Grouping view={filter.view} setFilter={setFilterCallback} />
          <Field>
            <SelectField
              onChange={(e: any) => setFilterCallback('fundingType', e)}
              list={FundingType}
              placeholder={'Выберите финансирование'}
              value={filter.fundingType}
              label={'Финансирование'}
            />
          </Field>
          <Field>
            <SelectField
              onChange={(e: any) => setFilterCallback('curator', e)}
              list={curators}
              placeholder={'Выберите куратора'}
              value={filter.curator}
              label={'Куратор'}
            />
          </Field>
          <Field>
            <Checkbox
              label={'Федеральное финансирование'}
              name={'hasFederalFunding'}
              onChange={({ check, name }) => setFilterCallback(name, check)}
              checked={filter.hasFederalFunding}
            />
          </Field>
          <Field>
            <Checkbox
              label={'Национальный проект'}
              name={'isNationalProject'}
              onChange={({ check, name }) => setFilterCallback(name, check)}
              checked={filter.isNationalProject}
            />
          </Field>
          <Field>
            <Checkbox
              label={'Открытие в текущем году'}
              name={'isCurrentYearToOpen'}
              onChange={({ check, name }) => setFilterCallback(name, check)}
              checked={filter.isCurrentYearToOpen}
            />
          </Field>
          {customFilters.map((item) => (
            <Field key={item.priority}>{item?.customField}</Field>
          ))}
        </Content>
        <Footer>
          <Button hollow onClick={onClose}>
            Отмена
          </Button>
          <Button onClick={onApply}>Применить</Button>
        </Footer>
      </ComponentContainer>
    );
  },
);

const ComponentContainer = styled.div``;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px;
`;
const Title = styled.div`
  ${({ theme }) => font({ family: theme.fonts.montserrat.semibold, size: theme.fonts.sizes.xmd })};
`;
const Clear = styled.div`
  ${({ theme }) => font({ color: theme.colors.placeholder })};
  cursor: pointer;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 12px 16px;
  width: 280px;
    > div {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
`;
const Field = styled.div``;
const Footer = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

export default FiltersList;
