export const rentFields = [
  [
    {
      id: 0,
      label: '',
      field: 'title',
      sorting: false,
      width: '75%',
    },
    {
      id: 1,
      label: (
        <>
          Всего
          <br />
          ЗУ
        </>
      ),
      field: 'total',
      sorting: true,
      width: '5%',
    },
    {
      id: 2,
      label: 'Средний % выполнения обязательств',
      field: 'median',
      sorting: true,
      width: '5%',
    },
    {
      id: 3,
      label: (
        <>
          Налоги
          <br />%
        </>
      ),
      field: 'taxes',
      sorting: true,
      width: '5%',
    },
    {
      id: 4,
      label: (
        <>
          PM
          <br />%
        </>
      ),
      field: 'pm',
      sorting: true,
      width: '5%',
    },
    {
      id: 5,
      label: (
        <>
          Объем инвестиций
          <br />%
        </>
      ),
      field: 'investment',
      sorting: true,
      width: '5%',
    },
  ],
];
