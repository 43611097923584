import styled from 'styled-components';
import { FC, useCallback } from 'react';
import GroupElement from './GroupElement';
import { font } from '../styled/mixins/fonts';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface Props {
  view: any[];
  setFilter: any;
}

const Grouping: FC<Props> = ({ view, setFilter }) => {
  const onDragEnd = useCallback(
    (result) => {
      const list = reorder(view, result.source.index, result.destination.index);
      setFilter('view', list);
    },
    [view, setFilter],
  );
  return (
    <ComponentContainer>
      <Title>Группировка</Title>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable' type='GROUP'>
          {(provided) => (
            <Content ref={provided.innerRef}>
              {view.map((el, idx) => (
                <GroupElement key={`group-${el.id}`} idx={idx} {...el} />
              ))}
              {provided.placeholder}
            </Content>
          )}
        </Droppable>
      </DragDropContext>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div``;
const Title = styled.div`
  ${({ theme }) => font({ color: theme.colors.dark, size: theme.fonts.sizes.sm })};
  margin-bottom: 8px;
`;
const Content = styled.div``;

export default Grouping;
