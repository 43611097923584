export const filters = [
    {
      id: 0,
      title: 'По периодам',
      value: 'period',
    },
    {
      id: 1,
      title: 'По субъектам',
      value: 'subject',
    },
    {
      id: 2,
      title: 'По ОМСУ',
      value: 'omsu',
    },
  ];

export const headerPeriodTypeFilter = [
    {
        id: 0,
        label: 'За год',
        value: 'year',
    },
    {
        id: 1,
        label: 'За квартал',
        value: 'quarter',
    },
    {
        id: 2,
        label: 'За месяц',
        value: 'month',
    },
]