import ActionIcon from '../ActionIcon';
import { FC, useRef, useState, isValidElement, cloneElement, useCallback } from 'react';
import styled from 'styled-components';
import { AnchorPopupStore } from '../../stores/anchorPopup';

interface Props {
  icon: any;
  content: any;
  tooltip?: string;
  opacity?: number;
  additionalPadding?: boolean;
  place?: string;
}

const IconButtonAction: FC<Props> = ({
  icon,
  content,
  tooltip = '',
  opacity = 0.3,
  additionalPadding = true,
  place,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const iconButtonRef = useRef<any>();

  const onCloseCallback = useCallback(() => {
    setIsOpen(false);
  }, []);

  const PopupComponent = (
    <ContentWrapper padding={additionalPadding ? 8 : 0}>
      {/* @ts-ignore*/}
      {isValidElement(content) ? cloneElement(content, { onCloseCallback }) : content}
    </ContentWrapper>
  );

  return (
    <TooltipWrapper ref={iconButtonRef} data-component='ActionIcon'>
      <ActionIcon
        action={() => {
          setIsOpen(!isOpen);
          if (!isOpen) {
            AnchorPopupStore.showPopup({
              component: PopupComponent,
              anchorRef: iconButtonRef.current,
              onClose: () => setIsOpen(false),
            });
          } else {
            AnchorPopupStore.hidePopup();
          }
        }}
        active={isOpen}
        tooltip={tooltip}
        opacity={opacity}
        place={place}
      >
        {icon}
      </ActionIcon>
    </TooltipWrapper>
  );
};

const TooltipWrapper = styled.div`
  position: relative;
`;

const ContentWrapper = styled.div<{ padding: number }>`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.02);
  box-shadow: 0 8px 16px rgba(8, 35, 48, 0.2);
  border-radius: 8px;
  padding: ${({ padding }) => `${padding} 0`};
  z-index: 1;
  margin-top: 6px;
`;

export default IconButtonAction;
