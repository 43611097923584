import { useCallback, useState } from 'react';
import { fetchInstance } from '../utils/axios';
import { useContext } from 'react';
import { ListsStoreContext } from '../stores/lists';

export const useFetching = ({ filter, url, limit, name }) => {
  const ListsStore = useContext(ListsStoreContext);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(
    async (params: { offset?: number; limit?: number } = {}) => {
      setLoading(true);
      try {
        let data = {} as any;
        if (filter) {
          data = { ...filter };
        }
        data.limit = params.limit || ListsStore.meta[name]?.maxItems || limit;
        data.offset = params.limit ? 0 : params.offset ?? ListsStore.meta[name]?.startIndex ?? 0;
        const response = await fetchInstance({
          method: 'POST',
          url,
          data,
        });
        ListsStore.setData(name, response.data.items);
        ListsStore.setMeta(name, response.data.info);
      } catch (e) {
        //
      } finally {
        setLoading(false);
      }
    },
    [filter, url, name, limit],
  );

  return { fetchData, loading };
};
