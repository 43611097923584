import { FC } from 'react';
import { theme } from '../../styles/theme';

interface Props {
  color: boolean;
}

const LoaderIcon: FC<Props> = ({ color }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='200px'
    height='200px'
    viewBox='0 0 100 100'
    preserveAspectRatio='xMidYMid'
  >
    <circle
      cx='50'
      cy='50'
      fill='none'
      stroke={color ? '#fff' : theme.colors.primary}
      strokeWidth='7'
      r='35'
      strokeDasharray='164.93361431346415 56.97787143782138'
    >
      <animateTransform
        attributeName='transform'
        type='rotate'
        repeatCount='indefinite'
        dur='1s'
        values='0 50 50;360 50 50'
        keyTimes='0;1'
      ></animateTransform>
    </circle>
  </svg>
);

export default LoaderIcon;
