import styled from 'styled-components';
import Avatar from '../Avatar';
import { FC, useState } from 'react';
import UserPanel from './UserPanel';
import { useOutsideClick } from '../../hooks/useOutsideClick';

export interface User {
  avatar: string;
  username: string;
  contact?: string;
}

const User: FC<User> = ({ avatar, username, contact }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const ref = useOutsideClick(() => {
    setOpen(false);
  });
  return (
    <>
      <UserWrapper ref={ref}>
        <UserInfo onClick={toggleOpen}>
          <UserName>{username}</UserName>
          <Avatar avatar={avatar} />
        </UserInfo>
        <UserMenu active={open}>
          <UserPanel username={username} avatar={avatar} contact={contact} />
        </UserMenu>
      </UserWrapper>
    </>
  );
};

const UserWrapper = styled.div`
  position: relative;
`;

const UserName = styled.div`
  font-family: ${({ theme }) => theme.fonts.montserrat.medium};
  font-size: ${({ theme }) => theme.fonts.sizes.md};
  max-width: 200px;
  text-align: right;
  margin-right: 12px;
  line-height: 18px;
  @media all and (max-width: 900px) {
    display: none;
  }
`;
const UserMenu = styled.div<{ active: boolean }>`
  position: absolute;
  right: 0;
  top: 100%;
  display: ${({ active }) => (active ? 'flex' : 'none')};
  margin-top: 6px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.02);
  box-shadow: 0 8px 16px rgba(8, 35, 48, 0.2);
  border-radius: 8px;
  z-index: 1111;
`;
const UserInfo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export default User;
