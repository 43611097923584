import AccordionItemComponent from './AccordionItem';
import { FC, ReactElement, ReactNode } from 'react';

export enum Position {
  LEFT,
  RIGHT,
}

export interface AccordionItem {
  id: number;
  header: {
    isOpen?: boolean;
    title: string;
    addition?: ReactElement;
    amount?: number;
    collapse?: boolean;
    additionPosition?: Position;
    collapsedContent?: ReactNode;
    collapsedContentPosition?: Position;
    collapsedAdditionVisible?: boolean;
    selector?: string;
  };
  content: ReactElement;
}

interface Props {
  items: AccordionItem[];
}

const Accordion: FC<Props> = ({ items }) => {
  return (
    <>
      {items.map((item) => (
        <AccordionItemComponent key={item.id} {...item} />
      ))}
    </>
  );
};

export default Accordion;
