const Logo = () => {
  return (
    <svg
      width="52"
      height="24"
      viewBox="0 0 52 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.57815 4.57808V23.6291H0V0.202568H10.7361V4.57808H4.57815Z"
        fill="#646CAA"
      />
      <path
        d="M17.8039 19.0509H14.3695L13.662 23.6291H8.81592L13.2601 0.202568H18.848L23.3231 23.6291H18.5114L17.8039 19.0509ZM15.4822 11.0074L15.0117 14.7441H17.1308L16.6946 11.0074L16.1897 6.26097H15.9871L15.4822 11.0074Z"
        fill="#646CAA"
      />
      <path
        d="M31.2336 0C33.2737 0 34.7849 0.497998 35.7603 1.49743C36.7357 2.49686 37.2233 3.79166 37.2233 5.38525C37.2233 5.49859 37.2233 5.60506 37.2233 5.70466C37.2233 5.80426 37.213 5.91072 37.189 6.02406L32.7791 6.79682C32.7585 6.10305 32.6074 5.52263 32.3292 5.06241C32.051 4.60219 31.6801 4.37208 31.213 4.37208C30.9005 4.37208 30.6532 4.4339 30.4643 4.55754C30.2754 4.68118 30.1243 4.8323 30.0144 5.01089C29.9045 5.18948 29.8255 5.38525 29.7809 5.59819C29.7362 5.81112 29.7156 5.99658 29.7156 6.15457V18.1031C29.7156 18.4191 29.7946 18.7213 29.9491 19.0132C30.1037 19.3052 30.4746 19.4494 31.055 19.4494C31.5015 19.4494 31.8656 19.2468 32.1438 18.8449C32.422 18.4397 32.5731 17.8352 32.5971 17.0281L37.1203 17.6326C37.0963 19.494 36.5811 20.9915 35.5714 22.1249C34.5616 23.2582 33.102 23.8249 31.1959 23.8249C29.0871 23.8249 27.5484 23.3303 26.5834 22.3447C25.6183 21.359 25.1375 19.8753 25.1375 17.9005V6.16144C25.1375 4.29996 25.6354 2.80596 26.6349 1.68632C27.6377 0.563253 29.1695 0 31.2336 0Z"
        fill="#646CAA"
      />
      <path
        d="M45.1662 11.4092L47.6562 0.202568H51.8978L47.22 18.4121C46.9041 19.6691 46.5537 20.6617 46.1588 21.3898C45.7673 22.1179 45.3242 22.6674 44.8296 23.0384C44.3351 23.4093 43.813 23.6566 43.2635 23.7802C42.714 23.9038 42.137 23.976 41.5291 24H40.1485V20.2633H40.6533C41.2818 20.2633 41.7352 20.1328 42.0168 19.8752C42.2984 19.6176 42.5595 19.2295 42.8067 18.7144C42.8514 18.601 42.9029 18.4636 42.9579 18.2919C43.0128 18.1236 43.0643 17.9485 43.109 17.7699L38.2629 0.233478H42.7381L44.9945 11.4092H45.1662Z"
        fill="#646CAA"
      />
    </svg>
  );
};

export default Logo;
