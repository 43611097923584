import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Card from '../../../components/Card';
import ColumnChart from '../../../components/ColumnChart';
import { AppStoreContext } from '../../../stores/app';
import { observer } from 'mobx-react-lite';
import { getBudgetConsolidatedInformation } from '../../../actions';
import groupBy from 'lodash/groupBy';
import { Loader } from '../../../components/Loader';

const BudgetSummaryInfo: FC = observer(() => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const isGlobalOmsu = selectedOmsu?.idx === 0;

  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getBudgetConsolidatedInformation({ omsuId: selectedOmsu?.id });
        const groupedResponse = groupBy(response.data.items, 'period.appliesToYear');
        setInfo(groupedResponse);
      } catch (e) {
        //
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedOmsu?.id]);

  const config = useMemo(() => {
    if (info) {
      const getindicatorValues = (idx) => Object.values(info).map((el) => ({ y: el[idx]?.indicatorValue, precision: el[idx]?.precision }));
      const valueFormatter = function () {
        return new Intl.NumberFormat('ru-RU', {
          minimumFractionDigits: this.point.options.precision,
          maximumFractionDigits: this.point.options.precision
        }).format(
          parseFloat(this.point.options.y),
        )
      }
      
      const maxDataLevel = Math.max(...(Object.values(info).flat().map((item: any) => item?.indicatorValue) || []));
      const maxChartLevel = Math.ceil(maxDataLevel / 10) * 10 + (maxDataLevel / 100 * 8);
      
      return {
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: Object.keys(info),
          labels: {
            style: {
              fontFamily: 'Roboto Condensed Regular',
              fontSize: 12,
            }
          },
        },
        legend: {
          verticalAlign: 'top',
          align: 'right',
        },
        yAxis: {
          visible: false,
          max: maxChartLevel,
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            type: 'column',
            name: 'Доход',
            color: '#172B75',
            data: getindicatorValues(0),
            stack: 'male',
            dataLabels: {
              inside: false,
              enabled: true,
              // format: '<div style="font-size: 16px;">' + '{point.y}</div>',
              useHTML: true,
              align: 'center',
              style: {
                fontFamily: 'Montserrat Bold',
                fontSize: '16px',
                color: '#000',
              },
              formatter: valueFormatter
            },
          },
          {
            type: 'column',
            name: 'Расход',
            color: '#646CAA',
            data: getindicatorValues(1),
            stack: 'males',
            dataLabels: {
              inside: false,
              enabled: true,
              // format: '<div style="font-size: 16px">' + '{point.y}</div>',
              useHTML: true,
              align: 'center',
              style: {
                fontFamily: 'Montserrat Bold',
                fontSize: '16px',
                color: '#000',
              },
              formatter: valueFormatter
            },
          },
          {
            type: 'column',
            name: isGlobalOmsu ? 'Госдолг' : 'Мундолг',
            color: '#A3A9D4',
            data: getindicatorValues(2),
            stack: 'female',
            dataLabels: {
              inside: false,
              enabled: true,
              // format: '<div style="font-size: 16px">' + '{point.y}</div>',
              useHTML: true,
              align: 'center',
              style: {
                fontFamily: 'Montserrat Bold',
                fontSize: '16px',
                color: '#000',
              },
              formatter: valueFormatter
            },
          },
        ],
      };
    }
    return null;
  }, [info, isGlobalOmsu]);

  return (
    <Card>
      <Content>{loading ? <Loader /> : <ColumnChart config={config} />}</Content>
    </Card>
  );
});

const Content = styled.div`
  padding: 24px;
`;

export default BudgetSummaryInfo;
