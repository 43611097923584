import styled from 'styled-components';
import Card, { CardTheme } from '../../../components/Card';
import Stats, { StatsSizes } from '../../../components/Stats';
import { font } from '../../../components/styled/mixins/fonts';
import { SimpleTable } from '../../../components/SimpleTable';
import { fields } from './fields';
import { FC, memo, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IndicatorStoreContext } from '../../../stores/indicator';
import { Loader } from '../../../components/Loader';
import NoDataIcon from '../../../assets/icons/NoDataIcon';
import NoData from '../../../components/NoData';
import { prepareNumber } from '../../../utils/prepareNumber';
import { useLocation } from 'react-router-dom';
import { getQueryParameterByName } from '../../../utils/getQueryParameterByName';

interface Statistic {
  change: number;
  name: string;
  plan: string;
  position: number;
  progress: number;
  positionTonality: number;
}

interface Data {
  firstSubjectStatistic: Statistic;
  lastSubjectStatistic: Statistic;
  subjectStatistic?: Statistic[];
  omsuStatistics?: Statistic[];
}

interface Props {
  indicatorId: string;
  fetchMethod: (data: any) => any;
  bySubjects?: boolean;
  precision?: number;
}

interface CardInterface {
  label: string;
  text: string;
  stats: number | string;
  precision: number;
}

const StatisticCard: FC<CardInterface> = memo(({ label, text, stats, precision }: CardInterface) => {
  return (
    <Card theme={CardTheme.DARK}>
      <CardContent>
        <Side>
          <Label>{label}</Label>
          <Text>{text}</Text>
        </Side>
        <Stats progress={stats} size={StatsSizes.BIG} precision={precision} />
      </CardContent>
    </Card>
  );
});

const IndicatorSubjectOMSUStats: FC<Props> = observer(
  ({ indicatorId, fetchMethod, bySubjects = false, precision = 0 }) => {
    const { indicatorHeaderFilters } = useContext(IndicatorStoreContext);
    const location = useLocation();

    const [data, setData] = useState<Data>();
    const [dataLoading, setDataLoading] = useState<boolean>(true);

    useEffect(() => {
      if (indicatorHeaderFilters?.period !== undefined) {
        const fetchData = async () => {
          setDataLoading(true);
          try {
            const params: any = {
              indicatorId,
              periodId: indicatorHeaderFilters.period?.id,
            };
            if (!bySubjects) {
              const omsuId = getQueryParameterByName(location.search, 'omsuId');
              if (omsuId) params.omsuId = omsuId;
            }
            const response = await fetchMethod(params);
            setData(response.data);
          } catch (e) {
            //
          } finally {
            setDataLoading(false);
          }
        };
        fetchData();
      }
    }, [indicatorHeaderFilters.period, indicatorId, fetchMethod, bySubjects]);

    const preparedData = useMemo(() => {
      return (data?.subjectStatistic || data?.omsuStatistics || []).map((el) => {
        return {
          ...el,
          plan: prepareNumber(el.plan, precision),
          progress: prepareNumber(el.progress, precision),
        };
      });
    }, [data, precision]);

    return (
      <ComponentContainer>
        {dataLoading ? (
          <Loader />
        ) : !data ? (
          <NoData description={'Нет информации'} image={<NoDataIcon />} />
        ) : (
          <>
            <Cards>
              <StatisticCard
                label={'Минимум'}
                text={data?.firstSubjectStatistic?.name}
                stats={data?.firstSubjectStatistic?.change}
                precision={precision}
              />
              <StatisticCard
                label={'Максимум'}
                text={data?.lastSubjectStatistic?.name}
                stats={data?.lastSubjectStatistic?.change}
                precision={precision}
              />
            </Cards>
            <TableWrapper>
              <SimpleTable fields={fields(bySubjects, precision)} data={preparedData} />
            </TableWrapper>
          </>
        )}
      </ComponentContainer>
    );
  },
);

const ComponentContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const Label = styled.div`
  ${({ theme }) => font({ size: theme.fonts.sizes.sm, color: theme.colors.dark })}
`;
const Text = styled.div`
  ${font()};
`;
const Side = styled.div``;
const CardContent = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Cards = styled.div`
  display: flex;
  gap: 24px;

  > div {
    flex: 1 1 calc(50% - 24px);
  }
`;
const TableWrapper = styled.div``;

export default IndicatorSubjectOMSUStats;
