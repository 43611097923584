import styled from 'styled-components';
import Select from '../../../../../components/Select';
import SelectList from '../../../../../components/Calendar/SelectList';
import { FC, useCallback, useContext } from 'react';
import { NsiStoreContext } from '../../../../../stores/nsi';
import DateFilter from '../../../../../components/DateFilter';

interface Props {
  setType: (type) => void;
  type: {
    label: string;
  };
  filter: any;
  setFilter: any;
}

const BudgetExecutionModalHeader: FC<Props> = ({ setType, type, filter, setFilter }) => {

  const { BudgetExecutionContext } = useContext(NsiStoreContext);

  const getDateSelectLabel = useCallback(() => {
    return filter?.selectedMonth
      ? `${filter?.selectedMonth?.name} ${filter?.selectedMonth?.appliesToYear}`
      : 'Все года открытия';
  }, [filter]);

  return (
    <ComponentContainer>
      <Select
        renderList={({ active, onClose }) => (
          <SelectList active={active} onClose={onClose} list={BudgetExecutionContext} onClick={setType} />
        )}
        label={''}
        value={type}
        fieldFullsize={true}
      />
      <DateFilter
        label={getDateSelectLabel()}
        onChange={(d) => setFilter('selectedMonth', d.month)}
        value={{ selectedMonth: filter.selectedMonth }}
      />
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export default BudgetExecutionModalHeader;
