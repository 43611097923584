import React, { FC, useMemo } from 'react';
import { prepareRadialChartValues } from '../../utils/prepareChartValues';
import HighchartsReact from 'highcharts-react-official';
import HC_patternFill from 'highcharts-pattern-fill';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { prepareNumber } from '../../utils/prepareNumber';
HighchartsMore(Highcharts);
HC_patternFill(Highcharts);

interface ItemData {
  y: number;
  color?: string;
}

interface Item {
  name: string;
  data: ItemData[];
}

interface Props {
  data: Item[];
}

const RadialChart: FC<Props> = ({ data: dataProp }) => {
  const data = useMemo(() => {
    return prepareRadialChartValues(dataProp);
  }, [dataProp]);

  const chartOptions = useMemo(() => {
    return {
      chart: {
        type: 'column',
        inverted: true,
        polar: true,
        height: 140,
        width: 300,
        margin: 0,
      },
      title: {
        text: '',
      },
      tooltip: {
        enabled: false,
        formatter: function () {
          return `${this.point.options.name}: <b>${prepareNumber(this.point.options.count, 0)}</b>`;
        },
      },
      credits: {
        enabled: false,
      },
      pane: {
        size: '134%',
        innerSize: '35%',
        endAngle: 270,
        startAngle: 90,
        center: ['50%', '0'],
        // background: {
        //   backgroundColor: '#000',
        // },
      },
      xAxis: {
        // min: 0,
        visible: false,
      },
      yAxis: {
        // min: 0,
        visible: false,
      },
      defs: {
        patterns: [
          {
            id: 'custom-pattern64',
            path: {
              d: 'M 0 0 H 1 V 1 H 0 L 0 0',
              stroke: '#FFFFFF',
              fill: '#646CAA',
              strokeWidth: 2,
            },
            width: 5,
            height: 5,
          },
          {
            id: 'custom-pattern84',
            path: {
              d: 'M 0 0 H 1 V 1 H 0 L 0 0',
              stroke: '#FFFFFF',
              fill: '#848ABE',
              strokeWidth: 2,
            },
            width: 5,
            height: 5,
          },
          {
            id: 'custom-patternA3',
            path: {
              d: 'M 0 0 H 1 V 1 H 0 L 0 0',
              stroke: '#FFFFFF',
              fill: '#A3A9D4',
              strokeWidth: 2,
            },
            width: 5,
            height: 5,
          },
        ],
      },
      legend: { enabled: false },
      plotOptions: {
        column: {
          crop: false,
          overflow: 'none',
          stacking: 'normal',
          borderWidth: 1,
          pointPadding: 0,
          groupPadding: 0,
          // minPointLength: 3,
        },
        series: {
          pointStart: 0,
          // minPointLength: 3,
          colorByPoint: true,
          dataLabels: {
            align: 'center',
            // crop: false,
            y: 0,
            // overflow: 'none',
            enabled: true,
            allowOverlap: true,
            formatter: function () {
              return prepareNumber(this.point.options.count, 0);
            },
            style: {
              fontSize: 14,
              fontFamily: 'Montserrat Semibold',
              color: '#FFFFFF',
              stroke: '#172B75',
              strokeWidth: 4,
              strokeLinecap: 'round',
              paintOrder: 'stroke fill',
              // textShadow:
              //   '1px 0 0 #172B75, -1px 0 0 #172B75, 0 1px 0 #172B75, 0 -1px 0 #172B75, 1px 1px #172B75, -1px -1px 0 #172B75, 1px -1px 0 #172B75, -1px 1px 0 #172B75',
              letterSpacing: 2,
            },
          },
        },
      },
      series: data,
    };
  }, [data]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default RadialChart;
