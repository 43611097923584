import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Card from '../../../components/Card';
import { AppStoreContext } from '../../../stores/app';
import { getBudgetExpense } from '../../../actions';
import ComplexTable from '../../../components/ComplexTable';
import { Loader } from '../../../components/Loader';
import { get } from 'lodash';
import { prepareNumberForTable } from '../../../utils/prepareNumber';

interface Props {
  filter?: any;
}

const BudgetExpenses: FC<Props> = ({ filter }) => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    if (selectedOmsu) {
      try {
        setLoading(true);
        const params = {
          omsuId: selectedOmsu.id,
          periodId: filter.selectedYear?.id,
          levelBudget: filter.levelBudget?.value,
          territoriesComparison: filter.selectedOmsu,
        };
        const response = await getBudgetExpense(params);
        setData(response.data?.items || []);
      } catch (e) {
        //
      } finally {
        setLoading(false);
      }
    }
  }, [selectedOmsu, filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const tableFields = useMemo(() => {
    return [
      [
        {
          id: 0,
          label: '',
          field: '',
          width: '37%',
          sorting: false,
          colspan: 1,
          options: {
            'border-radius': '22px;',
          },
        },
        {
          id: 1,
          label: get(data, '[0].valuesByPeriod[0].period.name', ''),
          field: '',
          width: '40%',
          sorting: false,
          colspan: 3,
          options: {},
        },
        {
          id: 2,
          label: get(data, '[0].valuesByPeriod[1].period.name', ''),
          width: '15%',
          field: '',
          sorting: false,
          colspan: 3,
          options: {},
        },
        {
          id: 3,
          label: get(data, '[0].valuesByPeriod[2].period.name', ''),
          width: '15%',
          field: '',
          sorting: false,
          colspan: 3,
          options: {
            'border-radius': '0px 22px 0px 0px;',
          },
        },
      ],
      [
        {
          id: 4,
          label: '',
          field: 'territories.name',
          sorting: false,
          width: '37%',
          options: {},
        },
        {
          id: 5,
          label: 'Факт в КБС',
          field: 'valuesByPeriod.0.indicatorValue',
          sorting: false,
          width: '7%',
          options: {},
        },
        {
          id: 6,
          label: 'Темп %',
          field: 'valuesByPeriod.0.growthRate',
          sorting: false,
          width: '7%',
          options: {},
        },
        {
          id: 7,
          label: 'Место по темпу',
          field: 'valuesByPeriod.0.place',
          sorting: false,
          width: '7%',
          options: {},
        },
        {
          id: 8,
          label: 'Факт в КБС',
          field: 'valuesByPeriod.1.indicatorValue',
          sorting: false,
          width: '7%',
          options: {},
        },
        {
          id: 9,
          label: 'Темп %',
          field: 'valuesByPeriod.1.growthRate',
          sorting: false,
          width: '7%',
          options: {},
        },
        {
          id: 10,
          label: 'Место по темпу',
          field: 'valuesByPeriod.1.place',
          sorting: false,
          width: '7%',
          options: {},
        },
        {
          id: 11,
          label: 'Факт в КБС',
          field: 'valuesByPeriod.2.indicatorValue',
          sorting: false,
          width: '7%',
          options: {},
        },
        {
          id: 12,
          label: 'Темп %',
          field: 'valuesByPeriod.2.growthRate',
          sorting: false,
          width: '7%',
          options: {},
        },
        {
          id: 13,
          label: 'Место по темпу',
          field: 'valuesByPeriod.2.place',
          sorting: false,
          width: '7%',
          options: {},
        },
      ],
    ];
  }, [filter, data]);

  return (
    <Card maxHeight>
      {loading ? <Loader/> :
        <ComplexTable
          fields={tableFields}
          data={data.map((item) => ({
            ...item,
            options: {
              styles: {
                'valuesByPeriod.0.indicatorValue': {
                  color: get(item, 'valuesByPeriod[0].indicatorValue') < 0 ? '#EB5757' : '#000000',
                },
                'valuesByPeriod.0.growthRate': {
                  color: get(item, 'valuesByPeriod[0].growthRate') < 0 ? '#EB5757' : '#000000',
                },
                'valuesByPeriod.1.indicatorValue': {
                  color: get(item, 'valuesByPeriod[0].indicatorValue') < 0 ? '#EB5757' : '#000000',
                },
                'valuesByPeriod.1.growthRate': {
                  color: get(item, 'valuesByPeriod[0].growthRate') < 0 ? '#EB5757' : '#000000',
                },
                'valuesByPeriod.2.indicatorValue': {
                  color: get(item, 'valuesByPeriod[0].indicatorValue') < 0 ? '#EB5757' : '#000000',
                },
                'valuesByPeriod.2.growthRate': {
                  color: get(item, 'valuesByPeriod[0].growthRate') < 0 ? '#EB5757' : '#000000',
                },
              },
            },
            valuesByPeriod: [
              {
                ...item.valuesByPeriod[0],
                indicatorValue: get(item, 'valuesByPeriod[0].indicatorValue')
                  ? 
                    prepareNumberForTable(item.valuesByPeriod[0].indicatorValue)
                  : '-',
                growthRate: get(item, 'valuesByPeriod[0].growthRate')
                  ? 
                    prepareNumberForTable(item.valuesByPeriod[0].growthRate)
                  : '-',
              },
              {
                ...item.valuesByPeriod[1],
                indicatorValue: get(item, 'valuesByPeriod[1].indicatorValue')
                  ? 
                    prepareNumberForTable(item.valuesByPeriod[1].indicatorValue)
                  : '-',
                growthRate: get(item, 'valuesByPeriod[1].growthRate')
                  ? 
                    prepareNumberForTable(item.valuesByPeriod[1].growthRate)
                  : '-',
              },
              {
                ...item.valuesByPeriod[2],
                indicatorValue: get(item, 'valuesByPeriod[2].indicatorValue')
                  ? 
                    prepareNumberForTable(item.valuesByPeriod[0].indicatorValue)
                  : '-',
                growthRate: get(item, 'valuesByPeriod[2].growthRate')
                  ? 
                    prepareNumberForTable(item.valuesByPeriod[2].growthRate)
                  : '-',
              },
            ],
          }))}
          orderedRows
          loading={loading}
        />
      }
    </Card>
  );
};

export default BudgetExpenses;
