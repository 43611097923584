import { Modal } from '../../../components/Modal';
import { connectModal } from '../../../hocs/Modal';
import styled from 'styled-components';
import LabeledText from '../../../components/LabeledText';
import { FC, useEffect, useState } from 'react';
import { font } from '../../../components/styled/mixins/fonts';
import { getAgendaIndicatorDescriptionById } from '../../../actions';
import { Loader } from '../../../components/Loader';

interface Data {
  curator: string;
  frequency: string;
  information: string;
  lifeTime: string;
  name: string;
  responsibleOrganization: string;
}

const IndicatorInfoModal: FC = connectModal('indicator-info-modal')(({ handleHide, indicatorId }) => {

  const [indicatorInfoData, setIndicatorInfoData] = useState<Data>();
  const [indicatorInfoDataLoading, setIndicatorInfoDataLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      setIndicatorInfoDataLoading(true);
      try {
        const response = await getAgendaIndicatorDescriptionById({ indicatorId });
        setIndicatorInfoData(response.data);
      } catch (e) {
        //
      } finally {
        setIndicatorInfoDataLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <Modal title={'Информация'} onClose={handleHide}>
      {indicatorInfoDataLoading ? <LoaderWrapper><Loader/></LoaderWrapper> :
        <Content>
          <Info>
            {indicatorInfoData?.information}
          </Info>
          <ListWrapper>
            <Subtitle>{indicatorInfoData?.name}</Subtitle>
            <List>
              <LabeledText
                labelWidth={223}
                label={'Переодичность мониторинга'}
                text={indicatorInfoData?.frequency || '-'}
              />
              <LabeledText
                labelWidth={223}
                label={'Ответственный ЦИОГВ, ГО Московской области'}
                text={indicatorInfoData?.responsibleOrganization || '-'}
              />
              <LabeledText
                labelWidth={223}
                label={'Срок актуализации'}
                text={indicatorInfoData?.lifeTime || '-'}
              />
              <LabeledText
                labelWidth={223}
                label={'Курирующий заместитель руководителя ЦИОГВ'}
                text={indicatorInfoData?.curator || '-'}
              />
            </List>
          </ListWrapper>
        </Content>
      }
    </Modal>
  );
});

const LoaderWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const Info = styled.div`
  ${font()};
`;
const Subtitle = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xxmd,
      family: theme.fonts.montserrat.semibold,
      lineHeight: '34px',
    })};
  margin-bottom: 12px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0 24px 24px 24px;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const ListWrapper = styled.div``;

export default IndicatorInfoModal;
