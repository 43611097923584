export const initialBudgetFilterFilterValues = {
  selectedMonth: '',
};

export const initialFinanceAdditionFilterFilterValues = {
  selectedMonth: '',
};

export const initialBudgetCardFilterValues = {
  selectedMonth: '',
};

export const initialEconomicsFilterValues = {
  selectedMonth: '',
};
