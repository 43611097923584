import Card from '../../../components/Card';
import HorizontalList from '../../../components/HorizontalList';
import RatingCard from '../../../components/RatingCard';
import styled from 'styled-components';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { getExecutionMainEconomicIndicators, getIndicatorTableData } from '../../../actions';
import { AppStoreContext } from '../../../stores/app';
import { get, isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Loader } from '../../../components/Loader';
import { prepareNumber } from '../../../utils/prepareNumber';

const IndicatorsMain: FC = observer(() => {

  const { selectedOmsu, selectedYear, selectedCiogv } = useContext(AppStoreContext);

  const [indicatorsInfo, setIndicatorsInfo] = useState([]);
  const [infoLoading, setInfoLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setInfoLoading(true);
      try {
        const response = await getExecutionMainEconomicIndicators({
          omsuId: selectedOmsu.id,
          periodId: selectedYear.id,
          // ciogvId: selectedCiogv.id,
        });
        setIndicatorsInfo(response.data.items);
      } catch (e) {
        //
      } finally {
        setInfoLoading(false);
      }
    };
    fetchData();
  }, [selectedOmsu, selectedYear, selectedCiogv]);

  const indicatorConfig = useMemo(() => {

    let config = [];
    
    if(indicatorsInfo){
      config = indicatorsInfo.map(item => ({
        id: item?.id,
        iconURL: item?.iconURL,
        title: item?.name,
        subtitle: item?.periodDescription,
        rating: {
          place: item?.position || '-',
          placeDifference: item?.change || '',
          amount: isNumber(item?.factValue) ? prepareNumber(item?.factValue, item?.precision) : '',
          amountMeasure: item?.unit,
          list: get(item, 'topOMSU', []).map((item, idx) => ({
              id: idx,
              place: item?.position,
              label: item?.name,
          }))
        },
      }))
    }

    return config;
  }, [indicatorsInfo])

  return (
    <Card>
      <Content>
        {infoLoading ? <Loader/> :
          <HorizontalList list={indicatorConfig} mobileItemsInRow={2} maxItemsInRow={2}>
            {(item) => <RatingCard {...item} />}
          </HorizontalList>
        }
      </Content>
    </Card>
  );
});

const Content = styled.div`
  padding: 24px;
`;

export default IndicatorsMain;
