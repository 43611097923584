import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

export const formatDate = (date) => {
  return format(date, 'd MMMM yyyy', { locale: ru });
};

export const serverDate = (date) => {
  return format(date, 'yyyy-MM-dd', { locale: ru });
};
