export const initialDebtFilterValues = {
  selectedYear: '',
  selectedOmsu: '',
};

export const initialDebtSortingValues = {
  field: 'rub0',
  direction: 'DESC',
};

export const initialBudgetIncomeAdditionFilterValues = {
  selectedLevel: { value: 2, label: 'Консолидированный бюджет' },
  selectedYear: '',
  selectedOmsu: '',
};

export const initialExecutionBudgetFilterValues = {
  selectedMonth: '',
};

export const initialMunicipalDebtSortingValues = {
  field: 'rub0',
  direction: 'DESC',
};

export const initialBudgetIncomeAdditionModalFilterValues = {
  selectedPeriod: '',
};

export const initialBudgetExpensesAdditionModalFilterValues = {
  selectedPeriod: '',
};

