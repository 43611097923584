import styled, { css } from 'styled-components';
import { FC } from 'react';
import { ListItem } from '../index';
import { font } from '../../styled/mixins/fonts';

interface Props {
  last: boolean;
}

const Item: FC<ListItem & Props> = ({ data, label, place, last }) => {
  return (
    <ComponentContainer last={last}>
      <Place>{place}</Place>
      <Side>
        <Label>{label}</Label>
        <Data>{data}</Data>
      </Side>
    </ComponentContainer>
  );
};
const Side = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex: 1 1 auto;
  padding: 0 16px 0 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  min-height: 46px;
`;
const ComponentContainer = styled.div<{ last: boolean }>`
  display: flex;
  align-items: center;
  > div {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
  ${({ last }) =>
    last &&
    css`
      ${Side} {
        border-bottom: 0;
      }
    `};
`;
const Place = styled.div`
  flex: 0 0 auto;
  padding-left: 16px;
  ${({ theme }) =>
    font({ family: theme.fonts.montserrat.semibold, color: theme.colors.secondary })};
`;
const Label = styled.div`
  ${({ theme }) => font({ family: theme.fonts.robotoCondensed.regular })};
  text-overflow:ellipsis;
  overflow:hidden;
  // Addition lines for 2 line or multiline ellipsis
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;
const Data = styled.div`
  ${font()};
  flex: 0 0 auto;
`;

export default Item;
