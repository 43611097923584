export const initialExecutionBudgetModalFilterValues = {
    selectedMonth: '',
};

export const initialCashFilterValues = {
  expnsesType: {
    id: ''
  },
  ciogv: '',
  nationalProject: '',
  gp: '',
  fundingSource: ''
};

export const initialExecutionTypeFilterValues = {
  expnsesType: {
    id: ''
  },
  ciogv: '',
  nationalProject: '',
  gp: '',
  fundingSource: ''
};

export const initialGPFilterValues = {
  expnsesType: {
    id: ''
  },
  ciogv: '',
  nationalProject: '',
  gp: '',
  fundingSource: ''
};

export const initialNPFilterValues = {
  expnsesType: {
    id: ''
  },
  ciogv: '',
  nationalProject: '',
  gp: '',
  fundingSource: ''
};

export const initialPlanTypeFilterValues = {
  expnsesType: {
    id: ''
  },
  ciogv: '',
  nationalProject: '',
  gp: '',
  fundingSource: ''
};

export const initialMainTypeFilterValues = {
  expnsesType: {
    id: ''
  },
  ciogv: '',
  nationalProject: '',
  gp: '',
  fundingSource: ''
};

export const initialTypeFilterValues = {
  expnsesType: {
    id: ''
  },
  ciogv: '',
  nationalProject: '',
  gp: '',
  fundingSource: ''
};

export const initialMainExpensesFilterValues = {
  expnsesType: {
    id: ''
  },
  ciogv: '',
  nationalProject: '',
  gp: '',
  fundingSource: ''
};