import { FC } from 'react';
import styled from 'styled-components';
import File from './File';

export interface FileProps {
  id: number;
  name: string;
  extension: string;
  link: string;
}

interface Props {
  list: FileProps[];
}

const FilesList: FC<Props> = ({ list }) => {
  return (
    <ComponentContainer>
      {list.map((item) => (
        <File key={item.id} {...item} />
      ))}
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div``;

export default FilesList;
