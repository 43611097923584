import { createContext } from 'react';
import { makeAutoObservable } from 'mobx';

class Lists {
  constructor() {
    makeAutoObservable(this);
  }

  data = {};
  meta = {};
  fetch = {};

  setData = (name, payload) => {
    this.data[name] = payload;
  };

  setMeta = (name, payload) => {
    this.meta[name] = payload;
  };

  setFetch = (name, payload) => {
    this.fetch[name] = payload;
  };

  setFirstPage = (name) => {
    if (this.meta[name]) {
      this.meta[name].startIndex = 0;
    }
  };

  clearList = (name) => {
    delete this.data[name];
    delete this.meta[name];
    delete this.fetch[name];
  };

  fetchData = (name) => {
    if (this.fetch[name]) {
      this.fetch[name]({ offset: 0 });
    }
  };

  getTotal = (name) => {
    return this.meta[name]?.total || 0;
  };
}

export const ListsStore = new Lists();
export const ListsStoreContext = createContext(ListsStore);
