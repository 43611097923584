import { FC } from 'react';
import styled from 'styled-components';
import { font } from '../styled/mixins/fonts';

interface Props {
  data: string;
  definition?: string;
  title: string;
}

const StatsItem: FC<Props> = ({ title, data, definition }) => {
  return (
    <ComponentContainer>
      <Info>
        <Data>{data}</Data>
        <Definition>{definition}</Definition>
      </Info>
      <Title>{title}</Title>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div``;
const Title = styled.div`
  ${({ theme }) =>
    font({
      color: theme.colors.dark,
    })};
  text-align: center;
`;
const Definition = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.sm,
      family: theme.fonts.montserrat.semibold,
      color: theme.colors.secondary,
      lineHeight: '24px',
    })};
`;
const Data = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xxlg,
      family: theme.fonts.montserrat.semibold,
      color: theme.colors.secondary,
      lineHeight: theme.fonts.sizes.xxlg,
    })};
  margin-right: 4px;
`;
const Info = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export default StatsItem;
