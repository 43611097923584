import Card from '../../../components/Card';
import styled from 'styled-components';
import ColumnChart from '../../../components/ColumnChart';
import LineChart from '../../../components/LineChart';
import LineChartSummary from './LineChartSummary';
import { FC, useEffect, useMemo, useState } from 'react';
import { getBudgetDebt, getGrowthRateDebt } from '../../../actions';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import Grade from '../../../components/Grade';
import { Loader } from '../../../components/Loader';
import { observer } from 'mobx-react-lite';
import { initialDebtSortingValues } from '../consts';
import ComplexTable from '../../../components/ComplexTable';
import { prepareNumber } from '../../../utils/prepareNumber';

interface Props {
  filter: any;
}

enum GrowthRateDataTypes {
  LINE_CHART = 2,
  COLUMN_CHART = 1,
}

const BudgetDebt: FC<Props> = observer(({ filter }) => {
  const [debtInfo, setDebtInfo] = useState(null);
  const [growthRateDebtInfo, setGrowthRateDebtInfo] = useState(null);
  const [debtLoading, setDebtLoading] = useState(false);
  const [growthRateDebtLoading, setGrowthRateDebtLoading] = useState(false);

  const [sorting, setSorting] = useState(initialDebtSortingValues);

  useEffect(() => {
    if (filter?.selectedYear || filter?.selectedOmsu) {
      const fetchData = async () => {
        setDebtLoading(true);
        try {
          const response = await getBudgetDebt({
            periodId: filter.selectedYear?.id,
            territoriesComparison: filter?.selectedOmsu,
          });
          setDebtInfo(response.data.items);
        } catch (e) {
          //
        } finally {
          setDebtLoading(false);
        }
      };
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    if (filter?.selectedYear) {
      const fetchData = async () => {
        setGrowthRateDebtLoading(true);
        try {
          const response = await getGrowthRateDebt({
            periodId: filter.selectedYear?.id,
          });
          setGrowthRateDebtInfo(response.data.items);
        } catch (e) {
          //
        } finally {
          setGrowthRateDebtLoading(false);
        }
      };
      fetchData();
    }
  }, [filter.selectedYear]);

  const debtFields = useMemo(() => {
    if (debtInfo) {
      const fillData = (itm, idx) => [
        {
          label: `${format(new Date(itm?.period?.periodStart), 'dd.MM.yyyy', {
            locale: ru,
          })} млрд.руб.`,
          field: `rub${idx}`,
          sorting: true,
          width: '5%',
          handleField: (row) => <Grade grade={row[`rub${idx}`]} isPrice/>,
        },
        {
          label: `${format(new Date(itm?.period?.periodStart), 'dd.MM.yyyy', { locale: ru })} %`,
          field: `percent${idx}`,
          sorting: false,
          width: '5%',
          handleField: (row) => <Grade grade={row[`percent${idx}`]} isPrice/>,
        },
      ];

      return [
        [
          {
            label: '',
            field: 'title',
            sorting: false,
            width: '70%',
          },
          ...(debtInfo[0]?.valuesByPeriod?.map((itm, idx) => fillData(itm, idx)).flat() || []),
        ],
      ];
    }
    return [];
  }, [debtInfo]);

  const debtData = useMemo(() => {
    if (debtInfo) {
      const fillData = (itm) => {
        return {
          title: itm?.omsu?.name,
          ...itm?.valuesByPeriod.reduce((acc, item, index) => {
            return {
              ...acc,
              [`rub${index}`]: item?.indicatorValue,
              [`percent${index}`]: item?.growthRate,
            };
          }, {}),
        };
      };

      return debtInfo.map((itm) => fillData(itm));
    }
    return [];
  }, [debtInfo]);

  const chartConfigs = useMemo(() => {
    if (growthRateDebtInfo) {
      const dataYears = growthRateDebtInfo[0]?.valuesByPeriod
        .map((item) => item?.period?.appliesToYear)
        .reverse();
      const dataLine = growthRateDebtInfo
        .filter((item) => item.typeView === GrowthRateDataTypes.LINE_CHART)
        .map((itm) => {
          let name = itm.name;
          if (itm.id === 346) {
            name = 'Объем долга к налоговым и неналоговым доходам';
          } else if (itm.id === 347) {
            name =
              'Объем долга по ценным бумагам и коммерческим кредитам к налоговым и неналоговым доходам';
          }
          return {
            name,
            values: itm?.valuesByPeriod?.map((itm) => itm?.indicatorValue).reverse(),
          };
        });
      const dataColumn = growthRateDebtInfo.find(
        (item) => item.typeView === GrowthRateDataTypes.COLUMN_CHART,
      );

      const maxDataLevel = Math.max(
        ...dataLine.reduce((acc, item) => {
          return [...acc, ...item.values];
        }, []),
      );
      const maxChartLevel = Math.ceil(maxDataLevel / 10) * 10;

      const fillData = (itm, idx) => {
        const color = idx === 0 ? '#A3A9D4' : '#172B75';

        return {
          name: itm?.name,
          data: itm?.values,
          color: color,
          marker: {
            lineWidth: 2,
            lineColor: color,
            fillColor: 'white',
            symbol: 'circle',
          },
        };
      };

      return {
        lineChart: {
          xAxis: {
            categories: Array(dataYears.length).fill(''),
            gridLineWidth: 1,
            gridLineDashStyle: 'dash',
          },
          yAxis: {
            min: 0,
            max: maxChartLevel,
            title: null,
            labels: {
              style: {
                fontFamily: 'Roboto Condensed Regular',
                fontSize: 12,
                opacity: 0.3,
              },
            },
            gridLineWidth: 0,
          },
          series: [...dataLine.map((itm, idx) => fillData(itm, idx))],
          tooltip: {
            formatter: function() {
                return `<span style="color: ${this?.point?.color}; fill: ${this?.point?.color};">●</span> ${this?.series?.name}: <b>${prepareNumber(this?.y, 2)}</b>`;
            }
          },
        },
        lineChartSummary: dataLine,
        columnChart: {
          xAxis: {
            categories: [...dataYears],
            gridLineWidth: 1,
            gridLineDashStyle: 'dash',
            labels: {
              formatter: (item) =>
                item.value === new Date().getFullYear() ? `<b>${item.value}</b>` : item.value,
            },
          },
          yAxis: {
            visible: false,
          },
          legend: {
            enabled: false,
          },
          title: {
            text: '',
          },
          series: [
            {
              type: 'column',
              name: '',
              data: dataYears?.map((item) => {
                const data = dataColumn?.valuesByPeriod?.find(
                  (itm) => itm?.period?.appliesToYear === item,
                );
                const cuurrentYear = new Date().getFullYear();
                return {
                  y: data?.indicatorValue,
                  color: cuurrentYear === item ? '#172B75' : '#A3A9D4',
                };
              }),
            },
          ],
          credits: {
            enabled: false,
          },
        },
      };
    }
  }, [growthRateDebtInfo]);

  return (
    <Card>
      <Content>
        <ComplexTable
          fields={debtFields}
          data={debtData}
          sorting={sorting}
          setSortingCallback={setSorting}
          loading={debtLoading}
        />
        {growthRateDebtLoading ? (
          <Loader />
        ) : (
          <ChartsWrapper>
            <LineChart title={'Темп роста %'} config={chartConfigs?.lineChart} />
            <LineChartSummary data={chartConfigs?.lineChartSummary} />
            <ColumnChart config={chartConfigs?.columnChart} />
          </ChartsWrapper>
        )}
      </Content>
    </Card>
  );
});

const Content = styled.div`
  padding-top: 15px;
`;
const ChartsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0 24px;
  margin-top: 32px;
`;

export default BudgetDebt;
