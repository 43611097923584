import styled, { css } from 'styled-components';
import { FC } from 'react';
import { font } from '../styled/mixins/fonts';
import WaveryIcon from '../../assets/icons/WaveryIcon';
import { theme } from '../../styles/theme';
import Stats from '../Stats';
import { RatingType } from '../RatingRow';
import { prepareNumber } from '../../utils/prepareNumber';

interface Props {
  rating: number;
  progress?: number | string;
  title?: string;
  type: RatingTypes;
  size?: RatingType;
}

export enum RatingTypes {
  DEFAULT,
  SUCCESS,
  SECONDARY,
}

export const renderProgress = (progress, precision = 0) => {
  if (parseInt(progress) > 0) {
    return { color: theme.colors.status.done, text: `↑ ${prepareNumber(progress, precision)}` };
  } else if (parseInt(progress) < 0) {
    return { color: theme.colors.status.overdue, text: `↓︎ ${prepareNumber(progress * -1, precision)}` };
  } else {
    return { color: theme.colors.dark, text: `${prepareNumber(progress, precision)}` };
  }
};

const renderColor = (color) => {
  switch (color) {
    case RatingTypes.DEFAULT:
      return '#646CAA';
    case RatingTypes.SUCCESS:
      return theme.colors.status.done;
    case RatingTypes.SECONDARY:
      return theme.colors.secondary;
    default:
      return '#646CAA';
  }
};

const calculateSize = (size: RatingType) => {
  switch (size) {
    case RatingType.NORMAL:
      return '86px';
    case RatingType.SMALL:
      return '54px';
    case RatingType.TINY:
      return '32px';
  }
};

const calculateTextSize = (size: RatingType) => {
  switch (size) {
    case RatingType.NORMAL:
      return theme.fonts.sizes.xxxlg;
    case RatingType.SMALL:
      return theme.fonts.sizes.lg;
    case RatingType.TINY:
      return theme.fonts.sizes.xsm;
  }
};

const Rating: FC<Props> = ({
  rating = 0,
  progress,
  title,
  type = RatingTypes.SUCCESS,
  size = RatingType.NORMAL,
}) => {
  return (
    <ComponentContainer size={size}>
      <Content size={calculateSize(size)}>
        <IconWrapper>
          <WaveryIcon color={renderColor(type)} />
        </IconWrapper>
        <Text color={renderColor(type)} size={calculateTextSize(size)}>
          {rating}
        </Text>
      </Content>
      <Sub size={size}>
        {title && <Title>{title}</Title>}
        {progress && <Stats progress={progress} />}
      </Sub>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div<{ size: RatingType }>`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  ${({ size }) =>
    size === RatingType.NORMAL &&
    css`
      align-items: center;
      flex-direction: column;
    `};
  ${({ size }) =>
    size === RatingType.SMALL &&
    css`
      align-items: flex-start;
      > div {
        margin-right: 12px;
        &:last-child {
          margin-right: 0;
        }
      }
    `};
`;
const Text = styled.div<{ color: string; size: string }>`
  position: relative;
  z-index: 1;
  margin-bottom: 4px;
  ${({ theme, color, size }) =>
    font({
      color,
      size,
      family: theme.fonts.montserrat.bold,
    })};
`;
const IconWrapper = styled.div`
  position: absolute;
  z-index: 0;
  svg {
    width: 100%;
    height: 100%;
  }
`;
const Content = styled.div<{ size: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  flex: 1 1 auto;
`;
const Title = styled.div`
  ${({ theme }) => font({ size: theme.fonts.sizes.xmd, family: theme.fonts.montserrat.semibold })}
`;
const Sub = styled.div<{ size: RatingType }>`
  display: flex;
  ${({ size }) =>
    size === RatingType.NORMAL &&
    css`
      align-items: center;
      gap: 8px;
    `};
  ${({ size }) =>
    size === RatingType.SMALL &&
    css`
      flex-direction: column;
    `};
`;

export default Rating;
