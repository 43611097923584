import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Table } from '../../../../../components/Table';
import { font } from '../../../../../components/styled/mixins/fonts';
import BudgetModalFilters, { FilterTypes } from '../../../../../components/BudgetModalFilters';
import { getDinamBudgetExecutionTable } from '../../../../../actions';
import { useFilter } from '../../../../../hooks/useFilter';
import { initialExecutionTypeFilterValues } from '../consts';
import Text, { TextFamily } from '../../../../../components/Text';
import { theme } from '../../../../../styles/theme';
import { get, isNumber } from 'lodash';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Loader } from '../../../../../components/Loader';
import { prepareNumberForTable } from '../../../../../utils/prepareNumber';
import { AppStoreContext } from '../../../../../stores/app';
import { observer } from 'mobx-react-lite';

interface Props {
  filter: any;
}

const CONTEXT_CODE = '10002';

const ExecutionType: FC<Props> = observer(({ filter }) => {
  const { selectedOmsu } = useContext(AppStoreContext);

  const { filter: executionTypeFilter, setFilterCallback: setExecutionTypeFilterCallback } =
    useFilter({
      initialFilterValues: { ...initialExecutionTypeFilterValues },
      name: 'budgetExecutionTypeFilters',
    });

  const [tableInfo, setTableData] = useState(null);
  const [tableDataLoading, setTableDataLoading] = useState<boolean>(false);

  useEffect(() => {
    if (filter?.selectedMonth) {
      const fetchData = async () => {
        setTableDataLoading(true);
        try {
          const response = await getDinamBudgetExecutionTable({
            ExecutionContextCode: CONTEXT_CODE,
            omsuId: selectedOmsu?.id,
            periodId: filter?.selectedMonth?.id,
            ciogvId: executionTypeFilter[FilterTypes.CIOGV],
            nacProjectId: executionTypeFilter[FilterTypes.NATIONAL_PROJECT],
            gp: executionTypeFilter[FilterTypes.GP],
            fundingSource: executionTypeFilter[FilterTypes.FUNDING_SOURCE],
          });
          setTableData(response.data.items);
        } catch (e) {
          //
        } finally {
          setTableDataLoading(false);
        }
      };
      fetchData();
    }
  }, [filter, executionTypeFilter]);

  const tableConfig = useMemo(() => {
    let tableData = [];
    let tableFields = [];
    let tableFieldsCustom = [];

    if (tableInfo) {
      const periodLength = get(tableInfo, '[0].periodValues', []).length;

      tableFieldsCustom = [
        {
          label: '',
          width: '1%',
        },
        {
          label: '',
          width: '28%',
        },
        ...get(tableInfo, '[0].periodValues', []).map((itm, idx) => ({
          label:
            idx === 0
              ? format(new Date(itm?.period?.periodStart), 'На d MMMM yyyy', { locale: ru })
              : itm?.period?.name,
          colspan: 4,
          width: '24%',
        })),
      ];

      tableFields = [
        {
          label: '',
          field: 'code',
          sorting: false,
          width: '1%',
          options: {
            text: 'left',
            family: theme.fonts.montserrat.bold,
            levelStep: 10,
            firstLevelPadding: 0,
            secondLevelPadding: 0,
          },
          justifyContent: 'flex-start',
        },
        {
          label: 'Наименование',
          field: 'name',
          width: '28%',
          options: {
            text: 'left',
            family: theme.fonts.montserrat.regular,
          },
          justifyContent: 'flex-start',
        },
        ...get(tableInfo, '[0].periodValues', [])
          .map((itm, idx) => [
            {
              label: 'План млрд руб.',
              field: `plan${idx}`,
              width: '6%',
              justifyContent: 'flex-end',
            },
            {
              label: 'БО млрд руб.',
              field: `bo${idx}`,
              width: '6%',
              justifyContent: 'flex-end',
            },
            {
              label: 'Исполнение млрд руб.',
              field: `execution${idx}`,
              width: '6%',
              justifyContent: 'flex-end',
            },
            {
              label: '%',
              field: `percent${idx}`,
              width: '6%',
            },
          ])
          .flat(),
      ];

      tableData = tableInfo.map((item) => ({
        code: item?.index || ' ',
        name: item?.name || ' ',
        ...Array(periodLength)
          .fill(0)
          .reduce((acc, arrItem, idx) => {
            const itm = get(item, `periodValues[${idx}]`);
            return {
              ...acc,
              [`plan${idx}`]: isNumber(itm?.originalPlanValue)
                ? prepareNumberForTable(itm?.originalPlanValue)
                : '-',
              [`bo${idx}`]: isNumber(itm?.correctedPlanValue)
                ? prepareNumberForTable(itm?.correctedPlanValue)
                : '-',
              [`execution${idx}`]: isNumber(itm?.factValue)
                ? prepareNumberForTable(itm?.factValue)
                : '-',
              [`percent${idx}`]: (
                <Text
                  data={isNumber(itm?.performance) ? prepareNumberForTable(itm?.performance) : '-'}
                  color={theme.colors.dark}
                  font={TextFamily.ROBOTO_CONDENSED_REGULAR}
                />
              ),
            };
          }, {}),
      }));
    }

    return {
      tableData,
      tableFields,
      tableFieldsCustom,
    };
  }, [tableInfo]);

  return (
    <ComponentContainer>
      <Heading>
        <Title>ЦИОГВ</Title>
        <Addition>
          <BudgetModalFilters
            filter={executionTypeFilter}
            setFilter={setExecutionTypeFilterCallback}
            fundingSourceMultiple
          />
        </Addition>
      </Heading>
      {tableDataLoading ? (
        <Loader />
      ) : (
        <Table
          sticky={false}
          fields={tableConfig.tableFields}
          data={tableConfig.tableData}
          customTheadFields={tableConfig.tableFieldsCustom}
        />
      )}
    </ComponentContainer>
  );
});

const ComponentContainer = styled.div``;
const Title = styled.div`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xxmd,
      family: theme.fonts.montserrat.semibold,
      lineHeight: '34px',
    })};
`;
const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Addition = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default ExecutionType;
