import styled from 'styled-components';
import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode | ReactNode[];
}

const Row: FC<Props> = ({ children }) => {
  return <RowContainer>{children}</RowContainer>;
};

const RowContainer = styled.div`
  display: flex;
`;

export default Row;
