import styled from 'styled-components';
import { font } from '../../styled/mixins/fonts';
import React, { FC } from 'react';

interface Props {
  fields: any[];
  setSortingCallback?: (val: any) => void;
  sticky: boolean;
}

const SecondaryTHead: FC<Props> = ({ fields, sticky }) => {
  return (
    <THeadRow sticky={sticky}>
      {fields?.map((item, idx) => {
        return (
          <THeadCell
            width={item.width}
            key={`t-second-head-${idx}`}
            colSpan={item.colspan}
            options={item.options}
          >
            <CellWrapper options={item.options} justifyContent={item.justifyContent}>
              <THeadCellLabel>{item.label}</THeadCellLabel>
            </CellWrapper>
          </THeadCell>
        );
      })}
    </THeadRow>
  );
};

const THeadRow = styled.tr<{ sticky: boolean }>`
  position: ${({ sticky }) => (sticky ? 'sticky' : 'static')};
  top: ${() => 60 + 44}px;
  z-index: 1;
`;

const THeadCell = styled.th<{ width: string; options: any }>`
  width: ${({ width }) => width};
  height: 22px;
  font-weight: normal;
  text-align: ${({ options }) => options?.text || 'center'};
  vertical-align: bottom;
  z-index: 10;
  padding: 0 12px;
  ${({ theme }) =>
    font({
      family: theme.fonts.robotoCondensed.regular,
      color: theme.colors.dark,
      size: theme.fonts.sizes.sm,
    })};
  background-color: ${({ options }) => (options?.fill ? 'rgba(100, 108, 170, 0.08)' : '#fff')};
  border-left: ${({ options }) => (options?.first ? '1px solid rgba(100, 108, 170, 0.24)' : '0')};
  border-right: ${({ options }) => (options?.last ? '1px solid rgba(100, 108, 170, 0.24)' : '0')};
`;

const CellWrapper = styled.div<{ justifyContent: string; options: any }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
  text-align: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
`;

const THeadCellLabel = styled.div``;

export default SecondaryTHead;
