import { FC, useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import SelectField from '../SelectField';
import { Button } from '../Button';
import { monthsList, yearList } from '../../config/consts';

interface Props {
  handleSelect: (data: any) => void;
  handleClose: () => void;
  initialDate?: Date;
}

const YearAndMonthSelector: FC<Props> = ({ handleSelect, handleClose, initialDate }) => {
  const initial = useRef(initialDate || new Date());

  const [data, setData] = useState({
    month: monthsList[initial.current.getMonth()],
    year: yearList.find((el) => el.value === initial.current.getFullYear()),
  });

  const handleChange = useCallback(({ value, type }: { value: any; type: string }) => {
    setData((state) => ({ ...state, [type]: value }));
  }, []);

  const handleApply = useCallback(() => {
    handleSelect(data);
    handleClose();
  }, [data]);

  return (
    <ComponentContainer>
      <Content>
        <Field>
          <SelectField
            label={'Месяц'}
            value={data['month']}
            onChange={(el) => handleChange({ value: el, type: 'month' })}
            list={monthsList}
          />
        </Field>
        <Field>
          <SelectField
            label={'Год'}
            value={data['year']}
            onChange={(el) => handleChange({ value: el, type: 'year' })}
            list={yearList}
          />
        </Field>
      </Content>
      <Footer>
        <Button hollow onClick={handleClose}>
          Отмена
        </Button>
        <Button onClick={handleApply}>Применить</Button>
      </Footer>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  width: 280px;
`;
const Footer = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const Content = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const Field = styled.div``;

export default YearAndMonthSelector;
