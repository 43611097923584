import styled from 'styled-components';
import SummaryInfoAddition from '../../../../Objects/SummaryInfoAddition';
import { routes } from '../../../../../config/routes';
import { matchPath, useRouteMatch } from 'react-router-dom';
import { FC } from 'react';
import Addition from './Addition';

interface Props {
  setSelectedOmsu: (omsu: any) => void;
  selectedOmsu: any;
}

const RatingModalHeader: FC<Props> = ({ setSelectedOmsu, selectedOmsu }) => {
  const match = useRouteMatch();
  const route = Object.values(routes).find((el) => matchPath(el.path, match.path)) as any;

  return (
    <ComponentContainer>
      <Additional>
        <Addition
          OMSUId={route.OMSUKey}
          setSelectedOmsu={setSelectedOmsu}
          selectedOmsu={selectedOmsu}
        />
      </Additional>
    </ComponentContainer>
  );
};

const Additional = styled.div``;
const ComponentContainer = styled.div``;

export default RatingModalHeader;
