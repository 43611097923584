import Card from '../../../components/Card';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ColumnCellTypes, TableTypes } from '..';
import { getIndicatorTableData } from '../../../actions';
import { ConfigItem } from '../consts';
import { isNumber } from 'lodash';
import { theme } from '../../../styles/theme';
import { AppStoreContext } from '../../../stores/app';
import { observer } from 'mobx-react-lite';
import ComplexTable from '../../../components/ComplexTable';
import { getTableFieldsConfig } from '../utils';
import { ModalsStore } from '../../../stores/modal';
import { Loader } from '../../../components/Loader';

interface Props {
  indicatorId: number | string;
  tableType: TableTypes;
  filter: any;
}

const Section: FC<Props> = observer(({ indicatorId, tableType, filter }) => {
  const { selectedOmsu, selectedYear, selectedCiogv } = useContext(AppStoreContext);

  const [tableInfo, setTableInfo] = useState([]);
  const [tableInfoLoading, setTableInfoLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setTableInfoLoading(true);
      try {
        const response = await getIndicatorTableData({
          indicatorID: indicatorId,
          omsuId: selectedOmsu.id,
          workingYearId: selectedYear.id,
          ciogvId: selectedCiogv.id,
          filterCode: filter?.viewType?.code || filter?.viewType?.id,
        });
        setTableInfo(response.data.items);
      } catch (e) {
        //
      } finally {
        setTableInfoLoading(false);
      }
    };
    fetchData();
  }, [selectedOmsu, selectedYear, selectedCiogv, filter]);

  const getConfig = useCallback(getTableFieldsConfig, []);

  const onCellClick = useCallback((item, cellType: ColumnCellTypes) => {
    ModalsStore.showModal('indicators-modal', { group: item?.id, cellType, title: item?.title });
  }, []);

  const tableConfig = useMemo(() => {
    const tableFields: Array<ConfigItem> = getConfig(tableType);
    let tableData = [];

    const fillData = (item) => {
      return {
        title: item?.name || ' ',
        id: item.id,
        ...(tableFields
          ? tableFields
              .filter((fItem) => fItem.field !== 'title')
              .reduce(
                (acc, itm) => {
                  return {
                    ...acc,
                    [itm.field]: isNumber(item?.data[itm.field]) ? `${item?.data[itm.field]}` : '-',
                    options: {
                      styles: {
                        ...acc.options.styles,
                        ...(item?.data[itm.field] === 0
                          ? {
                              [itm.field]: {
                                color: theme.colors.placeholder,
                              },
                            }
                          : {
                              [itm.field]: {
                                ...itm.dataOptions,
                              },
                            }),
                      },
                    },
                  };
                },
                { options: { styles: {} } },
              )
          : {}),
        children: item?.children?.map((chItem) => fillData(chItem)),
      };
    };

    if (tableInfo) {
      tableData = tableInfo.map((itm) => fillData(itm));
    }

    return {
      tableFields: getTableFieldsConfig(tableType, onCellClick),
      tableData,
    };
  }, [tableInfo, indicatorId]);

  return (
    <Card>
      {tableInfoLoading ? <Loader/> :
        <ComplexTable
          fields={[[...tableConfig.tableFields]]}
          data={tableConfig.tableData}
          loading={tableInfoLoading}
        />
      }
    </Card>
  );
});

export default Section;
