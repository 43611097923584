import styled from 'styled-components';
import GASULogo from '../../assets/images/GASULogo';

const ProjectLogo = () => {
  return (
    <ComponentContainer>
      <GASULogo />
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div``;

export default ProjectLogo;
