import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';

class Indicator {
  constructor() {
    makeAutoObservable(this);
  }

  indicatorHeaderFilters = {
    periodType: undefined,
    period: undefined
  };

  clearIndicatorFilters = () => {
    this.indicatorHeaderFilters.period = undefined;
    this.indicatorHeaderFilters.periodType = undefined;
  }

  setIndicatorHeaderFilter = (name, filter) => {
    this.indicatorHeaderFilters[name] = filter;
  };

  getIndicatorHeaderFilter = (name) => {
    return this.indicatorHeaderFilters[name];
  };
}

export const IndicatorStore = new Indicator();
export const IndicatorStoreContext = createContext(IndicatorStore);
