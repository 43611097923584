import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import styled from 'styled-components';
import TextField from '../../../components/TextField';
import { DevelopmentStoreContext } from '../../../stores/development';
import { theme } from '../../../styles/theme';
import { declOfNum } from '../../../utils/declOfNum';

const DevelopmentIndustrialCollapsedContent = observer(() => {

  const { developmentCollapsedHeaderData: { industrial } } = useContext(DevelopmentStoreContext);
  
  return (
    <ComponentContainer>
      {industrial?.notFilled !== 0 &&
        <TextField
          label={'Не заполнено'}
          value={`${industrial?.notFilled} ${declOfNum(industrial?.notFilled, ['территория', 'территории', 'территорий'])}`}
          valueBold
          valueColor={theme.colors.status.overdue}
        />
      }
      {industrial?.filled !== 0 &&
        <TextField
          label={'Заполнено'}
          value={`${industrial?.filled} ${declOfNum(industrial?.filled, ['территория', 'территории', 'территорий'])}`}
          valueBold
          valueColor={theme.colors.status.done}
        /> 
      }
    </ComponentContainer>
  );
});

const ComponentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export default DevelopmentIndustrialCollapsedContent;
