import { Modal } from '../../../../components/Modal';
import { connectModal } from '../../../../hocs/Modal';
import styled from 'styled-components';
import { Table } from '../../../../components/Table';
import { useContext, useEffect, useMemo, useState } from 'react';
import { getBudgetIncomeCompare, getCalendarIncomeInfo } from '../../../../actions';
import Text, { TextFamily } from '../../../../components/Text';
import { theme } from '../../../../styles/theme';
import { Loader } from '../../../../components/Loader';
import { observer } from 'mobx-react-lite';
import { AppStoreContext } from '../../../../stores/app';
import { get, isEmpty, isNumber } from 'lodash';
import HorizontalLabeledText from '../../../../components/HorizontalLabeledText';
import Select from '../../../../components/Select';
import SelectList from '../../../../components/Calendar/SelectList';
import { initialBudgetIncomeAdditionModalFilterValues } from '../../consts';
import { useFilter } from '../../../../hooks/useFilter';
import { prepareNumberForTable } from '../../../../utils/prepareNumber';

const BudgetIncomeModal = connectModal('budget-income-modal')(observer(({ handleHide, filter }) => {

  const { selectedOmsu } = useContext(AppStoreContext);

  const { filter: incomeModalFilter, setFilterCallback: setIncomeModalFilterCallback } = useFilter({
    initialFilterValues: { ...initialBudgetIncomeAdditionModalFilterValues },
    name: 'incomeAdditionModalBudgetFilter',
  });

  const [data, setData] = useState(null);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [periodList, setPeriodList] = useState(null);
  const [periodListLoading, setPeriodListLoading] = useState<boolean>(true);
  const [isEmptyPeriods, setIsEmptyPeriods] = useState<boolean>(false);


  useEffect(() => {
    if(incomeModalFilter?.selectedPeriod && !periodListLoading){
      const fetchData = async () => {
        setDataLoading(true);
        try {
          const response = await getBudgetIncomeCompare({
            periodId: incomeModalFilter?.selectedPeriod?.periodId,
            territoriesComparison: filter?.selectedOmsu,
            omsuId: selectedOmsu?.id,
            levelBudget: filter?.selectedLevel?.value
          });
          setData(response.data.items);
        } catch (e) {
          //
        } finally {
          setDataLoading(false);
        }
      };
      fetchData();
    }
  }, [incomeModalFilter]);

  useEffect(() => {
    if(filter.selectedLevel && selectedOmsu){
      const fetchData = async () => {
        setPeriodListLoading(true);
        try {
          const response = await getCalendarIncomeInfo({
            omsuId: selectedOmsu?.id,
            levelBudget: filter?.selectedLevel?.value
          });
          const data = (response.data?.items || []).map((el) => ({
            ...el,
            id: el.id,
            value: el.periodId,
            label: el.fullName,
          }));

          setPeriodList(data);

          if (isEmpty(data)) {
            setIsEmptyPeriods(true)
            setDataLoading(false);
          } else {
            setIncomeModalFilterCallback("selectedPeriod", data[0])
          }
        } catch (e) {
          //
        } finally {
          setPeriodListLoading(false);
        }
      };
      fetchData();
    }
  }, [filter.selectedLevel, selectedOmsu]);
  
  const tableConfig = useMemo(() => {

    let customFields = [];
    let tableFields = [];
    let tableData = [];

    if(data){

      const periodLength = get(data, '[0].valuesByPeriod', []).length;

      customFields = [
        {
          label: '',
          width: '40%',
        },
        ...(data[0]?.valuesByPeriod.map(itm => ({
          label: itm.territory?.name,
          colspan: 2,
          width: '16%',
        })) || [])
      ]

      tableFields = [
        {
          label: 'Наименование',
          field: 'title',
          sorting: false,
          width: '40%',
          options: {
            text: 'left',
            levelStep: 10,
            firstLevelPadding: 0,
            secondLevelPadding: 0
          },
          justifyContent: 'flex-start'
        },
        ...(data[0]?.valuesByPeriod.map((itm, idx) => {
          return [
            {
              label: 'Факт в КБС млдр руб.',
              field: `${idx}Fact`,
              sorting: false,
              width: '6%',
            },
            {
              label: '%',
              field: `${idx}Percent`,
              sorting: false,
              width: '6%',
            },
          ]
        }).flat() || [])
      ]

      const fillData = (itm, idx, data) => {
        return {
          title: itm?.name,
          ...(Array(periodLength).fill(0).reduce((acc, arrItem, rIdx) => {
            const rItem = get(itm, `valuesByPeriod[${rIdx}]`);
            return {
              ...acc,
              [`${rIdx}Fact`]: isNumber(rItem?.indicatorValue) ? prepareNumberForTable(rItem?.indicatorValue) : '-',
              [`${rIdx}Percent`]: (
                <Text data={isNumber(rItem?.growthRate) ? prepareNumberForTable(rItem?.growthRate) : '-'} color={theme.colors.dark} font={TextFamily.ROBOTO_CONDENSED_REGULAR} />
              )
            }
          }, {})),
          children: data.filter(fItm => fItm.parentId === itm.id).map((mItm, mIdx) => fillData(mItm, mIdx, data))
        }
      }

      tableData = data.filter(itm => !itm?.parentId).map((itm, idx) => fillData(itm, idx, data))
    }

    return {
      customFields,
      tableFields,
      tableData
    }
  }, [data])

  const periodSelect = useMemo(() => {
    return (
      <Select
        renderList={({ active, onClose }) => (
          <SelectList
            list={periodList}
            onClick={(el) => setIncomeModalFilterCallback("selectedPeriod" ,el)}
            onClose={onClose}
            active={active}
          />
        )}
        label={'Дата'}
        value={incomeModalFilter?.selectedPeriod}
      />
    )
  }, [periodList, incomeModalFilter?.selectedPeriod])

  return (
    <Modal
      title={'Поступление налоговых и неналоговых доходов в бюджет субъектов РФ'}
      onClose={handleHide}
      width={'75%'}
    >
      <Content>
        {!isEmptyPeriods && <HorizontalLabeledText label={'За период:'} data={periodListLoading ? 'Загрузка' : periodSelect} />}
        {dataLoading ? <Loader/> :
          <Table
            sticky={false}
            fields={tableConfig?.tableFields}
            data={tableConfig?.tableData}
            customTheadFields={tableConfig?.customFields}
            hideGroupArrow
            groupOpenDefaultState={true}
          />
        }
      </Content>
    </Modal>
  );
}));

const Content = styled.div`
  padding: 0 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export default BudgetIncomeModal;
