import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import Accordion from '../../components/Accordion';
import { PageWrapper } from '../../components/styled/PageWrapper';
import ObjectsAddition from './ObjectsAddition';
import ObjectsList from './ObjectsList';
import SummaryInfo from './SummaryInfo';
import RisksAddition from './RisksAddition';
import Risks from './Risks';
import { initialFilterValues, initialRisksFilter, initialSortingValues } from './consts';
import SelectedObjectModal from './SelectedObjectModal';
import { observer } from 'mobx-react-lite';
import { ListsStoreContext } from '../../stores/lists';
import { api } from '../../config/api';
import { useFilter } from '../../hooks/useFilter';
import Card from '../../components/Card';
import TooltipModule from '../../modules/Tooltip';
import { AppStoreContext } from '../../stores/app';
import { prepareParams } from './utils';
import { getObjects } from '../../actions';
import { Loader } from '../../components/Loader';

const Objects: FC = observer(() => {
  const { selectedOmsu } = useContext(AppStoreContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { filter, sorting, setFilterCallback, setMassFilterCallback, setSortingCallback } =
    useFilter({
      initialFilterValues: { ...initialFilterValues },
      initialSortingValues: { ...initialSortingValues },
      name: 'objectFilters',
      sortingName: 'objectsSorting',
    });

  const fetchData = useCallback(async () => {
    if (selectedOmsu && filter.selectedMonth && filter.selectedYear) {
      try {
        setLoading(true);
        const params = prepareParams({ filter, selectedOmsu });
        const response = await getObjects(params);
        setData(response.data?.items || []);
      } catch (e) {
        //
      } finally {
        setLoading(false);
      }
    }
  }, [filter, selectedOmsu]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Accordion
      items={[
        {
          id: 2,
          header: {
            collapse: false,
            title: 'Объекты',
            addition: (
              <ObjectsAddition
                filter={filter}
                setFilter={setFilterCallback}
                setMassFilterCallback={setMassFilterCallback}
                data={data}
                loading={loading}
              />
            ),
          },
          content: loading ? (
            <Loader />
          ) : (
            <ObjectsList
              filter={filter}
              sorting={sorting}
              setSortingCallback={setSortingCallback}
              data={data}
            />
          ),
        },
      ]}
    />
  );
});

const RisksAndSummaryBlock: FC = observer(() => {
  const { getTotal } = useContext(ListsStoreContext);
  const { filter, setFilterCallback, setMassFilterCallback } = useFilter({
    initialFilterValues: { ...initialRisksFilter },
    name: 'risksFilters',
    dateFields: ['fromDate', 'toDate'],
  });

  const total = getTotal(api.getObjectsReester);

  return (
    <>
      <Accordion
        items={[
          {
            id: 0,
            header: {
              title: 'Сводная информация',
            },
            content: <SummaryInfo setMassFilterCallback={setMassFilterCallback} />,
          },
        ]}
      />
      <Accordion
        items={[
          {
            id: 1,
            header: {
              title: filter.isRisk ? 'Риски' : 'Предриски',
              amount: total,
              addition: (
                <RisksAddition
                  filter={filter}
                  setFilterCallback={setFilterCallback}
                  setMassFilterCallback={setMassFilterCallback}
                />
              ),
            },
            content: (
              <Card>
                <Risks filter={filter} />
              </Card>
            ),
          },
        ]}
      />
    </>
  );
});

const MainPage: FC = () => {
  return (
    <>
      <PageWrapper>
        <RisksAndSummaryBlock />
        <Objects />
        <SelectedObjectModal />
      </PageWrapper>
      <TooltipModule />
    </>
  );
};

export default MainPage;
