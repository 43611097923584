import styled from 'styled-components';
import { useEffect, useContext, useCallback, FC, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { isNumber } from 'lodash';
import { AppStoreContext } from '../../../../../../stores/app';
import Select from '../../../../../../components/Select';
import SearchList from '../../../../../../components/SearchList';

interface Props {
  OMSUId?: number;
  setSelectedOmsu?: (omsu: any) => void;
  selectedOmsu?: any;
}

const Addition: FC<Props> = observer(
  ({ OMSUId, setSelectedOmsu, selectedOmsu }) => {
    const {
      omsuList
    } = useContext(AppStoreContext);

    const [localOmsuList, setLocalOmsuList] = useState(omsuList);

    useEffect(() => { 
      if(omsuList){
        if(isNumber(OMSUId)){
          setLocalOmsuList(omsuList)
        }else{
          setLocalOmsuList(omsuList.filter(item => item.idx !== 0))
        }
      }
    }, [omsuList]);

    const onSelect = useCallback((item) => {
      setSelectedOmsu(item);
    }, []);

    return (
      <AdditionWrapper>
        <Select
          label={'По всем муниципальным образованиям'}
          value={selectedOmsu}
          position={'left'}
          renderList={({ active, onClose }) => (
            <SearchList list={localOmsuList} onClick={onSelect} active={active} onClose={onClose} />
          )}
          // isAnchorPopup={false}
        />
      </AdditionWrapper>
    );
  },
);

const AdditionWrapper = styled.div``;

export default Addition;
