import styled from 'styled-components';
import { FC, ReactNode } from 'react';
import { font } from '../styled/mixins/fonts';

export interface Item {
  id: number;
  title?: string;
  subtitle?: string;
  list: {
    id: number;
    data: {
      id: number;
      info: any;
    }[];
  }[];
}

interface Props {
  header: {
    id: number;
    title: string;
    options?: any;
  }[];
  config: Item[];
  children: (item, options, length) => ReactNode;
}

const OrderedListTable: FC<Props> = ({ children, config, header }) => {
  const combineOptions = (header) => {
    return header.reduce((prev, curr) => [...prev, curr.options], []);
  };

  let order = 1;

  const calculateLength = () => {
    return () => order++;
  };

  return (
    <ComponentContainer>
      <Header>
        {header.map(({ id, title, options }) => {
          return (
            <HeaderCell key={id} width={options?.width} options={options}>
              {title}
            </HeaderCell>
          );
        })}
      </Header>
      <Content>
        {config.map((item) => children(item, combineOptions(header), calculateLength()))}
      </Content>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div``;
const Header = styled.div`
  display: flex;
  gap: 24px;
  padding: 6px 0;
`;
const HeaderCell = styled.div<{ width: string; options: any }>`
  flex: 1 1 ${({ width }) => width};
  text-align: ${({ options }) => options.textAlign};
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.sm,
      family: theme.fonts.robotoCondensed.regular,
      color: theme.colors.dark,
      lineHeight: '16px',
    })};
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default OrderedListTable;
