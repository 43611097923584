import styled from 'styled-components';
import { FC, ReactNode } from 'react';
import Avatar from '../../../../components/Avatar';
import InfoField from '../../../../components/InfoField';
import Divider from '../../../../components/Divider';
import NoData from '../../../../components/NoData';

interface Props {
  username: string;
  avatar: string;
  contacts: {
    id: number;
    label: string;
    value: string;
    icon: ReactNode;
    type?: string;
  }[];
}

const UserInfo: FC<Props> = ({ username, avatar, contacts }) => {
  return (
    <UserInfoWrapper>
      <Info>
        <Avatar avatar={avatar} />
        <UserName>{username}</UserName>
      </Info>
      <Divider margin={'8px 0'} />
      <Contacts>
        {contacts ? (
          <>
            <Title>Контакты</Title>
            {contacts.map((contact) => (
              <InfoField key={contact.id} {...contact} />
            ))}
          </>
        ) : (
          <NoData description={'Нет данных'} />
        )}
      </Contacts>
    </UserInfoWrapper>
  );
};

const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 280px;
  padding: 8px 0;
`;
const Info = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px 0 16px;
`;
const UserName = styled.div`
  margin-left: 12px;
  font-size: 14px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.montserrat.medium};
`;
const Contacts = styled.div``;
const Title = styled.div`
  font-size: 10px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.montserrat.medium};
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.56);
  padding: 0 16px 8px 16px;
`;

export default UserInfo;
