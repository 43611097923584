import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import SelectList from '../../../components/Calendar/SelectList';
import Select from '../../../components/Select';
import { TextButton } from '../../../components/Button';
import { ModalsStore } from '../../../stores/modal';
import BudgetIncomeModal from './BudgetIncomeModal';
import { observer } from 'mobx-react-lite';
import { AppStoreContext } from '../../../stores/app';
import { NsiStoreContext } from '../../../stores/nsi';
import SelectMultiple from '../../../components/SelectMultiple';

interface Props {
  setFilterCallback: any;
  filter: any;
}

const BudgetIncomeAddition: FC<Props> = observer(({ filter, setFilterCallback }) => {

  const { selectedOmsu, omsuList } = useContext(AppStoreContext);
  const { levelBudget, years, Territories } = useContext(NsiStoreContext);
  const nsiStore = useContext(NsiStoreContext);
  const [listThree, setListThree] = useState([]);

  useEffect(() => {
    if(selectedOmsu?.idx === 0){
      setListThree(
        Territories.map((item) => ({
          ...item,
          checked: filter.selectedOmsu ? filter.selectedOmsu.split(',').includes(item.id) : false,
        })),
      );
    }else{
      setListThree(
        omsuList.map((item) => ({
          ...item,
          checked: filter.selectedOmsu ? filter.selectedOmsu.split(',').includes(item.id) : false,
        })),
      );
    }
  }, [Territories, omsuList]);

  useEffect(() => {
    if (Territories?.length && !filter.selectedOmsu && selectedOmsu?.idx === 0) {
      setFilterCallback('selectedOmsu',
        Territories?.filter(item => {
          switch (item.name) {
            case 'г. Москва':
            case 'г. Санкт-Петербург':
            case 'Ленинградская область': return true;
          
            default: return false;
          }
        })
        .map((item) => item.id)
        .join(',')
      )
    }
  }, [Territories])

  useEffect(() => {
    if (omsuList?.length && !filter.selectedOmsu && selectedOmsu?.idx !== 0) {
      setFilterCallback('selectedOmsu',
        omsuList?.filter(item => {
          switch (item.label) {
            case 'Балашиха':
            case 'Красногорск':
            case 'Одинцовский':
            case 'Подольск': return true;
          
            default: return false;
          }
        })
        .map((item) => item.id)
        .join(',')
      )
    }
  }, [omsuList])
  

  useEffect(() => {
    if (selectedOmsu) {
      nsiStore.fetchLevelBudget(selectedOmsu?.idx === 0 ? 2 : 3);
    }
  }, [selectedOmsu]);

  const onYearClick = useCallback((e: any) => {
    setFilterCallback('selectedYear', e);
  }, []);

  useEffect(() => {
    if (years?.length && !filter.selectedYear) {
      const currentYear =
        years.find((el) => el.appliesToYear === new Date().getFullYear()) || years[0];
      onYearClick(currentYear);
    }
  }, [years, filter.selectedYear]);

  const handleLocalListThree = ({ check, name }) => {
    setListThree((list) =>
      list.map((item) => (item.value === name ? { ...item, checked: check } : item)),
    );
  };

  const onListAccept = () => {
    const selectedElements = listThree
      .filter((item) => item.checked)
      .map((item) => item.id)
      .join(',');
    setFilterCallback('selectedOmsu', selectedElements);
  };

  return (
    <ComponentContainer>
      <Select
        renderList={({ active, onClose }) => (
          <SelectList
            active={active}
            onClose={onClose}
            list={levelBudget}
            onClick={(e: any) => {
              setFilterCallback('selectedLevel', e);
            }}
          />
        )}
        label={''}
        value={filter.selectedLevel}
        fieldFullsize={false}
      />
      <Select
        key={filter.selectedYear?.id}
        renderList={({ active, onClose }) => (
          <SelectList active={active} onClose={onClose} list={years} onClick={onYearClick} />
        )}
        label={'Все года открытия'}
        value={filter.selectedYear}
        fieldFullsize={false}
      />
      <Select
        renderList={({ active, onClose }) => (
          <SelectMultiple
            onClose={onClose}
            list={listThree}
            onClick={handleLocalListThree}
            onAccept={onListAccept}
          />
        )}
        fullHeight
        label={filter?.selectedOmsu.trim() ? `Сравнить - ${filter?.selectedOmsu.split(',')?.length}` : 'Сравнить'}
        value={''}
        fieldFullsize={false}
      />
      <TextButton onClick={() => ModalsStore.showModal('budget-income-modal', { filter })}>
        Подробнее...
      </TextButton>
      <BudgetIncomeModal />
    </ComponentContainer>
  );
});

const ComponentContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export default BudgetIncomeAddition;
