import styled from 'styled-components';
import { FC, useEffect, useState } from 'react';
import { Item } from '..';
import { font } from '../../styled/mixins/fonts';

const ListItem: FC<Item> = ({ data, iconURL }) => {
  const [svg, setSvg] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrored, setIsErrored] = useState(false);

  useEffect(() => {
    fetch(iconURL)
      .then((res) => res.text())
      .then(setSvg)
      .catch(setIsErrored)
      .then(() => setIsLoaded(true));
  }, [iconURL]);

  return (
    <ComponentContainer>
      <IconWrapper>{svg && <Icon dangerouslySetInnerHTML={{ __html: svg }} />}</IconWrapper>
      <Items>
        {data.map(({ amount, amountType, label, sublabel, id }) => (
          <RowItem key={id}>
            <Row>
              <Amount>{amount}</Amount>
              <Type>{amountType}</Type>
            </Row>
            <Label>{label}</Label>
            <Sub>{sublabel}</Sub>
          </RowItem>
        ))}
      </Items>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //width: 140px;
  flex: 1 1 33%;
  text-align: center;
`;
const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`;
const Icon = styled.div``;
const Amount = styled.div`
  ${({ theme }) =>
    font({
      family: theme.fonts.montserrat.semibold,
      size: theme.fonts.sizes.xxmd,
      lineHeight: '24px',
    })};
`;
const Type = styled.div`
  ${({ theme }) =>
    font({
      family: theme.fonts.montserrat.semibold,
      size: theme.fonts.sizes.sm,
    })};
`;
const Label = styled.div`
  ${({ theme }) =>
    font({
      color: theme.colors.dark,
    })};
`;
const Row = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 6px;
  > div {
    margin-right: 3px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
const Sub = styled.div`
  ${({ theme }) => font({ size: theme.fonts.sizes.sm, color: theme.colors.placeholder })};
`;
const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default ListItem;
