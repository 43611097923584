import AccordionItem from '../../../components/Accordion/AccordionItem';
import { useFilter } from '../../../hooks/useFilter';
import Section from '../Section';
import SectionAddition from '../SectionAddition';
import SectionCollapsedContent from '../SectionCollapsedContent';
import { v4 as uuidv4 } from 'uuid';
import { initialSectorValues } from '../consts';
import { FC, memo, useMemo } from 'react';

interface Props {
  indicator: any;
  idx: number;
  setInnerFilterCallbacks: ({
    id,
    callback,
    list,
  }: {
    id: string;
    callback: any;
    list: any;
  }) => void;
}

const DynamicSectionComponent: FC<Props> = memo(({ indicator, idx, setInnerFilterCallbacks }) => {
  const { filter, setFilterCallback } = useFilter({
    name: `indicator${indicator?.id}Filter`,
    initialFilterValues: { ...initialSectorValues },
  });

  const config = useMemo(() => {
    return {
      id: uuidv4(),
      header: {
        selector: `dynamic_table_${indicator.id}`,
        title: indicator?.subsectionName,
        addition: (
          <SectionAddition
            key={`indicator-section-addition-${idx}`}
            indicatorId={indicator?.id}
            tableType={indicator.viewVariantType}
            filter={filter}
            setFilter={setFilterCallback}
            setInnerFilterCallbacks={setInnerFilterCallbacks}
          />
        ),
        collapsedContent: (
          <SectionCollapsedContent
            key={`indicator-section-collapsed-${idx}`}
            indicatorId={indicator?.id}
            tableType={indicator.viewVariantType}
            values={indicator.values}
          />
        ),
        collapsedAdditionVisible: false,
      },
      content: (
        <Section
          key={`indicator-section-${idx}`}
          indicatorId={indicator?.id}
          tableType={indicator.viewVariantType}
          filter={filter}
        />
      ),
    };
  }, [indicator, filter, setFilterCallback]);

  return <AccordionItem {...config} />;
});

export default DynamicSectionComponent;
