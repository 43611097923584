import { FC } from 'react';
import File from './File';
import styled from 'styled-components';

export interface FileType {
  id: number;
  name: string;
  type: string;
  size: string;
  path: string;
}

interface Props {
  title: string;
  files: FileType[];
}

const Files: FC<Props> = ({ title, files }) => {
  return (
    <FilesWrapper>
      <Title>{title}</Title>
      <Content>
        {files.map((file) => (
          <File key={file.id} {...file} />
        ))}
      </Content>
    </FilesWrapper>
  );
};

const FilesWrapper = styled.div``;
const Title = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-family: ${({ theme }) => theme.fonts.montserrat.regular};
  color: rgba(0, 0, 0, 0.56);
  margin-bottom: 4px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export default Files;
