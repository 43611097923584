import styled, { css } from 'styled-components';
import { FC, PropsWithChildren, ReactElement, ReactNode, useMemo } from 'react';
import { font } from '../styled/mixins/fonts';
import NoData from '../NoData';

export enum HorizontalListVerticalPosition {
  TOP,
  CENTER,
  BOTTOM,
}

export interface Item {
  id: number;
  icon?: ReactElement;
  iconURL: string;
  data: {
    id: number;
    amount: number;
    amountType?: string;
    label: string;
    sublabel?: string;
  }[];
}

export interface ChartItem {
  id: number;
  title: string;
  data: any;
}

interface Props {
  title?: string;
  addition?: ReactElement;
  list: any[];
  autoItemWidth?: boolean;
  children: (item) => ReactElement;
  itemWidth?: string;
  maxItemsInRow?: number;
  mobileItemsInRow?: number;
  fullHeight?: boolean;
  verticalAlign?: HorizontalListVerticalPosition;
}

export type PropsWithChildrenAsFunction<P> = P & { children?: (item) => ReactElement };

const calcVerticalAlign = (align: HorizontalListVerticalPosition) => {
  switch (align) {
    case HorizontalListVerticalPosition.BOTTOM:
      return 'flex-end';
    case HorizontalListVerticalPosition.CENTER:
      return 'center';
    case HorizontalListVerticalPosition.TOP:
    default:
      return 'flex-start';
  }
};

const HorizontalList: FC<PropsWithChildrenAsFunction<Props>> = ({
  title,
  list,
  addition,
  children,
  autoItemWidth,
  itemWidth = '100%',
  maxItemsInRow,
  mobileItemsInRow,
  fullHeight = true,
  verticalAlign = HorizontalListVerticalPosition.TOP,
}) => {
  const listLength = useMemo(() => {
    let listL = 1;
    if (!autoItemWidth) {
      if (maxItemsInRow) {
        listL = list.length > maxItemsInRow ? maxItemsInRow : list.length;
      } else {
        listL = list.length;
      }
    }
    return listL;
  }, [autoItemWidth, list, maxItemsInRow]);

  return (
    <ComponentContainer>
      {(title || addition) && (
        <Header>
          {title && <Title>{title}</Title>}
          {addition && <Addition>{addition}</Addition>}
        </Header>
      )}
      {list?.length > 0 ? (
        <List
          verticalAlign={calcVerticalAlign(verticalAlign)}
          itemWidth={itemWidth}
          listLength={listLength}
          mobileLength={mobileItemsInRow}
          fullHeight={fullHeight}
        >
          {list.map((item) => (
            <ListItem key={item.id}>{children(item)}</ListItem>
          ))}
        </List>
      ) : (
        <NoData description={'Нет данных'} />
      )}
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.div`
  ${({ theme }) =>
    font({
      family: theme.fonts.montserrat.semibold,
      size: theme.fonts.sizes.xxmd,
      lineHeight: '34px',
    })};
`;
const List = styled.div<{
  listLength: number;
  itemWidth: string;
  verticalAlign: string;
  mobileLength: number;
  fullHeight: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  > div {
    display: flex;
    flex: ${({ listLength, itemWidth }) => `1 1 calc(${itemWidth} / ${listLength} - 24px)`};
    align-items: ${({ verticalAlign }) => verticalAlign};
    margin-right: 24px;
    margin-bottom: 24px;
    &:nth-child(${({ listLength }) => listLength}) {
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    @media all and (max-width: 1200px) {
      flex: ${({ mobileLength, itemWidth }) =>
        mobileLength ? `1 1 calc(${itemWidth} / ${mobileLength} - 24px)` : `1 1 calc(33% - 24px)`};
      &:nth-child(${({ mobileLength }) => mobileLength}) {
        margin-right: 0;
      }
    }
    > div {
      width: 100%;
      ${({ fullHeight }) =>
        fullHeight &&
        css`
          height: 100%;
        `};
    }
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
const Addition = styled.div`
  margin-left: 12px;
`;
const ListItem = styled.div``;

export default HorizontalList;
