import styled from 'styled-components';
import PieChart from '../../modules/PieChart';
import { FC } from 'react';
import { font } from '../styled/mixins/fonts';

interface Props {
  title: string;
  data: any;
  total?: number;
  onClick?: (that: any) => void;
  subtitle?: string;
  width?: number;
}

const Chart: FC<Props> = ({ title, onClick, data, total, subtitle = 'Всего', width }) => {
  return (
    <ComponentContainer>
      <Heading>{title}</Heading>
      <ChartWrapper>
        <PieChart data={data} total={total} onClick={onClick && onClick} width={width} />
      </ChartWrapper>
      <DividerWrapper></DividerWrapper>
      <Label>{subtitle}</Label>
    </ComponentContainer>
  );
};

const DividerWrapper = styled.div`
  width: 210px;
  height: 1px;
  background-color: #000;
`;
const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Label = styled.div`
  text-align: center;
  ${({ theme }) => font({ size: theme.fonts.sizes.xmd })};
  margin-bottom: 15px;
`;
const ChartWrapper = styled.div`
  flex: 1 1 33%;
  justify-content: center;
  & > div {
    display: flex;
    justify-content: center;
  }
`;
const Heading = styled.div<{ pointer?: boolean }>`
  ${({ theme }) =>
    font({
      size: theme.fonts.sizes.xxmd,
      family: theme.fonts.montserrat.semibold,
      lineHeight: '34px',
    })};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ pointer }) => pointer && 'pointer'};
`;

export default Chart;
