import { AuthGuard } from '../guards/Auth';
import MainLayout from '../layouts/MainLayout';
import ObjectsPage from '../pages/Objects';
import ObjectPage from '../pages/Object';
import IndicatorsPage from '../pages/Indicators';
import AgendaPage from '../pages/Agenda';
import DevelopmentPage from '../pages/Development';
import HomeIcon from '../assets/icons/HomeIcon';
import ObjectsIcon from '../assets/icons/ObjectsIcon';
import IndicatorsIcon from '../assets/icons/IndicatorsIcon';
import AgendaIcon from '../assets/icons/AgendaIcon';
import DevelopmentIcon from '../assets/icons/DevelopmentIcon';
import Playground from '../pages/Playground';
import BudgetIcon from '../assets/icons/BudgetIcon';
import BudgetPage from '../pages/Budget';
import MainPage from '../pages/Main';
import IndicatorPage from '../pages/Indicator';
import IndicatorHeaderAddition from '../pages/Indicator/IndicatorHeaderAddition';
import IndicatorsHeaderAddition from '../pages/Indicators/IndicatorsHeaderAddition';
import DevelopmentHeaderAddition from '../pages/Development/DevelopmentHeaderAddition';

export const indicatorPath = '/indicator';

export const routes = {
  main: {
    exact: true,
    path: `/`,
    component: MainPage,
    layout: MainLayout,
    guard: AuthGuard,
    title: 'Главная',
    icon: HomeIcon,
    withAdditional: true,
    OMSUKey: null,
  },
  objects: {
    exact: true,
    path: `/objects`,
    component: ObjectsPage,
    layout: MainLayout,
    guard: AuthGuard,
    title: 'Объекты',
    icon: ObjectsIcon,
    withAdditional: true,
    OMSUKey: 43,
  },
  object: {
    path: `/objects/object/:actionId/:objectId`,
    component: ObjectPage,
    layout: MainLayout,
    guard: AuthGuard,
    title: 'Информация об объекте',
    withBack: true,
  },
  indicators: {
    exact: true,
    path: `/indicators`,
    component: IndicatorsPage,
    layout: MainLayout,
    guard: AuthGuard,
    title: 'Показатели',
    icon: IndicatorsIcon,
    withAdditional: true,
    addition: <IndicatorsHeaderAddition />,
    OMSUKey: 4,
  },
  indicator: {
    // exact: true,
    path: `${indicatorPath}/:indicatorId`,
    component: IndicatorPage,
    layout: MainLayout,
    guard: AuthGuard,
    withBack: true,
    title: 'О показателе',
    withAdditional: false,
    addition: <IndicatorHeaderAddition />,
  },
  agenda: {
    exact: true,
    path: `/agenda`,
    component: AgendaPage,
    layout: MainLayout,
    guard: AuthGuard,
    title: 'Повестка',
    icon: AgendaIcon,
    withAdditional: true,
    OMSUKey: 3,
  },
  development: {
    exact: true,
    path: `/development`,
    component: DevelopmentPage,
    layout: MainLayout,
    guard: AuthGuard,
    title: 'Развитие',
    icon: DevelopmentIcon,
    OMSUKey: 5,
    withAdditional: true,
    addition: <DevelopmentHeaderAddition />,
  },
  playground: {
    exact: true,
    path: `/playground`,
    component: Playground,
    guard: AuthGuard,
  },
  budget: {
    exact: true,
    path: `/budget`,
    component: BudgetPage,
    layout: MainLayout,
    guard: AuthGuard,
    title: 'Бюджет',
    icon: BudgetIcon,
    // addition: <BudgetHeaderAddition />,
    OMSUKey: 6,
    withAdditional: true,
  },
};

export const menu = [
  routes.main,
  routes.objects,
  routes.agenda,
  routes.budget,
  routes.development,
  routes.indicators,
];
