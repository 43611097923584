import Card from '../../components/Card';
import { PageWrapper } from '../../components/styled/PageWrapper';
import ObjectInfo from './ObjectInfo';
import Accordion from '../../components/Accordion';
import { accordionConfig } from './accordionConfig';
import { FC, useContext, useEffect, useRef } from 'react';
import { ObjectStoreContext } from '../../stores/object';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Loader } from '../../components/Loader';
import FinanceModal from './ObjectInfo/FinanceModal';
import ReactTooltip from 'react-tooltip';

interface Props {
  match: any;
}

const ObjectPage: FC<Props> = observer(({ match }) => {
  const requestParams = match.params;

  const { fetchObject, ObjectData, loading } = useContext(ObjectStoreContext);

  useEffect(() => {
    fetchObject({ requestParams });
  }, [fetchObject]);

  const timer: any = useRef();

  useEffect(() => {
    timer.current = setInterval(() => {
      ReactTooltip.rebuild();
    }, 3000);
    return () => clearInterval(timer.current);
  }, []);

  if (!ObjectData || loading) return <Loader />;

  return (
    <>
      <PageWrapper>
        <CardWrapper>
          <Card>
            <ObjectInfo />
          </Card>
        </CardWrapper>
        <Accordion items={accordionConfig} />
        <FinanceModal />
      </PageWrapper>
      <ReactTooltip effect={'solid'} className={'b-tooltip'} />
    </>
  );
});

const CardWrapper = styled.div`
  margin-bottom: 32px;
`;

export default ObjectPage;
