import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import THead from './THead';
import TBody from './TBody';
import NoData from '../NoData';
import TBodyTotal from './TBodyTotal';
import SecondaryTHead from './SecondaryTHead';

interface Props {
  fields: any[];
  data: any[];
  emptyPlaceholder?: string | ReactNode;
  uniqKey?: string;
  onRowClick?: (el: any) => any;
  childKey?: string;
  sorting?: any;
  onSortingChange?: (el: any) => any;
  hideZero?: boolean;
  total?: any;
  customTheadFields?: any[];
  customTheadFieldsTop?: any[];
  setSortingCallback?: any;
  sticky?: boolean;
  dataTotal?: any;
  footer?: any[];
  footerTotal?: any;
  hideGroupArrow?: boolean;
  groupOpenDefaultState?: boolean;
}

// show-on-hover - если нужно показать элемент на ховер
const Table: FC<Props> = ({
  fields,
  data,
  sorting = {},
  setSortingCallback,
  onSortingChange = () => null,
  onRowClick = () => null,
  childKey = 'children',
  uniqKey = 'id',
  emptyPlaceholder = <NoData description={'Нет данных'} />,
  hideZero,
  total,
  customTheadFields,
  customTheadFieldsTop,
  sticky = true,
  dataTotal,
  footer,
  footerTotal,
  hideGroupArrow = false,
  groupOpenDefaultState = false
}) => {
  return (
    <Wrapper data-table={true}>
      {data.length ? (
        <TableContent>
          <TableWrapper>
            {/*<THead fields={customTheadFieldsTop} sorting={sorting} layer={1} sticky={sticky} />*/}
            {/*<THead fields={customTheadFields} sorting={sorting} layer={1} sticky={sticky} />*/}
            <SecondaryTHead sticky={sticky} fields={customTheadFieldsTop} />
            <SecondaryTHead sticky={sticky} fields={customTheadFields} />
            <THead
              fields={fields}
              sorting={sorting}
              setSortingCallback={setSortingCallback}
              layer={2}
              sticky={sticky}
            />
            <TBody
              sticky={sticky}
              total={total}
              uniqKey={uniqKey}
              fields={fields}
              data={data}
              onRowClick={onRowClick}
              childKey={childKey}
              hideZero={hideZero}
              sorting={sorting}
              hideGroupArrow={hideGroupArrow}
              groupOpenDefaultState={groupOpenDefaultState}
            />
            {dataTotal && <TBodyTotal fields={fields} data={dataTotal} />}
            {footer && (
              <TBody
                sticky={sticky}
                total={total}
                uniqKey={uniqKey}
                fields={fields}
                data={footer}
                onRowClick={onRowClick}
                childKey={childKey}
                hideZero={hideZero}
                sorting={sorting}
                hideGroupArrow={hideGroupArrow}
                groupOpenDefaultState={groupOpenDefaultState}
              />
            )}
            {footerTotal && <TBodyTotal fields={fields} data={footerTotal} />}
          </TableWrapper>
        </TableContent>
      ) : (
        <Empty>{emptyPlaceholder}</Empty>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  justify-content: space-between;
`;
const TableContent = styled.div`
  display: flex;
`;
const TableWrapper = styled.div`
  display: table;
  width: 100%;
  border-collapse: collapse;
`;
const Empty = styled.div`
  padding: 30px 30%;
`;

export { Table };
