import styled, { css } from 'styled-components';
import { FC, Fragment, useCallback, useState } from 'react';
import ListItem from './ListItem';
import { ListItem as List } from '../Select';
import ActionIcon from '../ActionIcon';
import CloseIcon from '../../assets/icons/CloseIcon';
import { font } from '../styled/mixins/fonts';
import SearchField from '../SearchField';
import { isMobile } from 'react-device-detect';
import ListItemCount from './ListItemCount';

interface Props {
  list: List[];
  onClick: (object) => void;
  active: any;
  onClose?: any;
  label?: string;
  withCount?: boolean;
  withTotalElement?: boolean;
}

const SearchList: FC<Props> = ({
  list,
  onClick,
  active,
  onClose,
  label,
  withCount = false,
  withTotalElement = false,
}) => {
  const [search, setSearch] = useState<string>('');

  const filterList = useCallback((search: string, list: List[]) => {
    return list
      .filter((item) => item.label.toLowerCase().indexOf(search) !== -1)
      .sort((a, b) => {
        const aIndex = a.label.toLowerCase().indexOf(search);
        const bIndex = b.label.toLowerCase().indexOf(search);
        return aIndex == bIndex ? 0 : aIndex > bIndex ? 1 : -1;
      });
  }, []);

  const onClickCallback = useCallback((item) => {
    onClick(item);
    onClose && onClose();
  }, []);

  return (
    <>
      {label && <Label>{label}</Label>}
      <SearchField value={search} onChange={setSearch} />
      <ListWrapper>
        {filterList(search.toLowerCase(), list).map((item, idx) => (
          <Fragment key={item.id}>
            {withCount ? (
              <ListItemCount
                active={item.value === active?.value}
                {...item}
                onClick={onClickCallback}
                isFirstElement={idx === 0}
                withTotalElement={withTotalElement}
              />
            ) : (
              <ListItem active={item.value === active?.value} {...item} onClick={onClickCallback} />
            )}
          </Fragment>
        ))}
      </ListWrapper>
    </>
  );
};

const Label = styled.div`
  margin-bottom: 4px;
  ${({ theme }) => font({ color: theme.colors.dark, size: theme.fonts.sizes.sm })}
`;

const ListWrapper = styled.div`
  padding: 8px 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: 220px;
`;

export default SearchList;
