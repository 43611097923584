import styled from 'styled-components';
import { FC } from 'react';
import { font } from '../../../styled/mixins/fonts';

interface Props {
  data: any;
  options: any;
  length: any;
}

const ListRow: FC<Props> = ({ data, options, length }) => {
  return (
    <ComponentContainer>
      {length && <Order>{length()}</Order>}
      <ItemWrapper>
        {data.map((item, idx) => (
          <Item key={item.id} options={options[idx]} first={idx === 0}>
            {item.info}
          </Item>
        ))}
      </ItemWrapper>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
`;
const Item = styled.div<{ options: any; first: boolean }>`
  width: ${({ options, first }) => (first ? `calc(${options.width} - 34px)` : options.width)};
  text-align: ${({ options }) => options.textAlign};
  ${({ options }) => font({ family: options.fontFamily })};
`;
const Order = styled.div`
  width: 20px;
  margin-left: 14px;
  ${({ theme }) => font({ family: theme.fonts.montserrat.semibold })};
`;
const ItemWrapper = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 8px 0;
`;

export default ListRow;
