import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
  title: string;
}

const Section: FC<Props> = ({ children, title }) => {
  return (
    <SectionWrapper>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div`
  margin-bottom: 32px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const Title = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: ${({ theme }) => theme.fonts.montserrat.semibold};
  margin-bottom: 12px;
`;
const Content = styled.div``;

export default Section;
