import { PageWrapper } from '../../components/styled/PageWrapper';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import IndicatorsSummary from './IndicatorsSummary';
import IndicatorsMain from './IndicatorsMain';
import { getIndicatorsTableList } from '../../actions';
import AccordionItem from '../../components/Accordion/AccordionItem';
import DynamicSectionComponent from './DynamicSectionComponent';
import { Loader } from '../../components/Loader';

export enum TableTypes {
  SIX_COLUMNS = 1,
  TRIPLE_COLUNMS_TYPE_1 = 2,
  TRIPLE_COLUNMS_TYPE_2 = 4,
  FIVE_COLUMNS = 3,
}

export enum ColumnCellTypes {
  ALL_INDICATOR_CNT = 1, //всего
  RISK_INDICATOR_CNT = 2, //риск
  ACHIEVED_INDICATOR_CNT = 3, //достигнуто
  ALL_INDICATOR_CNT_2 = 4, //всего //не используемый
  LEADER_INDICATOR_CNT = 5, //лидер
  OUTSIDER_INDICATOR_CNT = 6, //аутсайдер
  BREAK_THROUGH_INDICATOR_CNT = 7, //прорыв
  FAILURE_INDICATOR_CNT = 8, //провал
  PLAN_FIN = 9, //план
  PLAN_FIN_BY_FED_FIN = 10, //не используемый
  CONTRACT_FIN = 11, //контракт
  CONTRACT_FIN_BY_FED_FIN = 12, //не используемый
  FACT_FIN = 13, //факт
  FACT_FIN_BY_FED_FIN = 14, //не используемый
}

interface Indicator {
  id: number | string;
  order: number;
  subsectionName: string;
  viewVariantType: TableTypes;
}

const IndicatorsPage: FC = () => {
  const [tableList, setTableList] = useState<Array<Indicator>>([]);
  const [tableListLoading, setTableListLoading] = useState<boolean>(false);
  const [innerFilterCallbacks, setInnerFilterCallbacks] = useState({});

  const handleSetInnerFilterCallbacks = useCallback(({ id, callback, list }) => {
    setInnerFilterCallbacks((state) => ({ ...state, [id]: { callback, list } }));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setTableListLoading(true);
      try {
        const response = await getIndicatorsTableList({});
        setTableList(response.data.items);
      } catch (e) {
        //
      } finally {
        setTableListLoading(false);
      }
    };
    fetchData();
  }, []);

  const topIndicator = useMemo(() => {
    return {
      id: 0,
      header: {
        title: 'Сводная информация',
      },
      content: <IndicatorsSummary innerFilterCallbacks={innerFilterCallbacks} />,
    };
  }, [innerFilterCallbacks]);

  const bottomIndicator = useMemo(() => {
    return {
      id: 9,
      header: {
        title: 'Показатели СЭР',
        collapse: false,
      },
      content: <IndicatorsMain />,
    };
  }, []);

  const dynamicContent = useMemo(() => {
    if (tableListLoading) {
      return <Loader />;
    }
    return (
      <>
        {tableList?.map((item: any, idx) => (
          <DynamicSectionComponent
            key={`indicator-dynamic-section-${idx}`}
            indicator={item}
            idx={idx}
            setInnerFilterCallbacks={handleSetInnerFilterCallbacks}
          />
        ))}
      </>
    );
  }, [tableList, tableListLoading, handleSetInnerFilterCallbacks]);

  return (
    <PageWrapper>
      <>
        <AccordionItem {...topIndicator} />
        {dynamicContent}
        <AccordionItem {...bottomIndicator} />
      </>
    </PageWrapper>
  );
};

export default IndicatorsPage;
