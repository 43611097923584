const NationalProjectsOfRussiaIcon = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='32' height='32' rx='16' fill='#EB5757' fillOpacity='0.08' />
      <path
        d='M12.2967 10.1013L6.01785 23.7513C5.95035 23.8863 6.08536 24.0381 6.23724 23.9875L25.8818 16.6154C25.9494 16.5985 26 16.531 26 16.4466V10.1688C26 10.0675 25.9155 10 25.8312 10H12.4486C12.3811 10 12.3304 10.0338 12.2967 10.1013Z'
        fill='#EF3B4C'
      />
      <path
        d='M13.7857 12H13V15.9964H13.7857V14.3979H15.3571V15.9964H16.1429V12H15.3571V13.5986H13.7857V12Z'
        fill='white'
      />
      <path
        d='M16.9286 12V15.9964H17.7143V12.7993H19.2857V15.9964H20.0714V12H16.9286Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.8571 15.9964V12H24V14.3979H21.6429V15.9964H20.8571ZM21.6429 13.5986H23.2143V12.7993H21.6429V13.5986Z'
        fill='white'
      />
    </svg>
  );
};

export default NationalProjectsOfRussiaIcon;
