const SmallArrowIcon = () => {
  return (
    <svg
      width="6"
      height="4"
      viewBox="0 0 6 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5125 3.48125L0.956251 1.90625C0.73125 1.68125 0.675 1.425 0.787501 1.1375C0.900001 0.837499 1.125 0.6875 1.4625 0.6875H4.6125C4.925 0.6875 5.1375 0.837499 5.25 1.1375C5.375 1.425 5.325 1.68125 5.1 1.90625L3.54375 3.48125C3.46875 3.54375 3.3875 3.6 3.3 3.65C3.2125 3.6875 3.11875 3.70625 3.01875 3.70625C2.93125 3.70625 2.84375 3.6875 2.75625 3.65C2.66875 3.6 2.5875 3.54375 2.5125 3.48125Z"
        fill="#00135C"
      />
    </svg>
  );
};

export default SmallArrowIcon;
