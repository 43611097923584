import styled from 'styled-components';
import { FC } from 'react';
import Stats from '../../../../../../components/Stats';
import TextWithDefinition from '../../../../../../components/TextWithDefinition';
import { font } from '../../../../../../components/styled/mixins/fonts';
import { prepareNumber } from '../../../../../../utils/prepareNumber';

interface Props {
  period: string;
  dynamics: string;
  factText: string;
  factDefinition: string;
  precision?: number;
}

const TablePeriod: FC<Props> = ({ period, factDefinition, factText, dynamics, precision = 2 }) => {
  return (
    <ComponentContainer>
      <Row>
        <TextWithDefinition text={prepareNumber(factText, precision)} definition={factDefinition} />
        <Stats progress={dynamics} definition={'%'} precision={0} />
      </Row>
      <Period>{period}</Period>
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Row = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
`;
const Period = styled.div`
  ${({ theme }) =>
    font({
      family: theme.fonts.robotoCondensed.regular,
      size: theme.fonts.sizes.sm,
      color: theme.colors.dark,
    })};
`;

export default TablePeriod;
