import { FC } from 'react';

interface Props {
  color: string;
}

const WaveryIcon: FC<Props> = ({ color }) => (
  <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M34.3795 3.16892C37.3954 -0.141275 42.6046 -0.141274 45.6205 3.16892C47.6212 5.36482 50.7175 6.19448 53.5481 5.29311C57.815 3.93435 62.3263 6.53893 63.283 10.9136C63.9177 13.8156 66.1844 16.0823 69.0864 16.717C73.4611 17.6737 76.0657 22.185 74.7069 26.4519C73.8055 29.2825 74.6352 32.3788 76.8311 34.3795C80.1413 37.3954 80.1413 42.6046 76.8311 45.6205C74.6352 47.6212 73.8055 50.7175 74.7069 53.5481C76.0657 57.815 73.4611 62.3263 69.0864 63.283C66.1844 63.9177 63.9177 66.1844 63.283 69.0864C62.3263 73.4611 57.815 76.0657 53.5481 74.7069C50.7175 73.8055 47.6212 74.6352 45.6205 76.8311C42.6046 80.1413 37.3954 80.1413 34.3795 76.8311C32.3788 74.6352 29.2825 73.8055 26.4519 74.7069C22.185 76.0657 17.6737 73.4611 16.717 69.0864C16.0823 66.1844 13.8156 63.9177 10.9136 63.283C6.53893 62.3263 3.93435 57.815 5.29311 53.5481C6.19448 50.7175 5.36482 47.6212 3.16892 45.6205C-0.141275 42.6046 -0.141274 37.3954 3.16892 34.3795C5.36482 32.3788 6.19448 29.2825 5.29311 26.4519C3.93435 22.185 6.53892 17.6737 10.9136 16.717C13.8156 16.0823 16.0823 13.8156 16.717 10.9136C17.6737 6.53892 22.185 3.93435 26.4519 5.29311C29.2825 6.19448 32.3788 5.36482 34.3795 3.16892Z'
      fill={color}
      fillOpacity='0.12'
    />
    <path
      d='M34.7491 3.50567C37.5667 0.413144 42.4333 0.413145 45.2509 3.50567C47.3831 5.84597 50.683 6.73018 53.6998 5.76954C57.6861 4.50013 61.9007 6.93343 62.7946 11.0204C63.471 14.1133 65.8867 16.529 68.9796 17.2054C73.0666 18.0993 75.4999 22.3139 74.2305 26.3002C73.2698 29.317 74.154 32.6169 76.4943 34.7491C79.5869 37.5667 79.5869 42.4333 76.4943 45.2509C74.154 47.3831 73.2698 50.683 74.2305 53.6998C75.4999 57.6861 73.0666 61.9007 68.9796 62.7946C65.8867 63.471 63.471 65.8867 62.7946 68.9796C61.9007 73.0666 57.6861 75.4999 53.6998 74.2305C50.683 73.2698 47.3831 74.154 45.2509 76.4943C42.4333 79.5869 37.5667 79.5869 34.7491 76.4943C32.6169 74.154 29.317 73.2698 26.3002 74.2305C22.3139 75.4999 18.0993 73.0666 17.2054 68.9796C16.529 65.8867 14.1133 63.471 11.0204 62.7946C6.93343 61.9007 4.50013 57.6861 5.76954 53.6998C6.73018 50.683 5.84597 47.3831 3.50567 45.2509C0.413144 42.4333 0.413145 37.5667 3.50567 34.7491C5.84597 32.6169 6.73018 29.317 5.76954 26.3002C4.50013 22.3139 6.93343 18.0993 11.0204 17.2054C14.1133 16.529 16.529 14.1133 17.2054 11.0204C18.0993 6.93343 22.3139 4.50013 26.3002 5.76954C29.317 6.73018 32.6169 5.84597 34.7491 3.50567Z'
      stroke={color}
      strokeOpacity='0.12'
    />
  </svg>
);

export default WaveryIcon;
