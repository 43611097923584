import React, { FC } from 'react';
import YearAndMonthSelectorWithBackendData from '../YearAndMonthSelectorWithBackendData';
import Select from '../Select';

interface Props {
  label: string;
  onChange: (data: any) => void;
  value?: any;
}

const DateFilter: FC<Props> = ({ label, onChange, value }) => {
  return (
    <Select
      label={label}
      value={''}
      fullHeight
      renderList={({ onClose }) => (
        <>
          <YearAndMonthSelectorWithBackendData
            handleClose={() => {
              onClose();
            }}
            handleSelect={onChange}
            initialDate={value}
          />
        </>
      )}
    />
  );
};

export default DateFilter;
