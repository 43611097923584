import Card from '../../components/Card';
import { PageWrapper } from '../../components/styled/PageWrapper';
import Accordion, { Position } from '../../components/Accordion';
import IndicatorStats from './IndicatorStats';
import IndicatorInfoModal from './IndicatorInfoModal';
import Stats, { StatsSizes } from '../../components/Stats';
import styled from 'styled-components';
import ButtonFilters from '../../components/ButtonFilters';
import IndicatorChartStats from './IndicatorChartStats';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import IndicatorSubjectOMSUStats from './IndicatorSubjectOMSUStats';
import { useParams } from 'react-router-dom';
import { filters } from './consts';
import {
  getAgendaIndicatorById,
  getIndicatorOMSUStatistic,
  getIndicatorSubjectsStatistic,
} from '../../actions';
import { Loader } from '../../components/Loader';
import { IndicatorStoreContext } from '../../stores/indicator';
import { observer } from 'mobx-react-lite';
import { prepareNumber } from '../../utils/prepareNumber';

interface Params {
  indicatorId: string;
}

interface Indicator {
  id: string;
  indicatorDetails: {
    dynamics: string;
    fact: string;
    period: string;
    plan: string;
    progress: string;
    precision?: number;
    unit?: string;
  };
  name: string;
  responsibleOrganization: string;
}

const IndicatorPage: FC = observer(() => {
  const params = useParams<Params>();

  const { indicatorHeaderFilters } = useContext(IndicatorStoreContext);

  const [filter, setFilter] = useState(filters[0]);

  const [indicatorData, setIndicatorData] = useState<Indicator>();
  const [indicatorDataLoading, setIndicatorDataLoading] = useState<boolean>(true);

  useEffect(() => {
    if (indicatorHeaderFilters?.period !== undefined) {
      const fetchData = async () => {
        setIndicatorDataLoading(true);
        try {
          const response = await getAgendaIndicatorById({
            indicatorId: params?.indicatorId,
            periodId: indicatorHeaderFilters.period?.id,
          });
          setIndicatorData(response.data);
        } catch (e) {
          //
        } finally {
          setIndicatorDataLoading(false);
        }
      };
      fetchData();
    }
  }, [indicatorHeaderFilters.period]);

  const renderContent = (filter) => {
    switch (filter.value) {
      case 'period':
        return (
          <IndicatorChartStats
            indicatorId={params?.indicatorId}
            precision={indicatorData?.indicatorDetails?.precision || 0}
          />
        );
      case 'subject':
        return (
          <IndicatorSubjectOMSUStats
            indicatorId={params?.indicatorId}
            fetchMethod={getIndicatorSubjectsStatistic}
            bySubjects={true}
            precision={indicatorData?.indicatorDetails?.precision || 0}
          />
        );
      case 'omsu':
        return (
          <IndicatorSubjectOMSUStats
            indicatorId={params?.indicatorId}
            fetchMethod={getIndicatorOMSUStatistic}
            precision={indicatorData?.indicatorDetails?.precision || 0}
          />
        );
      default:
        return (
          <IndicatorChartStats
            indicatorId={params?.indicatorId}
            precision={indicatorData?.indicatorDetails?.precision || 0}
          />
        );
    }
  };

  const indicatorConfig = [
    {
      id: 0,
      header: {
        title: 'Статистика',
        addition: <ButtonFilters filters={filters} active={filter} onChange={setFilter} />,
        collapse: false,
        additionPosition: Position.LEFT,
      },
      content: <Card overflow>{renderContent(filter)}</Card>,
    },
  ];

  const stats = useMemo(() => {
    if (indicatorData) {
      return {
        period: indicatorData?.indicatorDetails?.period,
        list: [
          {
            id: 0,
            data: prepareNumber(
              indicatorData?.indicatorDetails?.plan,
              indicatorData?.indicatorDetails?.precision,
            ),
            definition: indicatorData?.indicatorDetails?.unit,
            title: 'План',
          },
          {
            id: 1,
            data: prepareNumber(indicatorData?.indicatorDetails?.progress, 0),
            definition: '%',
            title: 'Выполнено',
          },
          {
            id: 2,
            data: prepareNumber(
              indicatorData?.indicatorDetails?.fact,
              indicatorData?.indicatorDetails?.precision,
            ),
            definition: indicatorData?.indicatorDetails?.unit,
            title: 'Факт',
          },
          {
            id: 3,
            data: (
              <Stats
                progress={indicatorData?.indicatorDetails?.dynamics}
                definition={'%'}
                size={StatsSizes.BIG}
              />
            ),
            title: 'Динамика',
          },
        ],
      };
    }

    return {
      period: '',
      list: [],
    };
  }, [indicatorData]);

  return (
    <PageWrapper>
      <CardWrapper>
        <Card>
          {indicatorDataLoading ? (
            <Loader />
          ) : (
            <IndicatorStats
              title={indicatorData?.name}
              subtitle={indicatorData?.responsibleOrganization}
              stats={stats}
              indicatorId={params?.indicatorId}
            />
          )}
        </Card>
      </CardWrapper>
      <Accordion items={indicatorConfig} />
      <IndicatorInfoModal />
    </PageWrapper>
  );
});

const CardWrapper = styled.div`
  margin-bottom: 32px;
`;

export default IndicatorPage;
