import { Table } from '../../../../../components/Table';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { getGosDebtDetails } from '../../../../../actions';
import { get } from 'lodash';
import { theme } from '../../../../../styles/theme';
import IconButtonAction from '../../../../../components/IconButtonAction';
import InfoIcon from '../../../../../assets/icons/InfoIcon';
import Note from '../Note';
import Grade from '../../../../../components/Grade';
import Text, { TextFamily } from '../../../../../components/Text';
import { Loader } from '../../../../../components/Loader';
import { ViewType } from '..';
import { AppStoreContext } from '../../../../../stores/app';
import { observer } from 'mobx-react-lite';

interface Props {
  filter: any;
}

const DebtTable: FC<Props> = observer(({ filter }) => {
  const { selectedOmsu } = useContext(AppStoreContext);

  const [tableInfo, setTableInfo] = useState(null);
  const [tableDataLoading, setTableDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      if (filter?.selectedYear) {
        setTableDataLoading(true);
        try {
          const response = await getGosDebtDetails({
            omsuId: selectedOmsu.id,
            viewType: ViewType.DEBT,
            periodId: filter?.selectedYear?.id,
          });
          setTableInfo(response.data.items);
        } catch (e) {
          //
        } finally {
          setTableDataLoading(false);
        }
      }
    };
    fetchData();
  }, [filter]);

  const tableConfig = useMemo(() => {
    let tableData = [];
    let tableFields = [];
    let tableFieldsCustom = [];

    if (tableInfo) {
      const fillData = (itm, idx) => {
        return {
          title: itm?.name ? `${itm?.name}${itm?.unit ? `,${itm?.unit}` : ''}` : ' ',
          info: <IconButtonAction icon={<InfoIcon />} content={<Note text={itm.description} />} />,
          ...get(itm, 'periodValues', []).reduce((acc, item, idx) => {
            return {
              ...acc,
              [`period${item?.period?.appliesToYear}`]: item?.indicatorValue ? (
                <Grade grade={item?.indicatorValue} isPrice={true} />
              ) : (
                <Text
                  data={`${item?.executionValue}${itm?.unit}`}
                  color={
                    item?.executionTonality === -1
                      ? theme.colors.status.overdue
                      : item?.executionTonality === 1
                      ? theme.colors.status.done
                      : undefined
                  }
                  font={TextFamily.ROBOTO_CONDENSED_REGULAR}
                />
              ),
            };
          }, {}),
          children: itm?.children?.map((mItm, mIdx) => fillData(mItm, mIdx)),
        };
      };

      const getCustomFields = (periods) => {
        const result = [];
        const currentYear = new Date().getFullYear();

        periods.forEach((element, index, arr) => {
          if (element?.period?.appliesToYear === currentYear) {
            result.push(
              {
                label: 'Факт',
                colspan: index,
                width: '12%',
              },
              {
                label: 'План',
                colspan: arr.length - index,
                width: '18%',
              },
            );
          }
        });

        return result;
      };

      tableFieldsCustom = [
        {
          label: '',
          width: '70%',
          colspan: 2,
        },
        ...getCustomFields(get(tableInfo, '[0].periodValues', [])),
      ];

      tableFields = [
        {
          label: 'Наименование',
          field: 'title',
          sorting: false,
          width: '69%',
          options: {
            text: 'left',
            levelStep: 10,
            firstLevelPadding: 0,
            secondLevelPadding: 0,
            headLabelWidth: '100%',
          },
          justifyContent: 'flex-start',
        },
        {
          label: '',
          field: 'info',
          sorting: false,
          width: '1%',
        },
        ...get(tableInfo, '[0].periodValues', [])
          .map((itm, idx) => [
            {
              label: itm?.period?.name,
              field: `period${itm?.period?.appliesToYear}`,
              sorting: false,
              width: '6%',
            },
          ])
          .flat(),
      ];

      tableData = tableInfo.map((itm, idx) => fillData(itm, idx));
    }

    return {
      tableData,
      tableFields,
      tableFieldsCustom,
    };
  }, [tableInfo]);

  return (
    <>
      {tableDataLoading ? (
        <Loader />
      ) : (
        <Table
          fields={tableConfig.tableFields}
          data={tableConfig.tableData}
          customTheadFields={tableConfig.tableFieldsCustom}
          sticky={false}
        />
      )}
    </>
  );
});

export default DebtTable;
