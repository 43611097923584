import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  amount: number;
}

const Amount: FC<Props> = ({ amount }) => {
  return <AmountWrapper>{amount} шт.</AmountWrapper>;
};

const AmountWrapper = styled.div`
  color: rgba(0, 0, 0, 0.56);
  font-size: 14px;
  line-height: 22px;
  font-family: ${({ theme }) => theme.fonts.montserrat.medium};
`;

export default Amount;
